.text-field {
  width: 100%;
  border: none;
  border: solid 1px $outline;
  font-weight: 400;
  line-height: 1.375rem;
  margin-bottom: 3rem;
  font-size: 1rem;
  padding: 1rem;
  background: none;
  color: $text-white;
  border-radius: 0.5rem;
  box-sizing: border-box;
  height: 2.5rem;
  &:focus {
    outline: none;
    border-color: $accent;
    border-width: 2px;
  }

  @media (max-width: $mobile-width) {
    margin-bottom: 2rem;
  }

  &--large {
    height: 3.5rem;
    width: 350px;
  }
  &--full {
    width: 100%;
  }
  &--collapsed {
    margin-bottom: 1rem;
  }

  &--copy-hidden,
  &--copy-hidden:focus {
    width: 1px;
    height: 1px;
    border: none;
    background: transparent;
    &:focus {
      outline: none;
    }
    &::selection {
      background: none;
    }
  }

  &--boxed {
    border: 1px solid $silver;
    padding: 0.5rem;
  }
  &--outline-light {
    border-color: $outline;
  }
}

.field-label {
  line-height: 1.5rem;
  font-size: 1rem;
  color: $dove-gray;
  display: block;
  margin-bottom: 0.5rem;
}
textarea {
  border-radius: 8px;
  border: solid 1px $outline;
  padding: 0.8rem 1rem;
  color: $text-white;
  font-size: 1rem;
  width: 100%;
  box-sizing: border-box;
  background: none;
  &:focus {
    outline: none;
    border-color: $accent;
  }
}
.sample-email-field {
  height: 11rem;
}

.text-field-container {
  &.valid .text-field {
    border-bottom-color: $green;
  }
  &.invalid .text-field {
    border-bottom-color: $crayon-orange;
  }
}

.user-registration-password-container {
  position: relative;
  &.invalid .text-field {
    padding-right: 16rem;
  }
  &.invalid:before {
    content: "Password must be at least 10 characters";
    position: absolute;
    right: 0;
    font-size: 0.9rem;
    color: $crayon-orange;
    top: 15px;
  }
}

.form-controls {
  text-align: center;
  margin-top: 0rem;
  margin-bottom: 6rem;
}

.form-cancel {
  margin-left: 1rem;
}

.confirmation-control {
  // margin: 0 0.5rem;
}

.survey-link,
.survey-code {
  padding: 1rem 1.5rem;
  color: $text-dark;
  font-size: 1.5rem;
  font-weight: 500;
  width: 100%;
  box-sizing: border-box;
  border: solid 1px $background-panel-element;
  &:focus {
    outline: none;
  }
  .product-card & {
    font-size: 1rem;
    padding: 0.5rem 1rem;
    margin-bottom: 1rem;
    margin-right: 0;
  }
}

.error-message {
  @include header-4;
  text-align: center;
  margin-bottom: 2rem;
  color: $crayon-orange;
}

.success-message {
  @include header-2;
  color: $green;
  text-align: center;
}

.radio-label {
  display: flex;
  align-items: center;
  & + & {
    margin-top: 0.75rem;
  }
  input[type="radio"] {
    margin-right: 0.75rem;
    margin-left: 0;
    margin-top: 0;
    margin-bottom: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    appearance: none;
    min-width: 20px;
    min-height: 20px;

    &:not(:checked) {
      &:before {
        content: "";
        border-radius: 100%;
        display: block;
        border: solid 2px $outline;
        background: $background-panel;
        min-height: 16px;
        min-width: 16px;
        margin: 0;
      }
    }
    &:checked {
      &:before {
        content: "";
        position: absolute;
        display: block;
        min-height: 16px;
        min-width: 16px;
        border: solid 2px $accent;
        border-radius: 100%;
        background: $background-panel;
      }
      &:after {
        content: "";
        display: block;
        background: $accent;
        border-radius: 100%;
        min-height: 10px;
        min-width: 10px;
        border: none;
        z-index: 99;
      }
    }
  }
}

.checkbox-label {
  display: inline-block;
  vertical-align: middle;
  margin-left: 0.5rem;
}

.checkbox {
  width: 16px;
  height: 16px;
  display: block;
  border-radius: 3px;
  border: 1px solid $white;
  position: relative;
  &:before {
    position: absolute;
    opacity: 0;
    top: 2px;
    left: 2px;
    width: 12px;
    height: 12px;
    background: $white;
    transform-origin: center;
    transform: scale(0);
    transition: all 0.25s $ease-pop;
  }
  &:after {
    content: "";
    position: absolute;
    display: block;
    height: 0px;
    width: 0px;
    border: 2px solid $white;
    border-width: 0 0 2px 2px;
    top: 10px;
    left: 2px;
    opacity: 0;
    transform-origin: top left;
    transition: opacity 0.15s ease-out, height 0.15s ease-in-out 0.15s,
      width 0.15s ease-in-out;
    margin-top: -2px;
    transform: rotate(-45deg);
  }
}

.checkbox {
  display: inline-block;
  vertical-align: middle;
  border: 1px solid $ship-gray;
  &:before {
    background: $white;
  }
  &:after {
    border-color: $background-overlay;
  }
}

.input--checkbox {
  &:first-child {
    margin-right: 0.75rem;
  }
  input {
    height: 1px;
    width: 1px;
    opacity: 0;
    position: absolute;
  }
  input:checked + .checkbox {
    background: $accent;
    border-color: $accent;
    &:before {
      transform: scale(1);
      opacity: 1;
    }
    &:after {
      height: 4px;
      width: 9px;
      opacity: 1;
      transition: opacity 0.15s ease-out, height 0.15s ease-in-out,
        width 0.15s $ease-pop 0.15s;
    }
  }
}
