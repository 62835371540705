.report-summary-timeframe {
  @include header-2;
  color: $ship-gray;
  margin-bottom: 1rem;
}

.report-summary-count {
  color: $dove-gray;
  font-weight: 100;
  font-size: 1.5rem;
}
.report-summary-details {
  lost-flex-container: row;
  align-items: center;
}
.report-summary-analysis-container {
  @media (max-width: $mobile-width) {
    text-align: center;
    lost-column: 1/1;
    lost-offset: 0;
  }
}

.results-breakdown {
  padding-top: 0;
}

.report-summary-analysis {
  display: none;
  text-align: left;

  &.is-visible {
    display: block;
  }
}

.report-summary-analysis-collapse {
  padding-top: 1rem;
}

.show-report-summary-analysis {
  > * {
    display: inline-block;
    vertical-align: middle;
  }
}

.report-summary-chart {
  lost-column: 7/12;

  @media (max-width: $mobile-width) {
    display: none;
  }
}
.report-summary-metrics {
  lost-column: 5/12;
  .report-summary-chart {
    display: none;
    lost-column: 1/1;
    margin-bottom: 2rem;
  }

  @media (max-width: $mobile-width) {
    lost-column: 1/1;
    text-align: center;
    .report-summary-chart {
      display: block;
    }
  }
}

.report-summary-upgrade {
  lost-column: 1/1;
  text-align: center;
  padding: 2rem 0;

  .upgrade-cta-text {
    @include large-paragraph;
  }
}
