.disabled {
  a {
    cursor: default;
  }
  .survey-next,
  .survey-submit {
    opacity: 0.4;
  }
}

@mixin survey-control {
  color: $white;
  cursor: pointer;
  font-weight: 600;
  font-size: 1.2rem;
  & > * {
    display: inline-block;
    vertical-align: middle;
  }
  [class^="icon-"] {
    font-size: 2.5rem;
    margin: 0 1rem;

    @media (max-width: $mobile-width) {
      font-size: 4rem;
    }
  }

  @media (max-width: $mobile-width) {
    .survey-pager-label {
      display: none;
    }
  }
}
.report-rung-header {
  margin-bottom: 3rem;
  align-items: center;

  @media (max-width: $mobile-width) {
    lost-flex-container: column;
    margin-bottom: 2rem;
  }
}

.survey-pager-tab {
  background: $background-panel-element;
  height: 1rem;
  flex-grow: 1;
  &:last-child {
    border-radius: 0 99999px 99999px 0;
  }

  &.active {
    @extend .survey-pager-tab;
    background: $accent;
    margin-right: -1rem;
    position: relative;
    z-index: 999;
    border-radius: 99999px;
    &:last-of-type {
      margin-right: 0;
    }
  }
}
.survey-pager {
  display: flex;
  width: 100%;

  @for $i from 1 through 5 {
    $sentiment: nth($sentiments, $i);
    $sentiment-color: $accent;
    &--#{$sentiment} {
      .survey-pager-tab.active {
        background: $sentiment-color;
      }
    }
  }
}
.survey-previous {
  @include survey-control;
}
.survey-next {
  @include survey-control;
  position: relative;
}

.survey-controls {
  padding-top: 2rem;
  align-items: center;
  display: flex;
  justify-content: center;
}
.survey-previous-container {
  text-align: left;
  margin: 0 1rem;
  & + .survey-next-container {
    margin: 0 1rem;
    text-align: right;
    .survey-next-notice {
      right: 0;
      left: auto;
      &:after {
        right: 27px;
        left: auto;
      }
    }
  }
}

.survey-next-container {
  text-align: center;
  position: relative;
  display: flex;
  align-self: flex-end;
  justify-content: center;

  .survey-next-notice {
    position: absolute;
    display: none;
    bottom: calc(100% + 0.5rem);
    box-shadow: 0 0 16px 0 rgba(0, 0, 0, 0.25);
    right: 50%;
    transform: translateX(50%);
    text-align: center;
    width: 30rem;
    p {
      margin: 0;
    }
  }
  &.disabled:hover {
    cursor: default;
    .survey-next-notice {
      display: block;
      @media (max-width: $mobile-width) {
        display: none;
      }
    }
  }
}

.survey-page-index {
  flex-grow: 1;
  font-size: 2.5rem;
  font-weight: 800;
  color: $accent;

  @media (max-width: $mobile-width) {
    order: 2;
  }
}
.survey-page {
  display: none;
}
.survey-header {
  @extend .site-container;
  margin-bottom: 3rem;
}
.survey-page-details {
  display: flex;
  margin-bottom: 1rem;
  width: 100%;
  align-items: center;

  @media (max-width: $mobile-width) {
    flex-direction: column;
    align-items: flex-start;
  }
}
.survey-comments {
  text-align: center;
  font-size: 2.5rem;
  font-weight: 300;
  padding-top: 4.5rem;
  padding-bottom: 4.5rem;
}
.survey-comments-label {
  color: $dove-gray;
  display: block;
  lost-column: 1/1;
  font-weight: 300;
  font-size: 1.1rem;
  line-height: 1.7rem;
  margin-bottom: 2rem;
}
.survey-comments-text-container {
  lost-column: 1/1;
}

.survey-complete-headline {
  lost-column: 1/1;
  font-weight: 300;
  font-size: 1.5rem;
  line-height: 4rem;
}
.survey-comments-text {
  border: solid 1px $outline;
  resize: none;
  width: 40rem;
  max-width: 100%;
  height: 10rem;
  padding: 1rem;
  font-size: 1.1rem;
  box-sizing: border-box;
  &:focus {
    outline: none;
    border-color: $accent;
  }
}

.site-container {
  lost-center: $site-width;

  @media (max-width: $site-width) {
    padding-left: 1rem;
    padding-right: 1rem;
  }
}

.survey-header-container {
  color: $white;
}
.survey-header-container--service {
  background: $service-offering;
}
.survey-header-container--process {
  background: $process-offering;
}
.survey-email-capture {
  font-size: 0.9rem;
  text-align: left;
  margin-bottom: 2rem;
}
.survey-email-capture-buttons {
  display: flex;
}
.survey-success-buttons {
  display: flex;
  align-items: center;
  justify-content: center;
}
.survey-email-field {
  margin-bottom: 0;
}
.survey-question-comments {
  text-align: center;
  lost-column: 1/1;
}
.survey-continue-close {
  margin-top: 1rem;
}
.survey-question-comments-field {
  width: 30rem;
  height: 8rem;
}
.survey-submit-confirmation {
  @include header-2;
  lost-column: 1/1;
  text-align: center;
  color: $white;
  margin-bottom: 3rem;
}
