$icon-font-path: "https://s3.amazonaws.com/uxrings/fonts/icons";
@font-face {
  font-family: "icons";
  src: url("#{$icon-font-path}.eot?zf1ayp");
  src: url("#{$icon-font-path}.eot?zf1ayp#iefix") format("embedded-opentype"),
    url("#{$icon-font-path}.ttf?zf1ayp") format("truetype"),
    url("#{$icon-font-path}.woff?zf1ayp") format("woff"),
    url("#{$icon-font-path}.svg?zf1ayp#icomoon") format("svg");
  font-weight: normal;
  font-style: normal;
}

[class^="icon-"],
[class*=" icon-"],
[class^="fa-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: "icons" !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  vertical-align: middle;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-trash:before {
  content: "\e910";
}
.icon-up-arrow:before {
  content: "\e90c";
}
.icon-uxrings-icon:before {
  content: "\e90d";
}
.icon-uxrings-logo:before {
  content: "\e90a";
}
.icon-comfortable:before {
  content: "\e905";
}
.icon-delightful:before {
  content: "\e906";
}
.icon-functional:before {
  content: "\e907";
}
.icon-meaningful:before {
  content: "\e908";
}
.icon-usable:before {
  content: "\e909";
}
.icon-previous:before {
  content: "\e903";
}
.icon-next:before,
.fs-chevron-left:before {
  content: "\e904";
}
.icon-arrow:before {
  content: "\e901";
}
.icon-design-dot-org-logo:before {
  content: "\e902";
}

.icon-back-arrow {
  fill: $white;
  height: 1.3rem;
  width: 1.3rem;
}

.icon-pencil {
  height: 1.1rem;
  width: 1.1rem;
  fill: $white;
}

.icon-plus {
  height: 4.5rem;
  width: 4.5rem;
  margin-bottom: 1rem;
}

.icon-hand {
  fill: $sea-green;
  height: 3rem;
  width: 3rem;
  margin-left: 2.5rem;
}

.icon-question {
  fill: $athens-gray;
  height: 1.1rem;
  width: 1.1rem;
  margin-right: 0.2rem;
}

.icon-drawbackwards-logo {
  fill: $athens-gray;
  height: 1.2rem;
  width: 11rem;
}

.delete {
  color: $french-gray;
  &:hover {
    color: $crayon-orange;
  }
}

.icon-arrow-upward-white,
.icon-arrow-upward-orange {
  display: inline-block;
  vertical-align: -1px;
  height: 0.8rem;
  width: 0.8rem;
  margin: 0 0.25rem;
  fill: white;
}

.icon-upgrade-downgrade {
  height: 68px;
  width: 35px;
  display: inline-block;
  line-height: 1em;
  vertical-align: middle;
  margin-right: 0.75rem;
}

.icon-close {
  height: 16px;
  width: 16px;
  display: inline-block;
  stroke: $french-gray;
}

.icon-smiley-face {
  height: 120px;
  width: 120px;
  display: inline-block;
  fill: $rangoon-green;
  margin: 2rem auto;
}

.icon-angry-emoji {
  height: 256px;
  width: 256px;
}
.icon-4 {
  height: 186px;
  width: 146px;
}
.icon-emoji-shadow {
  height: 11px;
  width: 112px;
}

.icon-search {
  height: 1rem;
  width: 1rem;
}
