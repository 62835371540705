@charset "UTF-8";
/*
 * Fix
 * Version 0.1.1
 * https://github.com/jaydenseric/Fix
*/
html {
  -ms-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -webkit-tap-highlight-color: transparent;
}

body {
  margin: 0;
  line-height: 1;
  font-family: sans-serif;
}

iframe {
  border: 0;
}

main {
  display: block;
}

ul,
ol {
  margin-top: 0;
  margin-bottom: 0;
  padding-left: 0;
}

li {
  display: block;
}

dl {
  margin-top: 0;
  margin-bottom: 0;
}

dd {
  margin-left: 0;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin-top: 0;
  margin-bottom: 0;
  font-size: inherit;
}

blockquote {
  margin: 0;
  padding: 0;
}

p {
  margin-top: 0;
  margin-bottom: 0;
}

sup {
  position: relative;
  top: -0.5em;
  vertical-align: baseline;
  font-size: 75%;
  line-height: 0;
}

strong {
  font-weight: bold;
}

figure {
  margin: 0;
}

img {
  border: 0;
  max-width: 100%;
  height: auto;
  vertical-align: middle;
}

a {
  text-decoration: none;
  color: inherit;
}

button {
  border: 0;
  margin: 0;
  padding: 0;
  text-align: inherit;
  text-transform: inherit;
  font: inherit;
  -webkit-font-smoothing: inherit;
  letter-spacing: inherit;
  background: none;
  cursor: pointer;
  overflow: visible;
}

::-moz-focus-inner {
  border: 0;
  padding: 0;
}

@keyframes fadein {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@lost flexbox flex;
html {
  height: 100%;
  background: #161616;
}

body {
  min-height: 100%;
  display: flex;
  overflow: auto;
}

.site-wrapper {
  flex: 1;
  display: flex;
  flex-direction: column;
  background: #161616;
  align-items: stretch;
  min-height: 100vh;
}
.rings.settings .site-wrapper, .users.update .site-wrapper {
  background: #f9f9fb;
}

.site-content {
  flex-grow: 1;
}
.site-content--center {
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.site-content--collapse {
  flex-grow: 0;
}

.thin-container {
  max-width: 750px;
  margin-left: auto;
  margin-right: auto;
}

@font-face {
  font-family: "icons";
  src: url("https://s3.amazonaws.com/uxrings/fonts/icons.eot?zf1ayp");
  src: url("https://s3.amazonaws.com/uxrings/fonts/icons.eot?zf1ayp#iefix") format("embedded-opentype"), url("https://s3.amazonaws.com/uxrings/fonts/icons.ttf?zf1ayp") format("truetype"), url("https://s3.amazonaws.com/uxrings/fonts/icons.woff?zf1ayp") format("woff"), url("https://s3.amazonaws.com/uxrings/fonts/icons.svg?zf1ayp#icomoon") format("svg");
  font-weight: normal;
  font-style: normal;
}
[class^=icon-],
[class*=" icon-"],
[class^=fa-] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: "icons" !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  vertical-align: middle;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-trash:before {
  content: "\e910";
}

.icon-up-arrow:before {
  content: "\e90c";
}

.icon-uxrings-icon:before {
  content: "\e90d";
}

.icon-uxrings-logo:before {
  content: "\e90a";
}

.icon-comfortable:before {
  content: "\e905";
}

.icon-delightful:before {
  content: "\e906";
}

.icon-functional:before {
  content: "\e907";
}

.icon-meaningful:before {
  content: "\e908";
}

.icon-usable:before {
  content: "\e909";
}

.icon-previous:before {
  content: "\e903";
}

.icon-next:before,
.fs-chevron-left:before {
  content: "\e904";
}

.icon-arrow:before {
  content: "\e901";
}

.icon-design-dot-org-logo:before {
  content: "\e902";
}

.icon-back-arrow {
  fill: #ffffff;
  height: 1.3rem;
  width: 1.3rem;
}

.icon-pencil {
  height: 1.1rem;
  width: 1.1rem;
  fill: #ffffff;
}

.icon-plus {
  height: 4.5rem;
  width: 4.5rem;
  margin-bottom: 1rem;
}

.icon-hand {
  fill: #23aea1;
  height: 3rem;
  width: 3rem;
  margin-left: 2.5rem;
}

.icon-question {
  fill: #efeff3;
  height: 1.1rem;
  width: 1.1rem;
  margin-right: 0.2rem;
}

.icon-drawbackwards-logo {
  fill: #efeff3;
  height: 1.2rem;
  width: 11rem;
}

.delete {
  color: #737373;
}
.delete:hover {
  color: #fc662b;
}

.icon-arrow-upward-white,
.icon-arrow-upward-orange {
  display: inline-block;
  vertical-align: -1px;
  height: 0.8rem;
  width: 0.8rem;
  margin: 0 0.25rem;
  fill: white;
}

.icon-upgrade-downgrade {
  height: 68px;
  width: 35px;
  display: inline-block;
  line-height: 1em;
  vertical-align: middle;
  margin-right: 0.75rem;
}

.icon-close {
  height: 16px;
  width: 16px;
  display: inline-block;
  stroke: #737373;
}

.icon-smiley-face {
  height: 120px;
  width: 120px;
  display: inline-block;
  fill: #1a1919;
  margin: 2rem auto;
}

.icon-angry-emoji {
  height: 256px;
  width: 256px;
}

.icon-4 {
  height: 186px;
  width: 146px;
}

.icon-emoji-shadow {
  height: 11px;
  width: 112px;
}

.icon-search {
  height: 1rem;
  width: 1rem;
}

.button-full {
  width: 100%;
}

.button-primary, .survey-link-button, .button-primary-small, .product-card .survey-link-button {
  padding: 0 3rem;
  height: 3.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1rem;
  font-weight: 600;
  -webkit-appearance: none;
  border-radius: 8px;
  color: #272727;
  min-width: 140px;
  box-sizing: border-box;
  border: none;
  background: #6ac89b;
}
@media (max-width: 768px) {
  .button-primary, .survey-link-button, .button-primary-small, .product-card .survey-link-button {
    line-height: 1.5em;
    padding: 0.5rem 1.5rem;
  }
}
@media (min-width: 768px) {
  .button-primary, .survey-link-button, .button-primary-small, .product-card .survey-link-button {
    white-space: nowrap;
  }
}
.button-primary:focus, .survey-link-button:focus, .button-primary-small:focus {
  outline: none;
}
.button-primary:hover, .survey-link-button:hover, .button-primary-small:hover {
  cursor: pointer;
}
.button-primary:disabled, .survey-link-button:disabled, .button-primary-small:disabled {
  opacity: 0.4;
  cursor: default;
}
.button-primary:last-child, .survey-link-button:last-child, .button-primary-small:last-child {
  margin-right: 0;
}
.button-primary + .button-outline:not(.button--no-space), .survey-link-button + .button-outline:not(.button--no-space), .button-primary-small + .button-outline:not(.button--no-space), .product-card .survey-link-button + .button-outline:not(.button--no-space) {
  margin-left: 1rem;
}

.button-primary-small, .product-card .survey-link-button {
  font-size: 0.9rem;
  padding: 0 1.5rem;
}

.button-secondary {
  padding: 0 3rem;
  height: 3.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1rem;
  font-weight: 600;
  -webkit-appearance: none;
  border-radius: 8px;
  color: #737373;
  min-width: 140px;
  box-sizing: border-box;
  border: none;
}
@media (max-width: 768px) {
  .button-secondary {
    line-height: 1.5em;
    padding: 0.5rem 1.5rem;
  }
}
@media (min-width: 768px) {
  .button-secondary {
    white-space: nowrap;
  }
}
.button-secondary:focus {
  outline: none;
}
.button-secondary:hover {
  cursor: pointer;
}
.button-secondary:disabled {
  opacity: 0.4;
  cursor: default;
}
.button-secondary:last-child {
  margin-right: 0;
}

.button-solid {
  padding: 0 3rem;
  height: 3.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1rem;
  font-weight: 600;
  -webkit-appearance: none;
  border-radius: 8px;
  color: #fc662b;
  min-width: 140px;
  box-sizing: border-box;
  border: none;
  border: none;
  padding: 0 2rem;
}
@media (max-width: 768px) {
  .button-solid {
    line-height: 1.5em;
    padding: 0.5rem 1.5rem;
  }
}
@media (min-width: 768px) {
  .button-solid {
    white-space: nowrap;
  }
}
.button-solid:focus {
  outline: none;
}
.button-solid:hover {
  cursor: pointer;
}
.button-solid:disabled {
  opacity: 0.4;
  cursor: default;
}
.button-solid:last-child {
  margin-right: 0;
}
.button-solid + * {
  margin-left: 0.5rem;
}

.button-solid--primary {
  background: #fc662b;
  color: #6ac89b;
}

.button-solid--secondary {
  background: #ffffff;
}

.button-small {
  padding: 0 0.7rem;
}

.button-light {
  padding: 0 3rem;
  height: 3.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1rem;
  font-weight: 600;
  -webkit-appearance: none;
  border-radius: 8px;
  color: #ffffff;
  min-width: 140px;
  box-sizing: border-box;
  border: none;
}
@media (max-width: 768px) {
  .button-light {
    line-height: 1.5em;
    padding: 0.5rem 1.5rem;
  }
}
@media (min-width: 768px) {
  .button-light {
    white-space: nowrap;
  }
}
.button-light:focus {
  outline: none;
}
.button-light:hover {
  cursor: pointer;
}
.button-light:disabled {
  opacity: 0.4;
  cursor: default;
}
.button-light:last-child {
  margin-right: 0;
}

.button-outline {
  padding: 0 3rem;
  height: 3.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1rem;
  font-weight: 600;
  -webkit-appearance: none;
  border-radius: 8px;
  color: #6ac89b;
  min-width: 140px;
  box-sizing: border-box;
  border: none;
  border: solid 1px #454545;
  background: transparent;
  box-sizing: border-box;
}
@media (max-width: 768px) {
  .button-outline {
    line-height: 1.5em;
    padding: 0.5rem 1.5rem;
  }
}
@media (min-width: 768px) {
  .button-outline {
    white-space: nowrap;
  }
}
.button-outline:focus {
  outline: none;
}
.button-outline:hover {
  cursor: pointer;
}
.button-outline:disabled {
  opacity: 0.4;
  cursor: default;
}
.button-outline:last-child {
  margin-right: 0;
}
.button-outline:not(:disabled):hover {
  border-width: 2px;
  border-color: #6ac89b;
  color: #6ac89b;
}
.button-outline + .button-primary:not(.button--no-space), .button-outline + .survey-link-button:not(.button--no-space), .button-outline + .button-primary-small:not(.button--no-space), .product-card .button-outline + .survey-link-button:not(.button--no-space), .button-outline + .button-outline {
  margin-left: 1rem;
}

.button-outline-orange {
  padding: 0 3rem;
  height: 3.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1rem;
  font-weight: 600;
  -webkit-appearance: none;
  border-radius: 8px;
  color: #ff7a2e;
  min-width: 140px;
  box-sizing: border-box;
  border: none;
}
@media (max-width: 768px) {
  .button-outline-orange {
    line-height: 1.5em;
    padding: 0.5rem 1.5rem;
  }
}
@media (min-width: 768px) {
  .button-outline-orange {
    white-space: nowrap;
  }
}
.button-outline-orange:focus {
  outline: none;
}
.button-outline-orange:hover {
  cursor: pointer;
}
.button-outline-orange:disabled {
  opacity: 0.4;
  cursor: default;
}
.button-outline-orange:last-child {
  margin-right: 0;
}

.tooltip {
  position: relative;
}
.tooltip svg path {
  fill: #8a8a8a;
}
.tooltip:after {
  position: absolute;
  width: 236px;
  padding: 1rem;
  border-radius: 8px;
  background: #303030;
  bottom: calc(100% + 0.5rem);
  left: 50%;
  transform: translateX(-50%);
  color: #ffffff;
  font-weight: 400;
  text-transform: none;
  display: none;
  font-size: 0.75rem;
  line-height: 1rem;
  box-sizing: border-box;
  z-index: 999;
}
.tooltip:hover svg path {
  fill: #ffffff;
}
.tooltip:hover:after {
  display: block;
}
.tooltip--survey-name:after {
  content: "This is the name that is displayed in each survey statement. (e.g. Acme Checkout Flow)";
}
.tooltip--screenshot:after {
  content: "Displayed at the beginning of the survey to provide context. Recommended image size: 760 x 760";
}

.tabs {
  display: flex;
}

.tab {
  border-radius: 8px 8px 0 0;
  text-align: center;
  flex-grow: 1;
  height: 64px;
  color: #8a8a8a;
  font-weight: 800;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  position: relative;
  cursor: pointer;
  box-sizing: border-box;
  line-height: 100%;
}
.tab svg {
  margin-right: 0.5rem;
}
.tab:hover {
  color: #ffffff;
}
.tab--active {
  color: #ffffff;
  background: #303030;
}
.tab--active svg {
  fill: #ffffff;
}
.tab--small {
  font-size: 0.75rem;
  height: 2rem;
  padding-left: 1rem;
  padding-right: 1rem;
  flex-grow: 0;
  background: #363636;
}
.tab--menu {
  margin: 0;
  height: calc(2.5rem - 1px);
  border: solid 1px #363636;
  border-bottom-width: 0.5rem;
  margin-bottom: -0.5rem;
  z-index: 9999;
  box-sizing: border-box;
}
.tab-menu-item {
  display: block;
  color: #C7C7C7;
  text-align: left;
  font-size: 1rem;
  font-weight: 600;
  line-height: 1.5;
}
.tab-menu-item:hover {
  color: #ffffff;
}
.tab-menu-item:not(:last-child) {
  margin-bottom: 1rem;
}
.tab-menu-item--divider {
  padding-bottom: 1.5rem;
  padding-left: 1.5rem;
  margin-left: -1.5rem;
  margin-right: -1.5rem;
  border-bottom: solid 1px #454545;
}
.tab-content {
  padding: 1.5rem;
  text-align: left;
  z-index: 999;
}
.pill-tab-container .tab-content {
  position: absolute;
  right: 0;
  top: calc(100% + 0.5rem);
  background: #363636;
  width: 272px;
}
.tab-content--right-tab {
  border-top-right-radius: 0 !important;
}
.tab-content--left-tab {
  border-top-left-radius: 0 !important;
}

.pill-tab-container {
  position: relative;
}
.pill-tab-container .tab-content {
  margin-top: -1px;
}

html,
body,
input,
textarea {
  font-family: "Gotham A", "Gotham B", "Helvetica Neue", "Helvetica", Arial, sans-serif;
}

body {
  color: #ffffff;
}

.bold {
  font-weight: 500;
}

.large-paragraph {
  font-size: 1rem;
  line-height: 1.5rem;
  font-family: "Gotham A", "Gotham B", "Helvetica Neue", "Helvetica", Arial, sans-serif;
  font-weight: 400;
  margin-bottom: 1rem;
  color: #C7C7C7;
  font-size: 1.5rem;
}

.header-1 {
  font-size: 2.5rem;
  font-weight: 800;
  font-family: "Gotham A", "Gotham B", "Helvetica Neue", "Helvetica", Arial, sans-serif;
  color: #ffffff;
}
@media (max-width: 600px) {
  .header-1 {
    font-size: 2.5rem;
  }
}

.list--bold {
  color: #636363;
  font-size: 1.1rem;
}
.list--bold li:not(:last-child) {
  margin-bottom: 1rem;
}

.text-green {
  color: #6ac89b;
}

.text-muted {
  color: #8a8a8a;
}

.header-2, .section-metric {
  font-size: 1.5rem;
  font-weight: 800;
  line-height: 2.5rem;
}
@media (max-width: 600px) {
  .header-2, .section-metric {
    font-size: 1.2rem;
    line-height: 1.6rem;
  }
}
.header-2--small {
  font-size: 1rem;
  line-height: 2.5rem;
}

.header-3 {
  font-size: 1.5rem;
  font-weight: 600;
  line-height: 1.7rem;
  color: #4d4d4d;
}

.header-4 {
  font-size: 1.1rem;
  font-weight: 600;
  text-transform: uppercase;
  line-height: 1.5rem;
  color: #4d4d4d;
}

.header-5 {
  color: #737373;
  font-size: 1.2rem;
  font-weight: 600;
  line-height: 2rem;
  margin-bottom: 1rem;
}

a {
  color: #C7C7C7;
  cursor: pointer;
}
a:hover {
  color: #ffffff;
}

.link {
  color: #C7C7C7;
  cursor: pointer;
}
.link:hover {
  color: #ffffff;
}

.link--add {
  background-image: svg-load("../svg/plus.svg");
  padding-left: 2rem;
  background-repeat: no-repeat;
  background-position: left center;
  background-size: 1rem 1rem;
}

.text-service {
  color: #333333 !important;
}

.text-primary {
  color: #fc662b !important;
}

.text-small {
  color: #8a8a8a;
  font-size: 0.75rem;
  font-weight: 500;
}

.link-secondary {
  color: #C7C7C7;
  cursor: pointer;
  color: #737373;
}
.link-secondary:hover {
  color: #ffffff;
}

.btn-link {
  color: #C7C7C7;
  cursor: pointer;
}
.btn-link:hover {
  color: #ffffff;
}

p,
.baseline-text {
  font-size: 1rem;
  line-height: 1.5rem;
  font-family: "Gotham A", "Gotham B", "Helvetica Neue", "Helvetica", Arial, sans-serif;
  font-weight: 400;
  margin-bottom: 1rem;
  color: #C7C7C7;
}

ul.list li {
  font-size: 1rem;
  line-height: 1.5rem;
  font-family: "Gotham A", "Gotham B", "Helvetica Neue", "Helvetica", Arial, sans-serif;
  font-weight: 400;
  margin-bottom: 1rem;
  color: #C7C7C7;
  display: list-item;
  list-style-type: disc;
  margin-left: 1.9rem;
}
ul.list--condensed {
  margin-bottom: 1.75rem;
}
ul.list--condensed li {
  line-height: 1.75rem;
  margin-bottom: 0;
}

.page-header {
  font-size: 2.5rem;
  font-weight: 800;
  font-family: "Gotham A", "Gotham B", "Helvetica Neue", "Helvetica", Arial, sans-serif;
  color: #ffffff;
  padding: 3rem 0;
  color: #4d4d4d;
}
@media (max-width: 600px) {
  .page-header {
    font-size: 2.5rem;
  }
}

.page-container {
  padding: 2rem 0;
  background: #f9f9fb;
}
.page-container h2 {
  font-size: 1.5rem;
  font-weight: 800;
  line-height: 2.5rem;
}
@media (max-width: 600px) {
  .page-container h2 {
    font-size: 1.2rem;
    line-height: 1.6rem;
  }
}
.page-container h2,
.page-container h3,
.page-container h4 {
  margin-bottom: 0.5rem;
  lost-column: 1/1;
}

.text-center {
  text-align: center;
}

.text-accent {
  color: #6ac89b;
}

body.plugin {
  background: white;
  text-align: center;
  overflow: hidden;
  height: 100%;
}
body.plugin .survey-comments-text {
  height: 90px;
  margin-bottom: 1rem;
}

@media (max-width: 768px) {
  .plugin-flex-wrap {
    display: flex;
    flex-direction: column;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    max-width: 100%;
    background: #f9f9fb;
    max-height: 100px;
    overflow-y: scroll;
    overflow-x: hidden;
  }
  .plugin-flex-wrap.is-expanded {
    max-height: 100%;
  }
}

.confirmation {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-height: 100%;
  height: 100%;
}

.confirmation-title {
  font-size: 2.4rem;
  margin-bottom: 1rem;
  color: #636363;
  font-weight: lighter;
}

.confirmation-description {
  font-weight: bold;
  color: #636363;
  font-size: 1.2rem;
  margin-bottom: 2.5rem;
}

.confirmation .icon-smiley-face {
  display: none;
}
@media (max-width: 768px) {
  .confirmation .icon-smiley-face {
    display: block;
  }
}

.plugin-nps-question {
  padding: 2rem 0;
}
@media (max-width: 768px) {
  .plugin-nps-question {
    padding: 1rem 3rem 1rem 1rem;
    flex-grow: 1;
  }
  .plugin-flex-wrap:not(.is-expanded) .plugin-nps-question {
    padding-top: 100px;
  }
}
@media (max-width: 768px) {
  .is-expanded .plugin-nps-question {
    padding: 10% 1rem 1rem;
    flex-grow: 1;
  }
}

.plugin-question-title {
  font-size: 1.25rem;
  line-height: 1.25em;
  margin-bottom: 1rem;
  color: #636363;
}
@media (max-width: 768px) {
  .plugin-question-title {
    text-align: left;
    font-size: 1.15rem;
  }
}
@media (max-width: 768px) {
  .plugin-flex-wrap:not(.is-expanded) .plugin-question-title {
    position: absolute;
    top: 50%;
    right: 2.5rem;
    left: 1rem;
    transform: translateY(-50%);
  }
}
@media (max-width: 768px) {
  .is-expanded .plugin-question-title {
    text-align: center;
    font-size: 1.5rem;
    line-height: 1.15em;
  }
}

.plugin-question-lead {
  text-transform: uppercase;
  font-weight: bold;
  color: #737373;
  font-size: 14px;
  margin-bottom: 0.5rem;
  display: none;
}
@media (max-width: 768px) {
  .is-expanded .plugin-question-lead {
    display: block;
  }
}

.plugin-expand {
  position: absolute;
  top: 0;
  left: 0;
  right: 2.5rem;
  width: 90%;
  bottom: 0;
  z-index: 100;
  border: 0;
  background: transparent;
  display: none;
}
@media (max-width: 768px) {
  .plugin-expand {
    display: block;
  }
}
.is-expanded .plugin-expand {
  display: none;
}

.plugin-close {
  position: absolute;
  top: 1rem;
  right: 1rem;
  background: transparent;
  height: 16px;
  width: 16px;
}
@media (max-width: 768px) {
  .plugin-flex-wrap:not(.is-expanded) .plugin-close {
    top: 50%;
    margin-top: -8px;
  }
}

.plugin-feedback-container {
  display: none;
  background: #f4f4fa;
  padding: 2rem 1rem;
}
@media (max-width: 768px) {
  .plugin-feedback-container {
    display: block;
    padding: 1rem;
  }
}

.plugin-feedback {
  max-width: 600px;
  margin: 0 auto;
}

.button-plugin {
  padding: 0 3rem;
  height: 3.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1rem;
  font-weight: 600;
  -webkit-appearance: none;
  border-radius: 8px;
  color: #4d4d4d;
  min-width: 140px;
  box-sizing: border-box;
  border: none;
}
@media (max-width: 768px) {
  .button-plugin {
    line-height: 1.5em;
    padding: 0.5rem 1.5rem;
  }
}
@media (min-width: 768px) {
  .button-plugin {
    white-space: nowrap;
  }
}
.button-plugin:focus {
  outline: none;
}
.button-plugin:hover {
  cursor: pointer;
}
.button-plugin:disabled {
  opacity: 0.4;
  cursor: default;
}
.button-plugin:last-child {
  margin-right: 0;
}

.plugin-feedback-title {
  text-align: left;
  margin-bottom: 0.5rem;
  color: #636363;
}

@media (max-width: 768px) {
  .plugin .survey-options {
    display: none;
  }
}

.survey-options-mobile {
  display: none;
}
@media (max-width: 768px) {
  .survey-options-mobile {
    display: block;
    width: 90%;
    margin: 7.5% auto 0;
  }
}

.slider-value {
  position: relative;
  height: 100px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 1rem;
}

.slider-instruction {
  display: block;
  transition: 0.125s all cubic-bezier(0.645, 0.045, 0.355, 1);
  max-width: 300px;
  margin: 0 auto;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
}
.slider-instruction svg {
  display: block;
  height: 60px;
  width: auto;
  animation: 6s cubic-bezier(0.645, 0.045, 0.355, 1) 0s infinite swipe;
}
.slider-instruction.hide {
  position: absolute;
  opacity: 0;
}

.slider-value-number {
  display: inline-block;
  font-size: 100px;
  font-weight: 100;
  color: #2a566f;
  opacity: 1;
  transition: 0.125s all cubic-bezier(0.645, 0.045, 0.355, 1);
}
.slider-value-number.hide {
  position: absolute;
  opacity: 0;
}
.slider-value-number.negative {
  color: #b3432d;
}
.slider-value-number.positive {
  color: #3b94c6;
}

.slider-wrap {
  position: relative;
}
.slider-wrap label {
  position: absolute;
  color: #737373;
  font-size: 14px;
}

.label-slider-min {
  left: 0;
}

.label-slider-max {
  right: 0;
}

.survey-options-slider {
  appearance: none;
  width: 100%;
  background: transparent;
}
.survey-options-slider::webkit-slider-thumb {
  appearance: none;
}
.survey-options-slider:focus {
  outline: none;
}
.survey-options-slider::ms-track {
  width: 100%;
  cursor: pointer;
  background: transparent;
  border-color: transparent;
  color: transparent;
}

@keyframes swipe {
  0%, 50%, 100% {
    transform: translateX(0%);
  }
  25% {
    transform: translateX(35%);
  }
  75% {
    transform: translateX(-35%);
  }
}
#uxrings-frame {
  border: none !important;
  background: white !important;
  display: none !important;
  box-shadow: 0 0 9px 2px rgba(0, 0, 0, 0.15) !important;
  position: fixed !important;
  left: 0 !important;
  right: 0 !important;
  bottom: 0 !important;
  height: 100%;
  max-height: 150px !important;
  width: 100% !important;
  z-index: 99999999 !important;
  transition: all 0.4s cubic-bezier(0.645, 0.045, 0.355, 1);
}
@media (max-width: 768px) {
  #uxrings-frame {
    max-height: 100px !important;
    transition: all 0.5s cubic-bezier(0.645, 0.045, 0.355, 1);
  }
}
@media (max-width: 768px) {
  #uxrings-frame.is-expanded {
    max-height: 100% !important;
    height: 100% !important;
  }
}

.no-overflow {
  overfow: hidden;
  height: 100%;
  width: 100%;
  position: fixed;
}

.hide,
[v-cloak] {
  display: none;
}

.show {
  display: block;
}

.center {
  text-align: center;
}

.text-right {
  text-align: right;
}

.text-ai {
  display: flex;
  align-items: center;
  color: #ffcb77 !important;
}
.text-ai svg {
  margin-right: 0.5rem;
}

.js-resultsViewTab:hover svg g,
.js-resultsViewTab.tab--active svg g {
  opacity: 1;
}

.transcript-tab--active {
  color: #ffffff;
  border-bottom: solid 2px #ffffff;
}

.pagination {
  text-align: center;
  margin: 3rem 0;
  font-size: 1.3rem;
}
.pagination .page {
  margin: 0 0.35rem;
}
.pagination .page.current {
  background: #ff7a2e;
  color: #ffffff;
}
.pagination .page.current, .pagination .page a {
  width: 2rem;
  height: 2rem;
  display: flex;
  align-items: center;
  justify-content: center;
  border: solid 2px #ff7a2e;
}
@media (max-width: 600px) {
  .pagination .page.current, .pagination .page a {
    width: 1.8rem;
    height: 1.8rem;
    line-height: 1.8rem;
  }
}
.pagination .page.gap {
  color: #c1c1c1;
}
.pagination .next {
  margin-left: 3rem;
}
.pagination .next a:after {
  content: "›";
  position: absolute;
  top: 42%;
  transform: translateY(-50%);
  font-size: 2rem;
  line-height: 2rem;
  right: 8px;
}
.pagination .prev {
  margin-right: 3rem;
}
.pagination .prev a:before {
  content: "‹";
  position: absolute;
  top: 42%;
  transform: translateY(-50%);
  font-size: 2rem;
  line-height: 2rem;
  left: 8px;
}
.pagination .next a, .pagination .prev a {
  width: 2rem;
  height: 2rem;
  display: flex;
  align-items: center;
  justify-content: center;
  border: solid 2px #ff7a2e;
  min-width: 9rem;
  position: relative;
}
@media (max-width: 600px) {
  .pagination .next a, .pagination .prev a {
    width: 1.8rem;
    height: 1.8rem;
    line-height: 1.8rem;
  }
}
@media (max-width: 768px) {
  .pagination .next, .pagination .prev {
    display: none;
  }
}

.daterangepicker {
  display: none;
  position: absolute;
  color: #ffffff;
  background-color: #363636;
  border-radius: 0.5rem;
  width: 278px;
  padding: 8px;
  box-shadow: 0 0 16px 0 rgba(0, 0, 0, 0.25);
  margin-top: 0;
  box-sizing: border-box;
  top: 100px;
  left: 20px;
  /* Calendars */
}
.daterangepicker * {
  box-sizing: border-box;
}
.daterangepicker:before, .daterangepicker:after {
  position: absolute;
  display: inline-block;
  border-bottom-color: rgba(0, 0, 0, 0.2);
  content: "";
}
.daterangepicker:before {
  top: -7px;
  border-right: 7px solid transparent;
  border-left: 7px solid transparent;
  border-bottom: 7px solid transparent;
}
.daterangepicker:after {
  top: -7px;
  border-right: 7px solid transparent;
  border-bottom: 7px solid #363636;
  border-left: 7px solid transparent;
}
.daterangepicker.opensleft:before {
  right: 9px;
}
.daterangepicker.opensleft:after {
  right: 11px;
  display: none;
}
.daterangepicker.openscenter:before {
  left: 0;
  right: 0;
  width: 0;
  margin-left: auto;
  margin-right: auto;
}
.daterangepicker.openscenter:after {
  left: 0;
  right: 0;
  width: 0;
  margin-left: auto;
  margin-right: auto;
}
.daterangepicker.opensright:before {
  left: 9px;
}
.daterangepicker.opensright:after {
  left: 11px;
}
.daterangepicker.dropup {
  margin-top: -5px;
}
.daterangepicker.dropup:before {
  top: initial;
  bottom: -7px;
  border-bottom: initial;
  border-top: 7px solid transparent;
}
.daterangepicker.dropup:after {
  top: initial;
  bottom: -7px;
  border-bottom: initial;
  border-top: 7px solid #363636;
}
.daterangepicker.dropdown-menu {
  max-width: none;
  z-index: 3001;
}
.daterangepicker.single .ranges,
.daterangepicker.single .calendar {
  float: none;
}
.daterangepicker.show-calendar .calendar {
  display: block;
}
.daterangepicker .calendar {
  display: none;
  max-width: 262px;
  margin: 8px;
}
.daterangepicker .calendar.single .calendar-table {
  border: none;
}
.daterangepicker .calendar th,
.daterangepicker .calendar td {
  white-space: nowrap;
  text-align: center;
  box-sizing: border-box;
}
.daterangepicker .calendar-table {
  border: 0px solid #363636;
  padding: 8px;
  border-radius: 0.5rem;
  background-color: #363636;
}
.daterangepicker table {
  width: 100%;
  margin: 0;
}
.daterangepicker td,
.daterangepicker th {
  text-align: center;
  width: 32px;
  height: 32px;
  border-radius: 100%;
  border: 0px solid transparent;
  white-space: nowrap;
  cursor: pointer;
  color: #c1c1c1;
  font-size: 0.875rem;
  font-weight: 500;
}
.daterangepicker td.available,
.daterangepicker th.available {
  background-color: transparent;
  color: #ffffff;
}
.daterangepicker td.available:hover,
.daterangepicker th.available:hover {
  background-color: #303030;
  border-color: #ffffff;
  color: #ffffff;
}
.daterangepicker td.week,
.daterangepicker th.week {
  font-size: 80%;
  color: #ccc;
}
.daterangepicker td.off, .daterangepicker td.off.in-range, .daterangepicker td.off.start-date, .daterangepicker td.off.end-date {
  background-color: transparent;
  border-color: transparent;
  color: #c1c1c1;
}
.daterangepicker td.in-range {
  background-color: transparent;
  border-color: transparent;
  color: #ffffff;
  border-radius: 0;
}
.daterangepicker td.start-date {
  border-radius: 100%;
}
.daterangepicker td.end-date {
  border-radius: 100%;
  border: solid #ffffff 2px !important;
  color: #ffffff !important;
  box-sizing: border-box;
  background-color: transparent !important;
}
.daterangepicker td.start-date.end-date {
  border-radius: 100%;
}
.daterangepicker td.active, .daterangepicker td.active:hover {
  background-color: #ffffff;
  border-color: transparent;
  color: #454545;
}
.daterangepicker th.month {
  width: auto;
  color: #ffffff;
  font-weight: 500;
}
.daterangepicker td.disabled,
.daterangepicker option.disabled {
  color: #999;
  cursor: not-allowed;
  text-decoration: line-through;
  border-color: #e5e5e9;
}
.daterangepicker select.monthselect, .daterangepicker select.yearselect {
  font-size: 12px;
  padding: 1px;
  height: auto;
  margin: 0;
  cursor: default;
}
.daterangepicker select.monthselect {
  margin-right: 2%;
  width: 56%;
}
.daterangepicker select.yearselect {
  width: 40%;
}
.daterangepicker select.hourselect, .daterangepicker select.minuteselect, .daterangepicker select.secondselect, .daterangepicker select.ampmselect {
  width: 50px;
  margin-bottom: 0;
}
.daterangepicker .input-mini {
  color: #ffffff;
  height: 48px;
  line-height: 48px;
  display: block;
  font-size: 1rem;
  font-weight: 900;
  border-radius: 0.5rem;
  position: absolute;
  border: solid 1px #454545;
  background: transparent;
  margin: 0 0 5px 0;
  padding: 0 6px 0;
  width: 100%;
}
.daterangepicker .input-mini.active {
  border: 1px solid #454545;
  border-radius: 0.5rem;
}
.daterangepicker .calendar.left .daterangepicker_input:before {
  content: "Start";
  font-weight: 500;
  color: #8a8a8a;
}
.daterangepicker .calendar.right > .daterangepicker_input {
  top: 6.5rem;
}
.daterangepicker .calendar.right .daterangepicker_input:before {
  content: "End";
  font-weight: 500;
  color: #8a8a8a;
}
.daterangepicker .daterangepicker_input {
  border-radius: 0.5rem;
  position: absolute;
  right: 0.5rem;
  padding-right: 0;
  width: 145px;
  background: transparent;
}
.daterangepicker .daterangepicker_input:before {
  display: block;
  font-size: 0.875rem;
  color: #c1c1c1;
  line-height: 1rem;
  margin-bottom: 0.25rem;
}
.daterangepicker .daterangepicker_input i {
  position: absolute;
  display: none;
  left: 8px;
  top: 8px;
}
.daterangepicker.rtl .input-mini {
  padding-right: 28px;
  padding-left: 6px;
}
.daterangepicker.rtl .daterangepicker_input i {
  left: auto;
  right: 8px;
}
.daterangepicker .calendar-time {
  text-align: center;
  margin: 5px auto;
  line-height: 48px;
  position: relative;
  padding-left: 28px;
}
.daterangepicker .calendar-time select.disabled {
  color: #4d4d4d;
  cursor: not-allowed;
}

.ranges {
  font-size: 11px;
  float: none;
  margin: 4px;
  text-align: left;
  padding-top: 10.3rem;
}
.ranges ul {
  list-style: none;
  margin: 0 auto;
  padding: 0;
  width: 100%;
}
.ranges li {
  font-size: 13px;
  background-color: #ffcb77;
  border: 50% solid #ffcb77;
  border-radius: 0.5rem;
  color: #ffffff;
  padding: 3px 12px;
  margin-bottom: 8px;
  cursor: pointer;
}
.ranges li:hover {
  background-color: #ffffff;
  border: 50% solid #ffffff;
  color: #fff;
}
.ranges li.active {
  background-color: #ffffff;
  border: 50% solid #ffffff;
  color: #fff;
}

/*  Larger Screen Styling */
@media (min-width: 564px) {
  .daterangepicker {
    width: auto;
  }
  .daterangepicker .ranges ul {
    width: 160px;
  }
  .daterangepicker.single .ranges ul {
    width: 100%;
  }
  .daterangepicker.single .calendar.left {
    clear: none;
  }
  .daterangepicker.single.ltr .ranges,
.daterangepicker.single.ltr .calendar {
    float: left;
  }
  .daterangepicker.single.rtl .ranges,
.daterangepicker.single.rtl .calendar {
    float: right;
  }
  .daterangepicker.ltr {
    direction: ltr;
    text-align: left;
  }
  .daterangepicker.ltr .calendar.left {
    clear: left;
    margin-right: 0;
  }
  .daterangepicker.ltr .calendar.left .calendar-table {
    border-right: none;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }
  .daterangepicker.ltr .calendar.right {
    margin-left: 0;
  }
  .daterangepicker.ltr .calendar.right .calendar-table {
    border-left: none;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
  .daterangepicker.ltr .calendar.left .calendar-table {
    padding-right: 12px;
  }
  .daterangepicker.ltr .ranges,
.daterangepicker.ltr .calendar {
    float: left;
  }
  .daterangepicker.rtl {
    direction: rtl;
    text-align: right;
  }
  .daterangepicker.rtl .calendar.left {
    clear: right;
    margin-left: 0;
  }
  .daterangepicker.rtl .calendar.left .calendar-table {
    border-left: none;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
  .daterangepicker.rtl .calendar.right {
    margin-right: 0;
  }
  .daterangepicker.rtl .calendar.right .calendar-table {
    border-right: none;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }
  .daterangepicker.rtl .calendar.left .calendar-table {
    padding-left: 12px;
  }
  .daterangepicker.rtl .ranges,
.daterangepicker.rtl .calendar {
    text-align: right;
    float: right;
  }
}
@media (min-width: 730px) {
  .daterangepicker .ranges {
    width: auto;
  }
  .daterangepicker.ltr .ranges {
    float: left;
  }
  .daterangepicker.rtl .ranges {
    float: right;
  }
  .daterangepicker .calendar.left {
    clear: none !important;
  }
  .range_inputs {
    display: flex;
    flex-direction: column;
  }
  .applyBtn {
    margin-bottom: 0.5rem;
  }
  .fa.fa-chevron-left,
.fa.fa-chevron-right {
    background: svg-load("../svg/datepicker-caret.svg");
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    height: 12px;
    width: 8px;
    display: inline-block;
    vertical-align: middle;
  }
  .fa.fa-chevron-right {
    transform: rotate(-180deg);
  }
}
.survey-question.survey-question {
  text-align: center;
  padding: 5rem 0;
}
@media (max-width: 600px) {
  .survey-question.survey-question {
    padding-top: 4rem;
    padding-bottom: 3rem;
  }
}

.plugin .survey-option-very_bad {
  font-weight: bold;
  text-align: center;
  margin: 0 1rem;
  font-size: 0.75rem;
}
@media (max-width: 600px) {
  .plugin .survey-option-very_bad {
    margin: 0.3rem;
  }
}
.plugin .survey-option-very_bad:first-child, .plugin .survey-option-very_bad:last-child {
  display: flex;
  align-items: center;
}
.plugin .survey-option-very_bad:first-child .survey-option-name, .plugin .survey-option-very_bad:last-child .survey-option-name {
  visibility: visible;
  margin-top: 0;
  width: 175px;
  color: #8a8a8a;
  font-weight: bold;
}
@media (max-width: 600px) {
  .plugin .survey-option-very_bad:first-child .survey-option-name, .plugin .survey-option-very_bad:last-child .survey-option-name {
    display: none;
  }
}
.plugin .survey-option-very_bad:first-child {
  flex-direction: row-reverse;
}
.plugin .survey-option-very_bad:first-child .survey-option-name {
  margin-right: 1.75rem;
}
.plugin .survey-option-very_bad:last-child .survey-option-name {
  margin-left: 1.75rem;
}
.plugin .survey-option-very_bad input[type=radio] {
  display: none;
}
.plugin .survey-option-very_bad input[type=radio]:checked ~ .survey-option-name {
  color: #ffffff !important;
  visibility: visible;
}
.plugin .survey-option-very_bad input[type=radio]:checked + .survey-option-label {
  background: #6ac89b;
  color: #ffffff;
}
.plugin .survey-option-very_bad .survey-option-label {
  width: 3rem;
  height: 3rem;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1.6666666667rem;
  font-weight: 800;
  border-radius: 50%;
  color: #8a8a8a;
  background: #303030;
}
@media (max-width: 600px) {
  .plugin .survey-option-very_bad .survey-option-label {
    width: 1.5rem;
    height: 1.5rem;
    line-height: 1.5rem;
  }
}
@media (max-width: 600px) {
  .plugin .survey-option-very_bad .survey-option-label {
    border-radius: 1.5rem;
    font-weight: 100;
  }
}
.plugin .survey-option-very_bad .survey-option-label:hover {
  cursor: pointer;
}
.plugin .survey-option-very_bad .survey-option-label--hover {
  cursor: pointer;
  color: #ffffff;
}
.plugin .survey-option-very_bad .survey-option-label--hover + .survey-option-name {
  color: #ffffff !important;
}
.plugin .survey-option-bad {
  font-weight: bold;
  text-align: center;
  margin: 0 1rem;
  font-size: 0.75rem;
}
@media (max-width: 600px) {
  .plugin .survey-option-bad {
    margin: 0.3rem;
  }
}
.plugin .survey-option-bad:first-child, .plugin .survey-option-bad:last-child {
  display: flex;
  align-items: center;
}
.plugin .survey-option-bad:first-child .survey-option-name, .plugin .survey-option-bad:last-child .survey-option-name {
  visibility: visible;
  margin-top: 0;
  width: 175px;
  color: #8a8a8a;
  font-weight: bold;
}
@media (max-width: 600px) {
  .plugin .survey-option-bad:first-child .survey-option-name, .plugin .survey-option-bad:last-child .survey-option-name {
    display: none;
  }
}
.plugin .survey-option-bad:first-child {
  flex-direction: row-reverse;
}
.plugin .survey-option-bad:first-child .survey-option-name {
  margin-right: 1.75rem;
}
.plugin .survey-option-bad:last-child .survey-option-name {
  margin-left: 1.75rem;
}
.plugin .survey-option-bad input[type=radio] {
  display: none;
}
.plugin .survey-option-bad input[type=radio]:checked ~ .survey-option-name {
  color: #ffffff !important;
  visibility: visible;
}
.plugin .survey-option-bad input[type=radio]:checked + .survey-option-label {
  background: #6ac89b;
  color: #ffffff;
}
.plugin .survey-option-bad .survey-option-label {
  width: 3rem;
  height: 3rem;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1.6666666667rem;
  font-weight: 800;
  border-radius: 50%;
  color: #8a8a8a;
  background: #303030;
}
@media (max-width: 600px) {
  .plugin .survey-option-bad .survey-option-label {
    width: 1.5rem;
    height: 1.5rem;
    line-height: 1.5rem;
  }
}
@media (max-width: 600px) {
  .plugin .survey-option-bad .survey-option-label {
    border-radius: 1.5rem;
    font-weight: 100;
  }
}
.plugin .survey-option-bad .survey-option-label:hover {
  cursor: pointer;
}
.plugin .survey-option-bad .survey-option-label--hover {
  cursor: pointer;
  color: #ffffff;
}
.plugin .survey-option-bad .survey-option-label--hover + .survey-option-name {
  color: #ffffff !important;
}
.plugin .survey-option-neutral {
  font-weight: bold;
  text-align: center;
  margin: 0 1rem;
  font-size: 0.75rem;
}
@media (max-width: 600px) {
  .plugin .survey-option-neutral {
    margin: 0.3rem;
  }
}
.plugin .survey-option-neutral:first-child, .plugin .survey-option-neutral:last-child {
  display: flex;
  align-items: center;
}
.plugin .survey-option-neutral:first-child .survey-option-name, .plugin .survey-option-neutral:last-child .survey-option-name {
  visibility: visible;
  margin-top: 0;
  width: 175px;
  color: #8a8a8a;
  font-weight: bold;
}
@media (max-width: 600px) {
  .plugin .survey-option-neutral:first-child .survey-option-name, .plugin .survey-option-neutral:last-child .survey-option-name {
    display: none;
  }
}
.plugin .survey-option-neutral:first-child {
  flex-direction: row-reverse;
}
.plugin .survey-option-neutral:first-child .survey-option-name {
  margin-right: 1.75rem;
}
.plugin .survey-option-neutral:last-child .survey-option-name {
  margin-left: 1.75rem;
}
.plugin .survey-option-neutral input[type=radio] {
  display: none;
}
.plugin .survey-option-neutral input[type=radio]:checked ~ .survey-option-name {
  color: #ffffff !important;
  visibility: visible;
}
.plugin .survey-option-neutral input[type=radio]:checked + .survey-option-label {
  background: #6ac89b;
  color: #ffffff;
}
.plugin .survey-option-neutral .survey-option-label {
  width: 3rem;
  height: 3rem;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1.6666666667rem;
  font-weight: 800;
  border-radius: 50%;
  color: #8a8a8a;
  background: #303030;
}
@media (max-width: 600px) {
  .plugin .survey-option-neutral .survey-option-label {
    width: 1.5rem;
    height: 1.5rem;
    line-height: 1.5rem;
  }
}
@media (max-width: 600px) {
  .plugin .survey-option-neutral .survey-option-label {
    border-radius: 1.5rem;
    font-weight: 100;
  }
}
.plugin .survey-option-neutral .survey-option-label:hover {
  cursor: pointer;
}
.plugin .survey-option-neutral .survey-option-label--hover {
  cursor: pointer;
  color: #ffffff;
}
.plugin .survey-option-neutral .survey-option-label--hover + .survey-option-name {
  color: #ffffff !important;
}
.plugin .survey-option-good {
  font-weight: bold;
  text-align: center;
  margin: 0 1rem;
  font-size: 0.75rem;
}
@media (max-width: 600px) {
  .plugin .survey-option-good {
    margin: 0.3rem;
  }
}
.plugin .survey-option-good:first-child, .plugin .survey-option-good:last-child {
  display: flex;
  align-items: center;
}
.plugin .survey-option-good:first-child .survey-option-name, .plugin .survey-option-good:last-child .survey-option-name {
  visibility: visible;
  margin-top: 0;
  width: 175px;
  color: #8a8a8a;
  font-weight: bold;
}
@media (max-width: 600px) {
  .plugin .survey-option-good:first-child .survey-option-name, .plugin .survey-option-good:last-child .survey-option-name {
    display: none;
  }
}
.plugin .survey-option-good:first-child {
  flex-direction: row-reverse;
}
.plugin .survey-option-good:first-child .survey-option-name {
  margin-right: 1.75rem;
}
.plugin .survey-option-good:last-child .survey-option-name {
  margin-left: 1.75rem;
}
.plugin .survey-option-good input[type=radio] {
  display: none;
}
.plugin .survey-option-good input[type=radio]:checked ~ .survey-option-name {
  color: #ffffff !important;
  visibility: visible;
}
.plugin .survey-option-good input[type=radio]:checked + .survey-option-label {
  background: #6ac89b;
  color: #ffffff;
}
.plugin .survey-option-good .survey-option-label {
  width: 3rem;
  height: 3rem;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1.6666666667rem;
  font-weight: 800;
  border-radius: 50%;
  color: #8a8a8a;
  background: #303030;
}
@media (max-width: 600px) {
  .plugin .survey-option-good .survey-option-label {
    width: 1.5rem;
    height: 1.5rem;
    line-height: 1.5rem;
  }
}
@media (max-width: 600px) {
  .plugin .survey-option-good .survey-option-label {
    border-radius: 1.5rem;
    font-weight: 100;
  }
}
.plugin .survey-option-good .survey-option-label:hover {
  cursor: pointer;
}
.plugin .survey-option-good .survey-option-label--hover {
  cursor: pointer;
  color: #ffffff;
}
.plugin .survey-option-good .survey-option-label--hover + .survey-option-name {
  color: #ffffff !important;
}
.plugin .survey-option-very_good {
  font-weight: bold;
  text-align: center;
  margin: 0 1rem;
  font-size: 0.75rem;
}
@media (max-width: 600px) {
  .plugin .survey-option-very_good {
    margin: 0.3rem;
  }
}
.plugin .survey-option-very_good:first-child, .plugin .survey-option-very_good:last-child {
  display: flex;
  align-items: center;
}
.plugin .survey-option-very_good:first-child .survey-option-name, .plugin .survey-option-very_good:last-child .survey-option-name {
  visibility: visible;
  margin-top: 0;
  width: 175px;
  color: #8a8a8a;
  font-weight: bold;
}
@media (max-width: 600px) {
  .plugin .survey-option-very_good:first-child .survey-option-name, .plugin .survey-option-very_good:last-child .survey-option-name {
    display: none;
  }
}
.plugin .survey-option-very_good:first-child {
  flex-direction: row-reverse;
}
.plugin .survey-option-very_good:first-child .survey-option-name {
  margin-right: 1.75rem;
}
.plugin .survey-option-very_good:last-child .survey-option-name {
  margin-left: 1.75rem;
}
.plugin .survey-option-very_good input[type=radio] {
  display: none;
}
.plugin .survey-option-very_good input[type=radio]:checked ~ .survey-option-name {
  color: #ffffff !important;
  visibility: visible;
}
.plugin .survey-option-very_good input[type=radio]:checked + .survey-option-label {
  background: #6ac89b;
  color: #ffffff;
}
.plugin .survey-option-very_good .survey-option-label {
  width: 3rem;
  height: 3rem;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1.6666666667rem;
  font-weight: 800;
  border-radius: 50%;
  color: #8a8a8a;
  background: #303030;
}
@media (max-width: 600px) {
  .plugin .survey-option-very_good .survey-option-label {
    width: 1.5rem;
    height: 1.5rem;
    line-height: 1.5rem;
  }
}
@media (max-width: 600px) {
  .plugin .survey-option-very_good .survey-option-label {
    border-radius: 1.5rem;
    font-weight: 100;
  }
}
.plugin .survey-option-very_good .survey-option-label:hover {
  cursor: pointer;
}
.plugin .survey-option-very_good .survey-option-label--hover {
  cursor: pointer;
  color: #ffffff;
}
.plugin .survey-option-very_good .survey-option-label--hover + .survey-option-name {
  color: #ffffff !important;
}

.survey-option-very_bad {
  font-weight: bold;
  text-align: center;
  margin: 0 1rem;
  font-size: 0.75rem;
}
@media (max-width: 600px) {
  .survey-option-very_bad {
    margin: 0.3rem;
  }
}
.survey-option-very_bad:first-child, .survey-option-very_bad:last-child {
  display: flex;
  align-items: center;
}
.survey-option-very_bad:first-child .survey-option-name, .survey-option-very_bad:last-child .survey-option-name {
  visibility: visible;
  margin-top: 0;
  width: 175px;
  color: #8a8a8a;
  font-weight: bold;
}
@media (max-width: 600px) {
  .survey-option-very_bad:first-child .survey-option-name, .survey-option-very_bad:last-child .survey-option-name {
    display: none;
  }
}
.survey-option-very_bad:first-child {
  flex-direction: row-reverse;
}
.survey-option-very_bad:first-child .survey-option-name {
  margin-right: 1.75rem;
}
.survey-option-very_bad:last-child .survey-option-name {
  margin-left: 1.75rem;
}
.survey-option-very_bad input[type=radio] {
  display: none;
}
.survey-option-very_bad input[type=radio]:checked ~ .survey-option-name {
  color: #ffffff !important;
  visibility: visible;
}
.survey-option-very_bad input[type=radio]:checked + .survey-option-label {
  background: #6ac89b;
  color: #ffffff;
}
.survey-option-very_bad .survey-option-label {
  width: 4.5rem;
  height: 4.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 2.5rem;
  font-weight: 800;
  border-radius: 50%;
  color: #8a8a8a;
  background: #303030;
}
@media (max-width: 600px) {
  .survey-option-very_bad .survey-option-label {
    width: 3.3rem;
    height: 3.3rem;
    line-height: 3.3rem;
  }
}
@media (max-width: 600px) {
  .survey-option-very_bad .survey-option-label {
    border-radius: 3.3rem;
    font-weight: 100;
  }
}
.survey-option-very_bad .survey-option-label:hover {
  cursor: pointer;
}
.survey-option-very_bad .survey-option-label--hover {
  cursor: pointer;
  color: #ffffff;
}
.survey-option-very_bad .survey-option-label--hover + .survey-option-name {
  color: #ffffff !important;
}

.survey-option-bad {
  font-weight: bold;
  text-align: center;
  margin: 0 1rem;
  font-size: 0.75rem;
}
@media (max-width: 600px) {
  .survey-option-bad {
    margin: 0.3rem;
  }
}
.survey-option-bad:first-child, .survey-option-bad:last-child {
  display: flex;
  align-items: center;
}
.survey-option-bad:first-child .survey-option-name, .survey-option-bad:last-child .survey-option-name {
  visibility: visible;
  margin-top: 0;
  width: 175px;
  color: #8a8a8a;
  font-weight: bold;
}
@media (max-width: 600px) {
  .survey-option-bad:first-child .survey-option-name, .survey-option-bad:last-child .survey-option-name {
    display: none;
  }
}
.survey-option-bad:first-child {
  flex-direction: row-reverse;
}
.survey-option-bad:first-child .survey-option-name {
  margin-right: 1.75rem;
}
.survey-option-bad:last-child .survey-option-name {
  margin-left: 1.75rem;
}
.survey-option-bad input[type=radio] {
  display: none;
}
.survey-option-bad input[type=radio]:checked ~ .survey-option-name {
  color: #ffffff !important;
  visibility: visible;
}
.survey-option-bad input[type=radio]:checked + .survey-option-label {
  background: #6ac89b;
  color: #ffffff;
}
.survey-option-bad .survey-option-label {
  width: 4.5rem;
  height: 4.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 2.5rem;
  font-weight: 800;
  border-radius: 50%;
  color: #8a8a8a;
  background: #303030;
}
@media (max-width: 600px) {
  .survey-option-bad .survey-option-label {
    width: 3.3rem;
    height: 3.3rem;
    line-height: 3.3rem;
  }
}
@media (max-width: 600px) {
  .survey-option-bad .survey-option-label {
    border-radius: 3.3rem;
    font-weight: 100;
  }
}
.survey-option-bad .survey-option-label:hover {
  cursor: pointer;
}
.survey-option-bad .survey-option-label--hover {
  cursor: pointer;
  color: #ffffff;
}
.survey-option-bad .survey-option-label--hover + .survey-option-name {
  color: #ffffff !important;
}

.survey-option-neutral {
  font-weight: bold;
  text-align: center;
  margin: 0 1rem;
  font-size: 0.75rem;
}
@media (max-width: 600px) {
  .survey-option-neutral {
    margin: 0.3rem;
  }
}
.survey-option-neutral:first-child, .survey-option-neutral:last-child {
  display: flex;
  align-items: center;
}
.survey-option-neutral:first-child .survey-option-name, .survey-option-neutral:last-child .survey-option-name {
  visibility: visible;
  margin-top: 0;
  width: 175px;
  color: #8a8a8a;
  font-weight: bold;
}
@media (max-width: 600px) {
  .survey-option-neutral:first-child .survey-option-name, .survey-option-neutral:last-child .survey-option-name {
    display: none;
  }
}
.survey-option-neutral:first-child {
  flex-direction: row-reverse;
}
.survey-option-neutral:first-child .survey-option-name {
  margin-right: 1.75rem;
}
.survey-option-neutral:last-child .survey-option-name {
  margin-left: 1.75rem;
}
.survey-option-neutral input[type=radio] {
  display: none;
}
.survey-option-neutral input[type=radio]:checked ~ .survey-option-name {
  color: #ffffff !important;
  visibility: visible;
}
.survey-option-neutral input[type=radio]:checked + .survey-option-label {
  background: #6ac89b;
  color: #ffffff;
}
.survey-option-neutral .survey-option-label {
  width: 4.5rem;
  height: 4.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 2.5rem;
  font-weight: 800;
  border-radius: 50%;
  color: #8a8a8a;
  background: #303030;
}
@media (max-width: 600px) {
  .survey-option-neutral .survey-option-label {
    width: 3.3rem;
    height: 3.3rem;
    line-height: 3.3rem;
  }
}
@media (max-width: 600px) {
  .survey-option-neutral .survey-option-label {
    border-radius: 3.3rem;
    font-weight: 100;
  }
}
.survey-option-neutral .survey-option-label:hover {
  cursor: pointer;
}
.survey-option-neutral .survey-option-label--hover {
  cursor: pointer;
  color: #ffffff;
}
.survey-option-neutral .survey-option-label--hover + .survey-option-name {
  color: #ffffff !important;
}

.survey-option-good {
  font-weight: bold;
  text-align: center;
  margin: 0 1rem;
  font-size: 0.75rem;
}
@media (max-width: 600px) {
  .survey-option-good {
    margin: 0.3rem;
  }
}
.survey-option-good:first-child, .survey-option-good:last-child {
  display: flex;
  align-items: center;
}
.survey-option-good:first-child .survey-option-name, .survey-option-good:last-child .survey-option-name {
  visibility: visible;
  margin-top: 0;
  width: 175px;
  color: #8a8a8a;
  font-weight: bold;
}
@media (max-width: 600px) {
  .survey-option-good:first-child .survey-option-name, .survey-option-good:last-child .survey-option-name {
    display: none;
  }
}
.survey-option-good:first-child {
  flex-direction: row-reverse;
}
.survey-option-good:first-child .survey-option-name {
  margin-right: 1.75rem;
}
.survey-option-good:last-child .survey-option-name {
  margin-left: 1.75rem;
}
.survey-option-good input[type=radio] {
  display: none;
}
.survey-option-good input[type=radio]:checked ~ .survey-option-name {
  color: #ffffff !important;
  visibility: visible;
}
.survey-option-good input[type=radio]:checked + .survey-option-label {
  background: #6ac89b;
  color: #ffffff;
}
.survey-option-good .survey-option-label {
  width: 4.5rem;
  height: 4.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 2.5rem;
  font-weight: 800;
  border-radius: 50%;
  color: #8a8a8a;
  background: #303030;
}
@media (max-width: 600px) {
  .survey-option-good .survey-option-label {
    width: 3.3rem;
    height: 3.3rem;
    line-height: 3.3rem;
  }
}
@media (max-width: 600px) {
  .survey-option-good .survey-option-label {
    border-radius: 3.3rem;
    font-weight: 100;
  }
}
.survey-option-good .survey-option-label:hover {
  cursor: pointer;
}
.survey-option-good .survey-option-label--hover {
  cursor: pointer;
  color: #ffffff;
}
.survey-option-good .survey-option-label--hover + .survey-option-name {
  color: #ffffff !important;
}

.survey-option-very_good {
  font-weight: bold;
  text-align: center;
  margin: 0 1rem;
  font-size: 0.75rem;
}
@media (max-width: 600px) {
  .survey-option-very_good {
    margin: 0.3rem;
  }
}
.survey-option-very_good:first-child, .survey-option-very_good:last-child {
  display: flex;
  align-items: center;
}
.survey-option-very_good:first-child .survey-option-name, .survey-option-very_good:last-child .survey-option-name {
  visibility: visible;
  margin-top: 0;
  width: 175px;
  color: #8a8a8a;
  font-weight: bold;
}
@media (max-width: 600px) {
  .survey-option-very_good:first-child .survey-option-name, .survey-option-very_good:last-child .survey-option-name {
    display: none;
  }
}
.survey-option-very_good:first-child {
  flex-direction: row-reverse;
}
.survey-option-very_good:first-child .survey-option-name {
  margin-right: 1.75rem;
}
.survey-option-very_good:last-child .survey-option-name {
  margin-left: 1.75rem;
}
.survey-option-very_good input[type=radio] {
  display: none;
}
.survey-option-very_good input[type=radio]:checked ~ .survey-option-name {
  color: #ffffff !important;
  visibility: visible;
}
.survey-option-very_good input[type=radio]:checked + .survey-option-label {
  background: #6ac89b;
  color: #ffffff;
}
.survey-option-very_good .survey-option-label {
  width: 4.5rem;
  height: 4.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 2.5rem;
  font-weight: 800;
  border-radius: 50%;
  color: #8a8a8a;
  background: #303030;
}
@media (max-width: 600px) {
  .survey-option-very_good .survey-option-label {
    width: 3.3rem;
    height: 3.3rem;
    line-height: 3.3rem;
  }
}
@media (max-width: 600px) {
  .survey-option-very_good .survey-option-label {
    border-radius: 3.3rem;
    font-weight: 100;
  }
}
.survey-option-very_good .survey-option-label:hover {
  cursor: pointer;
}
.survey-option-very_good .survey-option-label--hover {
  cursor: pointer;
  color: #ffffff;
}
.survey-option-very_good .survey-option-label--hover + .survey-option-name {
  color: #ffffff !important;
}

.survey-option-name {
  visibility: hidden;
  text-transform: uppercase;
  margin-top: 1rem;
}
@media (max-width: 600px) {
  .survey-option-name {
    display: none;
  }
}

.survey-question-ask {
  color: #ffffff;
  margin-bottom: 3rem;
  font-weight: 800;
  font-size: 1.5rem;
  line-height: 1.75rem;
}

.survey-options {
  lost-column: 1/1;
  margin-bottom: 2rem;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: center;
}
.plugin .survey-options {
  margin-bottom: 0;
}
@media (max-width: 600px) {
  .survey-options {
    margin-bottom: 2rem;
  }
}

.survey-option-range {
  display: none;
  color: #c1c1c1;
  text-transform: uppercase;
  font-weight: 600;
  font-size: 1.2rem;
  margin-top: 1rem;
  lost-column: 1/1;
}
@media (max-width: 600px) {
  .survey-option-range {
    display: flex;
  }
}
.survey-option-range > * {
  lost-column: 1/2;
}
.survey-option-range .survey-option-range-disagree {
  text-align: left;
}
.survey-option-range .survey-option-range-agree {
  text-align: right;
}

.survey-questions li:first-child .survey-question {
  border-top: none;
  padding-top: 2rem;
}

.disabled a {
  cursor: default;
}
.disabled .survey-next,
.disabled .survey-submit {
  opacity: 0.4;
}

.report-rung-header {
  margin-bottom: 3rem;
  align-items: center;
}
@media (max-width: 600px) {
  .report-rung-header {
    lost-flex-container: column;
    margin-bottom: 2rem;
  }
}

.survey-pager-tab, .survey-pager-tab.active {
  background: #303030;
  height: 1rem;
  flex-grow: 1;
}
.survey-pager-tab:last-child {
  border-radius: 0 99999px 99999px 0;
}
.survey-pager-tab.active {
  background: #6ac89b;
  margin-right: -1rem;
  position: relative;
  z-index: 999;
  border-radius: 99999px;
}
.survey-pager-tab.active:last-of-type {
  margin-right: 0;
}

.survey-pager {
  display: flex;
  width: 100%;
}
.survey-pager--very_bad .survey-pager-tab.active {
  background: #6ac89b;
}
.survey-pager--bad .survey-pager-tab.active {
  background: #6ac89b;
}
.survey-pager--neutral .survey-pager-tab.active {
  background: #6ac89b;
}
.survey-pager--good .survey-pager-tab.active {
  background: #6ac89b;
}
.survey-pager--very_good .survey-pager-tab.active {
  background: #6ac89b;
}

.survey-previous {
  color: #ffffff;
  cursor: pointer;
  font-weight: 600;
  font-size: 1.2rem;
}
.survey-previous > * {
  display: inline-block;
  vertical-align: middle;
}
.survey-previous [class^=icon-] {
  font-size: 2.5rem;
  margin: 0 1rem;
}
@media (max-width: 600px) {
  .survey-previous [class^=icon-] {
    font-size: 4rem;
  }
}
@media (max-width: 600px) {
  .survey-previous .survey-pager-label {
    display: none;
  }
}

.survey-next {
  color: #ffffff;
  cursor: pointer;
  font-weight: 600;
  font-size: 1.2rem;
  position: relative;
}
.survey-next > * {
  display: inline-block;
  vertical-align: middle;
}
.survey-next [class^=icon-] {
  font-size: 2.5rem;
  margin: 0 1rem;
}
@media (max-width: 600px) {
  .survey-next [class^=icon-] {
    font-size: 4rem;
  }
}
@media (max-width: 600px) {
  .survey-next .survey-pager-label {
    display: none;
  }
}

.survey-controls {
  padding-top: 2rem;
  align-items: center;
  display: flex;
  justify-content: center;
}

.survey-previous-container {
  text-align: left;
  margin: 0 1rem;
}
.survey-previous-container + .survey-next-container {
  margin: 0 1rem;
  text-align: right;
}
.survey-previous-container + .survey-next-container .survey-next-notice {
  right: 0;
  left: auto;
}
.survey-previous-container + .survey-next-container .survey-next-notice:after {
  right: 27px;
  left: auto;
}

.survey-next-container {
  text-align: center;
  position: relative;
  display: flex;
  align-self: flex-end;
  justify-content: center;
}
.survey-next-container .survey-next-notice {
  position: absolute;
  display: none;
  bottom: calc(100% + 0.5rem);
  box-shadow: 0 0 16px 0 rgba(0, 0, 0, 0.25);
  right: 50%;
  transform: translateX(50%);
  text-align: center;
  width: 30rem;
}
.survey-next-container .survey-next-notice p {
  margin: 0;
}
.survey-next-container.disabled:hover {
  cursor: default;
}
.survey-next-container.disabled:hover .survey-next-notice {
  display: block;
}
@media (max-width: 600px) {
  .survey-next-container.disabled:hover .survey-next-notice {
    display: none;
  }
}

.survey-page-index {
  flex-grow: 1;
  font-size: 2.5rem;
  font-weight: 800;
  color: #6ac89b;
}
@media (max-width: 600px) {
  .survey-page-index {
    order: 2;
  }
}

.survey-page {
  display: none;
}

.survey-header {
  margin-bottom: 3rem;
}

.survey-page-details {
  display: flex;
  margin-bottom: 1rem;
  width: 100%;
  align-items: center;
}
@media (max-width: 600px) {
  .survey-page-details {
    flex-direction: column;
    align-items: flex-start;
  }
}

.survey-comments {
  text-align: center;
  font-size: 2.5rem;
  font-weight: 300;
  padding-top: 4.5rem;
  padding-bottom: 4.5rem;
}

.survey-comments-label {
  color: #737373;
  display: block;
  lost-column: 1/1;
  font-weight: 300;
  font-size: 1.1rem;
  line-height: 1.7rem;
  margin-bottom: 2rem;
}

.survey-comments-text-container {
  lost-column: 1/1;
}

.survey-complete-headline {
  lost-column: 1/1;
  font-weight: 300;
  font-size: 1.5rem;
  line-height: 4rem;
}

.survey-comments-text {
  border: solid 1px #454545;
  resize: none;
  width: 40rem;
  max-width: 100%;
  height: 10rem;
  padding: 1rem;
  font-size: 1.1rem;
  box-sizing: border-box;
}
.survey-comments-text:focus {
  outline: none;
  border-color: #6ac89b;
}

.site-container, .upgrade-notice-container, .survey-link-inner-container, .site-footer, .products-container, .signed-in-container, .panel--alert, .survey-header {
  lost-center: 1180px;
}
@media (max-width: 1180px) {
  .site-container, .upgrade-notice-container, .survey-link-inner-container, .site-footer, .products-container, .signed-in-container, .panel--alert, .survey-header {
    padding-left: 1rem;
    padding-right: 1rem;
  }
}

.survey-header-container {
  color: #ffffff;
}

.survey-header-container--service {
  background: #333333;
}

.survey-header-container--process {
  background: #108dea;
}

.survey-email-capture {
  font-size: 0.9rem;
  text-align: left;
  margin-bottom: 2rem;
}

.survey-email-capture-buttons {
  display: flex;
}

.survey-success-buttons {
  display: flex;
  align-items: center;
  justify-content: center;
}

.survey-email-field {
  margin-bottom: 0;
}

.survey-question-comments {
  text-align: center;
  lost-column: 1/1;
}

.survey-continue-close {
  margin-top: 1rem;
}

.survey-question-comments-field {
  width: 30rem;
  height: 8rem;
}

.survey-submit-confirmation {
  font-size: 1.5rem;
  font-weight: 800;
  line-height: 2.5rem;
  lost-column: 1/1;
  text-align: center;
  color: #ffffff;
  margin-bottom: 3rem;
}
@media (max-width: 600px) {
  .survey-submit-confirmation {
    font-size: 1.2rem;
    line-height: 1.6rem;
  }
}

.report-rung-summary {
  font-weight: 100;
}
.report-rung-summary p {
  margin-bottom: 1rem;
  font-size: 1rem;
  line-height: 1.5rem;
  font-family: "Gotham A", "Gotham B", "Helvetica Neue", "Helvetica", Arial, sans-serif;
  font-weight: 400;
  margin-bottom: 1rem;
  color: #C7C7C7;
}
.report-rung-summary ul li {
  font-size: 1rem;
  line-height: 1.5rem;
  font-family: "Gotham A", "Gotham B", "Helvetica Neue", "Helvetica", Arial, sans-serif;
  font-weight: 400;
  margin-bottom: 1rem;
  color: #C7C7C7;
  display: list-item;
  list-style-type: disc;
  margin-left: 1.9rem;
}

.report-rung-meaning {
  font-size: 1rem;
  line-height: 1.5rem;
  font-family: "Gotham A", "Gotham B", "Helvetica Neue", "Helvetica", Arial, sans-serif;
  font-weight: 400;
  margin-bottom: 1rem;
  color: #C7C7C7;
}

.report-rung-next-steps p {
  font-size: 1rem;
  line-height: 1.5rem;
  font-family: "Gotham A", "Gotham B", "Helvetica Neue", "Helvetica", Arial, sans-serif;
  font-weight: 400;
  margin-bottom: 1rem;
  color: #C7C7C7;
}
.report-rung-next-steps ul li {
  font-size: 1rem;
  line-height: 1.5rem;
  font-family: "Gotham A", "Gotham B", "Helvetica Neue", "Helvetica", Arial, sans-serif;
  font-weight: 400;
  margin-bottom: 1rem;
  color: #C7C7C7;
  display: list-item;
  list-style-type: disc;
  margin-left: 1.9rem;
}

.result-details {
  display: flex;
  align-items: flex-start;
}
.result-details .survey-pager {
  margin-bottom: 3rem;
}

.result-details-description {
  flex-grow: 1;
}

@media (max-width: 600px) {
  .report-rung-details {
    lost-column: 1/1;
    margin-bottom: 3rem;
  }
}

.report-rung-name {
  font-weight: 900;
  font-size: 2.5rem;
  color: #ffffff;
  text-transform: capitalize;
}

.report-rung-score {
  line-height: 4rem;
  flex: 1;
  margin-right: 1rem;
}

.report-rung-meter-bar {
  background: #f0f0f0;
  height: 10px;
  flex: 1;
  margin-right: 5px;
}

.report-rung-meter-1 {
  display: flex;
  lost-column: 6/9;
  lost-offset: 1/9 row 0;
}
.report-rung-meter-1 .report-rung-meter-bar:nth-child(-n+1) {
  background: #b3432d;
}
@media (max-width: 600px) {
  .report-rung-meter-1 {
    lost-column: 1/1;
    lost-offset: 0/1 row 0;
  }
}

.report-rung-meter-2 {
  display: flex;
  lost-column: 6/9;
  lost-offset: 1/9 row 0;
}
.report-rung-meter-2 .report-rung-meter-bar:nth-child(-n+2) {
  background: #2a566f;
}
@media (max-width: 600px) {
  .report-rung-meter-2 {
    lost-column: 1/1;
    lost-offset: 0/1 row 0;
  }
}

.report-rung-meter-3 {
  display: flex;
  lost-column: 6/9;
  lost-offset: 1/9 row 0;
}
.report-rung-meter-3 .report-rung-meter-bar:nth-child(-n+3) {
  background: #2a566f;
}
@media (max-width: 600px) {
  .report-rung-meter-3 {
    lost-column: 1/1;
    lost-offset: 0/1 row 0;
  }
}

.report-rung-meter-4 {
  display: flex;
  lost-column: 6/9;
  lost-offset: 1/9 row 0;
}
.report-rung-meter-4 .report-rung-meter-bar:nth-child(-n+4) {
  background: #23aea1;
}
@media (max-width: 600px) {
  .report-rung-meter-4 {
    lost-column: 1/1;
    lost-offset: 0/1 row 0;
  }
}

.report-rung-meter-5 {
  display: flex;
  lost-column: 6/9;
  lost-offset: 1/9 row 0;
}
.report-rung-meter-5 .report-rung-meter-bar:nth-child(-n+5) {
  background: #23aea1;
}
@media (max-width: 600px) {
  .report-rung-meter-5 {
    lost-column: 1/1;
    lost-offset: 0/1 row 0;
  }
}

.sample-survey .survey-page {
  display: block;
}
.sample-survey .sample-survey-title {
  font-size: 2.5rem;
  font-weight: 800;
  font-family: "Gotham A", "Gotham B", "Helvetica Neue", "Helvetica", Arial, sans-serif;
  color: #ffffff;
  margin-bottom: 3.375rem;
}
@media (max-width: 600px) {
  .sample-survey .sample-survey-title {
    font-size: 2.5rem;
  }
}

.text-field {
  width: 100%;
  border: none;
  border: solid 1px #454545;
  font-weight: 400;
  line-height: 1.375rem;
  margin-bottom: 3rem;
  font-size: 1rem;
  padding: 1rem;
  background: none;
  color: #ffffff;
  border-radius: 0.5rem;
  box-sizing: border-box;
  height: 2.5rem;
}
.text-field:focus {
  outline: none;
  border-color: #6ac89b;
  border-width: 2px;
}
@media (max-width: 600px) {
  .text-field {
    margin-bottom: 2rem;
  }
}
.text-field--large {
  height: 3.5rem;
  width: 350px;
}
.text-field--full {
  width: 100%;
}
.text-field--collapsed {
  margin-bottom: 1rem;
}
.text-field--copy-hidden, .text-field--copy-hidden:focus {
  width: 1px;
  height: 1px;
  border: none;
  background: transparent;
}
.text-field--copy-hidden:focus, .text-field--copy-hidden:focus:focus {
  outline: none;
}
.text-field--copy-hidden::selection, .text-field--copy-hidden:focus::selection {
  background: none;
}
.text-field--boxed {
  border: 1px solid #c1c1c1;
  padding: 0.5rem;
}
.text-field--outline-light {
  border-color: #454545;
}

.field-label {
  line-height: 1.5rem;
  font-size: 1rem;
  color: #737373;
  display: block;
  margin-bottom: 0.5rem;
}

textarea {
  border-radius: 8px;
  border: solid 1px #454545;
  padding: 0.8rem 1rem;
  color: #ffffff;
  font-size: 1rem;
  width: 100%;
  box-sizing: border-box;
  background: none;
}
textarea:focus {
  outline: none;
  border-color: #6ac89b;
}

.sample-email-field {
  height: 11rem;
}

.text-field-container.valid .text-field {
  border-bottom-color: #0eb58b;
}
.text-field-container.invalid .text-field {
  border-bottom-color: #fc662b;
}

.user-registration-password-container {
  position: relative;
}
.user-registration-password-container.invalid .text-field {
  padding-right: 16rem;
}
.user-registration-password-container.invalid:before {
  content: "Password must be at least 10 characters";
  position: absolute;
  right: 0;
  font-size: 0.9rem;
  color: #fc662b;
  top: 15px;
}

.form-controls {
  text-align: center;
  margin-top: 0rem;
  margin-bottom: 6rem;
}

.form-cancel {
  margin-left: 1rem;
}

.survey-link,
.survey-code {
  padding: 1rem 1.5rem;
  color: #454545;
  font-size: 1.5rem;
  font-weight: 500;
  width: 100%;
  box-sizing: border-box;
  border: solid 1px #303030;
}
.survey-link:focus,
.survey-code:focus {
  outline: none;
}
.product-card .survey-link,
.product-card .survey-code {
  font-size: 1rem;
  padding: 0.5rem 1rem;
  margin-bottom: 1rem;
  margin-right: 0;
}

.error-message {
  font-size: 1.1rem;
  font-weight: 600;
  text-transform: uppercase;
  line-height: 1.5rem;
  color: #4d4d4d;
  text-align: center;
  margin-bottom: 2rem;
  color: #fc662b;
}

.success-message {
  font-size: 1.5rem;
  font-weight: 800;
  line-height: 2.5rem;
  color: #0eb58b;
  text-align: center;
}
@media (max-width: 600px) {
  .success-message {
    font-size: 1.2rem;
    line-height: 1.6rem;
  }
}

.radio-label {
  display: flex;
  align-items: center;
}
.radio-label + .radio-label {
  margin-top: 0.75rem;
}
.radio-label input[type=radio] {
  margin-right: 0.75rem;
  margin-left: 0;
  margin-top: 0;
  margin-bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  appearance: none;
  min-width: 20px;
  min-height: 20px;
}
.radio-label input[type=radio]:not(:checked):before {
  content: "";
  border-radius: 100%;
  display: block;
  border: solid 2px #454545;
  background: #272727;
  min-height: 16px;
  min-width: 16px;
  margin: 0;
}
.radio-label input[type=radio]:checked:before {
  content: "";
  position: absolute;
  display: block;
  min-height: 16px;
  min-width: 16px;
  border: solid 2px #6ac89b;
  border-radius: 100%;
  background: #272727;
}
.radio-label input[type=radio]:checked:after {
  content: "";
  display: block;
  background: #6ac89b;
  border-radius: 100%;
  min-height: 10px;
  min-width: 10px;
  border: none;
  z-index: 99;
}

.checkbox-label {
  display: inline-block;
  vertical-align: middle;
  margin-left: 0.5rem;
}

.checkbox {
  width: 16px;
  height: 16px;
  display: block;
  border-radius: 3px;
  border: 1px solid #ffffff;
  position: relative;
}
.checkbox:before {
  position: absolute;
  opacity: 0;
  top: 2px;
  left: 2px;
  width: 12px;
  height: 12px;
  background: #ffffff;
  transform-origin: center;
  transform: scale(0);
  transition: all 0.25s cubic-bezier(0.175, 0.885, 0.32, 1.275);
}
.checkbox:after {
  content: "";
  position: absolute;
  display: block;
  height: 0px;
  width: 0px;
  border: 2px solid #ffffff;
  border-width: 0 0 2px 2px;
  top: 10px;
  left: 2px;
  opacity: 0;
  transform-origin: top left;
  transition: opacity 0.15s ease-out, height 0.15s ease-in-out 0.15s, width 0.15s ease-in-out;
  margin-top: -2px;
  transform: rotate(-45deg);
}

.checkbox {
  display: inline-block;
  vertical-align: middle;
  border: 1px solid #4d4d4d;
}
.checkbox:before {
  background: #ffffff;
}
.checkbox:after {
  border-color: #363636;
}

.input--checkbox:first-child {
  margin-right: 0.75rem;
}
.input--checkbox input {
  height: 1px;
  width: 1px;
  opacity: 0;
  position: absolute;
}
.input--checkbox input:checked + .checkbox {
  background: #6ac89b;
  border-color: #6ac89b;
}
.input--checkbox input:checked + .checkbox:before {
  transform: scale(1);
  opacity: 1;
}
.input--checkbox input:checked + .checkbox:after {
  height: 4px;
  width: 9px;
  opacity: 1;
  transition: opacity 0.15s ease-out, height 0.15s ease-in-out, width 0.15s cubic-bezier(0.175, 0.885, 0.32, 1.275) 0.15s;
}

.report-summary-timeframe {
  font-size: 1.5rem;
  font-weight: 800;
  line-height: 2.5rem;
  color: #4d4d4d;
  margin-bottom: 1rem;
}
@media (max-width: 600px) {
  .report-summary-timeframe {
    font-size: 1.2rem;
    line-height: 1.6rem;
  }
}

.report-summary-count {
  color: #737373;
  font-weight: 100;
  font-size: 1.5rem;
}

.report-summary-details {
  lost-flex-container: row;
  align-items: center;
}

@media (max-width: 600px) {
  .report-summary-analysis-container {
    text-align: center;
    lost-column: 1/1;
    lost-offset: 0;
  }
}

.results-breakdown {
  padding-top: 0;
}

.report-summary-analysis {
  display: none;
  text-align: left;
}
.report-summary-analysis.is-visible {
  display: block;
}

.report-summary-analysis-collapse {
  padding-top: 1rem;
}

.show-report-summary-analysis > * {
  display: inline-block;
  vertical-align: middle;
}

.report-summary-chart {
  lost-column: 7/12;
}
@media (max-width: 600px) {
  .report-summary-chart {
    display: none;
  }
}

.report-summary-metrics {
  lost-column: 5/12;
}
.report-summary-metrics .report-summary-chart {
  display: none;
  lost-column: 1/1;
  margin-bottom: 2rem;
}
@media (max-width: 600px) {
  .report-summary-metrics {
    lost-column: 1/1;
    text-align: center;
  }
  .report-summary-metrics .report-summary-chart {
    display: block;
  }
}

.report-summary-upgrade {
  lost-column: 1/1;
  text-align: center;
  padding: 2rem 0;
}
.report-summary-upgrade .upgrade-cta-text {
  font-size: 1rem;
  line-height: 1.5rem;
  font-family: "Gotham A", "Gotham B", "Helvetica Neue", "Helvetica", Arial, sans-serif;
  font-weight: 400;
  margin-bottom: 1rem;
  color: #C7C7C7;
  font-size: 1.5rem;
}

.header {
  padding: 2rem 0;
  color: #8a8a8a;
}
@media (max-width: 768px) {
  .header {
    padding: 1rem 0;
  }
}
.header .icon-arrow-upward-white {
  display: inline-block;
}
.header .icon-arrow-upward-orange {
  display: none;
}
.header .icon-uxrings-logo-white {
  display: inline-block;
}
.header .icon-uxrings-logo-orange {
  display: none;
}
.header .icon-uxrings-logo-service,
.header .icon-uxrings-logo-service-white {
  display: none;
}
.header .button-solid {
  background: #ffffff;
  color: #fc662b;
}

.header--service {
  padding: 2rem 0;
}
@media (max-width: 768px) {
  .header--service {
    padding: 1rem 0;
  }
}
.header--service .icon-uxrings-logo-service-white {
  display: inline-block;
}
.header--service .icon-uxrings-logo-white {
  display: none;
}
.header--service .icon-uxrings-logo-orange {
  display: none;
}
.header--service .button-solid {
  background: #ffffff;
  color: #333333;
}

.header--process {
  padding: 2rem 0;
  background: #108dea;
}
@media (max-width: 768px) {
  .header--process {
    padding: 1rem 0;
  }
}
.header--process .icon-uxrings-logo-service-white {
  display: none;
}
.header--process .icon-uxrings-logo-white {
  display: inline-block;
}
.header--process .icon-uxrings-logo-orange {
  display: none;
}
.header--process .button-solid {
  background: #ffffff;
  color: #108dea;
}

.light-header {
  padding: 2rem 0;
  color: #fc662b;
}
@media (max-width: 768px) {
  .light-header {
    padding: 1rem 0;
  }
}
.light-header .mobile-menu-link {
  color: #fc662b;
}
.light-header .icon-arrow-upward-white {
  display: none;
}
.light-header .icon-arrow-upward-orange {
  display: inline-block;
}
.light-header .icon-uxrings-logo-white {
  display: none;
}
.light-header .icon-uxrings-logo-orange {
  display: inline-block;
}
.light-header .icon-uxrings-logo-service,
.light-header .icon-uxrings-logo-service-white {
  display: none;
}

.marketing-header-nav-link {
  color: #C7C7C7;
  cursor: pointer;
  margin-left: 1.5rem;
}
.marketing-header-nav-link:hover {
  color: #ffffff;
}

.marketing-header-nav-link-login {
  color: #C7C7C7;
  cursor: pointer;
  margin-left: 1.5rem;
}
.marketing-header-nav-link-login:hover {
  color: #ffffff;
}
@media (max-width: 600px) {
  .marketing-header-nav-link-login {
    display: none;
  }
}

.marketing-user-controls {
  position: absolute;
  right: 0rem;
  transition: all 0.25s cubic-bezier(0.645, 0.045, 0.355, 1);
}
@media (max-width: 600px) {
  .marketing-user-controls {
    top: 1.5rem;
  }
}
:not(.menu-open) .marketing-user-controls.alt a {
  margin: 0 1.5rem;
}
:not(.menu-open) .marketing-user-controls.alt a:last-child {
  margin-right: 0;
}
@media (max-width: 768px) {
  .marketing-user-controls {
    display: none;
    opacity: 0;
  }
}

.mobile-menu-link {
  position: absolute;
  top: 0.5rem;
  right: 1rem;
  color: white;
}
@media (min-width: 768px) {
  .mobile-menu-link {
    display: none;
  }
}

#mainHeader.menu-open {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: white;
  z-index: 1000;
}
#mainHeader.menu-open .signed-in-container {
  position: static;
}
#mainHeader.menu-open .mobile-menu-link {
  color: #fc662b;
  top: 1.5rem;
}
#mainHeader.menu-open .marketing-user-controls {
  display: block;
  opacity: 1;
  position: relative;
}
#mainHeader.menu-open .marketing-user-controls a {
  display: block;
  font-size: 44px;
  font-weight: 100;
  color: #fc662b;
  margin-bottom: 2rem;
}
#mainHeader.menu-open .marketing-user-controls .marketing-header-nav-link-signup {
  font-size: 18px;
  font-weight: bold;
}
#mainHeader.menu-open .mobile-contact {
  display: block;
  position: absolute;
  bottom: 0rem;
  padding: 1.5rem 1.5rem 1rem;
}
#mainHeader.menu-open .mobile-contact .header-4 {
  color: #737373;
  margin-bottom: 0.25rem;
}
#mainHeader.menu-open .mobile-contact a {
  font-size: 24px;
  font-weight: 100;
}

.mobile-contact {
  display: none;
}

.panel {
  background: #272727;
  border-radius: 0.5rem;
  padding: 1.75rem 1.5rem;
  box-sizing: border-box;
  max-width: 1180px;
  margin-left: auto;
  margin-right: auto;
  height: auto;
}
.panel > *:last-child {
  margin-bottom: 0;
}
.panel--outline {
  border: 2px dashed #8a8a8a;
}
.panel--hover:hover, .panel--highlight {
  background: #454545;
  cursor: pointer;
}
.panel--card {
  height: 6.75rem;
  background: #303030;
  padding: 2rem 2.5rem 2rem 2.5rem;
  display: flex;
  align-items: center;
}
.panel--card-icon {
  border-radius: 100%;
  background: #6ac89b;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 2.5rem;
  height: 2.5rem;
  margin-right: 1.5rem;
}
.panel--card h2 {
  line-height: 1.75rem;
}
.panel--card p {
  line-height: 1.5rem;
}
.panel:not(.panel--waffle) + .panel {
  margin-top: 1rem;
}
.panel--main {
  padding: 2.5rem;
  width: 1180px;
  overflow: hidden;
}
.panel--modalized-hide {
  display: none;
}
.panel--alert {
  font-size: 1.25rem;
  font-weight: bold;
  padding-top: 1.25rem;
  padding-bottom: 1.25rem;
  padding-left: 2.5rem;
  margin-bottom: 1.25rem;
  line-height: 1.375;
}
.panel--alert-success {
  background: #6ac89b;
}
.panel--alert-dark {
  color: #161616;
  padding: 1.125rem;
  font-size: 1rem;
}
.panel--alert-error {
  background: #b3432d;
  color: #ffffff;
}
.panel--waffle {
  margin-top: 0;
  display: block;
  margin: unset;
  max-width: unset;
}
.panel--dark {
  background: #161616;
}
.panel--light {
  background: #303030;
  padding: 1.5rem;
  margin: unset;
}
.panel--thin {
  margin-top: 4rem;
  max-width: 780px;
  margin-left: auto;
  margin-right: auto;
}
.panel--medium {
  min-width: 580px;
}
.panel--content {
  width: 800px;
}
.panel--no-margin {
  margin-top: unset;
  margin-bottom: unset;
}
.panel--inner-section {
  border-top: solid 1px #454545;
  padding: 1.5rem 0;
}
.panel--inner-section:last-child {
  padding-bottom: 0;
}
.panel--compact {
  padding-top: 1.5rem;
  padding-bottom: 1.5rem;
}
.panel--padded {
  padding: 2.5rem;
}
.panel--padded-y:not(:first-child) {
  padding-top: 2.5rem;
}
.panel--padded-y:not(:last-child) {
  padding-bottom: 0 !important;
  margin-bottom: 2.5rem;
}
.panel--padded-y:not(:last-child) p:last-child {
  margin-bottom: 0;
}
.panel--release-notes p {
  line-height: 1.75rem;
}
.panel--release-notes p + * {
  margin-bottom: 1.5rem;
}

.panel-section {
  border-bottom: solid 1px #454545;
  margin-left: -1.5rem;
  margin-right: -1.5rem;
  padding: 2rem 1.75rem;
}
.panel-section:not(.panel-section--no-outline):first-child {
  border-top: solid 1px #454545;
}
.panel-section--no-outline:last-child {
  border-bottom: none;
}

.signed-in-container {
  align-items: center;
  position: relative;
  display: flex;
  justify-content: space-between;
}

.logo {
  lost-column: 2/5;
  display: flex;
  align-items: flex-end;
}
.logo .icon-logo {
  width: 117px;
  height: 24px;
  color: #ffffff;
}
.logo:hover .logo-text {
  display: block;
}
.logo-text {
  display: none;
  margin-left: 1rem;
  font-weight: 500;
  margin-bottom: 1px;
}

.password-reset-confirmation {
  font-size: 1.5rem;
  font-weight: 800;
  line-height: 2.5rem;
  text-align: center;
}
@media (max-width: 600px) {
  .password-reset-confirmation {
    font-size: 1.2rem;
    line-height: 1.6rem;
  }
}

.password-reset-tagline {
  margin-bottom: 2rem;
  color: #ffffff;
}

.settings-confirmation {
  font-size: 1.5rem;
  font-weight: 800;
  line-height: 2.5rem;
  text-align: center;
  margin-bottom: 2rem;
  color: #fc662b;
}
@media (max-width: 600px) {
  .settings-confirmation {
    font-size: 1.2rem;
    line-height: 1.6rem;
  }
}

.user-controls {
  cursor: pointer;
  display: flex;
  align-items: center;
  position: relative;
}

.user-controls-container {
  text-align: right;
  position: relative;
}

.user-controls-caret {
  margin-right: 0.5rem;
  font-size: 0.9rem;
  margin-top: 1px;
}

.user-controls-plan {
  color: #ffffff;
  font-size: 0.9rem;
  text-transform: capitalize;
  font-weight: 400;
  margin-top: 0.5rem;
  margin-left: 1.6rem;
}

.user-controls-name {
  font-size: 1.1rem;
  font-weight: 600;
  text-transform: uppercase;
  line-height: 1.5rem;
  color: #4d4d4d;
  text-transform: capitalize;
  color: #ffffff;
  display: flex;
  align-items: flex-start;
  justify-content: flex-end;
  text-align: left;
}

.user-controls-expanded {
  display: none;
  position: absolute;
  right: 0;
  top: calc(100% + 0.5rem);
  text-align: left;
  z-index: 1000;
  box-shadow: 0 0 16px 0 rgba(0, 0, 0, 0.25);
}

.user-controls-nav .user-controls-nav--item-divided {
  padding-bottom: 1.5rem;
  padding-left: 1.5rem;
  padding-right: 1.5rem;
  border-bottom: solid 1px #454545;
  margin-left: -1.5rem;
  margin-right: -1.5rem;
  margin-bottom: 1.5rem;
}
.user-controls-nav a {
  display: block;
  margin-bottom: 1rem;
  font-size: 1rem;
}
.user-controls-nav a:last-child {
  margin-bottom: 0;
  line-height: 100%;
}

.user-controls-expanded-name {
  font-size: 1.1rem;
  font-weight: 600;
  text-transform: uppercase;
  line-height: 1.5rem;
  color: #4d4d4d;
  color: #ffffff;
  text-transform: capitalize;
}

.user-controls-expanded-email {
  font-size: 1rem;
  line-height: 1.5rem;
  font-family: "Gotham A", "Gotham B", "Helvetica Neue", "Helvetica", Arial, sans-serif;
  font-weight: 400;
  margin-bottom: 1rem;
  color: #C7C7C7;
  font-size: 1rem;
  margin-bottom: 1.5rem;
  font-weight: 300;
  text-transform: lowercase;
}

.products-container {
  display: block;
}

.products-responses {
  margin-top: 1.5rem;
  font-size: 1.3rem;
}

.product-cards-header {
  font-size: 1.5rem;
  font-weight: 600;
  line-height: 1.7rem;
  color: #4d4d4d;
  margin-bottom: 1rem;
}
@media (max-width: 600px) {
  .product-cards-header {
    text-align: center;
  }
}

.products-preview-survey {
  font-size: 1rem;
  margin-left: 1rem;
}

.products-header {
  text-align: center;
  padding-top: 4rem;
  padding-bottom: 4rem;
}

.four04 {
  height: 320px;
  display: block;
  margin: 0 auto 3rem;
  padding: 0;
  position: relative;
}
.four04 svg {
  position: absolute;
  left: 50%;
}
.four04 .emoji-404 {
  margin-left: -155px;
  z-index: 100;
  animation: 0.75s ease-out 0s 1 both dropin-emoji, 2s ease-in-out 1s infinite alternate float-emoji;
}
.four04 .icon-emoji-shadow {
  bottom: 0;
  margin-left: -56px;
  animation: 0.75s ease-out 0s 1 both shadow-grow, 2s ease-in-out 1s infinite alternate shadow-shrink;
}
.four04 .icon-4 {
  margin-left: -80px;
  top: 70px;
  animation: 0.5s cubic-bezier(0.645, 0.045, 0.355, 1) 0.4s 1 both flyout4-left;
}
.four04 .icon-4:last-child {
  animation: 0.5s cubic-bezier(0.645, 0.045, 0.355, 1) 0.425s 1 both flyout4-right;
}

.homelink-404 {
  color: #ffffff;
  text-transform: uppercase;
  font-weight: bold;
  line-height: 2rem;
}
.homelink-404 svg {
  fill: white;
  vertical-align: -5px;
  display: inline-block;
  margin-right: 0.5rem;
  transition: all cubic-bezier(0.645, 0.045, 0.355, 1) 0.25s;
}
.homelink-404:hover svg {
  transform: translateX(-5px);
}

.four04-message {
  animation: 0.5s cubic-bezier(0.645, 0.045, 0.355, 1) 1s both message-fadein;
}

@keyframes message-fadein {
  0% {
    transform: translateY(10px);
    opacity: 0;
  }
  100% {
    transform: translateY(0px);
    opacity: 1;
  }
}
@keyframes float-emoji {
  0% {
    transform: translateY(0);
  }
  100% {
    transform: translateY(-6px);
  }
}
@keyframes dropin-emoji {
  0% {
    transform: translateY(-1000px);
  }
  60% {
    transform: translateY(30px);
  }
  80% {
    transform: translateY(-10px);
  }
  100% {
    transform: translateY(0px);
  }
}
@keyframes shadow-shrink {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(0.9);
  }
}
@keyframes shadow-grow {
  0% {
    transform: scale(0);
  }
  60% {
    transform: scale(1.1);
  }
  80% {
    transform: scale(0.9);
  }
  100% {
    transform: scale(1);
  }
}
@keyframes flyout4-left {
  0% {
    opacity: 0;
  }
  60% {
    transform: translate(-80px, -20px);
  }
  80% {
    transform: translate(-130px, 10px);
  }
  90% {
    transform: translate(-160px, -5px);
  }
  100% {
    opacity: 1;
    transform: translate(-170px, 0);
  }
}
@keyframes flyout4-right {
  0% {
    opacity: 0;
    transform: translate(0, 0);
  }
  60% {
    transform: translate(80px, -20px);
  }
  80% {
    transform: translate(130px, 10px);
  }
  90% {
    transform: translate(160px, -5px);
  }
  100% {
    opacity: 1;
    transform: translate(170px, 0);
  }
}
.modalized {
  width: 100vw;
  position: fixed;
  left: 0;
  top: 0;
}
.modalized:before {
  content: " ";
  background: #161616;
  opacity: 0.7;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 9999;
}
.modalized .modal {
  display: block;
  box-shadow: 0 0 32px rgba(0, 0, 0, 0.5);
}

.modal {
  background: #272727;
  border-radius: 0.5rem;
  padding: 1.75rem 1.5rem;
  overflow-y: scroll;
  margin: auto;
  padding: 2.25rem;
  max-width: 780px;
  width: 780px;
  min-height: 250px !important;
  max-height: 588px !important;
  box-sizing: border-box;
  z-index: 9999999;
  position: relative;
}
@media (max-width: 768px) {
  .modal {
    left: 0;
    right: 0;
    width: auto;
    top: 0;
    bottom: 0;
    box-sizing: border-box;
  }
}
.modal-container {
  display: none;
  position: fixed;
  top: 50%;
  transform: translateY(-50%);
  left: 0;
  right: 0;
  z-index: 999999;
}
.modal .close {
  position: absolute;
  right: 2.8125rem;
  top: 2.8125rem;
  color: #737373;
  cursor: pointer;
}
.modal .close:hover svg path {
  fill: #ffffff;
}
.modal--wide {
  max-width: 780px;
}

.survey-success-container .modal,
.survey-success-container .modal--closeable {
  text-align: center;
  padding: 8rem 5rem;
}

.modal-header {
  font-size: 1.5rem;
  font-weight: 800;
  line-height: 2.5rem;
  font-size: 2.5rem;
  margin-bottom: 2rem;
}
@media (max-width: 600px) {
  .modal-header {
    font-size: 1.2rem;
    line-height: 1.6rem;
  }
}
.modal-header--pretext {
  color: #8a8a8a;
}
.modal-header:last-child {
  margin-bottom: 0;
}

.marketing-container {
  background: white;
  color: #4d4d4d;
}
.marketing-container * {
  box-sizing: border-box;
}

.marketing-signup-cta {
  color: #ffffff;
  position: relative;
  margin-top: 4rem;
  padding-top: 4rem;
  z-index: 1;
}
.marketing-signup-cta .payment-cancel {
  display: none;
}
.marketing-signup-cta .site-container, .marketing-signup-cta .upgrade-notice-container, .marketing-signup-cta .survey-link-inner-container, .marketing-signup-cta .site-footer, .marketing-signup-cta .survey-header, .marketing-signup-cta .panel--alert, .marketing-signup-cta .signed-in-container, .marketing-signup-cta .products-container {
  z-index: 10;
}
.marketing-signup-cta:before {
  content: "";
  background: #ff7a2e;
  background-image: linear-gradient(224deg, #ff8447 0%, #ff6417 100%);
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  z-index: -1;
}
.marketing-signup-cta:after {
  content: "";
  background-position: center;
  background-repeat: no-repeat;
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  z-index: -1;
  background-image: url("data:image/svg+xml,%0A<svg width='1523px' height='1523px' viewBox='-41 -374 1523 1523' version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'><!-- Generator: Sketch 40.3 %2833839%29 - http://www.bohemiancoding.com/sketch --><desc>Created with Sketch.</desc><defs></defs><g id='Group-3' opacity='0.0299999993' stroke='none' stroke-width='1' fill='none' fill-rule='evenodd' transform='translate%289.000000, -324.000000%29'><circle id='Oval-1' stroke='%23FFFFFF' stroke-width='100' cx='711.5' cy='711.5' r='335.5'></circle><circle id='Oval-1' stroke='%23FFFFFF' stroke-width='100' cx='712' cy='712' r='159'></circle><circle id='Oval-1-Copy' stroke='%23FFFFFF' stroke-width='100' cx='711.5' cy='711.5' r='523.5'></circle><circle id='Oval-1-Copy-2' stroke='%23FFFFFF' stroke-width='100' cx='711.5' cy='711.5' r='711.5'></circle></g></svg>");
}

.marketing-sample-dashboard {
  lost-column: 6/8;
  lost-offset: -1/8;
  margin-bottom: 6rem;
  box-sizing: border-box;
}
@media (max-width: 600px) {
  .marketing-sample-dashboard {
    lost-offset: 0;
    lost-column: 1/1;
  }
}

.register-button-container {
  lost-column: 1/1;
  text-align: center;
  margin-bottom: 2rem;
}

.paid-plans-tagline {
  font-size: 1rem;
  line-height: 1.5rem;
  font-family: "Gotham A", "Gotham B", "Helvetica Neue", "Helvetica", Arial, sans-serif;
  font-weight: 400;
  margin-bottom: 1rem;
  color: #C7C7C7;
  color: #ffffff;
  lost-column: 6/8;
  text-align: center;
  font-size: 1.3rem;
  lost-offset: -1/8;
  margin-bottom: 0;
}

.marketing-promo-tagline {
  color: #ff7a2e;
  text-align: center;
  lost-column: 1/1;
  margin-bottom: 4rem;
}

.marketing-trial {
  lost-column: 1/1;
  lost-flex-container: row;
  align-items: center;
}
@media (max-width: 1000px) {
  .marketing-trial .icon-hand {
    display: none;
  }
}

.marketing-trial__description {
  lost-offset: -1/8;
  lost-column: 3/8;
}
@media (max-width: 768px) {
  .marketing-trial__description {
    lost-column: 1/1;
    lost-offset: 0;
    margin-bottom: 4rem;
    text-align: center;
  }
}

.marketing-trial__register {
  lost-column: 3/8;
}
@media (max-width: 768px) {
  .marketing-trial__register {
    lost-column: 1/1;
    lost-offset: 0;
  }
}

.marketing-trial__title {
  margin-bottom: 1.5rem;
  border-bottom: solid 2px #23aea1;
  padding-bottom: 0.5rem;
  display: inline-block;
}

.marketing-title {
  font-size: 2.5rem;
  font-weight: 800;
  font-family: "Gotham A", "Gotham B", "Helvetica Neue", "Helvetica", Arial, sans-serif;
  color: #ffffff;
  margin-bottom: 3rem;
}
@media (max-width: 600px) {
  .marketing-title {
    font-size: 2.5rem;
  }
}
@media (max-width: 768px) {
  .marketing-title {
    margin-top: 4rem;
  }
}

.marketing-title--primary {
  color: #636363;
  font-weight: bold;
}

.marketing-tagline {
  font-size: 1.5rem;
  font-weight: 800;
  line-height: 2.5rem;
  text-align: center;
  lost-offset: -1/16;
  lost-column: 14/16;
  margin-top: 2rem;
  margin-bottom: 4rem;
}
@media (max-width: 600px) {
  .marketing-tagline {
    font-size: 1.2rem;
    line-height: 1.6rem;
  }
}
@media (max-width: 768px) {
  .marketing-tagline {
    margin-bottom: 2rem;
  }
}

.form-container {
  box-sizing: border-box;
  color: #737373;
  z-index: 1;
}
@media (max-width: 600px) {
  .form-container {
    padding: 2rem;
    lost-offset: 0;
    lost-column: 1/1;
  }
}

.form-mini {
  padding: 2.5rem;
  background: #ffffff;
}

.controls-helper-container {
  text-align: center;
}

.marketing-video-wrap {
  width: 100%;
  min-height: 180px;
  margin: 4rem auto;
  background: #ff7a2e;
}

.feature-row {
  lost-flex-container: row;
  margin: 2rem auto;
}
@media (max-width: 600px) {
  .feature-row {
    lost-flex-container: column;
  }
}

.marketing-feature {
  display: flex;
  align-content: center;
  align-items: center;
  box-sizing: border-box;
  padding: 5rem 0;
  border-top: 1px solid #c1c1c1;
  overflow: hidden;
}
.marketing-feature:first-child {
  border-top: 0;
}
@media (max-width: 600px) {
  .marketing-feature {
    flex-direction: column;
    padding: 3rem 0;
  }
}
.marketing-feature > .col {
  width: 50%;
}
@media (max-width: 768px) {
  .marketing-feature > .col {
    width: calc(100% - 2rem);
    margin-top: 2rem;
  }
  .marketing-feature > .col:first-child {
    margin-top: 0;
  }
}
@media (min-width: 768px) {
  .marketing-feature:nth-child(odd) > .col:first-child {
    order: 2;
  }
  .marketing-feature:nth-child(even) video {
    position: absolute;
    right: 0;
  }
}

.feature-graphic {
  padding: 0;
  margin: 0;
  min-height: 82px;
  text-align: right;
  position: relative;
}
.feature-graphic img {
  max-width: 100%;
  height: auto;
}
.feature-graphic video {
  display: inline-block;
}
@media (max-width: 768px) {
  .feature-graphic video {
    max-width: 100%;
    height: auto;
  }
}
.feature--system .feature-graphic, .feature--scores .feature-graphic {
  text-align: center;
}
@media (max-width: 768px) {
  .feature--system .feature-graphic video, .feature--scores .feature-graphic video {
    max-height: 150px;
  }
}
@media (min-width: 768px) {
  .feature--tips .feature-graphic {
    min-height: 560px;
  }
  .feature--feedback .feature-graphic {
    min-height: 550px;
  }
  .feature--embed .feature-graphic {
    min-height: 558px;
  }
}

.feature-message {
  width: 100%;
  max-width: 34em;
  margin: 0 auto;
  padding: 0 2rem;
}
.feature-message h4 {
  margin-bottom: 0.5rem;
  font-size: 1.5rem;
  font-weight: 800;
  line-height: 2.5rem;
}
@media (max-width: 600px) {
  .feature-message h4 {
    font-size: 1.2rem;
    line-height: 1.6rem;
  }
}

.list-faq {
  margin: 3rem auto;
  border-bottom: 1px solid #e6e6e6;
  padding-bottom: 1.5rem;
}
@media (max-width: 768px) {
  .list-faq {
    padding: 0 1.5rem 1.5rem;
    margin: 2rem auto;
  }
}

.faq-question {
  border-top: 1px solid #e6e6e6;
  margin-top: 1.5rem;
  margin-bottom: 1rem;
  padding-top: 2.5rem;
  padding-left: 3rem;
  font-size: 24px;
  font-weight: 600;
  letter-spacing: 1px;
  color: #4d4d4d;
  position: relative;
  cursor: pointer;
}
@media (max-width: 768px) {
  .faq-question {
    font-size: 20px;
  }
}
.faq-question:first-child {
  border-top: 0;
}
.faq-question:before, .faq-question:after {
  content: "";
  display: block;
  position: absolute;
  left: 0;
  top: calc(2.5rem + 11px);
  width: 16px;
  height: 3px;
  background: #ff7a2e;
  transition: 0.25s all cubic-bezier(0.645, 0.045, 0.355, 1);
}
.faq-question:after {
  transform: rotate(90deg);
}
.faq-question.is-open:after {
  transform: rotate(0);
}
.faq-question.is-open + .faq-answer {
  max-height: 300px;
  opacity: 1;
}
@media (max-width: 768px) {
  .faq-question.is-open + .faq-answer {
    max-height: 400px;
  }
}

.faq-answer {
  padding-left: 3rem;
  font-weight: 200;
  font-size: 18px;
  color: #737373;
  line-height: 1.5em;
  overflow: hidden;
  max-height: 0;
  opacity: 0;
  transition: 0.25s all cubic-bezier(0.645, 0.045, 0.355, 1);
}

.faq-cta {
  text-align: center;
  padding: 6rem 1.5rem;
}
.faq-cta h3 {
  font-size: 1.5rem;
  font-weight: 800;
  line-height: 2.5rem;
}
@media (max-width: 600px) {
  .faq-cta h3 {
    font-size: 1.2rem;
    line-height: 1.6rem;
  }
}

.page-header-alt {
  padding: 2.5rem 1.5rem 5.5rem;
  text-align: center;
  color: white;
  background: #fc662b;
}
@media (max-width: 768px) {
  .page-header-alt {
    padding: 3.5rem 1.5rem 4.5rem;
  }
}
.page-header-alt h1 {
  font-size: 2.5rem;
  font-weight: 800;
  font-family: "Gotham A", "Gotham B", "Helvetica Neue", "Helvetica", Arial, sans-serif;
  color: #ffffff;
}
@media (max-width: 600px) {
  .page-header-alt h1 {
    font-size: 2.5rem;
  }
}
.page-header-alt .sub-headline {
  color: white;
  font-size: 1.25rem;
  margin: 1rem auto 2rem;
  font-weight: 300;
}

.rings.pricing,
.create_pricing {
  background-color: #f9f9fb;
}

.site-footer-container {
  width: 100%;
}

.site-footer {
  padding-top: 3rem;
  padding-bottom: 3rem;
  align-items: center;
  font-size: 0.875rem;
  color: #8a8a8a;
}

@media (max-width: 600px) {
  .site-footer-nav {
    lost-column: 1/1;
    margin-bottom: 1rem;
    text-align: center;
  }
}

.site-footer-nav-link {
  color: #8a8a8a;
  margin: 0 0.5rem;
}

.site-footer-attribution {
  display: flex;
  align-items: center;
  margin-right: 1.25rem;
  color: #8a8a8a;
}
@media (max-width: 600px) {
  .site-footer-attribution {
    lost-column: 1/1;
    text-align: center;
    justify-content: center;
    margin-bottom: 1.5rem;
    margin-right: 0;
  }
}
.site-footer-attribution a {
  color: #8a8a8a;
}
.site-footer-attribution a:hover {
  color: #ffffff;
}

.site-footer-details {
  display: flex;
  align-items: center;
  flex-grow: 1;
  color: #8a8a8a;
}

.site-footer-copyright {
  text-align: right;
  color: #8a8a8a;
}
@media (max-width: 600px) {
  .site-footer-copyright {
    lost-column: 1/1;
    text-align: center;
  }
}

@media (max-width: 600px) {
  .site-footer-by {
    text-align: right;
  }
}

.payment-cvc, .payment-exp-month, .payment-exp-year {
  lost-column: 4/12;
}

.payment-verification {
  lost-flex-container: row;
}

.payment-errors {
  color: #ff7a2e;
}

.payment-success-message {
  display: none;
}

.payment-expiration-label {
  color: #c1c1c1;
  margin-bottom: 1rem;
  font-size: 0.9rem;
}

.payment-container {
  display: none;
  flex-grow: 2;
  margin-left: 2rem;
  text-align: left;
}
@media (max-width: 600px) {
  .payment-container {
    margin-left: 0;
  }
}
.payment-container.payment-submitted .payment-form {
  display: none;
  transition: display 2s ease-in-out;
}
.payment-container.payment-submitted .payment-success-message {
  display: block;
  transition: display 2s ease-in-out;
}

.payment-amount-notice {
  font-size: 1.4rem;
  margin-bottom: 2rem;
  text-align: center;
}

.payment-form-controls button {
  margin-left: 1rem;
}
@media (max-width: 600px) {
  .payment-form-controls button {
    margin-top: 1rem;
    margin-left: 0;
  }
}
@media (max-width: 600px) {
  .payment-form-controls {
    text-align: center;
  }
}

.notice-success {
  padding-top: 0.7rem;
  padding-bottom: 0.7rem;
  color: #ffffff;
  text-align: center;
  text-transform: uppercase;
  font-weight: 600;
  background: #0eb58b;
}
.notice-success a {
  color: #ffffff;
  text-decoration: underline;
  font-weight: inherit;
}

.notice-error {
  padding-top: 0.7rem;
  padding-bottom: 0.7rem;
  color: #ffffff;
  text-align: center;
  text-transform: uppercase;
  font-weight: 600;
  background: #b3432d;
}
.notice-error a {
  color: #ffffff;
  text-decoration: underline;
  font-weight: inherit;
}

.product-card-container {
  height: 180px;
  overflow: hidden;
  margin-bottom: 1rem;
  display: grid;
  grid-template-columns: 135px 515px 175px auto auto;
  align-items: center;
  column-gap: 1.5rem;
  padding: 2.5rem;
}

.product-add-text {
  margin-top: 2rem;
  color: #8a8a8a;
  font-size: 1.5rem;
}
.product-add-text:hover {
  color: #ffffff;
}
.product-card:hover .product-add-text {
  color: #ffffff;
}

.product-card {
  cursor: pointer;
  color: #ffffff;
}
.product-card:hover {
  background: #323232;
}
.product-card .show-delete-product {
  flex-grow: 1;
}
.product-card .show-delete-product:hover svg path {
  fill: #ffffff;
}
.product-card-survey-link {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: left;
  margin-bottom: 1rem;
  margin-top: 1rem;
}
.product-card-survey-link .survey-link-button {
  margin: 0;
}

.product-card-prompt {
  font-size: 1.1rem;
  font-weight: 600;
  text-transform: uppercase;
  line-height: 1.5rem;
  color: #4d4d4d;
  text-align: left;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  line-height: 1.3rem;
}

.product-card-name {
  font-weight: 900;
  font-size: 1.5rem;
  overflow: hidden;
  margin-bottom: 0.5rem;
  white-space: nowrap;
  text-overflow: ellipsis;
  flex-shrink: 0;
}

.product-card-survey-count {
  color: #8a8a8a;
}

.product-card-empty {
  cursor: pointer;
  color: #ffffff;
  border: dashed 3px #efeff3;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
}
.product-card-empty:hover {
  background: #323232;
}
.product-card-empty .show-delete-product {
  flex-grow: 1;
}
.product-card-empty .show-delete-product:hover svg path {
  fill: #ffffff;
}
.product-card-empty .icon-uxrings-icon {
  font-size: 8rem;
  color: #efeff3;
}

.product-card-promo {
  cursor: pointer;
  color: #ffffff;
  border: dashed 3px #efeff3;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
}
.product-card-promo:hover {
  background: #323232;
}
.product-card-promo .show-delete-product {
  flex-grow: 1;
}
.product-card-promo .show-delete-product:hover svg path {
  fill: #ffffff;
}

.product-card-promo-headline {
  font-size: 1.1rem;
  font-weight: 600;
  text-transform: uppercase;
  line-height: 1.5rem;
  color: #4d4d4d;
  lost-column: 1/1;
}

.product-card-add {
  cursor: pointer;
  color: #ffffff;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}
.product-card-add:hover {
  background: #323232;
}
.product-card-add .show-delete-product {
  flex-grow: 1;
}
.product-card-add .show-delete-product:hover svg path {
  fill: #ffffff;
}
.product-card-add svg:hover path {
  fill: #ffffff;
}

.product-card-add-name {
  margin-bottom: 2rem;
  font-weight: 900;
  font-size: 1.5rem;
  text-align: left;
}

.product-name-field {
  margin-bottom: 7rem;
}

.product-card-details {
  align-items: flex-start;
  display: flex;
  justify-content: flex-start;
}

.product-card-grade {
  font-size: 7rem;
  font-weight: 300;
  lost-column: 1/2;
}

.product-card-dial-container {
  flex-grow: 1;
  display: flex;
  justify-content: flex-end;
}

.product-card-chart {
  display: flex;
  flex-direction: column;
  justify-items: space-between;
  flex-grow: 1;
  align-content: space-between;
  align-items: flex-end;
}
.product-card-chart .dial {
  margin-bottom: 0;
}
.product-card-chart svg {
  height: 100%;
  max-width: 120px;
}

.product-card-footer {
  border-top: solid 1px #e5e5e9;
  padding-top: 1.5rem;
  margin-top: 1.5rem;
}

.product-card-responses {
  font-size: 1.1rem;
  font-weight: 600;
  text-transform: uppercase;
  line-height: 1.5rem;
  color: #4d4d4d;
  font-weight: 600;
}

.product-card-add-initiate {
  font-weight: 900;
  font-size: 1.75rem;
}

.product-card-add-naming {
  display: none;
  height: 100%;
  width: 100%;
  justify-content: space-between;
  flex-direction: column;
  align-items: flex-start;
}
.product-card-add-naming form {
  width: 100%;
}

.settings-edit,
.settings-show {
  lost-column: 1/1;
}

.settings-container {
  background: #ffffff;
  padding-top: 2rem;
  padding-bottom: 2rem;
}

.settings-user-name {
  font-size: 2.5rem;
  font-weight: 800;
  font-family: "Gotham A", "Gotham B", "Helvetica Neue", "Helvetica", Arial, sans-serif;
  color: #ffffff;
  margin-bottom: 1rem;
}
@media (max-width: 600px) {
  .settings-user-name {
    font-size: 2.5rem;
  }
}

.settings-user-email {
  margin-bottom: 2rem;
}

.payment-edit {
  display: none;
}
.payment-edit .payment-cancel {
  display: none;
}

.settings-form-container {
  lost-offset: -3/12;
  lost-column: 6/12;
}
@media (max-width: 600px) {
  .settings-form-container {
    lost-column: 1/1;
    lost-offset: 0;
  }
}

.settings-account-delete {
  color: #b3432d;
  font-weight: bold;
}

.settings-section {
  margin-top: 4rem;
  margin-bottom: 4rem;
}

.preview-survey-link {
  margin-left: 0.5rem;
  cursor: pointer;
}
.preview-survey-link, .preview-survey-link:hover {
  color: #6ac89b;
}

.custom-question {
  display: flex;
  align-items: center;
  flex-grow: 1;
}
.custom-question .large-paragraph {
  margin-bottom: 0;
  flex-grow: 1;
  margin-right: 1rem;
}
.custom-question form {
  width: 100%;
}
.custom-question .text-field {
  margin-bottom: 2rem;
}
.custom-question input[type=submit] {
  margin-right: 2rem;
}
.custom-question--add {
  color: #8a8a8a;
}
.custom-question--add:hover {
  color: #ffffff;
}
.custom-question--add:hover svg path {
  fill: #ffffff;
}

.custom-question__edit {
  margin-left: 1rem;
}

.custom-questions__actions {
  display: flex;
  align-items: center;
}

.custom-question__archive {
  flex-grow: 1;
}

.custom-question--outline {
  border-style: dashed;
  align-items: center;
}
.custom-question--outline:hover {
  border-color: #ff7a2e;
  border-style: solid;
  cursor: pointer;
}

.custom-question--highlight {
  border-color: #ff7a2e;
  border-style: solid;
}

.custom-questions {
  margin: 1rem auto;
}

.ux-ring {
  width: 100%;
  height: 100%;
}
.ux-ring .label-part {
  fill: #737373;
}
.ux-ring .ring {
  transition: all 0.35s cubic-bezier(0.175, 0.885, 0.32, 1.275);
}
.ux-ring .slice {
  transition: all 0.35s cubic-bezier(0.175, 0.885, 0.32, 1.275);
  transform-origin: center;
  transform: scale(0);
}
.ux-ring.bg-white .inner-ring {
  fill: #ffffff;
}
.ux-ring.bg-dark .inner-ring {
  fill: #1d3b4b;
}

.rung0 .inner-ring {
  fill: none;
}

.rung1 .inner-ring,
.rung1 .ring1 {
  stroke-width: 4px;
  stroke: #ffffff;
}

.rung2 .inner-ring,
.rung2 .ring1,
.rung2 .ring2 {
  stroke-width: 4px;
  stroke: #ffffff;
}

.rung3 .inner-ring,
.rung3 .ring1,
.rung3 .ring2,
.rung3 .ring3 {
  stroke-width: 4px;
  stroke: #ffffff;
}

.rung4 .inner-ring,
.rung4 .ring1,
.rung4 .ring2,
.rung4 .ring3,
.rung4 .ring4 {
  stroke-width: 4px;
  stroke: #ffffff;
}

.rung5 .ring {
  stroke-width: 4px;
  stroke: #ffffff;
}

.seg1-1 .slice1 {
  transform: scale(0.429);
  fill: #b3432d;
}

.seg1-2 .slice1 {
  transform: scale(0.572);
  fill: #2a566f;
}

.seg1-3 .slice1 {
  transform: scale(0.715);
  fill: #2a566f;
}

.seg1-4 .slice1 {
  transform: scale(0.858);
  fill: #2a566f;
  fill: #23aea1;
}

.seg1-5 .slice1 {
  transform: scale(1.001);
  fill: #2a566f;
  fill: #23aea1;
}

.seg2-1 .slice2 {
  transform: scale(0.429);
  fill: #b3432d;
}

.seg2-2 .slice2 {
  transform: scale(0.572);
  fill: #2a566f;
}

.seg2-3 .slice2 {
  transform: scale(0.715);
  fill: #2a566f;
}

.seg2-4 .slice2 {
  transform: scale(0.858);
  fill: #2a566f;
  fill: #23aea1;
}

.seg2-5 .slice2 {
  transform: scale(1.001);
  fill: #2a566f;
  fill: #23aea1;
}

.seg3-1 .slice3 {
  transform: scale(0.429);
  fill: #b3432d;
}

.seg3-2 .slice3 {
  transform: scale(0.572);
  fill: #2a566f;
}

.seg3-3 .slice3 {
  transform: scale(0.715);
  fill: #2a566f;
}

.seg3-4 .slice3 {
  transform: scale(0.858);
  fill: #2a566f;
  fill: #23aea1;
}

.seg3-5 .slice3 {
  transform: scale(1.001);
  fill: #2a566f;
  fill: #23aea1;
}

.seg4-1 .slice4 {
  transform: scale(0.429);
  fill: #b3432d;
}

.seg4-2 .slice4 {
  transform: scale(0.572);
  fill: #2a566f;
}

.seg4-3 .slice4 {
  transform: scale(0.715);
  fill: #2a566f;
}

.seg4-4 .slice4 {
  transform: scale(0.858);
  fill: #2a566f;
  fill: #23aea1;
}

.seg4-5 .slice4 {
  transform: scale(1.001);
  fill: #2a566f;
  fill: #23aea1;
}

.seg5-1 .slice5 {
  transform: scale(0.429);
  fill: #b3432d;
}

.seg5-2 .slice5 {
  transform: scale(0.572);
  fill: #2a566f;
}

.seg5-3 .slice5 {
  transform: scale(0.715);
  fill: #2a566f;
}

.seg5-4 .slice5 {
  transform: scale(0.858);
  fill: #2a566f;
  fill: #23aea1;
}

.seg5-5 .slice5 {
  transform: scale(1.001);
  fill: #2a566f;
  fill: #23aea1;
}

.product-card-chart .labels {
  display: none;
}

@media (max-width: 600px) {
  .results-breakdown {
    padding-top: 0;
  }
}
.results-breakdown .ux-ring .labels {
  display: none;
}
.results-breakdown .ux-ring .ring {
  stroke-width: 1px !important;
  stroke: #f0f0f0 !important;
}
.results-breakdown .ux-ring .dividers line {
  stroke: #f0f0f0;
}
.results-breakdown .ux-ring .inner-ring {
  fill: white;
}

@media (max-width: 600px) {
  .chart-wrap {
    order: 2;
    flex: 1;
    position: inherit;
    display: none;
  }
}

@media (max-width: 600px) {
  .result-container {
    padding-top: 1.5rem;
    padding-bottom: 1.5rem;
  }
}

.result-content,
.report-comments-container {
  lost-column: 5/7;
  lost-offset: -2/7;
}
@media (max-width: 600px) {
  .result-content,
.report-comments-container {
    lost-column: 1/1;
    lost-offset: 0;
    order: 1;
  }
}

.result-functional .ux-ring {
  transform: rotate(54deg);
}
.result-functional .ux-ring .slice2,
.result-functional .ux-ring .slice3,
.result-functional .ux-ring .slice4,
.result-functional .ux-ring .slice5 {
  fill: #f7f7f7;
}

.result-usable .ux-ring {
  transform: rotate(-18deg);
}
.result-usable .ux-ring .slice1,
.result-usable .ux-ring .slice3,
.result-usable .ux-ring .slice4,
.result-usable .ux-ring .slice5 {
  fill: #f7f7f7;
}

.result-comfortable .ux-ring {
  transform: rotate(-90deg);
}
.result-comfortable .ux-ring .slice2,
.result-comfortable .ux-ring .slice1,
.result-comfortable .ux-ring .slice4,
.result-comfortable .ux-ring .slice5 {
  fill: #f7f7f7;
}

.result-delightful .ux-ring {
  transform: rotate(-162deg);
}
.result-delightful .ux-ring .slice2,
.result-delightful .ux-ring .slice3,
.result-delightful .ux-ring .slice1,
.result-delightful .ux-ring .slice5 {
  fill: #f7f7f7;
}

.result-meaningful .ux-ring {
  transform: rotate(-234deg);
}
.result-meaningful .ux-ring .slice2,
.result-meaningful .ux-ring .slice3,
.result-meaningful .ux-ring .slice4,
.result-meaningful .ux-ring .slice1 {
  fill: #f7f7f7;
}

.score-circles {
  display: flex;
  justify-content: space-between;
  margin: 2rem auto;
}
@media (max-width: 600px) {
  .score-circles {
    text-align: center;
    flex-direction: column;
  }
}

.score-circle {
  position: relative;
}
@media (max-width: 600px) {
  .score-circle {
    margin-bottom: 2rem;
    flex-grow: 1;
  }
}
.score-circle .circle-text {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
}

.score-numbers {
  font-size: 1.5rem;
  font-weight: 600;
  display: block;
  white-space: nowrap;
}

.score-label {
  font-size: 0.75rem;
  text-transform: uppercase;
}

.completion-circle {
  width: 135px;
  height: 135px;
  display: block;
}
@media (max-width: 600px) {
  .completion-circle {
    margin: 0 auto;
  }
}
.completion-circle .circle {
  fill: none;
  stroke: #fc662b;
  stroke-miterlimit: 10;
  stroke-width: 5px;
  stroke-linecap: round;
}
.completion-circle .circle.c-complete {
  stroke-dasharray: 425px;
  transform: rotate(-90deg);
  transform-origin: center;
  stroke-dashoffset: 425px;
}
.completion-circle .circle.c-base {
  opacity: 0.2;
}
.completion-circle.surveys .c-complete {
  animation: 1s ease-in-out 1.5s 1 score-100 forwards;
}
.completion-circle.score-0 .c-complete {
  animation: 1s ease-in-out 0.5s 1 score-0 forwards;
}
.completion-circle.score-1 .c-complete {
  animation: 1s ease-in-out 0.5s 1 score-1 forwards;
}
.completion-circle.score-2 .c-complete {
  animation: 1s ease-in-out 0.5s 1 score-2 forwards;
}
.completion-circle.score-3 .c-complete {
  animation: 1s ease-in-out 0.5s 1 score-3 forwards;
}
.completion-circle.score-4 .c-complete {
  animation: 1s ease-in-out 0.5s 1 score-4 forwards;
}
.completion-circle.score-5 .c-complete {
  animation: 1s ease-in-out 0.5s 1 score-5 forwards;
}
.completion-circle.score-6 .c-complete {
  animation: 1s ease-in-out 0.5s 1 score-6 forwards;
}
.completion-circle.score-7 .c-complete {
  animation: 1s ease-in-out 0.5s 1 score-7 forwards;
}
.completion-circle.score-8 .c-complete {
  animation: 1s ease-in-out 0.5s 1 score-8 forwards;
}
.completion-circle.score-9 .c-complete {
  animation: 1s ease-in-out 0.5s 1 score-9 forwards;
}
.completion-circle.score-10 .c-complete {
  animation: 1s ease-in-out 0.5s 1 score-10 forwards;
}
.completion-circle.score-11 .c-complete {
  animation: 1s ease-in-out 0.5s 1 score-11 forwards;
}
.completion-circle.score-12 .c-complete {
  animation: 1s ease-in-out 0.5s 1 score-12 forwards;
}
.completion-circle.score-13 .c-complete {
  animation: 1s ease-in-out 0.5s 1 score-13 forwards;
}
.completion-circle.score-14 .c-complete {
  animation: 1s ease-in-out 0.5s 1 score-14 forwards;
}
.completion-circle.score-15 .c-complete {
  animation: 1s ease-in-out 0.5s 1 score-15 forwards;
}
.completion-circle.score-16 .c-complete {
  animation: 1s ease-in-out 0.5s 1 score-16 forwards;
}
.completion-circle.score-17 .c-complete {
  animation: 1s ease-in-out 0.5s 1 score-17 forwards;
}
.completion-circle.score-18 .c-complete {
  animation: 1s ease-in-out 0.5s 1 score-18 forwards;
}
.completion-circle.score-19 .c-complete {
  animation: 1s ease-in-out 0.5s 1 score-19 forwards;
}
.completion-circle.score-20 .c-complete {
  animation: 1s ease-in-out 0.5s 1 score-20 forwards;
}
.completion-circle.score-21 .c-complete {
  animation: 1s ease-in-out 0.5s 1 score-21 forwards;
}
.completion-circle.score-22 .c-complete {
  animation: 1s ease-in-out 0.5s 1 score-22 forwards;
}
.completion-circle.score-23 .c-complete {
  animation: 1s ease-in-out 0.5s 1 score-23 forwards;
}
.completion-circle.score-24 .c-complete {
  animation: 1s ease-in-out 0.5s 1 score-24 forwards;
}
.completion-circle.score-25 .c-complete {
  animation: 1s ease-in-out 0.5s 1 score-25 forwards;
}
.completion-circle.score-26 .c-complete {
  animation: 1s ease-in-out 0.5s 1 score-26 forwards;
}
.completion-circle.score-27 .c-complete {
  animation: 1s ease-in-out 0.5s 1 score-27 forwards;
}
.completion-circle.score-28 .c-complete {
  animation: 1s ease-in-out 0.5s 1 score-28 forwards;
}
.completion-circle.score-29 .c-complete {
  animation: 1s ease-in-out 0.5s 1 score-29 forwards;
}
.completion-circle.score-30 .c-complete {
  animation: 1s ease-in-out 0.5s 1 score-30 forwards;
}
.completion-circle.score-31 .c-complete {
  animation: 1s ease-in-out 0.5s 1 score-31 forwards;
}
.completion-circle.score-32 .c-complete {
  animation: 1s ease-in-out 0.5s 1 score-32 forwards;
}
.completion-circle.score-33 .c-complete {
  animation: 1s ease-in-out 0.5s 1 score-33 forwards;
}
.completion-circle.score-34 .c-complete {
  animation: 1s ease-in-out 0.5s 1 score-34 forwards;
}
.completion-circle.score-35 .c-complete {
  animation: 1s ease-in-out 0.5s 1 score-35 forwards;
}
.completion-circle.score-36 .c-complete {
  animation: 1s ease-in-out 0.5s 1 score-36 forwards;
}
.completion-circle.score-37 .c-complete {
  animation: 1s ease-in-out 0.5s 1 score-37 forwards;
}
.completion-circle.score-38 .c-complete {
  animation: 1s ease-in-out 0.5s 1 score-38 forwards;
}
.completion-circle.score-39 .c-complete {
  animation: 1s ease-in-out 0.5s 1 score-39 forwards;
}
.completion-circle.score-40 .c-complete {
  animation: 1s ease-in-out 0.5s 1 score-40 forwards;
}
.completion-circle.score-41 .c-complete {
  animation: 1s ease-in-out 0.5s 1 score-41 forwards;
}
.completion-circle.score-42 .c-complete {
  animation: 1s ease-in-out 0.5s 1 score-42 forwards;
}
.completion-circle.score-43 .c-complete {
  animation: 1s ease-in-out 0.5s 1 score-43 forwards;
}
.completion-circle.score-44 .c-complete {
  animation: 1s ease-in-out 0.5s 1 score-44 forwards;
}
.completion-circle.score-45 .c-complete {
  animation: 1s ease-in-out 0.5s 1 score-45 forwards;
}
.completion-circle.score-46 .c-complete {
  animation: 1s ease-in-out 0.5s 1 score-46 forwards;
}
.completion-circle.score-47 .c-complete {
  animation: 1s ease-in-out 0.5s 1 score-47 forwards;
}
.completion-circle.score-48 .c-complete {
  animation: 1s ease-in-out 0.5s 1 score-48 forwards;
}
.completion-circle.score-49 .c-complete {
  animation: 1s ease-in-out 0.5s 1 score-49 forwards;
}
.completion-circle.score-50 .c-complete {
  animation: 1s ease-in-out 0.5s 1 score-50 forwards;
}
.completion-circle.score-51 .c-complete {
  animation: 1s ease-in-out 0.5s 1 score-51 forwards;
}
.completion-circle.score-52 .c-complete {
  animation: 1s ease-in-out 0.5s 1 score-52 forwards;
}
.completion-circle.score-53 .c-complete {
  animation: 1s ease-in-out 0.5s 1 score-53 forwards;
}
.completion-circle.score-54 .c-complete {
  animation: 1s ease-in-out 0.5s 1 score-54 forwards;
}
.completion-circle.score-55 .c-complete {
  animation: 1s ease-in-out 0.5s 1 score-55 forwards;
}
.completion-circle.score-56 .c-complete {
  animation: 1s ease-in-out 0.5s 1 score-56 forwards;
}
.completion-circle.score-57 .c-complete {
  animation: 1s ease-in-out 0.5s 1 score-57 forwards;
}
.completion-circle.score-58 .c-complete {
  animation: 1s ease-in-out 0.5s 1 score-58 forwards;
}
.completion-circle.score-59 .c-complete {
  animation: 1s ease-in-out 0.5s 1 score-59 forwards;
}
.completion-circle.score-60 .c-complete {
  animation: 1s ease-in-out 0.5s 1 score-60 forwards;
}
.completion-circle.score-61 .c-complete {
  animation: 1s ease-in-out 0.5s 1 score-61 forwards;
}
.completion-circle.score-62 .c-complete {
  animation: 1s ease-in-out 0.5s 1 score-62 forwards;
}
.completion-circle.score-63 .c-complete {
  animation: 1s ease-in-out 0.5s 1 score-63 forwards;
}
.completion-circle.score-64 .c-complete {
  animation: 1s ease-in-out 0.5s 1 score-64 forwards;
}
.completion-circle.score-65 .c-complete {
  animation: 1s ease-in-out 0.5s 1 score-65 forwards;
}
.completion-circle.score-66 .c-complete {
  animation: 1s ease-in-out 0.5s 1 score-66 forwards;
}
.completion-circle.score-67 .c-complete {
  animation: 1s ease-in-out 0.5s 1 score-67 forwards;
}
.completion-circle.score-68 .c-complete {
  animation: 1s ease-in-out 0.5s 1 score-68 forwards;
}
.completion-circle.score-69 .c-complete {
  animation: 1s ease-in-out 0.5s 1 score-69 forwards;
}
.completion-circle.score-70 .c-complete {
  animation: 1s ease-in-out 0.5s 1 score-70 forwards;
}
.completion-circle.score-71 .c-complete {
  animation: 1s ease-in-out 0.5s 1 score-71 forwards;
}
.completion-circle.score-72 .c-complete {
  animation: 1s ease-in-out 0.5s 1 score-72 forwards;
}
.completion-circle.score-73 .c-complete {
  animation: 1s ease-in-out 0.5s 1 score-73 forwards;
}
.completion-circle.score-74 .c-complete {
  animation: 1s ease-in-out 0.5s 1 score-74 forwards;
}
.completion-circle.score-75 .c-complete {
  animation: 1s ease-in-out 0.5s 1 score-75 forwards;
}
.completion-circle.score-76 .c-complete {
  animation: 1s ease-in-out 0.5s 1 score-76 forwards;
}
.completion-circle.score-77 .c-complete {
  animation: 1s ease-in-out 0.5s 1 score-77 forwards;
}
.completion-circle.score-78 .c-complete {
  animation: 1s ease-in-out 0.5s 1 score-78 forwards;
}
.completion-circle.score-79 .c-complete {
  animation: 1s ease-in-out 0.5s 1 score-79 forwards;
}
.completion-circle.score-80 .c-complete {
  animation: 1s ease-in-out 0.5s 1 score-80 forwards;
}
.completion-circle.score-81 .c-complete {
  animation: 1s ease-in-out 0.5s 1 score-81 forwards;
}
.completion-circle.score-82 .c-complete {
  animation: 1s ease-in-out 0.5s 1 score-82 forwards;
}
.completion-circle.score-83 .c-complete {
  animation: 1s ease-in-out 0.5s 1 score-83 forwards;
}
.completion-circle.score-84 .c-complete {
  animation: 1s ease-in-out 0.5s 1 score-84 forwards;
}
.completion-circle.score-85 .c-complete {
  animation: 1s ease-in-out 0.5s 1 score-85 forwards;
}
.completion-circle.score-86 .c-complete {
  animation: 1s ease-in-out 0.5s 1 score-86 forwards;
}
.completion-circle.score-87 .c-complete {
  animation: 1s ease-in-out 0.5s 1 score-87 forwards;
}
.completion-circle.score-88 .c-complete {
  animation: 1s ease-in-out 0.5s 1 score-88 forwards;
}
.completion-circle.score-89 .c-complete {
  animation: 1s ease-in-out 0.5s 1 score-89 forwards;
}
.completion-circle.score-90 .c-complete {
  animation: 1s ease-in-out 0.5s 1 score-90 forwards;
}
.completion-circle.score-91 .c-complete {
  animation: 1s ease-in-out 0.5s 1 score-91 forwards;
}
.completion-circle.score-92 .c-complete {
  animation: 1s ease-in-out 0.5s 1 score-92 forwards;
}
.completion-circle.score-93 .c-complete {
  animation: 1s ease-in-out 0.5s 1 score-93 forwards;
}
.completion-circle.score-94 .c-complete {
  animation: 1s ease-in-out 0.5s 1 score-94 forwards;
}
.completion-circle.score-95 .c-complete {
  animation: 1s ease-in-out 0.5s 1 score-95 forwards;
}
.completion-circle.score-96 .c-complete {
  animation: 1s ease-in-out 0.5s 1 score-96 forwards;
}
.completion-circle.score-97 .c-complete {
  animation: 1s ease-in-out 0.5s 1 score-97 forwards;
}
.completion-circle.score-98 .c-complete {
  animation: 1s ease-in-out 0.5s 1 score-98 forwards;
}
.completion-circle.score-99 .c-complete {
  animation: 1s ease-in-out 0.5s 1 score-99 forwards;
}
.completion-circle.score-100 .c-complete {
  animation: 1s ease-in-out 0.5s 1 score-100 forwards;
}
.completion-circle.ring-score-1 .c-complete {
  animation: 1s ease-in-out 1s 1 score-20 forwards;
}
.completion-circle.ring-score-2 .c-complete {
  animation: 1s ease-in-out 1s 1 score-40 forwards;
}
.completion-circle.ring-score-3 .c-complete {
  animation: 1s ease-in-out 1s 1 score-60 forwards;
}
.completion-circle.ring-score-4 .c-complete {
  animation: 1s ease-in-out 1s 1 score-80 forwards;
}
.completion-circle.ring-score-5 .c-complete {
  animation: 1s ease-in-out 1s 1 score-100 forwards;
}

@keyframes score-0 {
  0% {
    stroke-dashoffset: 425px;
  }
  100% {
    stroke-dashoffset: 425px;
  }
}
@keyframes score-1 {
  0% {
    stroke-dashoffset: 425px;
  }
  100% {
    stroke-dashoffset: 420.75px;
  }
}
@keyframes score-2 {
  0% {
    stroke-dashoffset: 425px;
  }
  100% {
    stroke-dashoffset: 416.5px;
  }
}
@keyframes score-3 {
  0% {
    stroke-dashoffset: 425px;
  }
  100% {
    stroke-dashoffset: 412.25px;
  }
}
@keyframes score-4 {
  0% {
    stroke-dashoffset: 425px;
  }
  100% {
    stroke-dashoffset: 408px;
  }
}
@keyframes score-5 {
  0% {
    stroke-dashoffset: 425px;
  }
  100% {
    stroke-dashoffset: 403.75px;
  }
}
@keyframes score-6 {
  0% {
    stroke-dashoffset: 425px;
  }
  100% {
    stroke-dashoffset: 399.5px;
  }
}
@keyframes score-7 {
  0% {
    stroke-dashoffset: 425px;
  }
  100% {
    stroke-dashoffset: 395.25px;
  }
}
@keyframes score-8 {
  0% {
    stroke-dashoffset: 425px;
  }
  100% {
    stroke-dashoffset: 391px;
  }
}
@keyframes score-9 {
  0% {
    stroke-dashoffset: 425px;
  }
  100% {
    stroke-dashoffset: 386.75px;
  }
}
@keyframes score-10 {
  0% {
    stroke-dashoffset: 425px;
  }
  100% {
    stroke-dashoffset: 382.5px;
  }
}
@keyframes score-11 {
  0% {
    stroke-dashoffset: 425px;
  }
  100% {
    stroke-dashoffset: 378.25px;
  }
}
@keyframes score-12 {
  0% {
    stroke-dashoffset: 425px;
  }
  100% {
    stroke-dashoffset: 374px;
  }
}
@keyframes score-13 {
  0% {
    stroke-dashoffset: 425px;
  }
  100% {
    stroke-dashoffset: 369.75px;
  }
}
@keyframes score-14 {
  0% {
    stroke-dashoffset: 425px;
  }
  100% {
    stroke-dashoffset: 365.5px;
  }
}
@keyframes score-15 {
  0% {
    stroke-dashoffset: 425px;
  }
  100% {
    stroke-dashoffset: 361.25px;
  }
}
@keyframes score-16 {
  0% {
    stroke-dashoffset: 425px;
  }
  100% {
    stroke-dashoffset: 357px;
  }
}
@keyframes score-17 {
  0% {
    stroke-dashoffset: 425px;
  }
  100% {
    stroke-dashoffset: 352.75px;
  }
}
@keyframes score-18 {
  0% {
    stroke-dashoffset: 425px;
  }
  100% {
    stroke-dashoffset: 348.5px;
  }
}
@keyframes score-19 {
  0% {
    stroke-dashoffset: 425px;
  }
  100% {
    stroke-dashoffset: 344.25px;
  }
}
@keyframes score-20 {
  0% {
    stroke-dashoffset: 425px;
  }
  100% {
    stroke-dashoffset: 340px;
  }
}
@keyframes score-21 {
  0% {
    stroke-dashoffset: 425px;
  }
  100% {
    stroke-dashoffset: 335.75px;
  }
}
@keyframes score-22 {
  0% {
    stroke-dashoffset: 425px;
  }
  100% {
    stroke-dashoffset: 331.5px;
  }
}
@keyframes score-23 {
  0% {
    stroke-dashoffset: 425px;
  }
  100% {
    stroke-dashoffset: 327.25px;
  }
}
@keyframes score-24 {
  0% {
    stroke-dashoffset: 425px;
  }
  100% {
    stroke-dashoffset: 323px;
  }
}
@keyframes score-25 {
  0% {
    stroke-dashoffset: 425px;
  }
  100% {
    stroke-dashoffset: 318.75px;
  }
}
@keyframes score-26 {
  0% {
    stroke-dashoffset: 425px;
  }
  100% {
    stroke-dashoffset: 314.5px;
  }
}
@keyframes score-27 {
  0% {
    stroke-dashoffset: 425px;
  }
  100% {
    stroke-dashoffset: 310.25px;
  }
}
@keyframes score-28 {
  0% {
    stroke-dashoffset: 425px;
  }
  100% {
    stroke-dashoffset: 306px;
  }
}
@keyframes score-29 {
  0% {
    stroke-dashoffset: 425px;
  }
  100% {
    stroke-dashoffset: 301.75px;
  }
}
@keyframes score-30 {
  0% {
    stroke-dashoffset: 425px;
  }
  100% {
    stroke-dashoffset: 297.5px;
  }
}
@keyframes score-31 {
  0% {
    stroke-dashoffset: 425px;
  }
  100% {
    stroke-dashoffset: 293.25px;
  }
}
@keyframes score-32 {
  0% {
    stroke-dashoffset: 425px;
  }
  100% {
    stroke-dashoffset: 289px;
  }
}
@keyframes score-33 {
  0% {
    stroke-dashoffset: 425px;
  }
  100% {
    stroke-dashoffset: 284.75px;
  }
}
@keyframes score-34 {
  0% {
    stroke-dashoffset: 425px;
  }
  100% {
    stroke-dashoffset: 280.5px;
  }
}
@keyframes score-35 {
  0% {
    stroke-dashoffset: 425px;
  }
  100% {
    stroke-dashoffset: 276.25px;
  }
}
@keyframes score-36 {
  0% {
    stroke-dashoffset: 425px;
  }
  100% {
    stroke-dashoffset: 272px;
  }
}
@keyframes score-37 {
  0% {
    stroke-dashoffset: 425px;
  }
  100% {
    stroke-dashoffset: 267.75px;
  }
}
@keyframes score-38 {
  0% {
    stroke-dashoffset: 425px;
  }
  100% {
    stroke-dashoffset: 263.5px;
  }
}
@keyframes score-39 {
  0% {
    stroke-dashoffset: 425px;
  }
  100% {
    stroke-dashoffset: 259.25px;
  }
}
@keyframes score-40 {
  0% {
    stroke-dashoffset: 425px;
  }
  100% {
    stroke-dashoffset: 255px;
  }
}
@keyframes score-41 {
  0% {
    stroke-dashoffset: 425px;
  }
  100% {
    stroke-dashoffset: 250.75px;
  }
}
@keyframes score-42 {
  0% {
    stroke-dashoffset: 425px;
  }
  100% {
    stroke-dashoffset: 246.5px;
  }
}
@keyframes score-43 {
  0% {
    stroke-dashoffset: 425px;
  }
  100% {
    stroke-dashoffset: 242.25px;
  }
}
@keyframes score-44 {
  0% {
    stroke-dashoffset: 425px;
  }
  100% {
    stroke-dashoffset: 238px;
  }
}
@keyframes score-45 {
  0% {
    stroke-dashoffset: 425px;
  }
  100% {
    stroke-dashoffset: 233.75px;
  }
}
@keyframes score-46 {
  0% {
    stroke-dashoffset: 425px;
  }
  100% {
    stroke-dashoffset: 229.5px;
  }
}
@keyframes score-47 {
  0% {
    stroke-dashoffset: 425px;
  }
  100% {
    stroke-dashoffset: 225.25px;
  }
}
@keyframes score-48 {
  0% {
    stroke-dashoffset: 425px;
  }
  100% {
    stroke-dashoffset: 221px;
  }
}
@keyframes score-49 {
  0% {
    stroke-dashoffset: 425px;
  }
  100% {
    stroke-dashoffset: 216.75px;
  }
}
@keyframes score-50 {
  0% {
    stroke-dashoffset: 425px;
  }
  100% {
    stroke-dashoffset: 212.5px;
  }
}
@keyframes score-51 {
  0% {
    stroke-dashoffset: 425px;
  }
  100% {
    stroke-dashoffset: 208.25px;
  }
}
@keyframes score-52 {
  0% {
    stroke-dashoffset: 425px;
  }
  100% {
    stroke-dashoffset: 204px;
  }
}
@keyframes score-53 {
  0% {
    stroke-dashoffset: 425px;
  }
  100% {
    stroke-dashoffset: 199.75px;
  }
}
@keyframes score-54 {
  0% {
    stroke-dashoffset: 425px;
  }
  100% {
    stroke-dashoffset: 195.5px;
  }
}
@keyframes score-55 {
  0% {
    stroke-dashoffset: 425px;
  }
  100% {
    stroke-dashoffset: 191.25px;
  }
}
@keyframes score-56 {
  0% {
    stroke-dashoffset: 425px;
  }
  100% {
    stroke-dashoffset: 187px;
  }
}
@keyframes score-57 {
  0% {
    stroke-dashoffset: 425px;
  }
  100% {
    stroke-dashoffset: 182.75px;
  }
}
@keyframes score-58 {
  0% {
    stroke-dashoffset: 425px;
  }
  100% {
    stroke-dashoffset: 178.5px;
  }
}
@keyframes score-59 {
  0% {
    stroke-dashoffset: 425px;
  }
  100% {
    stroke-dashoffset: 174.25px;
  }
}
@keyframes score-60 {
  0% {
    stroke-dashoffset: 425px;
  }
  100% {
    stroke-dashoffset: 170px;
  }
}
@keyframes score-61 {
  0% {
    stroke-dashoffset: 425px;
  }
  100% {
    stroke-dashoffset: 165.75px;
  }
}
@keyframes score-62 {
  0% {
    stroke-dashoffset: 425px;
  }
  100% {
    stroke-dashoffset: 161.5px;
  }
}
@keyframes score-63 {
  0% {
    stroke-dashoffset: 425px;
  }
  100% {
    stroke-dashoffset: 157.25px;
  }
}
@keyframes score-64 {
  0% {
    stroke-dashoffset: 425px;
  }
  100% {
    stroke-dashoffset: 153px;
  }
}
@keyframes score-65 {
  0% {
    stroke-dashoffset: 425px;
  }
  100% {
    stroke-dashoffset: 148.75px;
  }
}
@keyframes score-66 {
  0% {
    stroke-dashoffset: 425px;
  }
  100% {
    stroke-dashoffset: 144.5px;
  }
}
@keyframes score-67 {
  0% {
    stroke-dashoffset: 425px;
  }
  100% {
    stroke-dashoffset: 140.25px;
  }
}
@keyframes score-68 {
  0% {
    stroke-dashoffset: 425px;
  }
  100% {
    stroke-dashoffset: 136px;
  }
}
@keyframes score-69 {
  0% {
    stroke-dashoffset: 425px;
  }
  100% {
    stroke-dashoffset: 131.75px;
  }
}
@keyframes score-70 {
  0% {
    stroke-dashoffset: 425px;
  }
  100% {
    stroke-dashoffset: 127.5px;
  }
}
@keyframes score-71 {
  0% {
    stroke-dashoffset: 425px;
  }
  100% {
    stroke-dashoffset: 123.25px;
  }
}
@keyframes score-72 {
  0% {
    stroke-dashoffset: 425px;
  }
  100% {
    stroke-dashoffset: 119px;
  }
}
@keyframes score-73 {
  0% {
    stroke-dashoffset: 425px;
  }
  100% {
    stroke-dashoffset: 114.75px;
  }
}
@keyframes score-74 {
  0% {
    stroke-dashoffset: 425px;
  }
  100% {
    stroke-dashoffset: 110.5px;
  }
}
@keyframes score-75 {
  0% {
    stroke-dashoffset: 425px;
  }
  100% {
    stroke-dashoffset: 106.25px;
  }
}
@keyframes score-76 {
  0% {
    stroke-dashoffset: 425px;
  }
  100% {
    stroke-dashoffset: 102px;
  }
}
@keyframes score-77 {
  0% {
    stroke-dashoffset: 425px;
  }
  100% {
    stroke-dashoffset: 97.75px;
  }
}
@keyframes score-78 {
  0% {
    stroke-dashoffset: 425px;
  }
  100% {
    stroke-dashoffset: 93.5px;
  }
}
@keyframes score-79 {
  0% {
    stroke-dashoffset: 425px;
  }
  100% {
    stroke-dashoffset: 89.25px;
  }
}
@keyframes score-80 {
  0% {
    stroke-dashoffset: 425px;
  }
  100% {
    stroke-dashoffset: 85px;
  }
}
@keyframes score-81 {
  0% {
    stroke-dashoffset: 425px;
  }
  100% {
    stroke-dashoffset: 80.75px;
  }
}
@keyframes score-82 {
  0% {
    stroke-dashoffset: 425px;
  }
  100% {
    stroke-dashoffset: 76.5px;
  }
}
@keyframes score-83 {
  0% {
    stroke-dashoffset: 425px;
  }
  100% {
    stroke-dashoffset: 72.25px;
  }
}
@keyframes score-84 {
  0% {
    stroke-dashoffset: 425px;
  }
  100% {
    stroke-dashoffset: 68px;
  }
}
@keyframes score-85 {
  0% {
    stroke-dashoffset: 425px;
  }
  100% {
    stroke-dashoffset: 63.75px;
  }
}
@keyframes score-86 {
  0% {
    stroke-dashoffset: 425px;
  }
  100% {
    stroke-dashoffset: 59.5px;
  }
}
@keyframes score-87 {
  0% {
    stroke-dashoffset: 425px;
  }
  100% {
    stroke-dashoffset: 55.25px;
  }
}
@keyframes score-88 {
  0% {
    stroke-dashoffset: 425px;
  }
  100% {
    stroke-dashoffset: 51px;
  }
}
@keyframes score-89 {
  0% {
    stroke-dashoffset: 425px;
  }
  100% {
    stroke-dashoffset: 46.75px;
  }
}
@keyframes score-90 {
  0% {
    stroke-dashoffset: 425px;
  }
  100% {
    stroke-dashoffset: 42.5px;
  }
}
@keyframes score-91 {
  0% {
    stroke-dashoffset: 425px;
  }
  100% {
    stroke-dashoffset: 38.25px;
  }
}
@keyframes score-92 {
  0% {
    stroke-dashoffset: 425px;
  }
  100% {
    stroke-dashoffset: 34px;
  }
}
@keyframes score-93 {
  0% {
    stroke-dashoffset: 425px;
  }
  100% {
    stroke-dashoffset: 29.75px;
  }
}
@keyframes score-94 {
  0% {
    stroke-dashoffset: 425px;
  }
  100% {
    stroke-dashoffset: 25.5px;
  }
}
@keyframes score-95 {
  0% {
    stroke-dashoffset: 425px;
  }
  100% {
    stroke-dashoffset: 21.25px;
  }
}
@keyframes score-96 {
  0% {
    stroke-dashoffset: 425px;
  }
  100% {
    stroke-dashoffset: 17px;
  }
}
@keyframes score-97 {
  0% {
    stroke-dashoffset: 425px;
  }
  100% {
    stroke-dashoffset: 12.75px;
  }
}
@keyframes score-98 {
  0% {
    stroke-dashoffset: 425px;
  }
  100% {
    stroke-dashoffset: 8.5px;
  }
}
@keyframes score-99 {
  0% {
    stroke-dashoffset: 425px;
  }
  100% {
    stroke-dashoffset: 4.25px;
  }
}
@keyframes score-100 {
  0% {
    stroke-dashoffset: 425px;
  }
  100% {
    stroke-dashoffset: 0px;
  }
}
.survey-report-card {
  lost-flex-container: row;
}

.survey-report-card-item {
  background: #ffffff;
  padding: 2rem 0;
  lost-column: 1/3;
  text-align: center;
}
.survey-report-summary.nps .survey-report-card-item {
  lost-column: 1/1;
}
.survey-reports--inverted .survey-report-card-item {
  background: #f9f9fb;
}

.survey-report-card-metric {
  font-size: 2.5rem;
  font-weight: 300;
  line-height: 4rem;
  color: #4d4d4d;
}

.survey-report-card-label {
  font-size: 0.9rem;
  text-transform: uppercase;
  color: #737373;
}

.landing-page {
  background: #f7f7f7;
  padding: 4rem 1rem;
}
@media (max-width: 768px) {
  .landing-page {
    padding: 1rem;
  }
}
.landing-page__container {
  max-width: 1180px;
  margin: 0 auto;
}
.landing-page--service {
  background-image: url("https://s3.amazonaws.com/uxrings/images/service-illustration.svg");
  background-repeat: no-repeat;
  background-position: top right;
  transition: all 0.25s ease-in-out;
}
@media (max-width: 1000px) {
  .landing-page--service {
    background-size: 60% auto;
    background-position: 140% 30%;
  }
}
@media (max-width: 768px) {
  .landing-page--service {
    padding-bottom: 300px;
    background-position: right 112%;
    background-size: 300px auto;
  }
}
.landing-page__form {
  max-width: 45%;
  margin: 3rem 0;
}
@media (max-width: 1000px) {
  .landing-page__form {
    max-width: 80%;
  }
}
@media (max-width: 768px) {
  .landing-page__form {
    max-width: 100%;
  }
}

.cx-companies {
  max-width: 50%;
}
@media (max-width: 768px) {
  .cx-companies {
    max-width: 100%;
    text-align: center;
  }
}

.list-company-logos {
  display: flex;
  align-content: center;
  align-items: center;
}
@media (max-width: 768px) {
  .list-company-logos {
    justify-content: center;
  }
}
.list-company-logos li {
  margin-right: 2rem;
  margin-bottom: 1.5rem;
  max-width: 130px;
}
.list-company-logos li:nth-child(3n) {
  margin-right: 0;
}
.list-company-logos li img {
  display: block;
  width: 100%;
  height: auto;
}

.list-checklist {
  margin: 2rem 0;
  padding: 0;
}
.list-checklist li {
  margin: 1.5rem 0;
  padding-left: 1.5rem;
  position: relative;
  font-size: 1.25rem;
  color: #636363;
}
@media (max-width: 768px) {
  .list-checklist li {
    font-size: 1rem;
  }
}
.list-checklist li:before {
  content: "";
  background: svg-load("../svg/checkmark.svg") no-repeat center;
  height: 16px;
  width: 16px;
  background-size: contain;
  position: absolute;
  left: 0;
}

.form-flex-row {
  display: flex;
  justify-content: space-between;
}
@media (max-width: 768px) {
  .form-flex-row {
    flex-direction: column;
  }
}
.form-flex-row .text-field {
  margin: 0 1.5rem 0 0;
  flex-basis: 60%;
}
@media (max-width: 768px) {
  .form-flex-row .text-field {
    margin-bottom: 1.5rem;
  }
}

.survey-share-container {
  flex: 1 1 1;
}

.dashboard--new .dashboard-product-details-container {
  position: relative;
}

.dashboard-product-details-header {
  align-items: center;
  display: flex;
}

.product-name-container {
  lost-column: 5/12;
}

.product-overview-container {
  display: flex;
}
@media (max-width: 768px) {
  .product-overview-container {
    lost-column: 1/1;
    text-align: center;
    margin-bottom: 2rem;
  }
}

.product-summary-chart {
  display: flex;
  flex-direction: column;
  align-items: center;
  align-content: center;
  flex-grow: 1;
  margin-right: 4rem;
}
@media (max-width: 768px) {
  .product-summary-chart {
    lost-column: 1/1;
    lost-move: 0;
    lost-center: 400px !important;
  }
}

.dashboard-product-name-container {
  font-size: 2.5rem;
  font-weight: 800;
  font-family: "Gotham A", "Gotham B", "Helvetica Neue", "Helvetica", Arial, sans-serif;
  color: #ffffff;
  color: #ffffff;
  margin-bottom: 2.5rem;
  height: 3.5rem;
}
@media (max-width: 600px) {
  .dashboard-product-name-container {
    font-size: 2.5rem;
  }
}

.dashboard-product-survey-stats {
  margin: 0.5rem 0 2rem;
  max-width: 460px;
}
@media (max-width: 768px) {
  .dashboard-product-survey-stats {
    margin-left: auto;
    margin-right: auto;
  }
}
.dashboard-product-survey-stats li {
  font-size: 1rem;
  line-height: 1.5rem;
  font-family: "Gotham A", "Gotham B", "Helvetica Neue", "Helvetica", Arial, sans-serif;
  font-weight: 400;
  margin-bottom: 1rem;
  color: #C7C7C7;
  font-size: 1.125rem;
  padding: 0.5rem 0;
  margin: 0;
}
.dashboard-product-survey-stats li:last-child {
  border-bottom: 1px solid #e5e5e9;
}
.dashboard-product-survey-stats li + li {
  border-top: 1px solid #e5e5e9;
}

.dashboard-product-score-container {
  display: flex;
  align-items: center;
  max-width: 400px;
  flex-wrap: wrap;
}
@media (max-width: 768px) {
  .dashboard-product-score-container {
    margin-left: auto;
    margin-right: auto;
  }
}

.score-block {
  display: block;
  padding: 0.75rem 1rem;
  background-color: #ffffff;
  margin-right: 0.75rem;
  box-sizing: border-box;
  flex-basis: calc(50% - 0.375rem);
  margin-bottom: 0.75rem;
}
.score-block:nth-child(even) {
  margin-right: 0;
}

.score-value {
  font-size: 1.5rem;
  font-weight: 600;
  line-height: 1.7rem;
  color: #4d4d4d;
  font-weight: 500;
  letter-spacing: -1px;
  margin-right: 0.25rem;
}

.score-label {
  color: #737373;
}

.improve-cta {
  margin-top: 5rem;
}
@media (max-width: 768px) {
  .improve-cta {
    margin: 1rem auto 0;
    flex-basis: 100%;
  }
}

.product-score--abridged {
  max-width: 100%;
}
@media (max-width: 768px) {
  .product-score--abridged {
    justify-content: center;
  }
}
.product-score--abridged .score-block {
  flex-basis: auto;
  margin-bottom: 0;
}

.pill-buttons-container {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
}
.pill-buttons-container:last-child {
  margin-top: 2rem;
}
.pill-buttons-container:not(:last-child) {
  margin-bottom: 2rem;
}
.pill-buttons-container p {
  margin-bottom: 0.5rem;
  font-size: 0.875rem;
  color: #737373;
  position: relative;
  z-index: 2;
}

.pill-buttons {
  background: #404040;
  height: 40px;
  overflow: hidden;
  display: flex;
  justify-content: center;
  border-radius: 99999px;
  padding: 3px;
  max-width: 40rem;
  position: relative;
}
@media (max-width: 768px) {
  .pill-buttons {
    max-width: calc(100% - 2rem);
  }
}

.section-label {
  text-transform: uppercase;
  color: #8a8a8a;
  font-size: 0.75rem;
  margin-bottom: 1rem;
  font-weight: 700;
}

.metric {
  flex: 1 1 0;
}

.section-metric {
  font-size: 1rem;
  line-height: 1rem;
}
.section-metric + .section-label {
  margin-top: 2rem;
}
.section-metric--big {
  font-size: 1.5rem;
}

.results-summary {
  border-radius: 8px;
  width: 92px;
  height: 83px;
  border: solid 4px #454545;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  flex-direction: column;
}
.results-summary + .results-summary {
  margin-left: 12px;
}
.results-summary-container {
  display: flex;
}
.results-summary-label {
  font-size: 0.75rem;
  font-weight: 400;
  color: #8a8a8a;
}
.results-summary-value {
  color: #ffffff;
  font-size: 1.5rem;
  font-weight: 450;
  margin-bottom: 6px;
  font-weight: 800;
}

.button-pill {
  font-weight: 600;
  border-radius: 99999px;
  white-space: nowrap;
  color: #C7C7C7;
  z-index: 1;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 800;
  padding: 0 2rem;
  box-sizing: border-box;
}
@media (max-width: 768px) {
  .button-pill {
    min-width: 0;
    font-size: 0.8rem;
  }
}
.button-pill--outline {
  background: none;
  border: solid 1px #454545;
  color: #454545;
}
.button-pill--collapse {
  min-width: unset;
}
.button-pill:focus {
  outline: 0;
}
.button-pill:hover:not(.active) {
  cursor: pointer;
  color: #ffffff;
}
.button-pill.active {
  background: #ffffff;
  color: #161616;
}
.button-pill.active a,
.button-pill.active a:hover {
  color: unset;
  cursor: none;
}

.dashboard-return {
  margin-left: 0.5rem;
  font-weight: bold;
}
@media (max-width: 768px) {
  .dashboard-return .dashboard-return-pretext {
    display: none;
  }
}
.dashboard:not(.dashboard--new) .dashboard-return {
  display: inline-block;
}

.dashboard-product-name {
  line-height: 1.25em;
  font-size: 2.5rem;
}
.dashboard:not(.dashboard--new) .dashboard-product-name {
  lost-column: 1/1;
  display: flex;
  align-items: center;
}
.dashboard:not(.dashboard--new) .dashboard-product-name > * {
  padding-bottom: 1rem;
}
.dashboard:not(.dashboard--new) .dashboard-product-name span {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  display: block;
}

.product-name-edit {
  font-size: 1.2rem;
  vertical-align: 6px;
  margin-left: 0.5rem;
}
.product-name-edit:hover svg path {
  fill: #ffffff;
}

.dashboard-product-name-form {
  display: none;
}
.dashboard-product-name-form > form {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.dashboard-product-name-form .text-field {
  font-size: 1.5rem;
  font-weight: 600;
  margin-bottom: 0rem;
}

.name-field-input {
  flex-basis: 85%;
}

.dashboard-return-container {
  flex-grow: 3;
}
.dashboard-return-container > a {
  display: flex;
  align-items: center;
}
.dashboard-return-container > a:hover svg path {
  fill: #ffffff;
}
.dashboard-return-container > a svg {
  margin-right: 0.5rem;
}

.dashboard-subnav-container {
  width: 100%;
}

.dashboard-spacer {
  flex-grow: 3;
}

.dashboard-subnav-container,
.dashboard:not(.dashboard--new) .dashboard-return-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  min-height: 48px;
}
.dashboard-subnav-container .icon-previous,
.dashboard:not(.dashboard--new) .dashboard-return-container .icon-previous {
  font-size: 2rem;
  margin-right: 0.5rem;
}
.dashboard-subnav-container a,
.dashboard:not(.dashboard--new) .dashboard-return-container a {
  font-size: 1rem;
}
@media (max-width: 768px) {
  .dashboard-subnav-container a,
.dashboard:not(.dashboard--new) .dashboard-return-container a {
    font-size: 0.8rem;
  }
}

.survey-share-tabs {
  display: flex;
  align-items: center;
}
.survey-share-tabs p {
  margin: 0 1rem 0 0;
}
@media (max-width: 768px) {
  .survey-share-tabs p {
    display: none;
  }
}
.modal .survey-share-tabs p {
  flex-grow: 1;
  text-align: left;
}
.modal .survey-share-tabs p.collapsed {
  display: none;
}
@media (max-width: 768px) {
  .modal .survey-share-tabs p.collapsed {
    display: block;
  }
  .modal .survey-share-tabs p.expanded {
    display: none;
  }
}
.modal .survey-share-tabs {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  height: 60px;
  padding: 0 1.5rem;
  background: #f9f9fb;
}

.share-tab {
  height: 48px;
  text-transform: uppercase;
  font-weight: bold;
  padding: 0 1rem;
  text-align: center;
}
.modal .share-tab {
  background: #f9f9fb;
  height: 100%;
}
.share-tab:hover {
  cursor: pointer;
}
.share-tab:focus {
  outline: 1px dotted #c1c1c1;
  outline-offset: -3px;
}
.share-tab.active {
  border-left: 1px solid #e6e6e6;
  border-right: 1px solid #e6e6e6;
  background: #f9f9fb;
}
.modal .share-tab.active {
  background: #ffffff;
}
@media (max-width: 768px) {
  .share-tab {
    padding: 0 0.8rem;
    font-size: 0.9rem;
  }
}

.modal .share-tab-container {
  margin-bottom: 50px;
}
.share-tab-container.hidden {
  display: none;
}

.survey-link-inner-container {
  align-items: center;
  lost-flex-container: row;
  margin-bottom: 1rem;
}

.survey-link-label,
.survey-embed-label {
  font-size: 1.1rem;
  font-weight: 600;
  text-transform: uppercase;
  line-height: 1.5rem;
  color: #4d4d4d;
  margin-bottom: 1rem;
  lost-column: 1/1;
}
@media (max-width: 600px) {
  .survey-link-label,
.survey-embed-label {
    text-align: center;
  }
}

.upgrade-notice {
  text-align: center;
  padding-top: 4rem;
  padding-bottom: 4rem;
  lost-column: 1/1;
}

.upgrade-notice-header {
  font-size: 1.5rem;
  font-weight: 800;
  line-height: 2.5rem;
  margin-bottom: 1rem;
}
@media (max-width: 600px) {
  .upgrade-notice-header {
    font-size: 1.2rem;
    line-height: 1.6rem;
  }
}

.score-circles {
  display: block;
}

.show-delete-product {
  display: none;
}
.product-card:hover .show-delete-product {
  display: block;
}
.show-delete-product:hover svg path {
  fill: #ffffff;
}

.show-delete-survey-response:hover {
  color: #ffffff;
}
.show-delete-survey-response:hover svg path {
  fill: #ffffff;
}

.score-circle {
  display: inline-block;
  margin-right: 2rem;
  margin-bottom: 2rem;
}
@media (max-width: 600px) {
  .score-circle {
    margin-right: 1rem;
    margin-bottom: 1rem;
  }
}

.survey-link-text {
  lost-column: 8/12;
}
.modal .survey-link-text {
  lost-column: 7/12;
}
@media (max-width: 600px) {
  .survey-link-text {
    lost-column: 1/1;
    text-align: center;
    margin-bottom: 1rem;
  }
}

.survey-link {
  margin-right: 2rem;
}

.survey-link-button {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  display: block;
  width: 100%;
}
.product-card .survey-link-button {
  width: auto;
  margin: 0 auto;
}

.survey-link-copy {
  lost-column: 4/12;
  text-align: right;
}
.modal .survey-link-copy {
  lost-column: 5/12;
}
@media (max-width: 600px) {
  .survey-link-copy {
    lost-column: 1/1;
    text-align: center;
  }
}

.survey-embed-copy {
  display: flex;
  lost-column: 1/1;
  align-items: center;
  margin-top: 1rem;
}
.survey-embed-copy p {
  margin: 0 0 0 1.5rem;
}
@media (max-width: 768px) {
  .survey-embed-copy {
    flex-direction: column;
  }
  .survey-embed-copy .survey-embed-button {
    margin-bottom: 1rem;
    width: 100%;
  }
  .survey-embed-copy p {
    margin: 0;
  }
}

.survey-report-chart {
  lost-column: 5/15;
}
@media (max-width: 600px) {
  .survey-report-chart {
    lost-column: 1/1;
    text-align: center;
    margin-bottom: 1rem;
  }
}

.survey-report-summary {
  lost-column: 10/15;
}
@media (max-width: 600px) {
  .survey-report-summary {
    lost-column: 1/1;
  }
}
.survey-report-summary.nps {
  lost-column: 15/15;
}

.survey-report-summary.nps .survey-report-comments {
  lost-column: 10/15;
  lost-offset: -5/15;
}
@media (max-width: 600px) {
  .survey-report-summary.nps .survey-report-comments {
    lost-column: 1/1;
    lost-offset: 0;
  }
}

@media (max-width: 600px) {
  .survey-report {
    padding-bottom: 3rem !important;
  }
  .survey-report:last-child {
    margin-bottom: 0;
  }
}

.hide-report-summary-analysis {
  display: none;
}
@media (max-width: 600px) {
  .hide-report-summary-analysis {
    text-align: center;
    lost-column: 1/1;
    font-size: 1.5rem;
  }
}

.survey-report-analysis-container {
  lost-flex-container: row;
  display: none;
}

.survey-report-analysis-collapse {
  lost-column: 1/20;
  padding-top: 1rem;
}

.survey-report-analysis {
  lost-column: 19/20;
}

.survey-report-view-container {
  margin-bottom: 2rem;
}

.survey-report-comment {
  font-size: 1rem;
  line-height: 1.5rem;
  font-family: "Gotham A", "Gotham B", "Helvetica Neue", "Helvetica", Arial, sans-serif;
  font-weight: 400;
  margin-bottom: 1rem;
  color: #C7C7C7;
  margin: 0;
  color: #C7C7C7;
}
.survey-report-comment p:last-child {
  margin: 0;
}

.report-rung-analysis-header {
  font-size: 1.1rem;
  font-weight: 600;
  text-transform: uppercase;
  line-height: 1.5rem;
  color: #4d4d4d;
  font-size: 1.5rem;
  font-weight: 900;
  color: #ffffff;
  text-transform: capitalize;
  margin-bottom: 1rem;
}

.survey-report-comment-question {
  font-size: 1.1rem;
  font-weight: 600;
  text-transform: uppercase;
  line-height: 1.5rem;
  color: #4d4d4d;
  text-transform: unset;
  color: #ffffff;
  font-size: 1rem;
  font-weight: 900;
}

.survey-report-view-less-comments {
  text-align: right;
}

.survey-report-metadata {
  lost-flex-container: row;
  align-items: center;
}
.survey-report-summary.nps .survey-report-metadata {
  margin-bottom: 0;
}

.survey-report-email {
  font-size: 1.5rem;
  font-weight: 800;
  line-height: 2.5rem;
  lost-column: 9/12;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 1.6rem;
}
@media (max-width: 600px) {
  .survey-report-email {
    font-size: 1.2rem;
    line-height: 1.6rem;
  }
}
@media (max-width: 600px) {
  .survey-report-email {
    lost-column: 1/1;
    margin-bottom: 1rem;
  }
}
.survey-report-summary.nps .survey-report-email {
  lost-column: 4/12;
}
@media (max-width: 600px) {
  .survey-report-summary.nps .survey-report-email {
    lost-column: 1/1;
  }
}

.survey-report-nps {
  font-size: 1.5rem;
  font-weight: 800;
  line-height: 2.5rem;
  lost-column: 5/12;
}
@media (max-width: 600px) {
  .survey-report-nps {
    font-size: 1.2rem;
    line-height: 1.6rem;
  }
}
@media (max-width: 600px) {
  .survey-report-nps {
    lost-column: 1/1;
    margin-bottom: 2rem;
  }
}

@media (max-width: 600px) {
  .survey-report-timestamp {
    lost-column: 1/1;
    text-align: left;
  }
}

.survey-reports-empty-headline {
  font-size: 1.5rem;
  font-weight: 800;
  line-height: 2.5rem;
  margin-bottom: 1rem;
  lost-column: 1/1;
}
@media (max-width: 600px) {
  .survey-reports-empty-headline {
    font-size: 1.2rem;
    line-height: 1.6rem;
  }
}

.survey-reports-empty {
  padding: 8rem 0;
  text-align: center;
}

.survey-reports-empty-tagline {
  font-size: 1rem;
  line-height: 1.5rem;
  font-family: "Gotham A", "Gotham B", "Helvetica Neue", "Helvetica", Arial, sans-serif;
  font-weight: 400;
  margin-bottom: 1rem;
  color: #C7C7C7;
  lost-column: 1/1;
}

.survey-reports-empty-icon {
  color: #fc662b;
  text-align: center;
  font-size: 7rem;
  lost-column: 1/1;
  margin-bottom: 2rem;
}

.survey-report-comments-preview {
  position: relative;
  display: table;
  width: 100%;
}
.survey-report-comments-preview .survey-report-comment {
  margin-bottom: 0;
}

.show-survey-report-comments {
  position: absolute;
  right: 0;
  top: 0;
  bottom: 0;
  width: 20rem;
  text-align: right;
  display: flex;
  justify-content: center;
  flex-direction: column;
  background: linear-gradient(to right, rgba(255, 255, 255, 0), rgb(249, 249, 251), rgb(249, 249, 251));
}

.sample-email {
  margin-top: 2rem;
  margin-bottom: 2rem;
  text-align: left;
}

.sample-email-header {
  text-align: center;
  font-size: 1.1rem;
  font-weight: 600;
  text-transform: uppercase;
  line-height: 1.5rem;
  color: #4d4d4d;
  margin-bottom: 1rem;
}

.search-description-container {
  font-size: 1rem;
  line-height: 1.5rem;
  font-family: "Gotham A", "Gotham B", "Helvetica Neue", "Helvetica", Arial, sans-serif;
  font-weight: 400;
  margin-bottom: 1rem;
  color: #C7C7C7;
  display: none;
  flex-grow: 1;
  align-items: center;
}
.search-description-container .search-description {
  flex-grow: 1;
}
.search-description-container .search-clear {
  font-weight: bold;
  cursor: pointer;
}

.filter-bank-container {
  flex-grow: 1;
  margin-bottom: 1.75rem;
}

.search-container {
  display: flex;
  align-items: center;
  margin-bottom: 0.5rem;
}

.text-field--controls {
  border-radius: 0.5rem;
  padding: 1rem;
  border: 1px solid #454545;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-grow: 1;
  height: 3.5rem;
  box-sizing: border-box;
}
.text-field--controls.active {
  border-color: #ffcb77;
  box-shadow: inset 0 0 0px 1px #ffcb77;
}
.text-field--controls.text-field--controls-standard.active {
  border-color: #6ac89b;
  box-shadow: inset 0 0 0px 1px #6ac89b;
}
@media (max-width: 768px) {
  .text-field--controls {
    border-width: 1px 0;
    flex-wrap: wrap;
    justify-content: space-between;
  }
}
.text-field--controls .remove-custom-question-button {
  display: none;
}
.text-field--controls-container:hover .remove-custom-question-button {
  display: block;
}

.filter-bank-bar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-top: 1rem;
  transition: all 0.25s cubic-bezier(0.645, 0.045, 0.355, 1);
}
@media (max-width: 768px) {
  .filter-bank-bar {
    flex-wrap: wrap;
    justify-content: flex-start;
  }
}

.saved-filters-label {
  white-space: nowrap;
  color: #8a8a8a;
  margin: 0;
  font-weight: 700;
}
@media (max-width: 768px) {
  .saved-filters-label {
    padding-left: 0;
    border-left: 0;
    order: 2;
  }
}

.results-shown-label {
  font-size: 2.5rem;
  font-weight: 800;
  font-family: "Gotham A", "Gotham B", "Helvetica Neue", "Helvetica", Arial, sans-serif;
  color: #ffffff;
  color: #ffffff;
  margin-bottom: 2rem;
  font-size: 2rem;
}
@media (max-width: 600px) {
  .results-shown-label {
    font-size: 2.5rem;
  }
}
@media (max-width: 768px) {
  .results-shown-label {
    order: 1;
  }
}

.text-field--controls-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-grow: 1;
}
@media (max-width: 768px) {
  .text-field--controls-container {
    flex-basis: 100%;
    order: 2;
    margin-right: 0;
  }
}
.text-field--controls-container:focus {
  border-bottom-color: #fc662b;
}
.text-field--controls-container input {
  border: 0;
  background: none;
  height: 100%;
  flex-grow: 1;
}
.text-field--controls-container input:focus {
  outline: 0;
}
.text-field--controls-container input:focus + .btn-submit-search {
  display: inline;
  margin-left: 1rem;
}
.text-field--controls-container .icon-search {
  margin-right: 0.5rem;
}

.btn-clear-search,
.btn-submit-search,
.btn-save-search {
  display: none;
}
.btn-clear-search.active,
.btn-submit-search.active,
.btn-save-search.active {
  display: block;
}

@media (max-width: 768px) {
  .btn-submit-search {
    display: none !important;
  }
}

.btn-save-search {
  padding: 0.5rem 1rem;
  color: #6ac89b;
  font-weight: 900;
}
@media (max-width: 768px) {
  .btn-save-search {
    flex-basis: 100%;
    order: 3;
    padding: 1rem 0;
    text-align: center;
  }
}

.search-form-input {
  font-size: 1rem;
  font-weight: 600;
  line-height: 42px;
  width: 100%;
  color: #ffffff;
}
.search-form-input:focus {
  outline: none;
  border-color: #ffcb77;
}
.search-form-input.active {
  color: #ffcb77;
  border-color: #ffcb77;
}

.uxr-date-picker {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex: 0 0 250px;
}
.uxr-date-picker .text-field {
  font-weight: 700;
}
.uxr-date-picker:after {
  content: "";
  height: 12px;
  width: 8px;
  background: svg-load("../svg/caret-left.svg");
  background-size: contain;
  background-repeat: no-repeat;
  transform: rotate(90deg);
  pointer-events: none;
  margin-right: 1.25rem;
}
.uxr-date-picker .text-field {
  margin-bottom: 0;
  padding-left: 1rem;
  padding-right: 1rem;
  border-radius: 0.5rem;
  padding: 1rem;
  border: 1px solid #454545;
  margin-right: -4.5rem;
}
.uxr-date-picker .text-field:hover {
  cursor: pointer;
}
@media (max-width: 768px) {
  .uxr-date-picker {
    order: 1;
    flex-basis: 100%;
    margin: 0 auto 1rem;
    display: flex;
    justify-content: space-between;
  }
  .uxr-date-picker:after {
    display: none;
  }
  .uxr-date-picker-desktop-input {
    display: none;
  }
}
.uxr-date-picker-mobile-input {
  display: none;
}
@media (max-width: 768px) {
  .uxr-date-picker-mobile-input {
    display: block;
    width: 0;
    flex-basis: 48%;
  }
}
.uxr-date-picker-mobile-input input {
  padding: 0.5rem;
  font-size: 0.875rem;
}
.uxr-date-picker-mobile-input input::-webkit-calendar-picker-indicator,
.uxr-date-picker-mobile-input input::-webkit-inner-spin-button {
  display: none;
  -webkit-appearance: none;
}

.search-results-title {
  font-size: 1.1rem;
  font-weight: 600;
  text-transform: uppercase;
  line-height: 1.5rem;
  color: #4d4d4d;
  margin: 0;
}
@media (max-width: 768px) {
  .search-results-title {
    flex-basis: 75%;
  }
}

.filter-bank {
  margin: 0 1rem;
  flex-grow: 1;
  display: flex;
  flex-wrap: wrap;
}
@media (max-width: 768px) {
  .filter-bank {
    margin: 1rem auto 0;
    align-items: flex-start;
    padding: 0;
    flex-basis: 100%;
    order: 3;
  }
}

.filter-tag-wrap {
  position: relative;
  width: auto;
  margin: 0 0.5rem 0 0;
}
@media (max-width: 768px) {
  .filter-tag-wrap {
    margin-bottom: 0.5rem;
  }
}
.filter-tag-wrap:hover .filter-tag {
  padding-right: 2rem;
}
.filter-tag-wrap:hover .filter-tag + .filter-remove {
  visibility: visible;
  transform: translateY(-50%) scale(1);
  opacity: 1;
  transition-delay: 0.15s;
}

.filter-tag {
  font-size: 0.75rem;
  height: 30px;
  line-height: 25px;
  border-radius: 99999px;
  background: #303030;
  padding: 0 1rem;
  color: #ffffff;
  transition: all 0.25s cubic-bezier(0.645, 0.045, 0.355, 1);
  margin: 0.15rem 0;
  font-weight: bold;
}
.filter-tag:focus, .filter-tag:active {
  outline: 0;
  box-shadow: 0 0 4px rgba(77, 77, 77, 0.3);
}
.filter-tag.active {
  background: #ffffff;
  color: #454545;
}

.filter-remove {
  visibility: hidden;
  opacity: 0;
  position: absolute;
  height: 10px;
  width: 10px;
  right: 0.5rem;
  top: 50%;
  transition: all 0.25s cubic-bezier(0.175, 0.885, 0.32, 1.275);
  transform: translateY(-50%) scale(0);
}
.filter-remove:hover .icon-close {
  stroke: #ffffff;
}
.filter-remove svg {
  height: 100%;
  width: 100%;
  display: block;
}
.active + .filter-remove .icon-close {
  stroke: #8a8a8a;
}
.active + .filter-remove:hover .icon-close {
  stroke: #454545;
}

.filter-bank-close {
  position: relative;
}
.filter-bank-close span {
  position: absolute;
  visibility: hidden;
  opacity: 0;
  font-size: 0.75rem;
  line-height: 1rem;
  color: #737373;
  right: 1.25rem;
  overflow: hidden;
  white-space: nowrap;
  text-align: right;
  transition: all 0.25s cubic-bezier(0.645, 0.045, 0.355, 1);
  transform: translateX(3px);
}
.filter-bank-close:hover span {
  opacity: 1;
  visibility: visible;
  transform: translateX(0);
}
@media (max-width: 768px) {
  .filter-bank-close {
    order: 2;
    flex-basis: 25%;
    text-align: right;
  }
  .filter-bank-close span {
    opacity: 1;
    visibility: visible;
    transform: translate(0);
  }
}

.sentiment--very_bad {
  color: #db694e;
}

.sentiment--bad {
  color: #ffcb77;
}

.sentiment--neutral {
  color: #c7c7c7;
}

.sentiment--good {
  color: #5c99c9;
}

.sentiment--very_good {
  color: #6ac89b;
}

.pill,
.sentiment-pill {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  border-radius: 9999999px;
  line-height: 100%;
  font-weight: 800;
  font-size: 0.75rem;
  min-height: 2rem;
  padding: 0.25rem 1rem;
  box-sizing: border-box;
  position: relative;
}

a.pill:hover svg,
a.pill:hover svg path {
  fill: #ffffff;
}

.pill {
  background: rgba(199, 199, 199, 0.2);
  color: #C7C7C7;
  box-sizing: border-box;
  line-height: 1rem;
  text-align: center;
}
.pill svg + * {
  margin-left: 0.5rem;
}
.pill--small {
  min-height: 1.5rem;
  padding: 0.25rem 0.75rem;
}
.pill--outline {
  background: none;
  border: solid 1px #454545;
  color: #8a8a8a;
}
.pill--outline svg {
  fill: #8a8a8a;
}
.pill--tab {
  cursor: pointer;
}
.pill--tab:hover {
  color: #ffffff;
}
.pill--tab:hover svg {
  fill: #ffffff;
}
.pill + .pill {
  margin-left: 0.5rem;
}

.sentiment-pill--lg {
  font-size: 1.5rem;
  height: 2.5rem;
}
.sentiment-pill.very_bad {
  background: rgba(219, 105, 78, 0.2);
  color: #db694e;
}
.sentiment-pill.bad {
  background: rgba(255, 203, 119, 0.2);
  color: #ffcb77;
}
.sentiment-pill.neutral {
  background: rgba(199, 199, 199, 0.2);
  color: #c7c7c7;
}
.sentiment-pill.good {
  background: rgba(92, 153, 201, 0.2);
  color: #5c99c9;
}
.sentiment-pill.very_good {
  background: rgba(106, 200, 155, 0.2);
  color: #6ac89b;
}
@media (max-width: 768px) {
  .sentiment-pill {
    margin: 0;
    height: 85px;
    width: 30%;
  }
}

.results-view-tab {
  display: none !important;
  border-top-left-radius: 0px;
  border-top-right-radius: 0px;
  margin-top: 0;
}
.results-view-tab.active {
  display: block !important;
}
.results-view-tab > h3 {
  padding-bottom: 1rem;
  border-bottom: 1px solid #e5e5e9;
}

.question-result {
  display: block;
}
.question-result-sentiment {
  font-size: 1.5rem;
  margin-bottom: 0.5rem;
}
.question-result-explainer {
  display: none;
  position: absolute;
  bottom: calc(100% + 0.5rem);
  right: 50%;
  transform: translateX(50%);
  width: 304px;
  box-shadow: 0 0 16px 0 rgba(0, 0, 0, 0.25);
}
.question-result-explainer-description {
  margin-bottom: 0.5rem;
}
.question-result-explainer-count {
  color: #8a8a8a;
  font-size: 0.875rem;
  margin: 0;
}
.question-result-expander {
  margin-right: 2.25rem;
}
.question-result-expander--expanded {
  transform: rotate(90deg);
}
.question-result-metabox {
  position: relative;
  margin-left: 2rem;
}
.question-result-metabox:hover .question-result-explainer {
  display: block;
}
.question-result-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
@media (max-width: 768px) {
  .question-result-header {
    flex-wrap: wrap;
  }
}
.question-result-header:not(.question-result-header--disabled):hover {
  cursor: pointer;
}
.question-result-header:not(.question-result-header--disabled):hover svg path {
  fill: #ffffff;
}
.question-result-title {
  flex-grow: 1;
}
@media (max-width: 768px) {
  .question-result-title {
    flex-basis: 100%;
    margin-bottom: 1.5rem;
  }
}
.question-result-nps-label {
  font-size: 1.1rem;
  font-weight: 600;
  text-transform: uppercase;
  line-height: 1.5rem;
  color: #4d4d4d;
}
.question-result-ask {
  font-size: 1.5rem;
  font-weight: 800;
  line-height: 2.5rem;
  line-height: 1.75rem;
}
@media (max-width: 600px) {
  .question-result-ask {
    font-size: 1.2rem;
    line-height: 1.6rem;
  }
}
.question-result-body {
  margin: 1rem 0 2rem 0;
}
@media (max-width: 768px) {
  .question-result-body {
    max-width: 100%;
    padding: 1rem;
  }
}
.question-result-answer-body {
  display: flex;
}
.question-result-answer-item {
  font-size: 1.125rem;
  font-weight: 300;
  line-height: 1.75rem;
}
.question-result-answer-header {
  display: flex;
  justify-content: space-between;
  align-items: baseline;
}
@media (max-width: 768px) {
  .question-result-answer-header {
    flex-direction: column;
    margin-bottom: 0.5rem;
  }
}
.question-result-answer-author {
  font-weight: 500;
}
.question-result-answer-details {
  flex: 1 1 auto;
}
.question-result-answer-label {
  margin-right: 2rem;
  flex: 0 0 150px;
}
.question-result-answer-datetime {
  font-size: 0.875rem;
  color: #8a8a8a;
  flex: 1 0 250px;
  text-align: right;
}
.question-comment-number {
  font-size: 1.5rem;
  font-weight: 800;
  line-height: 2.5rem;
  font-size: 45px;
}
@media (max-width: 600px) {
  .question-comment-number {
    font-size: 1.2rem;
    line-height: 1.6rem;
  }
}
@media (max-width: 768px) {
  .question-comment-number {
    font-size: 2rem;
  }
}

.question-average-label {
  font-size: 1.5rem;
  font-weight: 300;
  color: #1b5fa6;
  text-align: center;
}
@media (max-width: 768px) {
  .question-average-label {
    font-size: 1.125rem;
  }
}

.question-sentiment {
  position: relative;
}

.question-sentiment-indicator {
  font-size: 1.5rem;
  font-weight: 800;
  line-height: 2.5rem;
  font-size: 30px;
}
@media (max-width: 600px) {
  .question-sentiment-indicator {
    font-size: 1.2rem;
    line-height: 1.6rem;
  }
}
@media (max-width: 768px) {
  .question-sentiment-indicator {
    font-size: 1.5rem;
  }
}

.sentiment--very_bad {
  color: #db694e;
}

.sentiment--bad {
  color: #ffcb77;
}

.sentiment--neutral {
  color: #c7c7c7;
}

.sentiment--good {
  color: #5c99c9;
}

.sentiment--very_good {
  color: #6ac89b;
}

.meta-label {
  font-size: 0.9rem;
  text-transform: uppercase;
  color: #737373;
}

.preview-survey-container {
  color: text-light;
}

::selection,
.comment-highlight {
  color: #454545;
  background: #ffcb77;
}

.question-result-sentiment {
  color: #6ac89b;
}

.survey-share-options-container {
  display: flex;
  align-items: flex-start;
  flex-grow: 1;
  margin-top: 4rem;
  position: relative;
}
.survey-share-options-container .survey-link,
.survey-share-options-container .survey-code {
  padding: 0.5rem 1rem;
  font-size: 1.25rem;
  line-height: 2.2rem;
  color: #737373;
  font-weight: 300;
}
.survey-share-options-container .survey-link::selection,
.survey-share-options-container .survey-code::selection {
  color: #4d4d4d;
}
.survey-share-options-column {
  flex-basis: 100%;
}
@media (max-width: 768px) {
  .survey-share-options-column {
    flex-basis: 100%;
    margin-bottom: 3rem;
    margin-right: auto;
    margin-left: auto;
  }
  .survey-share-options-column:first-child {
    border-bottom: 1px solid #e5e5e9;
    padding-bottom: 3rem;
  }
}

.survey-link-header,
.survey-embed-header {
  margin-bottom: 0.5rem;
}

.survey-link-form {
  display: flex;
}
@media (max-width: 768px) {
  .survey-link-form {
    flex-wrap: wrap;
  }
}
.survey-link-form .survey-link {
  flex-grow: 1;
  margin: 0;
  border-right-width: 0;
  flex-basis: 70%;
  width: auto;
}
@media (max-width: 768px) {
  .survey-link-form .survey-link {
    border-right-width: 1px;
    flex-basis: 100%;
    margin-bottom: 1rem;
  }
}
.survey-link-form .survey-link-button {
  width: auto;
}
@media (min-width: 768px) {
  .survey-link-form .survey-link-button {
    padding-top: 0;
    padding-bottom: 0;
  }
}
@media (max-width: 768px) {
  .survey-link-form .survey-link-button {
    flex-basis: 100%;
  }
}

.is-loading {
  padding: 10rem 0;
  position: relative;
}
.is-loading:before {
  content: "";
  height: 20px;
  width: 25px;
  display: block;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  transform-origin: center;
  animation: loader 2s cubic-bezier(0.645, 0.045, 0.355, 1) infinite;
  background: svg-load("../svg/logo-icon.svg");
  background-repeat: no-repeat;
  background-size: cover;
}
.is-loading > * {
  display: none;
}

@keyframes loader {
  0% {
    transform: scale(1);
    opacity: 0.5;
  }
  50% {
    transform: scale(2);
    opacity: 1;
  }
  100% {
    transform: scale(1);
    opacity: 0.5;
  }
}
.survey-required-comments {
  margin-bottom: 2rem;
}

.screenshot-uploading {
  animation: spin 1s linear infinite;
}

@keyframes spin {
  100% {
    transform: rotate(360deg);
  }
}
.shadow {
  box-shadow: 0px 0px 16px rgba(0, 0, 0, 0.25);
}

.item-label {
  color: #8a8a8a;
  text-transform: uppercase;
  font-size: 0.75rem;
  font-weight: bold;
}

.ladder-step-bar-container {
  width: 80px;
}

.ladder-step-bar {
  background: #454545;
  border-radius: 99999px;
  box-sizing: border-box;
  height: 2px;
  z-index: 9999;
  flex: 0 0 80px;
  margin-left: 6px;
}
.ladder-step-bar:not(:first-child) {
  margin-top: 2px;
}
.ladder-step-bar--tiny {
  height: 10px;
  margin-right: -4px;
}
.ladder-step-bar--filled {
  background: #ffcb77;
}
.ladder-step-bar--highlight {
  background: #6ac89b;
  box-sizing: content-box;
  overflow: visible;
  position: relative;
  margin-bottom: 0px;
}

.ladder:not(.ladder--tiny) .ladder-average-marker-pointer:hover {
  width: 112px;
}
.ladder:not(.ladder--tiny) .ladder-average-marker-pointer, .ladder:not(.ladder--tiny) .ladder-average-marker-pointer:hover {
  transition: width 0.25s ease-in-out;
}

.ladder-average-marker {
  width: 100px;
  color: #ffffff;
  font-weight: 600;
  font-size: 1.25rem;
  display: flex;
  justify-content: center;
  align-items: center;
  align-content: center;
  position: absolute;
  width: 50px;
  height: 28px;
  right: 50%;
  transform: translateX(50%);
  z-index: 9999;
}
.ladder-average-marker--scale {
  display: none;
}
.ladder--tiny .ladder-average-marker {
  transform: translateX(200%) scale(3.25);
  transform-origin: top center;
  right: 0%;
}
.ladder-average-marker-pointer {
  right: -2rem;
  transform: none;
}
.ladder-average-marker, .ladder-average-marker:before {
  border-radius: 4px;
  background: #6ac89b;
}
.ladder-average-marker-pointer {
  justify-content: flex-start;
  left: 90%;
  width: 44px;
  padding-left: 0.4rem;
  box-sizing: border-box;
}
.ladder-average-marker-pointer .ladder-average-marker--amount {
  width: 31px;
  text-align: right;
  display: inline-block;
}
.ladder-average-marker-pointer .ladder-average-marker--scale {
  display: inline;
}
.ladder-average-marker-pointer:before {
  position: absolute;
  content: "";
  height: 22px;
  width: 22px;
  top: 50%;
  transform: translateY(-50%) rotate(45deg);
  left: -9px;
}
.ladder-average-marker-label {
  z-index: 99999;
  overflow: hidden;
  white-space: nowrap;
}

.ladder-section {
  display: flex;
}

.ladder-container {
  position: relative;
}
.ladder-container:not(:first-child) + .ladder-container {
  margin-left: 3rem;
}
.ladder-container--hover:before {
  position: absolute;
  content: "";
  background: #ffffff;
  opacity: 0;
  border-radius: 8px;
  top: -1.5rem;
  bottom: -1.5rem;
  left: -1.5rem;
  right: -1.5rem;
}
.ladder-container--hover:hover:before {
  opacity: 0.05;
}

.ladder {
  display: flex;
  flex-direction: column;
  height: 310px;
  position: relative;
  width: 350px;
}
.ladder-section--individual-response .ladder--small {
  transform-origin: top center;
  margin-bottom: 0;
}
.ladder:before, .ladder:after {
  background: #454545;
  border-radius: 99999px;
  box-sizing: border-box;
  position: absolute;
  content: "";
  width: 4px;
  z-index: 999;
  height: 310px;
}
.ladder:before {
  left: 138px;
}
.ladder:after {
  left: 220px;
}
.ladder--no-highlight {
  width: 205px;
}
.ladder--no-highlight:before {
  left: 131px;
}
.ladder--no-highlight:after {
  left: 208px;
}
.ladder--no-labels, .ladder--no-segment-labels {
  flex: 1 0 80px;
  margin-left: 0;
}
.ladder--no-labels:after, .ladder--no-segment-labels:after {
  left: 82px;
}
.ladder--no-labels:before, .ladder--no-segment-labels:before {
  left: 0;
}
.ladder--no-labels {
  width: 86px;
}
.ladder--no-labels .ladder-average-marker-pointer {
  left: 80%;
}
.ladder--no-segment-labels {
  width: 300px;
}
.ladder--tiny {
  transform: scale(0.2);
  transform-origin: top right;
  margin-bottom: -20rem;
  height: 305px;
  width: 225px;
}
.ladder--tiny:before, .ladder--tiny:after {
  width: 10px;
}
.ladder--tiny:before {
  left: 135px;
}
.ladder--tiny:after {
  left: 217px;
}
.ladder--tiny .ladder-average-marker--scale {
  display: none;
}
.ladder--small {
  transform: scale(0.55);
  transform-origin: top left;
  margin-bottom: -10rem;
}
.ladder--hide-ladder {
  width: 150px;
}
.ladder--hide-ladder:before, .ladder--hide-ladder:after {
  display: none;
}

.ladder-section--improve {
  margin-left: 6rem;
  margin-right: 3rem;
  flex: 1 0 auto;
}

.ladder-step-label {
  flex: 0 0 calc(138px - 1.5rem);
}
.ladder--tiny .ladder-step-label {
  font-size: 4rem;
  color: #6ac89b;
}

.ladder-step-score {
  margin-right: 2rem;
  text-align: right;
}

.ladder-step-name {
  margin-right: 1.125rem;
  text-align: right;
}
.ladder--hide-ladder .ladder-step-name {
  margin-right: 3rem;
}

.ladder-step {
  display: flex;
  align-items: center;
  z-index: 9999;
  width: 100%;
  flex: 1 1 0;
}
.ladder--hide-ladder .ladder-step {
  justify-content: flex-end;
}

.dial {
  position: relative;
  height: 180px;
  width: 180px;
  margin-bottom: 3rem;
}
.dial--small {
  transform: scale(0.65);
  transform-origin: top right;
}
.dial-container--individual-response .dial--small {
  transform-origin: top center;
}
.dial:before {
  position: absolute;
  border-radius: 50%;
  left: 50%;
  top: 50%;
  transform: translateX(-50%) translateY(-50%);
  content: "";
  height: 32px;
  width: 32px;
  background: #272727;
  z-index: 99999;
}

.dial-ring-cap-start,
.dial-ring-cap-end {
  content: "";
  position: absolute;
  border-radius: 50%;
  height: 10px;
  width: 10px;
  background: #8a8a8a;
}

.dial-ring-cap-start {
  top: 0%;
  left: 50%;
  transform: translateX(-50%);
}

.dial-ring-progress {
  position: relative;
  overflow: hidden;
  width: 100%;
  height: 100%;
  clip-path: polygon(50% 0, 100% 0, 100% 100%, 50% 100%);
}
.dial-ring-progress:before, .dial-ring-progress:not(.dial-ring-progress--less-than-halfway):after {
  content: "";
  position: absolute;
  background: #8a8a8a;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  overflow: hidden;
  border-radius: 50%;
  transform-origin: center;
}
.dial-ring-progress:before {
  clip-path: polygon(0 0, 50% 0, 50% 100%, 0 100%);
}
.dial-ring-progress:not(.dial-ring-progress--less-than-halfway):after {
  opacity: 0;
  clip-path: polygon(50% 0, 100% 0, 100% 100%, 50% 100%);
}

.dial-ring {
  position: absolute;
  border-radius: 50%;
  left: 50%;
  top: 50%;
  transform: translateX(-50%) translateY(-50%);
  background: #303030;
  box-sizing: border-box;
  overflow: hidden;
}
.dial-ring--active .dial-ring-progress:before,
.dial-ring--active .dial-ring-progress:after,
.dial-ring--active .dial-ring-cap-start,
.dial-ring--active .dial-ring-cap-end {
  background: #6ac89b;
}
.dial-ring--spacer:not(:last-child):nth-child(2) {
  position: absolute;
  border-radius: 50%;
  left: 50%;
  top: 50%;
  transform: translateX(-50%) translateY(-50%);
  width: calc(180px - calc(
        1 *
          10px *
          2 +
          ((1 - 1) * 6px * 2)
      ));
  height: calc(180px - calc(
        1 *
          10px *
          2 +
          ((1 - 1) * 6px * 2)
      ));
  background: #272727;
}
.dial-ring:nth-child(1) {
  width: calc(
        180px - (calc(0 * 10px * 2) + calc(
        0 * 6px * 2
      ))
      );
  height: calc(
        180px - (calc(0 * 10px * 2) + calc(
        0 * 6px * 2
      ))
      );
}
.dial-ring:nth-child(1) .dial-ring-progress:before {
  animation-delay: 0.4s;
  animation-timing-function: ease-in-out;
  animation-duration: 0.6s;
  animation-name: rotation-1;
  animation-fill-mode: forwards;
}
@keyframes rotation-1 {
  to {
    transform: rotate(var(--angle));
  }
}
.dial-ring:nth-child(1) .dial-ring-progress:not(.dial-ring-progress--less-than-halfway) {
  animation-name: show-left-hemisphere-1;
}
.dial-ring:nth-child(1) .dial-ring-progress:not(.dial-ring-progress--less-than-halfway), .dial-ring:nth-child(1) .dial-ring-progress:not(.dial-ring-progress--less-than-halfway):after {
  animation-timing-function: linear;
  animation-duration: 0s;
  animation-fill-mode: forwards;
}
.dial-ring:nth-child(1) .dial-ring-progress:not(.dial-ring-progress--less-than-halfway).dial-ring-progress--segments-3, .dial-ring:nth-child(1) .dial-ring-progress:not(.dial-ring-progress--less-than-halfway).dial-ring-progress--segments-3:after {
  animation-delay: calc(calc(
            var(--percent-complete-at-half-filled) *
              0.6s +
              0.4s
          ) - 0.075s);
}
.dial-ring:nth-child(1) .dial-ring-progress:not(.dial-ring-progress--less-than-halfway).dial-ring-progress--segments-4, .dial-ring:nth-child(1) .dial-ring-progress:not(.dial-ring-progress--less-than-halfway).dial-ring-progress--segments-4:after {
  animation-delay: calc(calc(
            var(--percent-complete-at-half-filled) *
              0.6s +
              0.4s
          ) - 0.03s);
}
.dial-ring:nth-child(1) .dial-ring-progress:not(.dial-ring-progress--less-than-halfway).dial-ring-progress--segments-5, .dial-ring:nth-child(1) .dial-ring-progress:not(.dial-ring-progress--less-than-halfway).dial-ring-progress--segments-5:after {
  animation-delay: calc(
            var(--percent-complete-at-half-filled) *
              0.6s +
              0.4s
          );
}
@keyframes show-left-hemisphere-1 {
  to {
    clip-path: none;
  }
}
.dial-ring:nth-child(1) .dial-ring-progress:not(.dial-ring-progress--less-than-halfway):after {
  animation-name: fill-right-hemisphere-1;
}
@keyframes fill-right-hemisphere-1 {
  to {
    opacity: 1;
  }
}
.dial-ring:nth-child(1) .dial-ring-cap-end {
  left: 50%;
  top: 50%;
  transform-origin: center;
  z-index: 9999999999999;
  animation-name: rotate-ring-cap-1;
  animation-delay: 0.4s;
  animation-timing-function: ease-in-out;
  animation-duration: 0.6s;
  animation-fill-mode: forwards;
  transform: translate(-50%, -50%) rotate(-90deg) translate(calc(calc( 180px - (calc(0 * 10px * 2) + calc( 0 * 6px * 2 )) ) / 2 - 10px / 2));
}
@keyframes rotate-ring-cap-1 {
  0% {
    transform: translate(-50%, -50%) rotate(calc(var(--angle) * (0 / 100) - 90deg)) translate(calc(calc( 180px - (calc(0 * 10px * 2) + calc( 0 * 6px * 2 )) ) / 2 - 10px / 2));
  }
}
.dial-ring:nth-child(1) .dial-ring-cap-end {
  left: 50%;
  top: 50%;
  transform-origin: center;
  z-index: 9999999999999;
  animation-name: rotate-ring-cap-1;
  animation-delay: 0.4s;
  animation-timing-function: ease-in-out;
  animation-duration: 0.6s;
  animation-fill-mode: forwards;
  transform: translate(-50%, -50%) rotate(-90deg) translate(calc(calc( 180px - (calc(0 * 10px * 2) + calc( 0 * 6px * 2 )) ) / 2 - 10px / 2));
}
@keyframes rotate-ring-cap-1 {
  1% {
    transform: translate(-50%, -50%) rotate(calc(var(--angle) * (1 / 100) - 90deg)) translate(calc(calc( 180px - (calc(0 * 10px * 2) + calc( 0 * 6px * 2 )) ) / 2 - 10px / 2));
  }
}
.dial-ring:nth-child(1) .dial-ring-cap-end {
  left: 50%;
  top: 50%;
  transform-origin: center;
  z-index: 9999999999999;
  animation-name: rotate-ring-cap-1;
  animation-delay: 0.4s;
  animation-timing-function: ease-in-out;
  animation-duration: 0.6s;
  animation-fill-mode: forwards;
  transform: translate(-50%, -50%) rotate(-90deg) translate(calc(calc( 180px - (calc(0 * 10px * 2) + calc( 0 * 6px * 2 )) ) / 2 - 10px / 2));
}
@keyframes rotate-ring-cap-1 {
  2% {
    transform: translate(-50%, -50%) rotate(calc(var(--angle) * (2 / 100) - 90deg)) translate(calc(calc( 180px - (calc(0 * 10px * 2) + calc( 0 * 6px * 2 )) ) / 2 - 10px / 2));
  }
}
.dial-ring:nth-child(1) .dial-ring-cap-end {
  left: 50%;
  top: 50%;
  transform-origin: center;
  z-index: 9999999999999;
  animation-name: rotate-ring-cap-1;
  animation-delay: 0.4s;
  animation-timing-function: ease-in-out;
  animation-duration: 0.6s;
  animation-fill-mode: forwards;
  transform: translate(-50%, -50%) rotate(-90deg) translate(calc(calc( 180px - (calc(0 * 10px * 2) + calc( 0 * 6px * 2 )) ) / 2 - 10px / 2));
}
@keyframes rotate-ring-cap-1 {
  3% {
    transform: translate(-50%, -50%) rotate(calc(var(--angle) * (3 / 100) - 90deg)) translate(calc(calc( 180px - (calc(0 * 10px * 2) + calc( 0 * 6px * 2 )) ) / 2 - 10px / 2));
  }
}
.dial-ring:nth-child(1) .dial-ring-cap-end {
  left: 50%;
  top: 50%;
  transform-origin: center;
  z-index: 9999999999999;
  animation-name: rotate-ring-cap-1;
  animation-delay: 0.4s;
  animation-timing-function: ease-in-out;
  animation-duration: 0.6s;
  animation-fill-mode: forwards;
  transform: translate(-50%, -50%) rotate(-90deg) translate(calc(calc( 180px - (calc(0 * 10px * 2) + calc( 0 * 6px * 2 )) ) / 2 - 10px / 2));
}
@keyframes rotate-ring-cap-1 {
  4% {
    transform: translate(-50%, -50%) rotate(calc(var(--angle) * (4 / 100) - 90deg)) translate(calc(calc( 180px - (calc(0 * 10px * 2) + calc( 0 * 6px * 2 )) ) / 2 - 10px / 2));
  }
}
.dial-ring:nth-child(1) .dial-ring-cap-end {
  left: 50%;
  top: 50%;
  transform-origin: center;
  z-index: 9999999999999;
  animation-name: rotate-ring-cap-1;
  animation-delay: 0.4s;
  animation-timing-function: ease-in-out;
  animation-duration: 0.6s;
  animation-fill-mode: forwards;
  transform: translate(-50%, -50%) rotate(-90deg) translate(calc(calc( 180px - (calc(0 * 10px * 2) + calc( 0 * 6px * 2 )) ) / 2 - 10px / 2));
}
@keyframes rotate-ring-cap-1 {
  5% {
    transform: translate(-50%, -50%) rotate(calc(var(--angle) * (5 / 100) - 90deg)) translate(calc(calc( 180px - (calc(0 * 10px * 2) + calc( 0 * 6px * 2 )) ) / 2 - 10px / 2));
  }
}
.dial-ring:nth-child(1) .dial-ring-cap-end {
  left: 50%;
  top: 50%;
  transform-origin: center;
  z-index: 9999999999999;
  animation-name: rotate-ring-cap-1;
  animation-delay: 0.4s;
  animation-timing-function: ease-in-out;
  animation-duration: 0.6s;
  animation-fill-mode: forwards;
  transform: translate(-50%, -50%) rotate(-90deg) translate(calc(calc( 180px - (calc(0 * 10px * 2) + calc( 0 * 6px * 2 )) ) / 2 - 10px / 2));
}
@keyframes rotate-ring-cap-1 {
  6% {
    transform: translate(-50%, -50%) rotate(calc(var(--angle) * (6 / 100) - 90deg)) translate(calc(calc( 180px - (calc(0 * 10px * 2) + calc( 0 * 6px * 2 )) ) / 2 - 10px / 2));
  }
}
.dial-ring:nth-child(1) .dial-ring-cap-end {
  left: 50%;
  top: 50%;
  transform-origin: center;
  z-index: 9999999999999;
  animation-name: rotate-ring-cap-1;
  animation-delay: 0.4s;
  animation-timing-function: ease-in-out;
  animation-duration: 0.6s;
  animation-fill-mode: forwards;
  transform: translate(-50%, -50%) rotate(-90deg) translate(calc(calc( 180px - (calc(0 * 10px * 2) + calc( 0 * 6px * 2 )) ) / 2 - 10px / 2));
}
@keyframes rotate-ring-cap-1 {
  7% {
    transform: translate(-50%, -50%) rotate(calc(var(--angle) * (7 / 100) - 90deg)) translate(calc(calc( 180px - (calc(0 * 10px * 2) + calc( 0 * 6px * 2 )) ) / 2 - 10px / 2));
  }
}
.dial-ring:nth-child(1) .dial-ring-cap-end {
  left: 50%;
  top: 50%;
  transform-origin: center;
  z-index: 9999999999999;
  animation-name: rotate-ring-cap-1;
  animation-delay: 0.4s;
  animation-timing-function: ease-in-out;
  animation-duration: 0.6s;
  animation-fill-mode: forwards;
  transform: translate(-50%, -50%) rotate(-90deg) translate(calc(calc( 180px - (calc(0 * 10px * 2) + calc( 0 * 6px * 2 )) ) / 2 - 10px / 2));
}
@keyframes rotate-ring-cap-1 {
  8% {
    transform: translate(-50%, -50%) rotate(calc(var(--angle) * (8 / 100) - 90deg)) translate(calc(calc( 180px - (calc(0 * 10px * 2) + calc( 0 * 6px * 2 )) ) / 2 - 10px / 2));
  }
}
.dial-ring:nth-child(1) .dial-ring-cap-end {
  left: 50%;
  top: 50%;
  transform-origin: center;
  z-index: 9999999999999;
  animation-name: rotate-ring-cap-1;
  animation-delay: 0.4s;
  animation-timing-function: ease-in-out;
  animation-duration: 0.6s;
  animation-fill-mode: forwards;
  transform: translate(-50%, -50%) rotate(-90deg) translate(calc(calc( 180px - (calc(0 * 10px * 2) + calc( 0 * 6px * 2 )) ) / 2 - 10px / 2));
}
@keyframes rotate-ring-cap-1 {
  9% {
    transform: translate(-50%, -50%) rotate(calc(var(--angle) * (9 / 100) - 90deg)) translate(calc(calc( 180px - (calc(0 * 10px * 2) + calc( 0 * 6px * 2 )) ) / 2 - 10px / 2));
  }
}
.dial-ring:nth-child(1) .dial-ring-cap-end {
  left: 50%;
  top: 50%;
  transform-origin: center;
  z-index: 9999999999999;
  animation-name: rotate-ring-cap-1;
  animation-delay: 0.4s;
  animation-timing-function: ease-in-out;
  animation-duration: 0.6s;
  animation-fill-mode: forwards;
  transform: translate(-50%, -50%) rotate(-90deg) translate(calc(calc( 180px - (calc(0 * 10px * 2) + calc( 0 * 6px * 2 )) ) / 2 - 10px / 2));
}
@keyframes rotate-ring-cap-1 {
  10% {
    transform: translate(-50%, -50%) rotate(calc(var(--angle) * (10 / 100) - 90deg)) translate(calc(calc( 180px - (calc(0 * 10px * 2) + calc( 0 * 6px * 2 )) ) / 2 - 10px / 2));
  }
}
.dial-ring:nth-child(1) .dial-ring-cap-end {
  left: 50%;
  top: 50%;
  transform-origin: center;
  z-index: 9999999999999;
  animation-name: rotate-ring-cap-1;
  animation-delay: 0.4s;
  animation-timing-function: ease-in-out;
  animation-duration: 0.6s;
  animation-fill-mode: forwards;
  transform: translate(-50%, -50%) rotate(-90deg) translate(calc(calc( 180px - (calc(0 * 10px * 2) + calc( 0 * 6px * 2 )) ) / 2 - 10px / 2));
}
@keyframes rotate-ring-cap-1 {
  11% {
    transform: translate(-50%, -50%) rotate(calc(var(--angle) * (11 / 100) - 90deg)) translate(calc(calc( 180px - (calc(0 * 10px * 2) + calc( 0 * 6px * 2 )) ) / 2 - 10px / 2));
  }
}
.dial-ring:nth-child(1) .dial-ring-cap-end {
  left: 50%;
  top: 50%;
  transform-origin: center;
  z-index: 9999999999999;
  animation-name: rotate-ring-cap-1;
  animation-delay: 0.4s;
  animation-timing-function: ease-in-out;
  animation-duration: 0.6s;
  animation-fill-mode: forwards;
  transform: translate(-50%, -50%) rotate(-90deg) translate(calc(calc( 180px - (calc(0 * 10px * 2) + calc( 0 * 6px * 2 )) ) / 2 - 10px / 2));
}
@keyframes rotate-ring-cap-1 {
  12% {
    transform: translate(-50%, -50%) rotate(calc(var(--angle) * (12 / 100) - 90deg)) translate(calc(calc( 180px - (calc(0 * 10px * 2) + calc( 0 * 6px * 2 )) ) / 2 - 10px / 2));
  }
}
.dial-ring:nth-child(1) .dial-ring-cap-end {
  left: 50%;
  top: 50%;
  transform-origin: center;
  z-index: 9999999999999;
  animation-name: rotate-ring-cap-1;
  animation-delay: 0.4s;
  animation-timing-function: ease-in-out;
  animation-duration: 0.6s;
  animation-fill-mode: forwards;
  transform: translate(-50%, -50%) rotate(-90deg) translate(calc(calc( 180px - (calc(0 * 10px * 2) + calc( 0 * 6px * 2 )) ) / 2 - 10px / 2));
}
@keyframes rotate-ring-cap-1 {
  13% {
    transform: translate(-50%, -50%) rotate(calc(var(--angle) * (13 / 100) - 90deg)) translate(calc(calc( 180px - (calc(0 * 10px * 2) + calc( 0 * 6px * 2 )) ) / 2 - 10px / 2));
  }
}
.dial-ring:nth-child(1) .dial-ring-cap-end {
  left: 50%;
  top: 50%;
  transform-origin: center;
  z-index: 9999999999999;
  animation-name: rotate-ring-cap-1;
  animation-delay: 0.4s;
  animation-timing-function: ease-in-out;
  animation-duration: 0.6s;
  animation-fill-mode: forwards;
  transform: translate(-50%, -50%) rotate(-90deg) translate(calc(calc( 180px - (calc(0 * 10px * 2) + calc( 0 * 6px * 2 )) ) / 2 - 10px / 2));
}
@keyframes rotate-ring-cap-1 {
  14% {
    transform: translate(-50%, -50%) rotate(calc(var(--angle) * (14 / 100) - 90deg)) translate(calc(calc( 180px - (calc(0 * 10px * 2) + calc( 0 * 6px * 2 )) ) / 2 - 10px / 2));
  }
}
.dial-ring:nth-child(1) .dial-ring-cap-end {
  left: 50%;
  top: 50%;
  transform-origin: center;
  z-index: 9999999999999;
  animation-name: rotate-ring-cap-1;
  animation-delay: 0.4s;
  animation-timing-function: ease-in-out;
  animation-duration: 0.6s;
  animation-fill-mode: forwards;
  transform: translate(-50%, -50%) rotate(-90deg) translate(calc(calc( 180px - (calc(0 * 10px * 2) + calc( 0 * 6px * 2 )) ) / 2 - 10px / 2));
}
@keyframes rotate-ring-cap-1 {
  15% {
    transform: translate(-50%, -50%) rotate(calc(var(--angle) * (15 / 100) - 90deg)) translate(calc(calc( 180px - (calc(0 * 10px * 2) + calc( 0 * 6px * 2 )) ) / 2 - 10px / 2));
  }
}
.dial-ring:nth-child(1) .dial-ring-cap-end {
  left: 50%;
  top: 50%;
  transform-origin: center;
  z-index: 9999999999999;
  animation-name: rotate-ring-cap-1;
  animation-delay: 0.4s;
  animation-timing-function: ease-in-out;
  animation-duration: 0.6s;
  animation-fill-mode: forwards;
  transform: translate(-50%, -50%) rotate(-90deg) translate(calc(calc( 180px - (calc(0 * 10px * 2) + calc( 0 * 6px * 2 )) ) / 2 - 10px / 2));
}
@keyframes rotate-ring-cap-1 {
  16% {
    transform: translate(-50%, -50%) rotate(calc(var(--angle) * (16 / 100) - 90deg)) translate(calc(calc( 180px - (calc(0 * 10px * 2) + calc( 0 * 6px * 2 )) ) / 2 - 10px / 2));
  }
}
.dial-ring:nth-child(1) .dial-ring-cap-end {
  left: 50%;
  top: 50%;
  transform-origin: center;
  z-index: 9999999999999;
  animation-name: rotate-ring-cap-1;
  animation-delay: 0.4s;
  animation-timing-function: ease-in-out;
  animation-duration: 0.6s;
  animation-fill-mode: forwards;
  transform: translate(-50%, -50%) rotate(-90deg) translate(calc(calc( 180px - (calc(0 * 10px * 2) + calc( 0 * 6px * 2 )) ) / 2 - 10px / 2));
}
@keyframes rotate-ring-cap-1 {
  17% {
    transform: translate(-50%, -50%) rotate(calc(var(--angle) * (17 / 100) - 90deg)) translate(calc(calc( 180px - (calc(0 * 10px * 2) + calc( 0 * 6px * 2 )) ) / 2 - 10px / 2));
  }
}
.dial-ring:nth-child(1) .dial-ring-cap-end {
  left: 50%;
  top: 50%;
  transform-origin: center;
  z-index: 9999999999999;
  animation-name: rotate-ring-cap-1;
  animation-delay: 0.4s;
  animation-timing-function: ease-in-out;
  animation-duration: 0.6s;
  animation-fill-mode: forwards;
  transform: translate(-50%, -50%) rotate(-90deg) translate(calc(calc( 180px - (calc(0 * 10px * 2) + calc( 0 * 6px * 2 )) ) / 2 - 10px / 2));
}
@keyframes rotate-ring-cap-1 {
  18% {
    transform: translate(-50%, -50%) rotate(calc(var(--angle) * (18 / 100) - 90deg)) translate(calc(calc( 180px - (calc(0 * 10px * 2) + calc( 0 * 6px * 2 )) ) / 2 - 10px / 2));
  }
}
.dial-ring:nth-child(1) .dial-ring-cap-end {
  left: 50%;
  top: 50%;
  transform-origin: center;
  z-index: 9999999999999;
  animation-name: rotate-ring-cap-1;
  animation-delay: 0.4s;
  animation-timing-function: ease-in-out;
  animation-duration: 0.6s;
  animation-fill-mode: forwards;
  transform: translate(-50%, -50%) rotate(-90deg) translate(calc(calc( 180px - (calc(0 * 10px * 2) + calc( 0 * 6px * 2 )) ) / 2 - 10px / 2));
}
@keyframes rotate-ring-cap-1 {
  19% {
    transform: translate(-50%, -50%) rotate(calc(var(--angle) * (19 / 100) - 90deg)) translate(calc(calc( 180px - (calc(0 * 10px * 2) + calc( 0 * 6px * 2 )) ) / 2 - 10px / 2));
  }
}
.dial-ring:nth-child(1) .dial-ring-cap-end {
  left: 50%;
  top: 50%;
  transform-origin: center;
  z-index: 9999999999999;
  animation-name: rotate-ring-cap-1;
  animation-delay: 0.4s;
  animation-timing-function: ease-in-out;
  animation-duration: 0.6s;
  animation-fill-mode: forwards;
  transform: translate(-50%, -50%) rotate(-90deg) translate(calc(calc( 180px - (calc(0 * 10px * 2) + calc( 0 * 6px * 2 )) ) / 2 - 10px / 2));
}
@keyframes rotate-ring-cap-1 {
  20% {
    transform: translate(-50%, -50%) rotate(calc(var(--angle) * (20 / 100) - 90deg)) translate(calc(calc( 180px - (calc(0 * 10px * 2) + calc( 0 * 6px * 2 )) ) / 2 - 10px / 2));
  }
}
.dial-ring:nth-child(1) .dial-ring-cap-end {
  left: 50%;
  top: 50%;
  transform-origin: center;
  z-index: 9999999999999;
  animation-name: rotate-ring-cap-1;
  animation-delay: 0.4s;
  animation-timing-function: ease-in-out;
  animation-duration: 0.6s;
  animation-fill-mode: forwards;
  transform: translate(-50%, -50%) rotate(-90deg) translate(calc(calc( 180px - (calc(0 * 10px * 2) + calc( 0 * 6px * 2 )) ) / 2 - 10px / 2));
}
@keyframes rotate-ring-cap-1 {
  21% {
    transform: translate(-50%, -50%) rotate(calc(var(--angle) * (21 / 100) - 90deg)) translate(calc(calc( 180px - (calc(0 * 10px * 2) + calc( 0 * 6px * 2 )) ) / 2 - 10px / 2));
  }
}
.dial-ring:nth-child(1) .dial-ring-cap-end {
  left: 50%;
  top: 50%;
  transform-origin: center;
  z-index: 9999999999999;
  animation-name: rotate-ring-cap-1;
  animation-delay: 0.4s;
  animation-timing-function: ease-in-out;
  animation-duration: 0.6s;
  animation-fill-mode: forwards;
  transform: translate(-50%, -50%) rotate(-90deg) translate(calc(calc( 180px - (calc(0 * 10px * 2) + calc( 0 * 6px * 2 )) ) / 2 - 10px / 2));
}
@keyframes rotate-ring-cap-1 {
  22% {
    transform: translate(-50%, -50%) rotate(calc(var(--angle) * (22 / 100) - 90deg)) translate(calc(calc( 180px - (calc(0 * 10px * 2) + calc( 0 * 6px * 2 )) ) / 2 - 10px / 2));
  }
}
.dial-ring:nth-child(1) .dial-ring-cap-end {
  left: 50%;
  top: 50%;
  transform-origin: center;
  z-index: 9999999999999;
  animation-name: rotate-ring-cap-1;
  animation-delay: 0.4s;
  animation-timing-function: ease-in-out;
  animation-duration: 0.6s;
  animation-fill-mode: forwards;
  transform: translate(-50%, -50%) rotate(-90deg) translate(calc(calc( 180px - (calc(0 * 10px * 2) + calc( 0 * 6px * 2 )) ) / 2 - 10px / 2));
}
@keyframes rotate-ring-cap-1 {
  23% {
    transform: translate(-50%, -50%) rotate(calc(var(--angle) * (23 / 100) - 90deg)) translate(calc(calc( 180px - (calc(0 * 10px * 2) + calc( 0 * 6px * 2 )) ) / 2 - 10px / 2));
  }
}
.dial-ring:nth-child(1) .dial-ring-cap-end {
  left: 50%;
  top: 50%;
  transform-origin: center;
  z-index: 9999999999999;
  animation-name: rotate-ring-cap-1;
  animation-delay: 0.4s;
  animation-timing-function: ease-in-out;
  animation-duration: 0.6s;
  animation-fill-mode: forwards;
  transform: translate(-50%, -50%) rotate(-90deg) translate(calc(calc( 180px - (calc(0 * 10px * 2) + calc( 0 * 6px * 2 )) ) / 2 - 10px / 2));
}
@keyframes rotate-ring-cap-1 {
  24% {
    transform: translate(-50%, -50%) rotate(calc(var(--angle) * (24 / 100) - 90deg)) translate(calc(calc( 180px - (calc(0 * 10px * 2) + calc( 0 * 6px * 2 )) ) / 2 - 10px / 2));
  }
}
.dial-ring:nth-child(1) .dial-ring-cap-end {
  left: 50%;
  top: 50%;
  transform-origin: center;
  z-index: 9999999999999;
  animation-name: rotate-ring-cap-1;
  animation-delay: 0.4s;
  animation-timing-function: ease-in-out;
  animation-duration: 0.6s;
  animation-fill-mode: forwards;
  transform: translate(-50%, -50%) rotate(-90deg) translate(calc(calc( 180px - (calc(0 * 10px * 2) + calc( 0 * 6px * 2 )) ) / 2 - 10px / 2));
}
@keyframes rotate-ring-cap-1 {
  25% {
    transform: translate(-50%, -50%) rotate(calc(var(--angle) * (25 / 100) - 90deg)) translate(calc(calc( 180px - (calc(0 * 10px * 2) + calc( 0 * 6px * 2 )) ) / 2 - 10px / 2));
  }
}
.dial-ring:nth-child(1) .dial-ring-cap-end {
  left: 50%;
  top: 50%;
  transform-origin: center;
  z-index: 9999999999999;
  animation-name: rotate-ring-cap-1;
  animation-delay: 0.4s;
  animation-timing-function: ease-in-out;
  animation-duration: 0.6s;
  animation-fill-mode: forwards;
  transform: translate(-50%, -50%) rotate(-90deg) translate(calc(calc( 180px - (calc(0 * 10px * 2) + calc( 0 * 6px * 2 )) ) / 2 - 10px / 2));
}
@keyframes rotate-ring-cap-1 {
  26% {
    transform: translate(-50%, -50%) rotate(calc(var(--angle) * (26 / 100) - 90deg)) translate(calc(calc( 180px - (calc(0 * 10px * 2) + calc( 0 * 6px * 2 )) ) / 2 - 10px / 2));
  }
}
.dial-ring:nth-child(1) .dial-ring-cap-end {
  left: 50%;
  top: 50%;
  transform-origin: center;
  z-index: 9999999999999;
  animation-name: rotate-ring-cap-1;
  animation-delay: 0.4s;
  animation-timing-function: ease-in-out;
  animation-duration: 0.6s;
  animation-fill-mode: forwards;
  transform: translate(-50%, -50%) rotate(-90deg) translate(calc(calc( 180px - (calc(0 * 10px * 2) + calc( 0 * 6px * 2 )) ) / 2 - 10px / 2));
}
@keyframes rotate-ring-cap-1 {
  27% {
    transform: translate(-50%, -50%) rotate(calc(var(--angle) * (27 / 100) - 90deg)) translate(calc(calc( 180px - (calc(0 * 10px * 2) + calc( 0 * 6px * 2 )) ) / 2 - 10px / 2));
  }
}
.dial-ring:nth-child(1) .dial-ring-cap-end {
  left: 50%;
  top: 50%;
  transform-origin: center;
  z-index: 9999999999999;
  animation-name: rotate-ring-cap-1;
  animation-delay: 0.4s;
  animation-timing-function: ease-in-out;
  animation-duration: 0.6s;
  animation-fill-mode: forwards;
  transform: translate(-50%, -50%) rotate(-90deg) translate(calc(calc( 180px - (calc(0 * 10px * 2) + calc( 0 * 6px * 2 )) ) / 2 - 10px / 2));
}
@keyframes rotate-ring-cap-1 {
  28% {
    transform: translate(-50%, -50%) rotate(calc(var(--angle) * (28 / 100) - 90deg)) translate(calc(calc( 180px - (calc(0 * 10px * 2) + calc( 0 * 6px * 2 )) ) / 2 - 10px / 2));
  }
}
.dial-ring:nth-child(1) .dial-ring-cap-end {
  left: 50%;
  top: 50%;
  transform-origin: center;
  z-index: 9999999999999;
  animation-name: rotate-ring-cap-1;
  animation-delay: 0.4s;
  animation-timing-function: ease-in-out;
  animation-duration: 0.6s;
  animation-fill-mode: forwards;
  transform: translate(-50%, -50%) rotate(-90deg) translate(calc(calc( 180px - (calc(0 * 10px * 2) + calc( 0 * 6px * 2 )) ) / 2 - 10px / 2));
}
@keyframes rotate-ring-cap-1 {
  29% {
    transform: translate(-50%, -50%) rotate(calc(var(--angle) * (29 / 100) - 90deg)) translate(calc(calc( 180px - (calc(0 * 10px * 2) + calc( 0 * 6px * 2 )) ) / 2 - 10px / 2));
  }
}
.dial-ring:nth-child(1) .dial-ring-cap-end {
  left: 50%;
  top: 50%;
  transform-origin: center;
  z-index: 9999999999999;
  animation-name: rotate-ring-cap-1;
  animation-delay: 0.4s;
  animation-timing-function: ease-in-out;
  animation-duration: 0.6s;
  animation-fill-mode: forwards;
  transform: translate(-50%, -50%) rotate(-90deg) translate(calc(calc( 180px - (calc(0 * 10px * 2) + calc( 0 * 6px * 2 )) ) / 2 - 10px / 2));
}
@keyframes rotate-ring-cap-1 {
  30% {
    transform: translate(-50%, -50%) rotate(calc(var(--angle) * (30 / 100) - 90deg)) translate(calc(calc( 180px - (calc(0 * 10px * 2) + calc( 0 * 6px * 2 )) ) / 2 - 10px / 2));
  }
}
.dial-ring:nth-child(1) .dial-ring-cap-end {
  left: 50%;
  top: 50%;
  transform-origin: center;
  z-index: 9999999999999;
  animation-name: rotate-ring-cap-1;
  animation-delay: 0.4s;
  animation-timing-function: ease-in-out;
  animation-duration: 0.6s;
  animation-fill-mode: forwards;
  transform: translate(-50%, -50%) rotate(-90deg) translate(calc(calc( 180px - (calc(0 * 10px * 2) + calc( 0 * 6px * 2 )) ) / 2 - 10px / 2));
}
@keyframes rotate-ring-cap-1 {
  31% {
    transform: translate(-50%, -50%) rotate(calc(var(--angle) * (31 / 100) - 90deg)) translate(calc(calc( 180px - (calc(0 * 10px * 2) + calc( 0 * 6px * 2 )) ) / 2 - 10px / 2));
  }
}
.dial-ring:nth-child(1) .dial-ring-cap-end {
  left: 50%;
  top: 50%;
  transform-origin: center;
  z-index: 9999999999999;
  animation-name: rotate-ring-cap-1;
  animation-delay: 0.4s;
  animation-timing-function: ease-in-out;
  animation-duration: 0.6s;
  animation-fill-mode: forwards;
  transform: translate(-50%, -50%) rotate(-90deg) translate(calc(calc( 180px - (calc(0 * 10px * 2) + calc( 0 * 6px * 2 )) ) / 2 - 10px / 2));
}
@keyframes rotate-ring-cap-1 {
  32% {
    transform: translate(-50%, -50%) rotate(calc(var(--angle) * (32 / 100) - 90deg)) translate(calc(calc( 180px - (calc(0 * 10px * 2) + calc( 0 * 6px * 2 )) ) / 2 - 10px / 2));
  }
}
.dial-ring:nth-child(1) .dial-ring-cap-end {
  left: 50%;
  top: 50%;
  transform-origin: center;
  z-index: 9999999999999;
  animation-name: rotate-ring-cap-1;
  animation-delay: 0.4s;
  animation-timing-function: ease-in-out;
  animation-duration: 0.6s;
  animation-fill-mode: forwards;
  transform: translate(-50%, -50%) rotate(-90deg) translate(calc(calc( 180px - (calc(0 * 10px * 2) + calc( 0 * 6px * 2 )) ) / 2 - 10px / 2));
}
@keyframes rotate-ring-cap-1 {
  33% {
    transform: translate(-50%, -50%) rotate(calc(var(--angle) * (33 / 100) - 90deg)) translate(calc(calc( 180px - (calc(0 * 10px * 2) + calc( 0 * 6px * 2 )) ) / 2 - 10px / 2));
  }
}
.dial-ring:nth-child(1) .dial-ring-cap-end {
  left: 50%;
  top: 50%;
  transform-origin: center;
  z-index: 9999999999999;
  animation-name: rotate-ring-cap-1;
  animation-delay: 0.4s;
  animation-timing-function: ease-in-out;
  animation-duration: 0.6s;
  animation-fill-mode: forwards;
  transform: translate(-50%, -50%) rotate(-90deg) translate(calc(calc( 180px - (calc(0 * 10px * 2) + calc( 0 * 6px * 2 )) ) / 2 - 10px / 2));
}
@keyframes rotate-ring-cap-1 {
  34% {
    transform: translate(-50%, -50%) rotate(calc(var(--angle) * (34 / 100) - 90deg)) translate(calc(calc( 180px - (calc(0 * 10px * 2) + calc( 0 * 6px * 2 )) ) / 2 - 10px / 2));
  }
}
.dial-ring:nth-child(1) .dial-ring-cap-end {
  left: 50%;
  top: 50%;
  transform-origin: center;
  z-index: 9999999999999;
  animation-name: rotate-ring-cap-1;
  animation-delay: 0.4s;
  animation-timing-function: ease-in-out;
  animation-duration: 0.6s;
  animation-fill-mode: forwards;
  transform: translate(-50%, -50%) rotate(-90deg) translate(calc(calc( 180px - (calc(0 * 10px * 2) + calc( 0 * 6px * 2 )) ) / 2 - 10px / 2));
}
@keyframes rotate-ring-cap-1 {
  35% {
    transform: translate(-50%, -50%) rotate(calc(var(--angle) * (35 / 100) - 90deg)) translate(calc(calc( 180px - (calc(0 * 10px * 2) + calc( 0 * 6px * 2 )) ) / 2 - 10px / 2));
  }
}
.dial-ring:nth-child(1) .dial-ring-cap-end {
  left: 50%;
  top: 50%;
  transform-origin: center;
  z-index: 9999999999999;
  animation-name: rotate-ring-cap-1;
  animation-delay: 0.4s;
  animation-timing-function: ease-in-out;
  animation-duration: 0.6s;
  animation-fill-mode: forwards;
  transform: translate(-50%, -50%) rotate(-90deg) translate(calc(calc( 180px - (calc(0 * 10px * 2) + calc( 0 * 6px * 2 )) ) / 2 - 10px / 2));
}
@keyframes rotate-ring-cap-1 {
  36% {
    transform: translate(-50%, -50%) rotate(calc(var(--angle) * (36 / 100) - 90deg)) translate(calc(calc( 180px - (calc(0 * 10px * 2) + calc( 0 * 6px * 2 )) ) / 2 - 10px / 2));
  }
}
.dial-ring:nth-child(1) .dial-ring-cap-end {
  left: 50%;
  top: 50%;
  transform-origin: center;
  z-index: 9999999999999;
  animation-name: rotate-ring-cap-1;
  animation-delay: 0.4s;
  animation-timing-function: ease-in-out;
  animation-duration: 0.6s;
  animation-fill-mode: forwards;
  transform: translate(-50%, -50%) rotate(-90deg) translate(calc(calc( 180px - (calc(0 * 10px * 2) + calc( 0 * 6px * 2 )) ) / 2 - 10px / 2));
}
@keyframes rotate-ring-cap-1 {
  37% {
    transform: translate(-50%, -50%) rotate(calc(var(--angle) * (37 / 100) - 90deg)) translate(calc(calc( 180px - (calc(0 * 10px * 2) + calc( 0 * 6px * 2 )) ) / 2 - 10px / 2));
  }
}
.dial-ring:nth-child(1) .dial-ring-cap-end {
  left: 50%;
  top: 50%;
  transform-origin: center;
  z-index: 9999999999999;
  animation-name: rotate-ring-cap-1;
  animation-delay: 0.4s;
  animation-timing-function: ease-in-out;
  animation-duration: 0.6s;
  animation-fill-mode: forwards;
  transform: translate(-50%, -50%) rotate(-90deg) translate(calc(calc( 180px - (calc(0 * 10px * 2) + calc( 0 * 6px * 2 )) ) / 2 - 10px / 2));
}
@keyframes rotate-ring-cap-1 {
  38% {
    transform: translate(-50%, -50%) rotate(calc(var(--angle) * (38 / 100) - 90deg)) translate(calc(calc( 180px - (calc(0 * 10px * 2) + calc( 0 * 6px * 2 )) ) / 2 - 10px / 2));
  }
}
.dial-ring:nth-child(1) .dial-ring-cap-end {
  left: 50%;
  top: 50%;
  transform-origin: center;
  z-index: 9999999999999;
  animation-name: rotate-ring-cap-1;
  animation-delay: 0.4s;
  animation-timing-function: ease-in-out;
  animation-duration: 0.6s;
  animation-fill-mode: forwards;
  transform: translate(-50%, -50%) rotate(-90deg) translate(calc(calc( 180px - (calc(0 * 10px * 2) + calc( 0 * 6px * 2 )) ) / 2 - 10px / 2));
}
@keyframes rotate-ring-cap-1 {
  39% {
    transform: translate(-50%, -50%) rotate(calc(var(--angle) * (39 / 100) - 90deg)) translate(calc(calc( 180px - (calc(0 * 10px * 2) + calc( 0 * 6px * 2 )) ) / 2 - 10px / 2));
  }
}
.dial-ring:nth-child(1) .dial-ring-cap-end {
  left: 50%;
  top: 50%;
  transform-origin: center;
  z-index: 9999999999999;
  animation-name: rotate-ring-cap-1;
  animation-delay: 0.4s;
  animation-timing-function: ease-in-out;
  animation-duration: 0.6s;
  animation-fill-mode: forwards;
  transform: translate(-50%, -50%) rotate(-90deg) translate(calc(calc( 180px - (calc(0 * 10px * 2) + calc( 0 * 6px * 2 )) ) / 2 - 10px / 2));
}
@keyframes rotate-ring-cap-1 {
  40% {
    transform: translate(-50%, -50%) rotate(calc(var(--angle) * (40 / 100) - 90deg)) translate(calc(calc( 180px - (calc(0 * 10px * 2) + calc( 0 * 6px * 2 )) ) / 2 - 10px / 2));
  }
}
.dial-ring:nth-child(1) .dial-ring-cap-end {
  left: 50%;
  top: 50%;
  transform-origin: center;
  z-index: 9999999999999;
  animation-name: rotate-ring-cap-1;
  animation-delay: 0.4s;
  animation-timing-function: ease-in-out;
  animation-duration: 0.6s;
  animation-fill-mode: forwards;
  transform: translate(-50%, -50%) rotate(-90deg) translate(calc(calc( 180px - (calc(0 * 10px * 2) + calc( 0 * 6px * 2 )) ) / 2 - 10px / 2));
}
@keyframes rotate-ring-cap-1 {
  41% {
    transform: translate(-50%, -50%) rotate(calc(var(--angle) * (41 / 100) - 90deg)) translate(calc(calc( 180px - (calc(0 * 10px * 2) + calc( 0 * 6px * 2 )) ) / 2 - 10px / 2));
  }
}
.dial-ring:nth-child(1) .dial-ring-cap-end {
  left: 50%;
  top: 50%;
  transform-origin: center;
  z-index: 9999999999999;
  animation-name: rotate-ring-cap-1;
  animation-delay: 0.4s;
  animation-timing-function: ease-in-out;
  animation-duration: 0.6s;
  animation-fill-mode: forwards;
  transform: translate(-50%, -50%) rotate(-90deg) translate(calc(calc( 180px - (calc(0 * 10px * 2) + calc( 0 * 6px * 2 )) ) / 2 - 10px / 2));
}
@keyframes rotate-ring-cap-1 {
  42% {
    transform: translate(-50%, -50%) rotate(calc(var(--angle) * (42 / 100) - 90deg)) translate(calc(calc( 180px - (calc(0 * 10px * 2) + calc( 0 * 6px * 2 )) ) / 2 - 10px / 2));
  }
}
.dial-ring:nth-child(1) .dial-ring-cap-end {
  left: 50%;
  top: 50%;
  transform-origin: center;
  z-index: 9999999999999;
  animation-name: rotate-ring-cap-1;
  animation-delay: 0.4s;
  animation-timing-function: ease-in-out;
  animation-duration: 0.6s;
  animation-fill-mode: forwards;
  transform: translate(-50%, -50%) rotate(-90deg) translate(calc(calc( 180px - (calc(0 * 10px * 2) + calc( 0 * 6px * 2 )) ) / 2 - 10px / 2));
}
@keyframes rotate-ring-cap-1 {
  43% {
    transform: translate(-50%, -50%) rotate(calc(var(--angle) * (43 / 100) - 90deg)) translate(calc(calc( 180px - (calc(0 * 10px * 2) + calc( 0 * 6px * 2 )) ) / 2 - 10px / 2));
  }
}
.dial-ring:nth-child(1) .dial-ring-cap-end {
  left: 50%;
  top: 50%;
  transform-origin: center;
  z-index: 9999999999999;
  animation-name: rotate-ring-cap-1;
  animation-delay: 0.4s;
  animation-timing-function: ease-in-out;
  animation-duration: 0.6s;
  animation-fill-mode: forwards;
  transform: translate(-50%, -50%) rotate(-90deg) translate(calc(calc( 180px - (calc(0 * 10px * 2) + calc( 0 * 6px * 2 )) ) / 2 - 10px / 2));
}
@keyframes rotate-ring-cap-1 {
  44% {
    transform: translate(-50%, -50%) rotate(calc(var(--angle) * (44 / 100) - 90deg)) translate(calc(calc( 180px - (calc(0 * 10px * 2) + calc( 0 * 6px * 2 )) ) / 2 - 10px / 2));
  }
}
.dial-ring:nth-child(1) .dial-ring-cap-end {
  left: 50%;
  top: 50%;
  transform-origin: center;
  z-index: 9999999999999;
  animation-name: rotate-ring-cap-1;
  animation-delay: 0.4s;
  animation-timing-function: ease-in-out;
  animation-duration: 0.6s;
  animation-fill-mode: forwards;
  transform: translate(-50%, -50%) rotate(-90deg) translate(calc(calc( 180px - (calc(0 * 10px * 2) + calc( 0 * 6px * 2 )) ) / 2 - 10px / 2));
}
@keyframes rotate-ring-cap-1 {
  45% {
    transform: translate(-50%, -50%) rotate(calc(var(--angle) * (45 / 100) - 90deg)) translate(calc(calc( 180px - (calc(0 * 10px * 2) + calc( 0 * 6px * 2 )) ) / 2 - 10px / 2));
  }
}
.dial-ring:nth-child(1) .dial-ring-cap-end {
  left: 50%;
  top: 50%;
  transform-origin: center;
  z-index: 9999999999999;
  animation-name: rotate-ring-cap-1;
  animation-delay: 0.4s;
  animation-timing-function: ease-in-out;
  animation-duration: 0.6s;
  animation-fill-mode: forwards;
  transform: translate(-50%, -50%) rotate(-90deg) translate(calc(calc( 180px - (calc(0 * 10px * 2) + calc( 0 * 6px * 2 )) ) / 2 - 10px / 2));
}
@keyframes rotate-ring-cap-1 {
  46% {
    transform: translate(-50%, -50%) rotate(calc(var(--angle) * (46 / 100) - 90deg)) translate(calc(calc( 180px - (calc(0 * 10px * 2) + calc( 0 * 6px * 2 )) ) / 2 - 10px / 2));
  }
}
.dial-ring:nth-child(1) .dial-ring-cap-end {
  left: 50%;
  top: 50%;
  transform-origin: center;
  z-index: 9999999999999;
  animation-name: rotate-ring-cap-1;
  animation-delay: 0.4s;
  animation-timing-function: ease-in-out;
  animation-duration: 0.6s;
  animation-fill-mode: forwards;
  transform: translate(-50%, -50%) rotate(-90deg) translate(calc(calc( 180px - (calc(0 * 10px * 2) + calc( 0 * 6px * 2 )) ) / 2 - 10px / 2));
}
@keyframes rotate-ring-cap-1 {
  47% {
    transform: translate(-50%, -50%) rotate(calc(var(--angle) * (47 / 100) - 90deg)) translate(calc(calc( 180px - (calc(0 * 10px * 2) + calc( 0 * 6px * 2 )) ) / 2 - 10px / 2));
  }
}
.dial-ring:nth-child(1) .dial-ring-cap-end {
  left: 50%;
  top: 50%;
  transform-origin: center;
  z-index: 9999999999999;
  animation-name: rotate-ring-cap-1;
  animation-delay: 0.4s;
  animation-timing-function: ease-in-out;
  animation-duration: 0.6s;
  animation-fill-mode: forwards;
  transform: translate(-50%, -50%) rotate(-90deg) translate(calc(calc( 180px - (calc(0 * 10px * 2) + calc( 0 * 6px * 2 )) ) / 2 - 10px / 2));
}
@keyframes rotate-ring-cap-1 {
  48% {
    transform: translate(-50%, -50%) rotate(calc(var(--angle) * (48 / 100) - 90deg)) translate(calc(calc( 180px - (calc(0 * 10px * 2) + calc( 0 * 6px * 2 )) ) / 2 - 10px / 2));
  }
}
.dial-ring:nth-child(1) .dial-ring-cap-end {
  left: 50%;
  top: 50%;
  transform-origin: center;
  z-index: 9999999999999;
  animation-name: rotate-ring-cap-1;
  animation-delay: 0.4s;
  animation-timing-function: ease-in-out;
  animation-duration: 0.6s;
  animation-fill-mode: forwards;
  transform: translate(-50%, -50%) rotate(-90deg) translate(calc(calc( 180px - (calc(0 * 10px * 2) + calc( 0 * 6px * 2 )) ) / 2 - 10px / 2));
}
@keyframes rotate-ring-cap-1 {
  49% {
    transform: translate(-50%, -50%) rotate(calc(var(--angle) * (49 / 100) - 90deg)) translate(calc(calc( 180px - (calc(0 * 10px * 2) + calc( 0 * 6px * 2 )) ) / 2 - 10px / 2));
  }
}
.dial-ring:nth-child(1) .dial-ring-cap-end {
  left: 50%;
  top: 50%;
  transform-origin: center;
  z-index: 9999999999999;
  animation-name: rotate-ring-cap-1;
  animation-delay: 0.4s;
  animation-timing-function: ease-in-out;
  animation-duration: 0.6s;
  animation-fill-mode: forwards;
  transform: translate(-50%, -50%) rotate(-90deg) translate(calc(calc( 180px - (calc(0 * 10px * 2) + calc( 0 * 6px * 2 )) ) / 2 - 10px / 2));
}
@keyframes rotate-ring-cap-1 {
  50% {
    transform: translate(-50%, -50%) rotate(calc(var(--angle) * (50 / 100) - 90deg)) translate(calc(calc( 180px - (calc(0 * 10px * 2) + calc( 0 * 6px * 2 )) ) / 2 - 10px / 2));
  }
}
.dial-ring:nth-child(1) .dial-ring-cap-end {
  left: 50%;
  top: 50%;
  transform-origin: center;
  z-index: 9999999999999;
  animation-name: rotate-ring-cap-1;
  animation-delay: 0.4s;
  animation-timing-function: ease-in-out;
  animation-duration: 0.6s;
  animation-fill-mode: forwards;
  transform: translate(-50%, -50%) rotate(-90deg) translate(calc(calc( 180px - (calc(0 * 10px * 2) + calc( 0 * 6px * 2 )) ) / 2 - 10px / 2));
}
@keyframes rotate-ring-cap-1 {
  51% {
    transform: translate(-50%, -50%) rotate(calc(var(--angle) * (51 / 100) - 90deg)) translate(calc(calc( 180px - (calc(0 * 10px * 2) + calc( 0 * 6px * 2 )) ) / 2 - 10px / 2));
  }
}
.dial-ring:nth-child(1) .dial-ring-cap-end {
  left: 50%;
  top: 50%;
  transform-origin: center;
  z-index: 9999999999999;
  animation-name: rotate-ring-cap-1;
  animation-delay: 0.4s;
  animation-timing-function: ease-in-out;
  animation-duration: 0.6s;
  animation-fill-mode: forwards;
  transform: translate(-50%, -50%) rotate(-90deg) translate(calc(calc( 180px - (calc(0 * 10px * 2) + calc( 0 * 6px * 2 )) ) / 2 - 10px / 2));
}
@keyframes rotate-ring-cap-1 {
  52% {
    transform: translate(-50%, -50%) rotate(calc(var(--angle) * (52 / 100) - 90deg)) translate(calc(calc( 180px - (calc(0 * 10px * 2) + calc( 0 * 6px * 2 )) ) / 2 - 10px / 2));
  }
}
.dial-ring:nth-child(1) .dial-ring-cap-end {
  left: 50%;
  top: 50%;
  transform-origin: center;
  z-index: 9999999999999;
  animation-name: rotate-ring-cap-1;
  animation-delay: 0.4s;
  animation-timing-function: ease-in-out;
  animation-duration: 0.6s;
  animation-fill-mode: forwards;
  transform: translate(-50%, -50%) rotate(-90deg) translate(calc(calc( 180px - (calc(0 * 10px * 2) + calc( 0 * 6px * 2 )) ) / 2 - 10px / 2));
}
@keyframes rotate-ring-cap-1 {
  53% {
    transform: translate(-50%, -50%) rotate(calc(var(--angle) * (53 / 100) - 90deg)) translate(calc(calc( 180px - (calc(0 * 10px * 2) + calc( 0 * 6px * 2 )) ) / 2 - 10px / 2));
  }
}
.dial-ring:nth-child(1) .dial-ring-cap-end {
  left: 50%;
  top: 50%;
  transform-origin: center;
  z-index: 9999999999999;
  animation-name: rotate-ring-cap-1;
  animation-delay: 0.4s;
  animation-timing-function: ease-in-out;
  animation-duration: 0.6s;
  animation-fill-mode: forwards;
  transform: translate(-50%, -50%) rotate(-90deg) translate(calc(calc( 180px - (calc(0 * 10px * 2) + calc( 0 * 6px * 2 )) ) / 2 - 10px / 2));
}
@keyframes rotate-ring-cap-1 {
  54% {
    transform: translate(-50%, -50%) rotate(calc(var(--angle) * (54 / 100) - 90deg)) translate(calc(calc( 180px - (calc(0 * 10px * 2) + calc( 0 * 6px * 2 )) ) / 2 - 10px / 2));
  }
}
.dial-ring:nth-child(1) .dial-ring-cap-end {
  left: 50%;
  top: 50%;
  transform-origin: center;
  z-index: 9999999999999;
  animation-name: rotate-ring-cap-1;
  animation-delay: 0.4s;
  animation-timing-function: ease-in-out;
  animation-duration: 0.6s;
  animation-fill-mode: forwards;
  transform: translate(-50%, -50%) rotate(-90deg) translate(calc(calc( 180px - (calc(0 * 10px * 2) + calc( 0 * 6px * 2 )) ) / 2 - 10px / 2));
}
@keyframes rotate-ring-cap-1 {
  55% {
    transform: translate(-50%, -50%) rotate(calc(var(--angle) * (55 / 100) - 90deg)) translate(calc(calc( 180px - (calc(0 * 10px * 2) + calc( 0 * 6px * 2 )) ) / 2 - 10px / 2));
  }
}
.dial-ring:nth-child(1) .dial-ring-cap-end {
  left: 50%;
  top: 50%;
  transform-origin: center;
  z-index: 9999999999999;
  animation-name: rotate-ring-cap-1;
  animation-delay: 0.4s;
  animation-timing-function: ease-in-out;
  animation-duration: 0.6s;
  animation-fill-mode: forwards;
  transform: translate(-50%, -50%) rotate(-90deg) translate(calc(calc( 180px - (calc(0 * 10px * 2) + calc( 0 * 6px * 2 )) ) / 2 - 10px / 2));
}
@keyframes rotate-ring-cap-1 {
  56% {
    transform: translate(-50%, -50%) rotate(calc(var(--angle) * (56 / 100) - 90deg)) translate(calc(calc( 180px - (calc(0 * 10px * 2) + calc( 0 * 6px * 2 )) ) / 2 - 10px / 2));
  }
}
.dial-ring:nth-child(1) .dial-ring-cap-end {
  left: 50%;
  top: 50%;
  transform-origin: center;
  z-index: 9999999999999;
  animation-name: rotate-ring-cap-1;
  animation-delay: 0.4s;
  animation-timing-function: ease-in-out;
  animation-duration: 0.6s;
  animation-fill-mode: forwards;
  transform: translate(-50%, -50%) rotate(-90deg) translate(calc(calc( 180px - (calc(0 * 10px * 2) + calc( 0 * 6px * 2 )) ) / 2 - 10px / 2));
}
@keyframes rotate-ring-cap-1 {
  57% {
    transform: translate(-50%, -50%) rotate(calc(var(--angle) * (57 / 100) - 90deg)) translate(calc(calc( 180px - (calc(0 * 10px * 2) + calc( 0 * 6px * 2 )) ) / 2 - 10px / 2));
  }
}
.dial-ring:nth-child(1) .dial-ring-cap-end {
  left: 50%;
  top: 50%;
  transform-origin: center;
  z-index: 9999999999999;
  animation-name: rotate-ring-cap-1;
  animation-delay: 0.4s;
  animation-timing-function: ease-in-out;
  animation-duration: 0.6s;
  animation-fill-mode: forwards;
  transform: translate(-50%, -50%) rotate(-90deg) translate(calc(calc( 180px - (calc(0 * 10px * 2) + calc( 0 * 6px * 2 )) ) / 2 - 10px / 2));
}
@keyframes rotate-ring-cap-1 {
  58% {
    transform: translate(-50%, -50%) rotate(calc(var(--angle) * (58 / 100) - 90deg)) translate(calc(calc( 180px - (calc(0 * 10px * 2) + calc( 0 * 6px * 2 )) ) / 2 - 10px / 2));
  }
}
.dial-ring:nth-child(1) .dial-ring-cap-end {
  left: 50%;
  top: 50%;
  transform-origin: center;
  z-index: 9999999999999;
  animation-name: rotate-ring-cap-1;
  animation-delay: 0.4s;
  animation-timing-function: ease-in-out;
  animation-duration: 0.6s;
  animation-fill-mode: forwards;
  transform: translate(-50%, -50%) rotate(-90deg) translate(calc(calc( 180px - (calc(0 * 10px * 2) + calc( 0 * 6px * 2 )) ) / 2 - 10px / 2));
}
@keyframes rotate-ring-cap-1 {
  59% {
    transform: translate(-50%, -50%) rotate(calc(var(--angle) * (59 / 100) - 90deg)) translate(calc(calc( 180px - (calc(0 * 10px * 2) + calc( 0 * 6px * 2 )) ) / 2 - 10px / 2));
  }
}
.dial-ring:nth-child(1) .dial-ring-cap-end {
  left: 50%;
  top: 50%;
  transform-origin: center;
  z-index: 9999999999999;
  animation-name: rotate-ring-cap-1;
  animation-delay: 0.4s;
  animation-timing-function: ease-in-out;
  animation-duration: 0.6s;
  animation-fill-mode: forwards;
  transform: translate(-50%, -50%) rotate(-90deg) translate(calc(calc( 180px - (calc(0 * 10px * 2) + calc( 0 * 6px * 2 )) ) / 2 - 10px / 2));
}
@keyframes rotate-ring-cap-1 {
  60% {
    transform: translate(-50%, -50%) rotate(calc(var(--angle) * (60 / 100) - 90deg)) translate(calc(calc( 180px - (calc(0 * 10px * 2) + calc( 0 * 6px * 2 )) ) / 2 - 10px / 2));
  }
}
.dial-ring:nth-child(1) .dial-ring-cap-end {
  left: 50%;
  top: 50%;
  transform-origin: center;
  z-index: 9999999999999;
  animation-name: rotate-ring-cap-1;
  animation-delay: 0.4s;
  animation-timing-function: ease-in-out;
  animation-duration: 0.6s;
  animation-fill-mode: forwards;
  transform: translate(-50%, -50%) rotate(-90deg) translate(calc(calc( 180px - (calc(0 * 10px * 2) + calc( 0 * 6px * 2 )) ) / 2 - 10px / 2));
}
@keyframes rotate-ring-cap-1 {
  61% {
    transform: translate(-50%, -50%) rotate(calc(var(--angle) * (61 / 100) - 90deg)) translate(calc(calc( 180px - (calc(0 * 10px * 2) + calc( 0 * 6px * 2 )) ) / 2 - 10px / 2));
  }
}
.dial-ring:nth-child(1) .dial-ring-cap-end {
  left: 50%;
  top: 50%;
  transform-origin: center;
  z-index: 9999999999999;
  animation-name: rotate-ring-cap-1;
  animation-delay: 0.4s;
  animation-timing-function: ease-in-out;
  animation-duration: 0.6s;
  animation-fill-mode: forwards;
  transform: translate(-50%, -50%) rotate(-90deg) translate(calc(calc( 180px - (calc(0 * 10px * 2) + calc( 0 * 6px * 2 )) ) / 2 - 10px / 2));
}
@keyframes rotate-ring-cap-1 {
  62% {
    transform: translate(-50%, -50%) rotate(calc(var(--angle) * (62 / 100) - 90deg)) translate(calc(calc( 180px - (calc(0 * 10px * 2) + calc( 0 * 6px * 2 )) ) / 2 - 10px / 2));
  }
}
.dial-ring:nth-child(1) .dial-ring-cap-end {
  left: 50%;
  top: 50%;
  transform-origin: center;
  z-index: 9999999999999;
  animation-name: rotate-ring-cap-1;
  animation-delay: 0.4s;
  animation-timing-function: ease-in-out;
  animation-duration: 0.6s;
  animation-fill-mode: forwards;
  transform: translate(-50%, -50%) rotate(-90deg) translate(calc(calc( 180px - (calc(0 * 10px * 2) + calc( 0 * 6px * 2 )) ) / 2 - 10px / 2));
}
@keyframes rotate-ring-cap-1 {
  63% {
    transform: translate(-50%, -50%) rotate(calc(var(--angle) * (63 / 100) - 90deg)) translate(calc(calc( 180px - (calc(0 * 10px * 2) + calc( 0 * 6px * 2 )) ) / 2 - 10px / 2));
  }
}
.dial-ring:nth-child(1) .dial-ring-cap-end {
  left: 50%;
  top: 50%;
  transform-origin: center;
  z-index: 9999999999999;
  animation-name: rotate-ring-cap-1;
  animation-delay: 0.4s;
  animation-timing-function: ease-in-out;
  animation-duration: 0.6s;
  animation-fill-mode: forwards;
  transform: translate(-50%, -50%) rotate(-90deg) translate(calc(calc( 180px - (calc(0 * 10px * 2) + calc( 0 * 6px * 2 )) ) / 2 - 10px / 2));
}
@keyframes rotate-ring-cap-1 {
  64% {
    transform: translate(-50%, -50%) rotate(calc(var(--angle) * (64 / 100) - 90deg)) translate(calc(calc( 180px - (calc(0 * 10px * 2) + calc( 0 * 6px * 2 )) ) / 2 - 10px / 2));
  }
}
.dial-ring:nth-child(1) .dial-ring-cap-end {
  left: 50%;
  top: 50%;
  transform-origin: center;
  z-index: 9999999999999;
  animation-name: rotate-ring-cap-1;
  animation-delay: 0.4s;
  animation-timing-function: ease-in-out;
  animation-duration: 0.6s;
  animation-fill-mode: forwards;
  transform: translate(-50%, -50%) rotate(-90deg) translate(calc(calc( 180px - (calc(0 * 10px * 2) + calc( 0 * 6px * 2 )) ) / 2 - 10px / 2));
}
@keyframes rotate-ring-cap-1 {
  65% {
    transform: translate(-50%, -50%) rotate(calc(var(--angle) * (65 / 100) - 90deg)) translate(calc(calc( 180px - (calc(0 * 10px * 2) + calc( 0 * 6px * 2 )) ) / 2 - 10px / 2));
  }
}
.dial-ring:nth-child(1) .dial-ring-cap-end {
  left: 50%;
  top: 50%;
  transform-origin: center;
  z-index: 9999999999999;
  animation-name: rotate-ring-cap-1;
  animation-delay: 0.4s;
  animation-timing-function: ease-in-out;
  animation-duration: 0.6s;
  animation-fill-mode: forwards;
  transform: translate(-50%, -50%) rotate(-90deg) translate(calc(calc( 180px - (calc(0 * 10px * 2) + calc( 0 * 6px * 2 )) ) / 2 - 10px / 2));
}
@keyframes rotate-ring-cap-1 {
  66% {
    transform: translate(-50%, -50%) rotate(calc(var(--angle) * (66 / 100) - 90deg)) translate(calc(calc( 180px - (calc(0 * 10px * 2) + calc( 0 * 6px * 2 )) ) / 2 - 10px / 2));
  }
}
.dial-ring:nth-child(1) .dial-ring-cap-end {
  left: 50%;
  top: 50%;
  transform-origin: center;
  z-index: 9999999999999;
  animation-name: rotate-ring-cap-1;
  animation-delay: 0.4s;
  animation-timing-function: ease-in-out;
  animation-duration: 0.6s;
  animation-fill-mode: forwards;
  transform: translate(-50%, -50%) rotate(-90deg) translate(calc(calc( 180px - (calc(0 * 10px * 2) + calc( 0 * 6px * 2 )) ) / 2 - 10px / 2));
}
@keyframes rotate-ring-cap-1 {
  67% {
    transform: translate(-50%, -50%) rotate(calc(var(--angle) * (67 / 100) - 90deg)) translate(calc(calc( 180px - (calc(0 * 10px * 2) + calc( 0 * 6px * 2 )) ) / 2 - 10px / 2));
  }
}
.dial-ring:nth-child(1) .dial-ring-cap-end {
  left: 50%;
  top: 50%;
  transform-origin: center;
  z-index: 9999999999999;
  animation-name: rotate-ring-cap-1;
  animation-delay: 0.4s;
  animation-timing-function: ease-in-out;
  animation-duration: 0.6s;
  animation-fill-mode: forwards;
  transform: translate(-50%, -50%) rotate(-90deg) translate(calc(calc( 180px - (calc(0 * 10px * 2) + calc( 0 * 6px * 2 )) ) / 2 - 10px / 2));
}
@keyframes rotate-ring-cap-1 {
  68% {
    transform: translate(-50%, -50%) rotate(calc(var(--angle) * (68 / 100) - 90deg)) translate(calc(calc( 180px - (calc(0 * 10px * 2) + calc( 0 * 6px * 2 )) ) / 2 - 10px / 2));
  }
}
.dial-ring:nth-child(1) .dial-ring-cap-end {
  left: 50%;
  top: 50%;
  transform-origin: center;
  z-index: 9999999999999;
  animation-name: rotate-ring-cap-1;
  animation-delay: 0.4s;
  animation-timing-function: ease-in-out;
  animation-duration: 0.6s;
  animation-fill-mode: forwards;
  transform: translate(-50%, -50%) rotate(-90deg) translate(calc(calc( 180px - (calc(0 * 10px * 2) + calc( 0 * 6px * 2 )) ) / 2 - 10px / 2));
}
@keyframes rotate-ring-cap-1 {
  69% {
    transform: translate(-50%, -50%) rotate(calc(var(--angle) * (69 / 100) - 90deg)) translate(calc(calc( 180px - (calc(0 * 10px * 2) + calc( 0 * 6px * 2 )) ) / 2 - 10px / 2));
  }
}
.dial-ring:nth-child(1) .dial-ring-cap-end {
  left: 50%;
  top: 50%;
  transform-origin: center;
  z-index: 9999999999999;
  animation-name: rotate-ring-cap-1;
  animation-delay: 0.4s;
  animation-timing-function: ease-in-out;
  animation-duration: 0.6s;
  animation-fill-mode: forwards;
  transform: translate(-50%, -50%) rotate(-90deg) translate(calc(calc( 180px - (calc(0 * 10px * 2) + calc( 0 * 6px * 2 )) ) / 2 - 10px / 2));
}
@keyframes rotate-ring-cap-1 {
  70% {
    transform: translate(-50%, -50%) rotate(calc(var(--angle) * (70 / 100) - 90deg)) translate(calc(calc( 180px - (calc(0 * 10px * 2) + calc( 0 * 6px * 2 )) ) / 2 - 10px / 2));
  }
}
.dial-ring:nth-child(1) .dial-ring-cap-end {
  left: 50%;
  top: 50%;
  transform-origin: center;
  z-index: 9999999999999;
  animation-name: rotate-ring-cap-1;
  animation-delay: 0.4s;
  animation-timing-function: ease-in-out;
  animation-duration: 0.6s;
  animation-fill-mode: forwards;
  transform: translate(-50%, -50%) rotate(-90deg) translate(calc(calc( 180px - (calc(0 * 10px * 2) + calc( 0 * 6px * 2 )) ) / 2 - 10px / 2));
}
@keyframes rotate-ring-cap-1 {
  71% {
    transform: translate(-50%, -50%) rotate(calc(var(--angle) * (71 / 100) - 90deg)) translate(calc(calc( 180px - (calc(0 * 10px * 2) + calc( 0 * 6px * 2 )) ) / 2 - 10px / 2));
  }
}
.dial-ring:nth-child(1) .dial-ring-cap-end {
  left: 50%;
  top: 50%;
  transform-origin: center;
  z-index: 9999999999999;
  animation-name: rotate-ring-cap-1;
  animation-delay: 0.4s;
  animation-timing-function: ease-in-out;
  animation-duration: 0.6s;
  animation-fill-mode: forwards;
  transform: translate(-50%, -50%) rotate(-90deg) translate(calc(calc( 180px - (calc(0 * 10px * 2) + calc( 0 * 6px * 2 )) ) / 2 - 10px / 2));
}
@keyframes rotate-ring-cap-1 {
  72% {
    transform: translate(-50%, -50%) rotate(calc(var(--angle) * (72 / 100) - 90deg)) translate(calc(calc( 180px - (calc(0 * 10px * 2) + calc( 0 * 6px * 2 )) ) / 2 - 10px / 2));
  }
}
.dial-ring:nth-child(1) .dial-ring-cap-end {
  left: 50%;
  top: 50%;
  transform-origin: center;
  z-index: 9999999999999;
  animation-name: rotate-ring-cap-1;
  animation-delay: 0.4s;
  animation-timing-function: ease-in-out;
  animation-duration: 0.6s;
  animation-fill-mode: forwards;
  transform: translate(-50%, -50%) rotate(-90deg) translate(calc(calc( 180px - (calc(0 * 10px * 2) + calc( 0 * 6px * 2 )) ) / 2 - 10px / 2));
}
@keyframes rotate-ring-cap-1 {
  73% {
    transform: translate(-50%, -50%) rotate(calc(var(--angle) * (73 / 100) - 90deg)) translate(calc(calc( 180px - (calc(0 * 10px * 2) + calc( 0 * 6px * 2 )) ) / 2 - 10px / 2));
  }
}
.dial-ring:nth-child(1) .dial-ring-cap-end {
  left: 50%;
  top: 50%;
  transform-origin: center;
  z-index: 9999999999999;
  animation-name: rotate-ring-cap-1;
  animation-delay: 0.4s;
  animation-timing-function: ease-in-out;
  animation-duration: 0.6s;
  animation-fill-mode: forwards;
  transform: translate(-50%, -50%) rotate(-90deg) translate(calc(calc( 180px - (calc(0 * 10px * 2) + calc( 0 * 6px * 2 )) ) / 2 - 10px / 2));
}
@keyframes rotate-ring-cap-1 {
  74% {
    transform: translate(-50%, -50%) rotate(calc(var(--angle) * (74 / 100) - 90deg)) translate(calc(calc( 180px - (calc(0 * 10px * 2) + calc( 0 * 6px * 2 )) ) / 2 - 10px / 2));
  }
}
.dial-ring:nth-child(1) .dial-ring-cap-end {
  left: 50%;
  top: 50%;
  transform-origin: center;
  z-index: 9999999999999;
  animation-name: rotate-ring-cap-1;
  animation-delay: 0.4s;
  animation-timing-function: ease-in-out;
  animation-duration: 0.6s;
  animation-fill-mode: forwards;
  transform: translate(-50%, -50%) rotate(-90deg) translate(calc(calc( 180px - (calc(0 * 10px * 2) + calc( 0 * 6px * 2 )) ) / 2 - 10px / 2));
}
@keyframes rotate-ring-cap-1 {
  75% {
    transform: translate(-50%, -50%) rotate(calc(var(--angle) * (75 / 100) - 90deg)) translate(calc(calc( 180px - (calc(0 * 10px * 2) + calc( 0 * 6px * 2 )) ) / 2 - 10px / 2));
  }
}
.dial-ring:nth-child(1) .dial-ring-cap-end {
  left: 50%;
  top: 50%;
  transform-origin: center;
  z-index: 9999999999999;
  animation-name: rotate-ring-cap-1;
  animation-delay: 0.4s;
  animation-timing-function: ease-in-out;
  animation-duration: 0.6s;
  animation-fill-mode: forwards;
  transform: translate(-50%, -50%) rotate(-90deg) translate(calc(calc( 180px - (calc(0 * 10px * 2) + calc( 0 * 6px * 2 )) ) / 2 - 10px / 2));
}
@keyframes rotate-ring-cap-1 {
  76% {
    transform: translate(-50%, -50%) rotate(calc(var(--angle) * (76 / 100) - 90deg)) translate(calc(calc( 180px - (calc(0 * 10px * 2) + calc( 0 * 6px * 2 )) ) / 2 - 10px / 2));
  }
}
.dial-ring:nth-child(1) .dial-ring-cap-end {
  left: 50%;
  top: 50%;
  transform-origin: center;
  z-index: 9999999999999;
  animation-name: rotate-ring-cap-1;
  animation-delay: 0.4s;
  animation-timing-function: ease-in-out;
  animation-duration: 0.6s;
  animation-fill-mode: forwards;
  transform: translate(-50%, -50%) rotate(-90deg) translate(calc(calc( 180px - (calc(0 * 10px * 2) + calc( 0 * 6px * 2 )) ) / 2 - 10px / 2));
}
@keyframes rotate-ring-cap-1 {
  77% {
    transform: translate(-50%, -50%) rotate(calc(var(--angle) * (77 / 100) - 90deg)) translate(calc(calc( 180px - (calc(0 * 10px * 2) + calc( 0 * 6px * 2 )) ) / 2 - 10px / 2));
  }
}
.dial-ring:nth-child(1) .dial-ring-cap-end {
  left: 50%;
  top: 50%;
  transform-origin: center;
  z-index: 9999999999999;
  animation-name: rotate-ring-cap-1;
  animation-delay: 0.4s;
  animation-timing-function: ease-in-out;
  animation-duration: 0.6s;
  animation-fill-mode: forwards;
  transform: translate(-50%, -50%) rotate(-90deg) translate(calc(calc( 180px - (calc(0 * 10px * 2) + calc( 0 * 6px * 2 )) ) / 2 - 10px / 2));
}
@keyframes rotate-ring-cap-1 {
  78% {
    transform: translate(-50%, -50%) rotate(calc(var(--angle) * (78 / 100) - 90deg)) translate(calc(calc( 180px - (calc(0 * 10px * 2) + calc( 0 * 6px * 2 )) ) / 2 - 10px / 2));
  }
}
.dial-ring:nth-child(1) .dial-ring-cap-end {
  left: 50%;
  top: 50%;
  transform-origin: center;
  z-index: 9999999999999;
  animation-name: rotate-ring-cap-1;
  animation-delay: 0.4s;
  animation-timing-function: ease-in-out;
  animation-duration: 0.6s;
  animation-fill-mode: forwards;
  transform: translate(-50%, -50%) rotate(-90deg) translate(calc(calc( 180px - (calc(0 * 10px * 2) + calc( 0 * 6px * 2 )) ) / 2 - 10px / 2));
}
@keyframes rotate-ring-cap-1 {
  79% {
    transform: translate(-50%, -50%) rotate(calc(var(--angle) * (79 / 100) - 90deg)) translate(calc(calc( 180px - (calc(0 * 10px * 2) + calc( 0 * 6px * 2 )) ) / 2 - 10px / 2));
  }
}
.dial-ring:nth-child(1) .dial-ring-cap-end {
  left: 50%;
  top: 50%;
  transform-origin: center;
  z-index: 9999999999999;
  animation-name: rotate-ring-cap-1;
  animation-delay: 0.4s;
  animation-timing-function: ease-in-out;
  animation-duration: 0.6s;
  animation-fill-mode: forwards;
  transform: translate(-50%, -50%) rotate(-90deg) translate(calc(calc( 180px - (calc(0 * 10px * 2) + calc( 0 * 6px * 2 )) ) / 2 - 10px / 2));
}
@keyframes rotate-ring-cap-1 {
  80% {
    transform: translate(-50%, -50%) rotate(calc(var(--angle) * (80 / 100) - 90deg)) translate(calc(calc( 180px - (calc(0 * 10px * 2) + calc( 0 * 6px * 2 )) ) / 2 - 10px / 2));
  }
}
.dial-ring:nth-child(1) .dial-ring-cap-end {
  left: 50%;
  top: 50%;
  transform-origin: center;
  z-index: 9999999999999;
  animation-name: rotate-ring-cap-1;
  animation-delay: 0.4s;
  animation-timing-function: ease-in-out;
  animation-duration: 0.6s;
  animation-fill-mode: forwards;
  transform: translate(-50%, -50%) rotate(-90deg) translate(calc(calc( 180px - (calc(0 * 10px * 2) + calc( 0 * 6px * 2 )) ) / 2 - 10px / 2));
}
@keyframes rotate-ring-cap-1 {
  81% {
    transform: translate(-50%, -50%) rotate(calc(var(--angle) * (81 / 100) - 90deg)) translate(calc(calc( 180px - (calc(0 * 10px * 2) + calc( 0 * 6px * 2 )) ) / 2 - 10px / 2));
  }
}
.dial-ring:nth-child(1) .dial-ring-cap-end {
  left: 50%;
  top: 50%;
  transform-origin: center;
  z-index: 9999999999999;
  animation-name: rotate-ring-cap-1;
  animation-delay: 0.4s;
  animation-timing-function: ease-in-out;
  animation-duration: 0.6s;
  animation-fill-mode: forwards;
  transform: translate(-50%, -50%) rotate(-90deg) translate(calc(calc( 180px - (calc(0 * 10px * 2) + calc( 0 * 6px * 2 )) ) / 2 - 10px / 2));
}
@keyframes rotate-ring-cap-1 {
  82% {
    transform: translate(-50%, -50%) rotate(calc(var(--angle) * (82 / 100) - 90deg)) translate(calc(calc( 180px - (calc(0 * 10px * 2) + calc( 0 * 6px * 2 )) ) / 2 - 10px / 2));
  }
}
.dial-ring:nth-child(1) .dial-ring-cap-end {
  left: 50%;
  top: 50%;
  transform-origin: center;
  z-index: 9999999999999;
  animation-name: rotate-ring-cap-1;
  animation-delay: 0.4s;
  animation-timing-function: ease-in-out;
  animation-duration: 0.6s;
  animation-fill-mode: forwards;
  transform: translate(-50%, -50%) rotate(-90deg) translate(calc(calc( 180px - (calc(0 * 10px * 2) + calc( 0 * 6px * 2 )) ) / 2 - 10px / 2));
}
@keyframes rotate-ring-cap-1 {
  83% {
    transform: translate(-50%, -50%) rotate(calc(var(--angle) * (83 / 100) - 90deg)) translate(calc(calc( 180px - (calc(0 * 10px * 2) + calc( 0 * 6px * 2 )) ) / 2 - 10px / 2));
  }
}
.dial-ring:nth-child(1) .dial-ring-cap-end {
  left: 50%;
  top: 50%;
  transform-origin: center;
  z-index: 9999999999999;
  animation-name: rotate-ring-cap-1;
  animation-delay: 0.4s;
  animation-timing-function: ease-in-out;
  animation-duration: 0.6s;
  animation-fill-mode: forwards;
  transform: translate(-50%, -50%) rotate(-90deg) translate(calc(calc( 180px - (calc(0 * 10px * 2) + calc( 0 * 6px * 2 )) ) / 2 - 10px / 2));
}
@keyframes rotate-ring-cap-1 {
  84% {
    transform: translate(-50%, -50%) rotate(calc(var(--angle) * (84 / 100) - 90deg)) translate(calc(calc( 180px - (calc(0 * 10px * 2) + calc( 0 * 6px * 2 )) ) / 2 - 10px / 2));
  }
}
.dial-ring:nth-child(1) .dial-ring-cap-end {
  left: 50%;
  top: 50%;
  transform-origin: center;
  z-index: 9999999999999;
  animation-name: rotate-ring-cap-1;
  animation-delay: 0.4s;
  animation-timing-function: ease-in-out;
  animation-duration: 0.6s;
  animation-fill-mode: forwards;
  transform: translate(-50%, -50%) rotate(-90deg) translate(calc(calc( 180px - (calc(0 * 10px * 2) + calc( 0 * 6px * 2 )) ) / 2 - 10px / 2));
}
@keyframes rotate-ring-cap-1 {
  85% {
    transform: translate(-50%, -50%) rotate(calc(var(--angle) * (85 / 100) - 90deg)) translate(calc(calc( 180px - (calc(0 * 10px * 2) + calc( 0 * 6px * 2 )) ) / 2 - 10px / 2));
  }
}
.dial-ring:nth-child(1) .dial-ring-cap-end {
  left: 50%;
  top: 50%;
  transform-origin: center;
  z-index: 9999999999999;
  animation-name: rotate-ring-cap-1;
  animation-delay: 0.4s;
  animation-timing-function: ease-in-out;
  animation-duration: 0.6s;
  animation-fill-mode: forwards;
  transform: translate(-50%, -50%) rotate(-90deg) translate(calc(calc( 180px - (calc(0 * 10px * 2) + calc( 0 * 6px * 2 )) ) / 2 - 10px / 2));
}
@keyframes rotate-ring-cap-1 {
  86% {
    transform: translate(-50%, -50%) rotate(calc(var(--angle) * (86 / 100) - 90deg)) translate(calc(calc( 180px - (calc(0 * 10px * 2) + calc( 0 * 6px * 2 )) ) / 2 - 10px / 2));
  }
}
.dial-ring:nth-child(1) .dial-ring-cap-end {
  left: 50%;
  top: 50%;
  transform-origin: center;
  z-index: 9999999999999;
  animation-name: rotate-ring-cap-1;
  animation-delay: 0.4s;
  animation-timing-function: ease-in-out;
  animation-duration: 0.6s;
  animation-fill-mode: forwards;
  transform: translate(-50%, -50%) rotate(-90deg) translate(calc(calc( 180px - (calc(0 * 10px * 2) + calc( 0 * 6px * 2 )) ) / 2 - 10px / 2));
}
@keyframes rotate-ring-cap-1 {
  87% {
    transform: translate(-50%, -50%) rotate(calc(var(--angle) * (87 / 100) - 90deg)) translate(calc(calc( 180px - (calc(0 * 10px * 2) + calc( 0 * 6px * 2 )) ) / 2 - 10px / 2));
  }
}
.dial-ring:nth-child(1) .dial-ring-cap-end {
  left: 50%;
  top: 50%;
  transform-origin: center;
  z-index: 9999999999999;
  animation-name: rotate-ring-cap-1;
  animation-delay: 0.4s;
  animation-timing-function: ease-in-out;
  animation-duration: 0.6s;
  animation-fill-mode: forwards;
  transform: translate(-50%, -50%) rotate(-90deg) translate(calc(calc( 180px - (calc(0 * 10px * 2) + calc( 0 * 6px * 2 )) ) / 2 - 10px / 2));
}
@keyframes rotate-ring-cap-1 {
  88% {
    transform: translate(-50%, -50%) rotate(calc(var(--angle) * (88 / 100) - 90deg)) translate(calc(calc( 180px - (calc(0 * 10px * 2) + calc( 0 * 6px * 2 )) ) / 2 - 10px / 2));
  }
}
.dial-ring:nth-child(1) .dial-ring-cap-end {
  left: 50%;
  top: 50%;
  transform-origin: center;
  z-index: 9999999999999;
  animation-name: rotate-ring-cap-1;
  animation-delay: 0.4s;
  animation-timing-function: ease-in-out;
  animation-duration: 0.6s;
  animation-fill-mode: forwards;
  transform: translate(-50%, -50%) rotate(-90deg) translate(calc(calc( 180px - (calc(0 * 10px * 2) + calc( 0 * 6px * 2 )) ) / 2 - 10px / 2));
}
@keyframes rotate-ring-cap-1 {
  89% {
    transform: translate(-50%, -50%) rotate(calc(var(--angle) * (89 / 100) - 90deg)) translate(calc(calc( 180px - (calc(0 * 10px * 2) + calc( 0 * 6px * 2 )) ) / 2 - 10px / 2));
  }
}
.dial-ring:nth-child(1) .dial-ring-cap-end {
  left: 50%;
  top: 50%;
  transform-origin: center;
  z-index: 9999999999999;
  animation-name: rotate-ring-cap-1;
  animation-delay: 0.4s;
  animation-timing-function: ease-in-out;
  animation-duration: 0.6s;
  animation-fill-mode: forwards;
  transform: translate(-50%, -50%) rotate(-90deg) translate(calc(calc( 180px - (calc(0 * 10px * 2) + calc( 0 * 6px * 2 )) ) / 2 - 10px / 2));
}
@keyframes rotate-ring-cap-1 {
  90% {
    transform: translate(-50%, -50%) rotate(calc(var(--angle) * (90 / 100) - 90deg)) translate(calc(calc( 180px - (calc(0 * 10px * 2) + calc( 0 * 6px * 2 )) ) / 2 - 10px / 2));
  }
}
.dial-ring:nth-child(1) .dial-ring-cap-end {
  left: 50%;
  top: 50%;
  transform-origin: center;
  z-index: 9999999999999;
  animation-name: rotate-ring-cap-1;
  animation-delay: 0.4s;
  animation-timing-function: ease-in-out;
  animation-duration: 0.6s;
  animation-fill-mode: forwards;
  transform: translate(-50%, -50%) rotate(-90deg) translate(calc(calc( 180px - (calc(0 * 10px * 2) + calc( 0 * 6px * 2 )) ) / 2 - 10px / 2));
}
@keyframes rotate-ring-cap-1 {
  91% {
    transform: translate(-50%, -50%) rotate(calc(var(--angle) * (91 / 100) - 90deg)) translate(calc(calc( 180px - (calc(0 * 10px * 2) + calc( 0 * 6px * 2 )) ) / 2 - 10px / 2));
  }
}
.dial-ring:nth-child(1) .dial-ring-cap-end {
  left: 50%;
  top: 50%;
  transform-origin: center;
  z-index: 9999999999999;
  animation-name: rotate-ring-cap-1;
  animation-delay: 0.4s;
  animation-timing-function: ease-in-out;
  animation-duration: 0.6s;
  animation-fill-mode: forwards;
  transform: translate(-50%, -50%) rotate(-90deg) translate(calc(calc( 180px - (calc(0 * 10px * 2) + calc( 0 * 6px * 2 )) ) / 2 - 10px / 2));
}
@keyframes rotate-ring-cap-1 {
  92% {
    transform: translate(-50%, -50%) rotate(calc(var(--angle) * (92 / 100) - 90deg)) translate(calc(calc( 180px - (calc(0 * 10px * 2) + calc( 0 * 6px * 2 )) ) / 2 - 10px / 2));
  }
}
.dial-ring:nth-child(1) .dial-ring-cap-end {
  left: 50%;
  top: 50%;
  transform-origin: center;
  z-index: 9999999999999;
  animation-name: rotate-ring-cap-1;
  animation-delay: 0.4s;
  animation-timing-function: ease-in-out;
  animation-duration: 0.6s;
  animation-fill-mode: forwards;
  transform: translate(-50%, -50%) rotate(-90deg) translate(calc(calc( 180px - (calc(0 * 10px * 2) + calc( 0 * 6px * 2 )) ) / 2 - 10px / 2));
}
@keyframes rotate-ring-cap-1 {
  93% {
    transform: translate(-50%, -50%) rotate(calc(var(--angle) * (93 / 100) - 90deg)) translate(calc(calc( 180px - (calc(0 * 10px * 2) + calc( 0 * 6px * 2 )) ) / 2 - 10px / 2));
  }
}
.dial-ring:nth-child(1) .dial-ring-cap-end {
  left: 50%;
  top: 50%;
  transform-origin: center;
  z-index: 9999999999999;
  animation-name: rotate-ring-cap-1;
  animation-delay: 0.4s;
  animation-timing-function: ease-in-out;
  animation-duration: 0.6s;
  animation-fill-mode: forwards;
  transform: translate(-50%, -50%) rotate(-90deg) translate(calc(calc( 180px - (calc(0 * 10px * 2) + calc( 0 * 6px * 2 )) ) / 2 - 10px / 2));
}
@keyframes rotate-ring-cap-1 {
  94% {
    transform: translate(-50%, -50%) rotate(calc(var(--angle) * (94 / 100) - 90deg)) translate(calc(calc( 180px - (calc(0 * 10px * 2) + calc( 0 * 6px * 2 )) ) / 2 - 10px / 2));
  }
}
.dial-ring:nth-child(1) .dial-ring-cap-end {
  left: 50%;
  top: 50%;
  transform-origin: center;
  z-index: 9999999999999;
  animation-name: rotate-ring-cap-1;
  animation-delay: 0.4s;
  animation-timing-function: ease-in-out;
  animation-duration: 0.6s;
  animation-fill-mode: forwards;
  transform: translate(-50%, -50%) rotate(-90deg) translate(calc(calc( 180px - (calc(0 * 10px * 2) + calc( 0 * 6px * 2 )) ) / 2 - 10px / 2));
}
@keyframes rotate-ring-cap-1 {
  95% {
    transform: translate(-50%, -50%) rotate(calc(var(--angle) * (95 / 100) - 90deg)) translate(calc(calc( 180px - (calc(0 * 10px * 2) + calc( 0 * 6px * 2 )) ) / 2 - 10px / 2));
  }
}
.dial-ring:nth-child(1) .dial-ring-cap-end {
  left: 50%;
  top: 50%;
  transform-origin: center;
  z-index: 9999999999999;
  animation-name: rotate-ring-cap-1;
  animation-delay: 0.4s;
  animation-timing-function: ease-in-out;
  animation-duration: 0.6s;
  animation-fill-mode: forwards;
  transform: translate(-50%, -50%) rotate(-90deg) translate(calc(calc( 180px - (calc(0 * 10px * 2) + calc( 0 * 6px * 2 )) ) / 2 - 10px / 2));
}
@keyframes rotate-ring-cap-1 {
  96% {
    transform: translate(-50%, -50%) rotate(calc(var(--angle) * (96 / 100) - 90deg)) translate(calc(calc( 180px - (calc(0 * 10px * 2) + calc( 0 * 6px * 2 )) ) / 2 - 10px / 2));
  }
}
.dial-ring:nth-child(1) .dial-ring-cap-end {
  left: 50%;
  top: 50%;
  transform-origin: center;
  z-index: 9999999999999;
  animation-name: rotate-ring-cap-1;
  animation-delay: 0.4s;
  animation-timing-function: ease-in-out;
  animation-duration: 0.6s;
  animation-fill-mode: forwards;
  transform: translate(-50%, -50%) rotate(-90deg) translate(calc(calc( 180px - (calc(0 * 10px * 2) + calc( 0 * 6px * 2 )) ) / 2 - 10px / 2));
}
@keyframes rotate-ring-cap-1 {
  97% {
    transform: translate(-50%, -50%) rotate(calc(var(--angle) * (97 / 100) - 90deg)) translate(calc(calc( 180px - (calc(0 * 10px * 2) + calc( 0 * 6px * 2 )) ) / 2 - 10px / 2));
  }
}
.dial-ring:nth-child(1) .dial-ring-cap-end {
  left: 50%;
  top: 50%;
  transform-origin: center;
  z-index: 9999999999999;
  animation-name: rotate-ring-cap-1;
  animation-delay: 0.4s;
  animation-timing-function: ease-in-out;
  animation-duration: 0.6s;
  animation-fill-mode: forwards;
  transform: translate(-50%, -50%) rotate(-90deg) translate(calc(calc( 180px - (calc(0 * 10px * 2) + calc( 0 * 6px * 2 )) ) / 2 - 10px / 2));
}
@keyframes rotate-ring-cap-1 {
  98% {
    transform: translate(-50%, -50%) rotate(calc(var(--angle) * (98 / 100) - 90deg)) translate(calc(calc( 180px - (calc(0 * 10px * 2) + calc( 0 * 6px * 2 )) ) / 2 - 10px / 2));
  }
}
.dial-ring:nth-child(1) .dial-ring-cap-end {
  left: 50%;
  top: 50%;
  transform-origin: center;
  z-index: 9999999999999;
  animation-name: rotate-ring-cap-1;
  animation-delay: 0.4s;
  animation-timing-function: ease-in-out;
  animation-duration: 0.6s;
  animation-fill-mode: forwards;
  transform: translate(-50%, -50%) rotate(-90deg) translate(calc(calc( 180px - (calc(0 * 10px * 2) + calc( 0 * 6px * 2 )) ) / 2 - 10px / 2));
}
@keyframes rotate-ring-cap-1 {
  99% {
    transform: translate(-50%, -50%) rotate(calc(var(--angle) * (99 / 100) - 90deg)) translate(calc(calc( 180px - (calc(0 * 10px * 2) + calc( 0 * 6px * 2 )) ) / 2 - 10px / 2));
  }
}
.dial-ring:nth-child(1) .dial-ring-cap-end {
  left: 50%;
  top: 50%;
  transform-origin: center;
  z-index: 9999999999999;
  animation-name: rotate-ring-cap-1;
  animation-delay: 0.4s;
  animation-timing-function: ease-in-out;
  animation-duration: 0.6s;
  animation-fill-mode: forwards;
  transform: translate(-50%, -50%) rotate(-90deg) translate(calc(calc( 180px - (calc(0 * 10px * 2) + calc( 0 * 6px * 2 )) ) / 2 - 10px / 2));
}
@keyframes rotate-ring-cap-1 {
  100% {
    transform: translate(-50%, -50%) rotate(calc(var(--angle) * (100 / 100) - 90deg)) translate(calc(calc( 180px - (calc(0 * 10px * 2) + calc( 0 * 6px * 2 )) ) / 2 - 10px / 2));
  }
}
.dial-ring--spacer:not(:last-child):nth-child(4) {
  position: absolute;
  border-radius: 50%;
  left: 50%;
  top: 50%;
  transform: translateX(-50%) translateY(-50%);
  width: calc(180px - calc(
        2 *
          10px *
          2 +
          ((2 - 1) * 6px * 2)
      ));
  height: calc(180px - calc(
        2 *
          10px *
          2 +
          ((2 - 1) * 6px * 2)
      ));
  background: #272727;
}
.dial-ring:nth-child(3) {
  width: calc(
        180px - (calc(1 * 10px * 2) + calc(
        1 * 6px * 2
      ))
      );
  height: calc(
        180px - (calc(1 * 10px * 2) + calc(
        1 * 6px * 2
      ))
      );
}
.dial-ring:nth-child(3) .dial-ring-progress:before {
  animation-delay: 0.4s;
  animation-timing-function: ease-in-out;
  animation-duration: 0.6s;
  animation-name: rotation-2;
  animation-fill-mode: forwards;
}
@keyframes rotation-2 {
  to {
    transform: rotate(var(--angle));
  }
}
.dial-ring:nth-child(3) .dial-ring-progress:not(.dial-ring-progress--less-than-halfway) {
  animation-name: show-left-hemisphere-2;
}
.dial-ring:nth-child(3) .dial-ring-progress:not(.dial-ring-progress--less-than-halfway), .dial-ring:nth-child(3) .dial-ring-progress:not(.dial-ring-progress--less-than-halfway):after {
  animation-timing-function: linear;
  animation-duration: 0s;
  animation-fill-mode: forwards;
}
.dial-ring:nth-child(3) .dial-ring-progress:not(.dial-ring-progress--less-than-halfway).dial-ring-progress--segments-3, .dial-ring:nth-child(3) .dial-ring-progress:not(.dial-ring-progress--less-than-halfway).dial-ring-progress--segments-3:after {
  animation-delay: calc(calc(
            var(--percent-complete-at-half-filled) *
              0.6s +
              0.4s
          ) - 0.075s);
}
.dial-ring:nth-child(3) .dial-ring-progress:not(.dial-ring-progress--less-than-halfway).dial-ring-progress--segments-4, .dial-ring:nth-child(3) .dial-ring-progress:not(.dial-ring-progress--less-than-halfway).dial-ring-progress--segments-4:after {
  animation-delay: calc(calc(
            var(--percent-complete-at-half-filled) *
              0.6s +
              0.4s
          ) - 0.03s);
}
.dial-ring:nth-child(3) .dial-ring-progress:not(.dial-ring-progress--less-than-halfway).dial-ring-progress--segments-5, .dial-ring:nth-child(3) .dial-ring-progress:not(.dial-ring-progress--less-than-halfway).dial-ring-progress--segments-5:after {
  animation-delay: calc(
            var(--percent-complete-at-half-filled) *
              0.6s +
              0.4s
          );
}
@keyframes show-left-hemisphere-2 {
  to {
    clip-path: none;
  }
}
.dial-ring:nth-child(3) .dial-ring-progress:not(.dial-ring-progress--less-than-halfway):after {
  animation-name: fill-right-hemisphere-2;
}
@keyframes fill-right-hemisphere-2 {
  to {
    opacity: 1;
  }
}
.dial-ring:nth-child(3) .dial-ring-cap-end {
  left: 50%;
  top: 50%;
  transform-origin: center;
  z-index: 9999999999999;
  animation-name: rotate-ring-cap-2;
  animation-delay: 0.4s;
  animation-timing-function: ease-in-out;
  animation-duration: 0.6s;
  animation-fill-mode: forwards;
  transform: translate(-50%, -50%) rotate(-90deg) translate(calc(calc( 180px - (calc(1 * 10px * 2) + calc( 1 * 6px * 2 )) ) / 2 - 10px / 2));
}
@keyframes rotate-ring-cap-2 {
  0% {
    transform: translate(-50%, -50%) rotate(calc(var(--angle) * (0 / 100) - 90deg)) translate(calc(calc( 180px - (calc(1 * 10px * 2) + calc( 1 * 6px * 2 )) ) / 2 - 10px / 2));
  }
}
.dial-ring:nth-child(3) .dial-ring-cap-end {
  left: 50%;
  top: 50%;
  transform-origin: center;
  z-index: 9999999999999;
  animation-name: rotate-ring-cap-2;
  animation-delay: 0.4s;
  animation-timing-function: ease-in-out;
  animation-duration: 0.6s;
  animation-fill-mode: forwards;
  transform: translate(-50%, -50%) rotate(-90deg) translate(calc(calc( 180px - (calc(1 * 10px * 2) + calc( 1 * 6px * 2 )) ) / 2 - 10px / 2));
}
@keyframes rotate-ring-cap-2 {
  1% {
    transform: translate(-50%, -50%) rotate(calc(var(--angle) * (1 / 100) - 90deg)) translate(calc(calc( 180px - (calc(1 * 10px * 2) + calc( 1 * 6px * 2 )) ) / 2 - 10px / 2));
  }
}
.dial-ring:nth-child(3) .dial-ring-cap-end {
  left: 50%;
  top: 50%;
  transform-origin: center;
  z-index: 9999999999999;
  animation-name: rotate-ring-cap-2;
  animation-delay: 0.4s;
  animation-timing-function: ease-in-out;
  animation-duration: 0.6s;
  animation-fill-mode: forwards;
  transform: translate(-50%, -50%) rotate(-90deg) translate(calc(calc( 180px - (calc(1 * 10px * 2) + calc( 1 * 6px * 2 )) ) / 2 - 10px / 2));
}
@keyframes rotate-ring-cap-2 {
  2% {
    transform: translate(-50%, -50%) rotate(calc(var(--angle) * (2 / 100) - 90deg)) translate(calc(calc( 180px - (calc(1 * 10px * 2) + calc( 1 * 6px * 2 )) ) / 2 - 10px / 2));
  }
}
.dial-ring:nth-child(3) .dial-ring-cap-end {
  left: 50%;
  top: 50%;
  transform-origin: center;
  z-index: 9999999999999;
  animation-name: rotate-ring-cap-2;
  animation-delay: 0.4s;
  animation-timing-function: ease-in-out;
  animation-duration: 0.6s;
  animation-fill-mode: forwards;
  transform: translate(-50%, -50%) rotate(-90deg) translate(calc(calc( 180px - (calc(1 * 10px * 2) + calc( 1 * 6px * 2 )) ) / 2 - 10px / 2));
}
@keyframes rotate-ring-cap-2 {
  3% {
    transform: translate(-50%, -50%) rotate(calc(var(--angle) * (3 / 100) - 90deg)) translate(calc(calc( 180px - (calc(1 * 10px * 2) + calc( 1 * 6px * 2 )) ) / 2 - 10px / 2));
  }
}
.dial-ring:nth-child(3) .dial-ring-cap-end {
  left: 50%;
  top: 50%;
  transform-origin: center;
  z-index: 9999999999999;
  animation-name: rotate-ring-cap-2;
  animation-delay: 0.4s;
  animation-timing-function: ease-in-out;
  animation-duration: 0.6s;
  animation-fill-mode: forwards;
  transform: translate(-50%, -50%) rotate(-90deg) translate(calc(calc( 180px - (calc(1 * 10px * 2) + calc( 1 * 6px * 2 )) ) / 2 - 10px / 2));
}
@keyframes rotate-ring-cap-2 {
  4% {
    transform: translate(-50%, -50%) rotate(calc(var(--angle) * (4 / 100) - 90deg)) translate(calc(calc( 180px - (calc(1 * 10px * 2) + calc( 1 * 6px * 2 )) ) / 2 - 10px / 2));
  }
}
.dial-ring:nth-child(3) .dial-ring-cap-end {
  left: 50%;
  top: 50%;
  transform-origin: center;
  z-index: 9999999999999;
  animation-name: rotate-ring-cap-2;
  animation-delay: 0.4s;
  animation-timing-function: ease-in-out;
  animation-duration: 0.6s;
  animation-fill-mode: forwards;
  transform: translate(-50%, -50%) rotate(-90deg) translate(calc(calc( 180px - (calc(1 * 10px * 2) + calc( 1 * 6px * 2 )) ) / 2 - 10px / 2));
}
@keyframes rotate-ring-cap-2 {
  5% {
    transform: translate(-50%, -50%) rotate(calc(var(--angle) * (5 / 100) - 90deg)) translate(calc(calc( 180px - (calc(1 * 10px * 2) + calc( 1 * 6px * 2 )) ) / 2 - 10px / 2));
  }
}
.dial-ring:nth-child(3) .dial-ring-cap-end {
  left: 50%;
  top: 50%;
  transform-origin: center;
  z-index: 9999999999999;
  animation-name: rotate-ring-cap-2;
  animation-delay: 0.4s;
  animation-timing-function: ease-in-out;
  animation-duration: 0.6s;
  animation-fill-mode: forwards;
  transform: translate(-50%, -50%) rotate(-90deg) translate(calc(calc( 180px - (calc(1 * 10px * 2) + calc( 1 * 6px * 2 )) ) / 2 - 10px / 2));
}
@keyframes rotate-ring-cap-2 {
  6% {
    transform: translate(-50%, -50%) rotate(calc(var(--angle) * (6 / 100) - 90deg)) translate(calc(calc( 180px - (calc(1 * 10px * 2) + calc( 1 * 6px * 2 )) ) / 2 - 10px / 2));
  }
}
.dial-ring:nth-child(3) .dial-ring-cap-end {
  left: 50%;
  top: 50%;
  transform-origin: center;
  z-index: 9999999999999;
  animation-name: rotate-ring-cap-2;
  animation-delay: 0.4s;
  animation-timing-function: ease-in-out;
  animation-duration: 0.6s;
  animation-fill-mode: forwards;
  transform: translate(-50%, -50%) rotate(-90deg) translate(calc(calc( 180px - (calc(1 * 10px * 2) + calc( 1 * 6px * 2 )) ) / 2 - 10px / 2));
}
@keyframes rotate-ring-cap-2 {
  7% {
    transform: translate(-50%, -50%) rotate(calc(var(--angle) * (7 / 100) - 90deg)) translate(calc(calc( 180px - (calc(1 * 10px * 2) + calc( 1 * 6px * 2 )) ) / 2 - 10px / 2));
  }
}
.dial-ring:nth-child(3) .dial-ring-cap-end {
  left: 50%;
  top: 50%;
  transform-origin: center;
  z-index: 9999999999999;
  animation-name: rotate-ring-cap-2;
  animation-delay: 0.4s;
  animation-timing-function: ease-in-out;
  animation-duration: 0.6s;
  animation-fill-mode: forwards;
  transform: translate(-50%, -50%) rotate(-90deg) translate(calc(calc( 180px - (calc(1 * 10px * 2) + calc( 1 * 6px * 2 )) ) / 2 - 10px / 2));
}
@keyframes rotate-ring-cap-2 {
  8% {
    transform: translate(-50%, -50%) rotate(calc(var(--angle) * (8 / 100) - 90deg)) translate(calc(calc( 180px - (calc(1 * 10px * 2) + calc( 1 * 6px * 2 )) ) / 2 - 10px / 2));
  }
}
.dial-ring:nth-child(3) .dial-ring-cap-end {
  left: 50%;
  top: 50%;
  transform-origin: center;
  z-index: 9999999999999;
  animation-name: rotate-ring-cap-2;
  animation-delay: 0.4s;
  animation-timing-function: ease-in-out;
  animation-duration: 0.6s;
  animation-fill-mode: forwards;
  transform: translate(-50%, -50%) rotate(-90deg) translate(calc(calc( 180px - (calc(1 * 10px * 2) + calc( 1 * 6px * 2 )) ) / 2 - 10px / 2));
}
@keyframes rotate-ring-cap-2 {
  9% {
    transform: translate(-50%, -50%) rotate(calc(var(--angle) * (9 / 100) - 90deg)) translate(calc(calc( 180px - (calc(1 * 10px * 2) + calc( 1 * 6px * 2 )) ) / 2 - 10px / 2));
  }
}
.dial-ring:nth-child(3) .dial-ring-cap-end {
  left: 50%;
  top: 50%;
  transform-origin: center;
  z-index: 9999999999999;
  animation-name: rotate-ring-cap-2;
  animation-delay: 0.4s;
  animation-timing-function: ease-in-out;
  animation-duration: 0.6s;
  animation-fill-mode: forwards;
  transform: translate(-50%, -50%) rotate(-90deg) translate(calc(calc( 180px - (calc(1 * 10px * 2) + calc( 1 * 6px * 2 )) ) / 2 - 10px / 2));
}
@keyframes rotate-ring-cap-2 {
  10% {
    transform: translate(-50%, -50%) rotate(calc(var(--angle) * (10 / 100) - 90deg)) translate(calc(calc( 180px - (calc(1 * 10px * 2) + calc( 1 * 6px * 2 )) ) / 2 - 10px / 2));
  }
}
.dial-ring:nth-child(3) .dial-ring-cap-end {
  left: 50%;
  top: 50%;
  transform-origin: center;
  z-index: 9999999999999;
  animation-name: rotate-ring-cap-2;
  animation-delay: 0.4s;
  animation-timing-function: ease-in-out;
  animation-duration: 0.6s;
  animation-fill-mode: forwards;
  transform: translate(-50%, -50%) rotate(-90deg) translate(calc(calc( 180px - (calc(1 * 10px * 2) + calc( 1 * 6px * 2 )) ) / 2 - 10px / 2));
}
@keyframes rotate-ring-cap-2 {
  11% {
    transform: translate(-50%, -50%) rotate(calc(var(--angle) * (11 / 100) - 90deg)) translate(calc(calc( 180px - (calc(1 * 10px * 2) + calc( 1 * 6px * 2 )) ) / 2 - 10px / 2));
  }
}
.dial-ring:nth-child(3) .dial-ring-cap-end {
  left: 50%;
  top: 50%;
  transform-origin: center;
  z-index: 9999999999999;
  animation-name: rotate-ring-cap-2;
  animation-delay: 0.4s;
  animation-timing-function: ease-in-out;
  animation-duration: 0.6s;
  animation-fill-mode: forwards;
  transform: translate(-50%, -50%) rotate(-90deg) translate(calc(calc( 180px - (calc(1 * 10px * 2) + calc( 1 * 6px * 2 )) ) / 2 - 10px / 2));
}
@keyframes rotate-ring-cap-2 {
  12% {
    transform: translate(-50%, -50%) rotate(calc(var(--angle) * (12 / 100) - 90deg)) translate(calc(calc( 180px - (calc(1 * 10px * 2) + calc( 1 * 6px * 2 )) ) / 2 - 10px / 2));
  }
}
.dial-ring:nth-child(3) .dial-ring-cap-end {
  left: 50%;
  top: 50%;
  transform-origin: center;
  z-index: 9999999999999;
  animation-name: rotate-ring-cap-2;
  animation-delay: 0.4s;
  animation-timing-function: ease-in-out;
  animation-duration: 0.6s;
  animation-fill-mode: forwards;
  transform: translate(-50%, -50%) rotate(-90deg) translate(calc(calc( 180px - (calc(1 * 10px * 2) + calc( 1 * 6px * 2 )) ) / 2 - 10px / 2));
}
@keyframes rotate-ring-cap-2 {
  13% {
    transform: translate(-50%, -50%) rotate(calc(var(--angle) * (13 / 100) - 90deg)) translate(calc(calc( 180px - (calc(1 * 10px * 2) + calc( 1 * 6px * 2 )) ) / 2 - 10px / 2));
  }
}
.dial-ring:nth-child(3) .dial-ring-cap-end {
  left: 50%;
  top: 50%;
  transform-origin: center;
  z-index: 9999999999999;
  animation-name: rotate-ring-cap-2;
  animation-delay: 0.4s;
  animation-timing-function: ease-in-out;
  animation-duration: 0.6s;
  animation-fill-mode: forwards;
  transform: translate(-50%, -50%) rotate(-90deg) translate(calc(calc( 180px - (calc(1 * 10px * 2) + calc( 1 * 6px * 2 )) ) / 2 - 10px / 2));
}
@keyframes rotate-ring-cap-2 {
  14% {
    transform: translate(-50%, -50%) rotate(calc(var(--angle) * (14 / 100) - 90deg)) translate(calc(calc( 180px - (calc(1 * 10px * 2) + calc( 1 * 6px * 2 )) ) / 2 - 10px / 2));
  }
}
.dial-ring:nth-child(3) .dial-ring-cap-end {
  left: 50%;
  top: 50%;
  transform-origin: center;
  z-index: 9999999999999;
  animation-name: rotate-ring-cap-2;
  animation-delay: 0.4s;
  animation-timing-function: ease-in-out;
  animation-duration: 0.6s;
  animation-fill-mode: forwards;
  transform: translate(-50%, -50%) rotate(-90deg) translate(calc(calc( 180px - (calc(1 * 10px * 2) + calc( 1 * 6px * 2 )) ) / 2 - 10px / 2));
}
@keyframes rotate-ring-cap-2 {
  15% {
    transform: translate(-50%, -50%) rotate(calc(var(--angle) * (15 / 100) - 90deg)) translate(calc(calc( 180px - (calc(1 * 10px * 2) + calc( 1 * 6px * 2 )) ) / 2 - 10px / 2));
  }
}
.dial-ring:nth-child(3) .dial-ring-cap-end {
  left: 50%;
  top: 50%;
  transform-origin: center;
  z-index: 9999999999999;
  animation-name: rotate-ring-cap-2;
  animation-delay: 0.4s;
  animation-timing-function: ease-in-out;
  animation-duration: 0.6s;
  animation-fill-mode: forwards;
  transform: translate(-50%, -50%) rotate(-90deg) translate(calc(calc( 180px - (calc(1 * 10px * 2) + calc( 1 * 6px * 2 )) ) / 2 - 10px / 2));
}
@keyframes rotate-ring-cap-2 {
  16% {
    transform: translate(-50%, -50%) rotate(calc(var(--angle) * (16 / 100) - 90deg)) translate(calc(calc( 180px - (calc(1 * 10px * 2) + calc( 1 * 6px * 2 )) ) / 2 - 10px / 2));
  }
}
.dial-ring:nth-child(3) .dial-ring-cap-end {
  left: 50%;
  top: 50%;
  transform-origin: center;
  z-index: 9999999999999;
  animation-name: rotate-ring-cap-2;
  animation-delay: 0.4s;
  animation-timing-function: ease-in-out;
  animation-duration: 0.6s;
  animation-fill-mode: forwards;
  transform: translate(-50%, -50%) rotate(-90deg) translate(calc(calc( 180px - (calc(1 * 10px * 2) + calc( 1 * 6px * 2 )) ) / 2 - 10px / 2));
}
@keyframes rotate-ring-cap-2 {
  17% {
    transform: translate(-50%, -50%) rotate(calc(var(--angle) * (17 / 100) - 90deg)) translate(calc(calc( 180px - (calc(1 * 10px * 2) + calc( 1 * 6px * 2 )) ) / 2 - 10px / 2));
  }
}
.dial-ring:nth-child(3) .dial-ring-cap-end {
  left: 50%;
  top: 50%;
  transform-origin: center;
  z-index: 9999999999999;
  animation-name: rotate-ring-cap-2;
  animation-delay: 0.4s;
  animation-timing-function: ease-in-out;
  animation-duration: 0.6s;
  animation-fill-mode: forwards;
  transform: translate(-50%, -50%) rotate(-90deg) translate(calc(calc( 180px - (calc(1 * 10px * 2) + calc( 1 * 6px * 2 )) ) / 2 - 10px / 2));
}
@keyframes rotate-ring-cap-2 {
  18% {
    transform: translate(-50%, -50%) rotate(calc(var(--angle) * (18 / 100) - 90deg)) translate(calc(calc( 180px - (calc(1 * 10px * 2) + calc( 1 * 6px * 2 )) ) / 2 - 10px / 2));
  }
}
.dial-ring:nth-child(3) .dial-ring-cap-end {
  left: 50%;
  top: 50%;
  transform-origin: center;
  z-index: 9999999999999;
  animation-name: rotate-ring-cap-2;
  animation-delay: 0.4s;
  animation-timing-function: ease-in-out;
  animation-duration: 0.6s;
  animation-fill-mode: forwards;
  transform: translate(-50%, -50%) rotate(-90deg) translate(calc(calc( 180px - (calc(1 * 10px * 2) + calc( 1 * 6px * 2 )) ) / 2 - 10px / 2));
}
@keyframes rotate-ring-cap-2 {
  19% {
    transform: translate(-50%, -50%) rotate(calc(var(--angle) * (19 / 100) - 90deg)) translate(calc(calc( 180px - (calc(1 * 10px * 2) + calc( 1 * 6px * 2 )) ) / 2 - 10px / 2));
  }
}
.dial-ring:nth-child(3) .dial-ring-cap-end {
  left: 50%;
  top: 50%;
  transform-origin: center;
  z-index: 9999999999999;
  animation-name: rotate-ring-cap-2;
  animation-delay: 0.4s;
  animation-timing-function: ease-in-out;
  animation-duration: 0.6s;
  animation-fill-mode: forwards;
  transform: translate(-50%, -50%) rotate(-90deg) translate(calc(calc( 180px - (calc(1 * 10px * 2) + calc( 1 * 6px * 2 )) ) / 2 - 10px / 2));
}
@keyframes rotate-ring-cap-2 {
  20% {
    transform: translate(-50%, -50%) rotate(calc(var(--angle) * (20 / 100) - 90deg)) translate(calc(calc( 180px - (calc(1 * 10px * 2) + calc( 1 * 6px * 2 )) ) / 2 - 10px / 2));
  }
}
.dial-ring:nth-child(3) .dial-ring-cap-end {
  left: 50%;
  top: 50%;
  transform-origin: center;
  z-index: 9999999999999;
  animation-name: rotate-ring-cap-2;
  animation-delay: 0.4s;
  animation-timing-function: ease-in-out;
  animation-duration: 0.6s;
  animation-fill-mode: forwards;
  transform: translate(-50%, -50%) rotate(-90deg) translate(calc(calc( 180px - (calc(1 * 10px * 2) + calc( 1 * 6px * 2 )) ) / 2 - 10px / 2));
}
@keyframes rotate-ring-cap-2 {
  21% {
    transform: translate(-50%, -50%) rotate(calc(var(--angle) * (21 / 100) - 90deg)) translate(calc(calc( 180px - (calc(1 * 10px * 2) + calc( 1 * 6px * 2 )) ) / 2 - 10px / 2));
  }
}
.dial-ring:nth-child(3) .dial-ring-cap-end {
  left: 50%;
  top: 50%;
  transform-origin: center;
  z-index: 9999999999999;
  animation-name: rotate-ring-cap-2;
  animation-delay: 0.4s;
  animation-timing-function: ease-in-out;
  animation-duration: 0.6s;
  animation-fill-mode: forwards;
  transform: translate(-50%, -50%) rotate(-90deg) translate(calc(calc( 180px - (calc(1 * 10px * 2) + calc( 1 * 6px * 2 )) ) / 2 - 10px / 2));
}
@keyframes rotate-ring-cap-2 {
  22% {
    transform: translate(-50%, -50%) rotate(calc(var(--angle) * (22 / 100) - 90deg)) translate(calc(calc( 180px - (calc(1 * 10px * 2) + calc( 1 * 6px * 2 )) ) / 2 - 10px / 2));
  }
}
.dial-ring:nth-child(3) .dial-ring-cap-end {
  left: 50%;
  top: 50%;
  transform-origin: center;
  z-index: 9999999999999;
  animation-name: rotate-ring-cap-2;
  animation-delay: 0.4s;
  animation-timing-function: ease-in-out;
  animation-duration: 0.6s;
  animation-fill-mode: forwards;
  transform: translate(-50%, -50%) rotate(-90deg) translate(calc(calc( 180px - (calc(1 * 10px * 2) + calc( 1 * 6px * 2 )) ) / 2 - 10px / 2));
}
@keyframes rotate-ring-cap-2 {
  23% {
    transform: translate(-50%, -50%) rotate(calc(var(--angle) * (23 / 100) - 90deg)) translate(calc(calc( 180px - (calc(1 * 10px * 2) + calc( 1 * 6px * 2 )) ) / 2 - 10px / 2));
  }
}
.dial-ring:nth-child(3) .dial-ring-cap-end {
  left: 50%;
  top: 50%;
  transform-origin: center;
  z-index: 9999999999999;
  animation-name: rotate-ring-cap-2;
  animation-delay: 0.4s;
  animation-timing-function: ease-in-out;
  animation-duration: 0.6s;
  animation-fill-mode: forwards;
  transform: translate(-50%, -50%) rotate(-90deg) translate(calc(calc( 180px - (calc(1 * 10px * 2) + calc( 1 * 6px * 2 )) ) / 2 - 10px / 2));
}
@keyframes rotate-ring-cap-2 {
  24% {
    transform: translate(-50%, -50%) rotate(calc(var(--angle) * (24 / 100) - 90deg)) translate(calc(calc( 180px - (calc(1 * 10px * 2) + calc( 1 * 6px * 2 )) ) / 2 - 10px / 2));
  }
}
.dial-ring:nth-child(3) .dial-ring-cap-end {
  left: 50%;
  top: 50%;
  transform-origin: center;
  z-index: 9999999999999;
  animation-name: rotate-ring-cap-2;
  animation-delay: 0.4s;
  animation-timing-function: ease-in-out;
  animation-duration: 0.6s;
  animation-fill-mode: forwards;
  transform: translate(-50%, -50%) rotate(-90deg) translate(calc(calc( 180px - (calc(1 * 10px * 2) + calc( 1 * 6px * 2 )) ) / 2 - 10px / 2));
}
@keyframes rotate-ring-cap-2 {
  25% {
    transform: translate(-50%, -50%) rotate(calc(var(--angle) * (25 / 100) - 90deg)) translate(calc(calc( 180px - (calc(1 * 10px * 2) + calc( 1 * 6px * 2 )) ) / 2 - 10px / 2));
  }
}
.dial-ring:nth-child(3) .dial-ring-cap-end {
  left: 50%;
  top: 50%;
  transform-origin: center;
  z-index: 9999999999999;
  animation-name: rotate-ring-cap-2;
  animation-delay: 0.4s;
  animation-timing-function: ease-in-out;
  animation-duration: 0.6s;
  animation-fill-mode: forwards;
  transform: translate(-50%, -50%) rotate(-90deg) translate(calc(calc( 180px - (calc(1 * 10px * 2) + calc( 1 * 6px * 2 )) ) / 2 - 10px / 2));
}
@keyframes rotate-ring-cap-2 {
  26% {
    transform: translate(-50%, -50%) rotate(calc(var(--angle) * (26 / 100) - 90deg)) translate(calc(calc( 180px - (calc(1 * 10px * 2) + calc( 1 * 6px * 2 )) ) / 2 - 10px / 2));
  }
}
.dial-ring:nth-child(3) .dial-ring-cap-end {
  left: 50%;
  top: 50%;
  transform-origin: center;
  z-index: 9999999999999;
  animation-name: rotate-ring-cap-2;
  animation-delay: 0.4s;
  animation-timing-function: ease-in-out;
  animation-duration: 0.6s;
  animation-fill-mode: forwards;
  transform: translate(-50%, -50%) rotate(-90deg) translate(calc(calc( 180px - (calc(1 * 10px * 2) + calc( 1 * 6px * 2 )) ) / 2 - 10px / 2));
}
@keyframes rotate-ring-cap-2 {
  27% {
    transform: translate(-50%, -50%) rotate(calc(var(--angle) * (27 / 100) - 90deg)) translate(calc(calc( 180px - (calc(1 * 10px * 2) + calc( 1 * 6px * 2 )) ) / 2 - 10px / 2));
  }
}
.dial-ring:nth-child(3) .dial-ring-cap-end {
  left: 50%;
  top: 50%;
  transform-origin: center;
  z-index: 9999999999999;
  animation-name: rotate-ring-cap-2;
  animation-delay: 0.4s;
  animation-timing-function: ease-in-out;
  animation-duration: 0.6s;
  animation-fill-mode: forwards;
  transform: translate(-50%, -50%) rotate(-90deg) translate(calc(calc( 180px - (calc(1 * 10px * 2) + calc( 1 * 6px * 2 )) ) / 2 - 10px / 2));
}
@keyframes rotate-ring-cap-2 {
  28% {
    transform: translate(-50%, -50%) rotate(calc(var(--angle) * (28 / 100) - 90deg)) translate(calc(calc( 180px - (calc(1 * 10px * 2) + calc( 1 * 6px * 2 )) ) / 2 - 10px / 2));
  }
}
.dial-ring:nth-child(3) .dial-ring-cap-end {
  left: 50%;
  top: 50%;
  transform-origin: center;
  z-index: 9999999999999;
  animation-name: rotate-ring-cap-2;
  animation-delay: 0.4s;
  animation-timing-function: ease-in-out;
  animation-duration: 0.6s;
  animation-fill-mode: forwards;
  transform: translate(-50%, -50%) rotate(-90deg) translate(calc(calc( 180px - (calc(1 * 10px * 2) + calc( 1 * 6px * 2 )) ) / 2 - 10px / 2));
}
@keyframes rotate-ring-cap-2 {
  29% {
    transform: translate(-50%, -50%) rotate(calc(var(--angle) * (29 / 100) - 90deg)) translate(calc(calc( 180px - (calc(1 * 10px * 2) + calc( 1 * 6px * 2 )) ) / 2 - 10px / 2));
  }
}
.dial-ring:nth-child(3) .dial-ring-cap-end {
  left: 50%;
  top: 50%;
  transform-origin: center;
  z-index: 9999999999999;
  animation-name: rotate-ring-cap-2;
  animation-delay: 0.4s;
  animation-timing-function: ease-in-out;
  animation-duration: 0.6s;
  animation-fill-mode: forwards;
  transform: translate(-50%, -50%) rotate(-90deg) translate(calc(calc( 180px - (calc(1 * 10px * 2) + calc( 1 * 6px * 2 )) ) / 2 - 10px / 2));
}
@keyframes rotate-ring-cap-2 {
  30% {
    transform: translate(-50%, -50%) rotate(calc(var(--angle) * (30 / 100) - 90deg)) translate(calc(calc( 180px - (calc(1 * 10px * 2) + calc( 1 * 6px * 2 )) ) / 2 - 10px / 2));
  }
}
.dial-ring:nth-child(3) .dial-ring-cap-end {
  left: 50%;
  top: 50%;
  transform-origin: center;
  z-index: 9999999999999;
  animation-name: rotate-ring-cap-2;
  animation-delay: 0.4s;
  animation-timing-function: ease-in-out;
  animation-duration: 0.6s;
  animation-fill-mode: forwards;
  transform: translate(-50%, -50%) rotate(-90deg) translate(calc(calc( 180px - (calc(1 * 10px * 2) + calc( 1 * 6px * 2 )) ) / 2 - 10px / 2));
}
@keyframes rotate-ring-cap-2 {
  31% {
    transform: translate(-50%, -50%) rotate(calc(var(--angle) * (31 / 100) - 90deg)) translate(calc(calc( 180px - (calc(1 * 10px * 2) + calc( 1 * 6px * 2 )) ) / 2 - 10px / 2));
  }
}
.dial-ring:nth-child(3) .dial-ring-cap-end {
  left: 50%;
  top: 50%;
  transform-origin: center;
  z-index: 9999999999999;
  animation-name: rotate-ring-cap-2;
  animation-delay: 0.4s;
  animation-timing-function: ease-in-out;
  animation-duration: 0.6s;
  animation-fill-mode: forwards;
  transform: translate(-50%, -50%) rotate(-90deg) translate(calc(calc( 180px - (calc(1 * 10px * 2) + calc( 1 * 6px * 2 )) ) / 2 - 10px / 2));
}
@keyframes rotate-ring-cap-2 {
  32% {
    transform: translate(-50%, -50%) rotate(calc(var(--angle) * (32 / 100) - 90deg)) translate(calc(calc( 180px - (calc(1 * 10px * 2) + calc( 1 * 6px * 2 )) ) / 2 - 10px / 2));
  }
}
.dial-ring:nth-child(3) .dial-ring-cap-end {
  left: 50%;
  top: 50%;
  transform-origin: center;
  z-index: 9999999999999;
  animation-name: rotate-ring-cap-2;
  animation-delay: 0.4s;
  animation-timing-function: ease-in-out;
  animation-duration: 0.6s;
  animation-fill-mode: forwards;
  transform: translate(-50%, -50%) rotate(-90deg) translate(calc(calc( 180px - (calc(1 * 10px * 2) + calc( 1 * 6px * 2 )) ) / 2 - 10px / 2));
}
@keyframes rotate-ring-cap-2 {
  33% {
    transform: translate(-50%, -50%) rotate(calc(var(--angle) * (33 / 100) - 90deg)) translate(calc(calc( 180px - (calc(1 * 10px * 2) + calc( 1 * 6px * 2 )) ) / 2 - 10px / 2));
  }
}
.dial-ring:nth-child(3) .dial-ring-cap-end {
  left: 50%;
  top: 50%;
  transform-origin: center;
  z-index: 9999999999999;
  animation-name: rotate-ring-cap-2;
  animation-delay: 0.4s;
  animation-timing-function: ease-in-out;
  animation-duration: 0.6s;
  animation-fill-mode: forwards;
  transform: translate(-50%, -50%) rotate(-90deg) translate(calc(calc( 180px - (calc(1 * 10px * 2) + calc( 1 * 6px * 2 )) ) / 2 - 10px / 2));
}
@keyframes rotate-ring-cap-2 {
  34% {
    transform: translate(-50%, -50%) rotate(calc(var(--angle) * (34 / 100) - 90deg)) translate(calc(calc( 180px - (calc(1 * 10px * 2) + calc( 1 * 6px * 2 )) ) / 2 - 10px / 2));
  }
}
.dial-ring:nth-child(3) .dial-ring-cap-end {
  left: 50%;
  top: 50%;
  transform-origin: center;
  z-index: 9999999999999;
  animation-name: rotate-ring-cap-2;
  animation-delay: 0.4s;
  animation-timing-function: ease-in-out;
  animation-duration: 0.6s;
  animation-fill-mode: forwards;
  transform: translate(-50%, -50%) rotate(-90deg) translate(calc(calc( 180px - (calc(1 * 10px * 2) + calc( 1 * 6px * 2 )) ) / 2 - 10px / 2));
}
@keyframes rotate-ring-cap-2 {
  35% {
    transform: translate(-50%, -50%) rotate(calc(var(--angle) * (35 / 100) - 90deg)) translate(calc(calc( 180px - (calc(1 * 10px * 2) + calc( 1 * 6px * 2 )) ) / 2 - 10px / 2));
  }
}
.dial-ring:nth-child(3) .dial-ring-cap-end {
  left: 50%;
  top: 50%;
  transform-origin: center;
  z-index: 9999999999999;
  animation-name: rotate-ring-cap-2;
  animation-delay: 0.4s;
  animation-timing-function: ease-in-out;
  animation-duration: 0.6s;
  animation-fill-mode: forwards;
  transform: translate(-50%, -50%) rotate(-90deg) translate(calc(calc( 180px - (calc(1 * 10px * 2) + calc( 1 * 6px * 2 )) ) / 2 - 10px / 2));
}
@keyframes rotate-ring-cap-2 {
  36% {
    transform: translate(-50%, -50%) rotate(calc(var(--angle) * (36 / 100) - 90deg)) translate(calc(calc( 180px - (calc(1 * 10px * 2) + calc( 1 * 6px * 2 )) ) / 2 - 10px / 2));
  }
}
.dial-ring:nth-child(3) .dial-ring-cap-end {
  left: 50%;
  top: 50%;
  transform-origin: center;
  z-index: 9999999999999;
  animation-name: rotate-ring-cap-2;
  animation-delay: 0.4s;
  animation-timing-function: ease-in-out;
  animation-duration: 0.6s;
  animation-fill-mode: forwards;
  transform: translate(-50%, -50%) rotate(-90deg) translate(calc(calc( 180px - (calc(1 * 10px * 2) + calc( 1 * 6px * 2 )) ) / 2 - 10px / 2));
}
@keyframes rotate-ring-cap-2 {
  37% {
    transform: translate(-50%, -50%) rotate(calc(var(--angle) * (37 / 100) - 90deg)) translate(calc(calc( 180px - (calc(1 * 10px * 2) + calc( 1 * 6px * 2 )) ) / 2 - 10px / 2));
  }
}
.dial-ring:nth-child(3) .dial-ring-cap-end {
  left: 50%;
  top: 50%;
  transform-origin: center;
  z-index: 9999999999999;
  animation-name: rotate-ring-cap-2;
  animation-delay: 0.4s;
  animation-timing-function: ease-in-out;
  animation-duration: 0.6s;
  animation-fill-mode: forwards;
  transform: translate(-50%, -50%) rotate(-90deg) translate(calc(calc( 180px - (calc(1 * 10px * 2) + calc( 1 * 6px * 2 )) ) / 2 - 10px / 2));
}
@keyframes rotate-ring-cap-2 {
  38% {
    transform: translate(-50%, -50%) rotate(calc(var(--angle) * (38 / 100) - 90deg)) translate(calc(calc( 180px - (calc(1 * 10px * 2) + calc( 1 * 6px * 2 )) ) / 2 - 10px / 2));
  }
}
.dial-ring:nth-child(3) .dial-ring-cap-end {
  left: 50%;
  top: 50%;
  transform-origin: center;
  z-index: 9999999999999;
  animation-name: rotate-ring-cap-2;
  animation-delay: 0.4s;
  animation-timing-function: ease-in-out;
  animation-duration: 0.6s;
  animation-fill-mode: forwards;
  transform: translate(-50%, -50%) rotate(-90deg) translate(calc(calc( 180px - (calc(1 * 10px * 2) + calc( 1 * 6px * 2 )) ) / 2 - 10px / 2));
}
@keyframes rotate-ring-cap-2 {
  39% {
    transform: translate(-50%, -50%) rotate(calc(var(--angle) * (39 / 100) - 90deg)) translate(calc(calc( 180px - (calc(1 * 10px * 2) + calc( 1 * 6px * 2 )) ) / 2 - 10px / 2));
  }
}
.dial-ring:nth-child(3) .dial-ring-cap-end {
  left: 50%;
  top: 50%;
  transform-origin: center;
  z-index: 9999999999999;
  animation-name: rotate-ring-cap-2;
  animation-delay: 0.4s;
  animation-timing-function: ease-in-out;
  animation-duration: 0.6s;
  animation-fill-mode: forwards;
  transform: translate(-50%, -50%) rotate(-90deg) translate(calc(calc( 180px - (calc(1 * 10px * 2) + calc( 1 * 6px * 2 )) ) / 2 - 10px / 2));
}
@keyframes rotate-ring-cap-2 {
  40% {
    transform: translate(-50%, -50%) rotate(calc(var(--angle) * (40 / 100) - 90deg)) translate(calc(calc( 180px - (calc(1 * 10px * 2) + calc( 1 * 6px * 2 )) ) / 2 - 10px / 2));
  }
}
.dial-ring:nth-child(3) .dial-ring-cap-end {
  left: 50%;
  top: 50%;
  transform-origin: center;
  z-index: 9999999999999;
  animation-name: rotate-ring-cap-2;
  animation-delay: 0.4s;
  animation-timing-function: ease-in-out;
  animation-duration: 0.6s;
  animation-fill-mode: forwards;
  transform: translate(-50%, -50%) rotate(-90deg) translate(calc(calc( 180px - (calc(1 * 10px * 2) + calc( 1 * 6px * 2 )) ) / 2 - 10px / 2));
}
@keyframes rotate-ring-cap-2 {
  41% {
    transform: translate(-50%, -50%) rotate(calc(var(--angle) * (41 / 100) - 90deg)) translate(calc(calc( 180px - (calc(1 * 10px * 2) + calc( 1 * 6px * 2 )) ) / 2 - 10px / 2));
  }
}
.dial-ring:nth-child(3) .dial-ring-cap-end {
  left: 50%;
  top: 50%;
  transform-origin: center;
  z-index: 9999999999999;
  animation-name: rotate-ring-cap-2;
  animation-delay: 0.4s;
  animation-timing-function: ease-in-out;
  animation-duration: 0.6s;
  animation-fill-mode: forwards;
  transform: translate(-50%, -50%) rotate(-90deg) translate(calc(calc( 180px - (calc(1 * 10px * 2) + calc( 1 * 6px * 2 )) ) / 2 - 10px / 2));
}
@keyframes rotate-ring-cap-2 {
  42% {
    transform: translate(-50%, -50%) rotate(calc(var(--angle) * (42 / 100) - 90deg)) translate(calc(calc( 180px - (calc(1 * 10px * 2) + calc( 1 * 6px * 2 )) ) / 2 - 10px / 2));
  }
}
.dial-ring:nth-child(3) .dial-ring-cap-end {
  left: 50%;
  top: 50%;
  transform-origin: center;
  z-index: 9999999999999;
  animation-name: rotate-ring-cap-2;
  animation-delay: 0.4s;
  animation-timing-function: ease-in-out;
  animation-duration: 0.6s;
  animation-fill-mode: forwards;
  transform: translate(-50%, -50%) rotate(-90deg) translate(calc(calc( 180px - (calc(1 * 10px * 2) + calc( 1 * 6px * 2 )) ) / 2 - 10px / 2));
}
@keyframes rotate-ring-cap-2 {
  43% {
    transform: translate(-50%, -50%) rotate(calc(var(--angle) * (43 / 100) - 90deg)) translate(calc(calc( 180px - (calc(1 * 10px * 2) + calc( 1 * 6px * 2 )) ) / 2 - 10px / 2));
  }
}
.dial-ring:nth-child(3) .dial-ring-cap-end {
  left: 50%;
  top: 50%;
  transform-origin: center;
  z-index: 9999999999999;
  animation-name: rotate-ring-cap-2;
  animation-delay: 0.4s;
  animation-timing-function: ease-in-out;
  animation-duration: 0.6s;
  animation-fill-mode: forwards;
  transform: translate(-50%, -50%) rotate(-90deg) translate(calc(calc( 180px - (calc(1 * 10px * 2) + calc( 1 * 6px * 2 )) ) / 2 - 10px / 2));
}
@keyframes rotate-ring-cap-2 {
  44% {
    transform: translate(-50%, -50%) rotate(calc(var(--angle) * (44 / 100) - 90deg)) translate(calc(calc( 180px - (calc(1 * 10px * 2) + calc( 1 * 6px * 2 )) ) / 2 - 10px / 2));
  }
}
.dial-ring:nth-child(3) .dial-ring-cap-end {
  left: 50%;
  top: 50%;
  transform-origin: center;
  z-index: 9999999999999;
  animation-name: rotate-ring-cap-2;
  animation-delay: 0.4s;
  animation-timing-function: ease-in-out;
  animation-duration: 0.6s;
  animation-fill-mode: forwards;
  transform: translate(-50%, -50%) rotate(-90deg) translate(calc(calc( 180px - (calc(1 * 10px * 2) + calc( 1 * 6px * 2 )) ) / 2 - 10px / 2));
}
@keyframes rotate-ring-cap-2 {
  45% {
    transform: translate(-50%, -50%) rotate(calc(var(--angle) * (45 / 100) - 90deg)) translate(calc(calc( 180px - (calc(1 * 10px * 2) + calc( 1 * 6px * 2 )) ) / 2 - 10px / 2));
  }
}
.dial-ring:nth-child(3) .dial-ring-cap-end {
  left: 50%;
  top: 50%;
  transform-origin: center;
  z-index: 9999999999999;
  animation-name: rotate-ring-cap-2;
  animation-delay: 0.4s;
  animation-timing-function: ease-in-out;
  animation-duration: 0.6s;
  animation-fill-mode: forwards;
  transform: translate(-50%, -50%) rotate(-90deg) translate(calc(calc( 180px - (calc(1 * 10px * 2) + calc( 1 * 6px * 2 )) ) / 2 - 10px / 2));
}
@keyframes rotate-ring-cap-2 {
  46% {
    transform: translate(-50%, -50%) rotate(calc(var(--angle) * (46 / 100) - 90deg)) translate(calc(calc( 180px - (calc(1 * 10px * 2) + calc( 1 * 6px * 2 )) ) / 2 - 10px / 2));
  }
}
.dial-ring:nth-child(3) .dial-ring-cap-end {
  left: 50%;
  top: 50%;
  transform-origin: center;
  z-index: 9999999999999;
  animation-name: rotate-ring-cap-2;
  animation-delay: 0.4s;
  animation-timing-function: ease-in-out;
  animation-duration: 0.6s;
  animation-fill-mode: forwards;
  transform: translate(-50%, -50%) rotate(-90deg) translate(calc(calc( 180px - (calc(1 * 10px * 2) + calc( 1 * 6px * 2 )) ) / 2 - 10px / 2));
}
@keyframes rotate-ring-cap-2 {
  47% {
    transform: translate(-50%, -50%) rotate(calc(var(--angle) * (47 / 100) - 90deg)) translate(calc(calc( 180px - (calc(1 * 10px * 2) + calc( 1 * 6px * 2 )) ) / 2 - 10px / 2));
  }
}
.dial-ring:nth-child(3) .dial-ring-cap-end {
  left: 50%;
  top: 50%;
  transform-origin: center;
  z-index: 9999999999999;
  animation-name: rotate-ring-cap-2;
  animation-delay: 0.4s;
  animation-timing-function: ease-in-out;
  animation-duration: 0.6s;
  animation-fill-mode: forwards;
  transform: translate(-50%, -50%) rotate(-90deg) translate(calc(calc( 180px - (calc(1 * 10px * 2) + calc( 1 * 6px * 2 )) ) / 2 - 10px / 2));
}
@keyframes rotate-ring-cap-2 {
  48% {
    transform: translate(-50%, -50%) rotate(calc(var(--angle) * (48 / 100) - 90deg)) translate(calc(calc( 180px - (calc(1 * 10px * 2) + calc( 1 * 6px * 2 )) ) / 2 - 10px / 2));
  }
}
.dial-ring:nth-child(3) .dial-ring-cap-end {
  left: 50%;
  top: 50%;
  transform-origin: center;
  z-index: 9999999999999;
  animation-name: rotate-ring-cap-2;
  animation-delay: 0.4s;
  animation-timing-function: ease-in-out;
  animation-duration: 0.6s;
  animation-fill-mode: forwards;
  transform: translate(-50%, -50%) rotate(-90deg) translate(calc(calc( 180px - (calc(1 * 10px * 2) + calc( 1 * 6px * 2 )) ) / 2 - 10px / 2));
}
@keyframes rotate-ring-cap-2 {
  49% {
    transform: translate(-50%, -50%) rotate(calc(var(--angle) * (49 / 100) - 90deg)) translate(calc(calc( 180px - (calc(1 * 10px * 2) + calc( 1 * 6px * 2 )) ) / 2 - 10px / 2));
  }
}
.dial-ring:nth-child(3) .dial-ring-cap-end {
  left: 50%;
  top: 50%;
  transform-origin: center;
  z-index: 9999999999999;
  animation-name: rotate-ring-cap-2;
  animation-delay: 0.4s;
  animation-timing-function: ease-in-out;
  animation-duration: 0.6s;
  animation-fill-mode: forwards;
  transform: translate(-50%, -50%) rotate(-90deg) translate(calc(calc( 180px - (calc(1 * 10px * 2) + calc( 1 * 6px * 2 )) ) / 2 - 10px / 2));
}
@keyframes rotate-ring-cap-2 {
  50% {
    transform: translate(-50%, -50%) rotate(calc(var(--angle) * (50 / 100) - 90deg)) translate(calc(calc( 180px - (calc(1 * 10px * 2) + calc( 1 * 6px * 2 )) ) / 2 - 10px / 2));
  }
}
.dial-ring:nth-child(3) .dial-ring-cap-end {
  left: 50%;
  top: 50%;
  transform-origin: center;
  z-index: 9999999999999;
  animation-name: rotate-ring-cap-2;
  animation-delay: 0.4s;
  animation-timing-function: ease-in-out;
  animation-duration: 0.6s;
  animation-fill-mode: forwards;
  transform: translate(-50%, -50%) rotate(-90deg) translate(calc(calc( 180px - (calc(1 * 10px * 2) + calc( 1 * 6px * 2 )) ) / 2 - 10px / 2));
}
@keyframes rotate-ring-cap-2 {
  51% {
    transform: translate(-50%, -50%) rotate(calc(var(--angle) * (51 / 100) - 90deg)) translate(calc(calc( 180px - (calc(1 * 10px * 2) + calc( 1 * 6px * 2 )) ) / 2 - 10px / 2));
  }
}
.dial-ring:nth-child(3) .dial-ring-cap-end {
  left: 50%;
  top: 50%;
  transform-origin: center;
  z-index: 9999999999999;
  animation-name: rotate-ring-cap-2;
  animation-delay: 0.4s;
  animation-timing-function: ease-in-out;
  animation-duration: 0.6s;
  animation-fill-mode: forwards;
  transform: translate(-50%, -50%) rotate(-90deg) translate(calc(calc( 180px - (calc(1 * 10px * 2) + calc( 1 * 6px * 2 )) ) / 2 - 10px / 2));
}
@keyframes rotate-ring-cap-2 {
  52% {
    transform: translate(-50%, -50%) rotate(calc(var(--angle) * (52 / 100) - 90deg)) translate(calc(calc( 180px - (calc(1 * 10px * 2) + calc( 1 * 6px * 2 )) ) / 2 - 10px / 2));
  }
}
.dial-ring:nth-child(3) .dial-ring-cap-end {
  left: 50%;
  top: 50%;
  transform-origin: center;
  z-index: 9999999999999;
  animation-name: rotate-ring-cap-2;
  animation-delay: 0.4s;
  animation-timing-function: ease-in-out;
  animation-duration: 0.6s;
  animation-fill-mode: forwards;
  transform: translate(-50%, -50%) rotate(-90deg) translate(calc(calc( 180px - (calc(1 * 10px * 2) + calc( 1 * 6px * 2 )) ) / 2 - 10px / 2));
}
@keyframes rotate-ring-cap-2 {
  53% {
    transform: translate(-50%, -50%) rotate(calc(var(--angle) * (53 / 100) - 90deg)) translate(calc(calc( 180px - (calc(1 * 10px * 2) + calc( 1 * 6px * 2 )) ) / 2 - 10px / 2));
  }
}
.dial-ring:nth-child(3) .dial-ring-cap-end {
  left: 50%;
  top: 50%;
  transform-origin: center;
  z-index: 9999999999999;
  animation-name: rotate-ring-cap-2;
  animation-delay: 0.4s;
  animation-timing-function: ease-in-out;
  animation-duration: 0.6s;
  animation-fill-mode: forwards;
  transform: translate(-50%, -50%) rotate(-90deg) translate(calc(calc( 180px - (calc(1 * 10px * 2) + calc( 1 * 6px * 2 )) ) / 2 - 10px / 2));
}
@keyframes rotate-ring-cap-2 {
  54% {
    transform: translate(-50%, -50%) rotate(calc(var(--angle) * (54 / 100) - 90deg)) translate(calc(calc( 180px - (calc(1 * 10px * 2) + calc( 1 * 6px * 2 )) ) / 2 - 10px / 2));
  }
}
.dial-ring:nth-child(3) .dial-ring-cap-end {
  left: 50%;
  top: 50%;
  transform-origin: center;
  z-index: 9999999999999;
  animation-name: rotate-ring-cap-2;
  animation-delay: 0.4s;
  animation-timing-function: ease-in-out;
  animation-duration: 0.6s;
  animation-fill-mode: forwards;
  transform: translate(-50%, -50%) rotate(-90deg) translate(calc(calc( 180px - (calc(1 * 10px * 2) + calc( 1 * 6px * 2 )) ) / 2 - 10px / 2));
}
@keyframes rotate-ring-cap-2 {
  55% {
    transform: translate(-50%, -50%) rotate(calc(var(--angle) * (55 / 100) - 90deg)) translate(calc(calc( 180px - (calc(1 * 10px * 2) + calc( 1 * 6px * 2 )) ) / 2 - 10px / 2));
  }
}
.dial-ring:nth-child(3) .dial-ring-cap-end {
  left: 50%;
  top: 50%;
  transform-origin: center;
  z-index: 9999999999999;
  animation-name: rotate-ring-cap-2;
  animation-delay: 0.4s;
  animation-timing-function: ease-in-out;
  animation-duration: 0.6s;
  animation-fill-mode: forwards;
  transform: translate(-50%, -50%) rotate(-90deg) translate(calc(calc( 180px - (calc(1 * 10px * 2) + calc( 1 * 6px * 2 )) ) / 2 - 10px / 2));
}
@keyframes rotate-ring-cap-2 {
  56% {
    transform: translate(-50%, -50%) rotate(calc(var(--angle) * (56 / 100) - 90deg)) translate(calc(calc( 180px - (calc(1 * 10px * 2) + calc( 1 * 6px * 2 )) ) / 2 - 10px / 2));
  }
}
.dial-ring:nth-child(3) .dial-ring-cap-end {
  left: 50%;
  top: 50%;
  transform-origin: center;
  z-index: 9999999999999;
  animation-name: rotate-ring-cap-2;
  animation-delay: 0.4s;
  animation-timing-function: ease-in-out;
  animation-duration: 0.6s;
  animation-fill-mode: forwards;
  transform: translate(-50%, -50%) rotate(-90deg) translate(calc(calc( 180px - (calc(1 * 10px * 2) + calc( 1 * 6px * 2 )) ) / 2 - 10px / 2));
}
@keyframes rotate-ring-cap-2 {
  57% {
    transform: translate(-50%, -50%) rotate(calc(var(--angle) * (57 / 100) - 90deg)) translate(calc(calc( 180px - (calc(1 * 10px * 2) + calc( 1 * 6px * 2 )) ) / 2 - 10px / 2));
  }
}
.dial-ring:nth-child(3) .dial-ring-cap-end {
  left: 50%;
  top: 50%;
  transform-origin: center;
  z-index: 9999999999999;
  animation-name: rotate-ring-cap-2;
  animation-delay: 0.4s;
  animation-timing-function: ease-in-out;
  animation-duration: 0.6s;
  animation-fill-mode: forwards;
  transform: translate(-50%, -50%) rotate(-90deg) translate(calc(calc( 180px - (calc(1 * 10px * 2) + calc( 1 * 6px * 2 )) ) / 2 - 10px / 2));
}
@keyframes rotate-ring-cap-2 {
  58% {
    transform: translate(-50%, -50%) rotate(calc(var(--angle) * (58 / 100) - 90deg)) translate(calc(calc( 180px - (calc(1 * 10px * 2) + calc( 1 * 6px * 2 )) ) / 2 - 10px / 2));
  }
}
.dial-ring:nth-child(3) .dial-ring-cap-end {
  left: 50%;
  top: 50%;
  transform-origin: center;
  z-index: 9999999999999;
  animation-name: rotate-ring-cap-2;
  animation-delay: 0.4s;
  animation-timing-function: ease-in-out;
  animation-duration: 0.6s;
  animation-fill-mode: forwards;
  transform: translate(-50%, -50%) rotate(-90deg) translate(calc(calc( 180px - (calc(1 * 10px * 2) + calc( 1 * 6px * 2 )) ) / 2 - 10px / 2));
}
@keyframes rotate-ring-cap-2 {
  59% {
    transform: translate(-50%, -50%) rotate(calc(var(--angle) * (59 / 100) - 90deg)) translate(calc(calc( 180px - (calc(1 * 10px * 2) + calc( 1 * 6px * 2 )) ) / 2 - 10px / 2));
  }
}
.dial-ring:nth-child(3) .dial-ring-cap-end {
  left: 50%;
  top: 50%;
  transform-origin: center;
  z-index: 9999999999999;
  animation-name: rotate-ring-cap-2;
  animation-delay: 0.4s;
  animation-timing-function: ease-in-out;
  animation-duration: 0.6s;
  animation-fill-mode: forwards;
  transform: translate(-50%, -50%) rotate(-90deg) translate(calc(calc( 180px - (calc(1 * 10px * 2) + calc( 1 * 6px * 2 )) ) / 2 - 10px / 2));
}
@keyframes rotate-ring-cap-2 {
  60% {
    transform: translate(-50%, -50%) rotate(calc(var(--angle) * (60 / 100) - 90deg)) translate(calc(calc( 180px - (calc(1 * 10px * 2) + calc( 1 * 6px * 2 )) ) / 2 - 10px / 2));
  }
}
.dial-ring:nth-child(3) .dial-ring-cap-end {
  left: 50%;
  top: 50%;
  transform-origin: center;
  z-index: 9999999999999;
  animation-name: rotate-ring-cap-2;
  animation-delay: 0.4s;
  animation-timing-function: ease-in-out;
  animation-duration: 0.6s;
  animation-fill-mode: forwards;
  transform: translate(-50%, -50%) rotate(-90deg) translate(calc(calc( 180px - (calc(1 * 10px * 2) + calc( 1 * 6px * 2 )) ) / 2 - 10px / 2));
}
@keyframes rotate-ring-cap-2 {
  61% {
    transform: translate(-50%, -50%) rotate(calc(var(--angle) * (61 / 100) - 90deg)) translate(calc(calc( 180px - (calc(1 * 10px * 2) + calc( 1 * 6px * 2 )) ) / 2 - 10px / 2));
  }
}
.dial-ring:nth-child(3) .dial-ring-cap-end {
  left: 50%;
  top: 50%;
  transform-origin: center;
  z-index: 9999999999999;
  animation-name: rotate-ring-cap-2;
  animation-delay: 0.4s;
  animation-timing-function: ease-in-out;
  animation-duration: 0.6s;
  animation-fill-mode: forwards;
  transform: translate(-50%, -50%) rotate(-90deg) translate(calc(calc( 180px - (calc(1 * 10px * 2) + calc( 1 * 6px * 2 )) ) / 2 - 10px / 2));
}
@keyframes rotate-ring-cap-2 {
  62% {
    transform: translate(-50%, -50%) rotate(calc(var(--angle) * (62 / 100) - 90deg)) translate(calc(calc( 180px - (calc(1 * 10px * 2) + calc( 1 * 6px * 2 )) ) / 2 - 10px / 2));
  }
}
.dial-ring:nth-child(3) .dial-ring-cap-end {
  left: 50%;
  top: 50%;
  transform-origin: center;
  z-index: 9999999999999;
  animation-name: rotate-ring-cap-2;
  animation-delay: 0.4s;
  animation-timing-function: ease-in-out;
  animation-duration: 0.6s;
  animation-fill-mode: forwards;
  transform: translate(-50%, -50%) rotate(-90deg) translate(calc(calc( 180px - (calc(1 * 10px * 2) + calc( 1 * 6px * 2 )) ) / 2 - 10px / 2));
}
@keyframes rotate-ring-cap-2 {
  63% {
    transform: translate(-50%, -50%) rotate(calc(var(--angle) * (63 / 100) - 90deg)) translate(calc(calc( 180px - (calc(1 * 10px * 2) + calc( 1 * 6px * 2 )) ) / 2 - 10px / 2));
  }
}
.dial-ring:nth-child(3) .dial-ring-cap-end {
  left: 50%;
  top: 50%;
  transform-origin: center;
  z-index: 9999999999999;
  animation-name: rotate-ring-cap-2;
  animation-delay: 0.4s;
  animation-timing-function: ease-in-out;
  animation-duration: 0.6s;
  animation-fill-mode: forwards;
  transform: translate(-50%, -50%) rotate(-90deg) translate(calc(calc( 180px - (calc(1 * 10px * 2) + calc( 1 * 6px * 2 )) ) / 2 - 10px / 2));
}
@keyframes rotate-ring-cap-2 {
  64% {
    transform: translate(-50%, -50%) rotate(calc(var(--angle) * (64 / 100) - 90deg)) translate(calc(calc( 180px - (calc(1 * 10px * 2) + calc( 1 * 6px * 2 )) ) / 2 - 10px / 2));
  }
}
.dial-ring:nth-child(3) .dial-ring-cap-end {
  left: 50%;
  top: 50%;
  transform-origin: center;
  z-index: 9999999999999;
  animation-name: rotate-ring-cap-2;
  animation-delay: 0.4s;
  animation-timing-function: ease-in-out;
  animation-duration: 0.6s;
  animation-fill-mode: forwards;
  transform: translate(-50%, -50%) rotate(-90deg) translate(calc(calc( 180px - (calc(1 * 10px * 2) + calc( 1 * 6px * 2 )) ) / 2 - 10px / 2));
}
@keyframes rotate-ring-cap-2 {
  65% {
    transform: translate(-50%, -50%) rotate(calc(var(--angle) * (65 / 100) - 90deg)) translate(calc(calc( 180px - (calc(1 * 10px * 2) + calc( 1 * 6px * 2 )) ) / 2 - 10px / 2));
  }
}
.dial-ring:nth-child(3) .dial-ring-cap-end {
  left: 50%;
  top: 50%;
  transform-origin: center;
  z-index: 9999999999999;
  animation-name: rotate-ring-cap-2;
  animation-delay: 0.4s;
  animation-timing-function: ease-in-out;
  animation-duration: 0.6s;
  animation-fill-mode: forwards;
  transform: translate(-50%, -50%) rotate(-90deg) translate(calc(calc( 180px - (calc(1 * 10px * 2) + calc( 1 * 6px * 2 )) ) / 2 - 10px / 2));
}
@keyframes rotate-ring-cap-2 {
  66% {
    transform: translate(-50%, -50%) rotate(calc(var(--angle) * (66 / 100) - 90deg)) translate(calc(calc( 180px - (calc(1 * 10px * 2) + calc( 1 * 6px * 2 )) ) / 2 - 10px / 2));
  }
}
.dial-ring:nth-child(3) .dial-ring-cap-end {
  left: 50%;
  top: 50%;
  transform-origin: center;
  z-index: 9999999999999;
  animation-name: rotate-ring-cap-2;
  animation-delay: 0.4s;
  animation-timing-function: ease-in-out;
  animation-duration: 0.6s;
  animation-fill-mode: forwards;
  transform: translate(-50%, -50%) rotate(-90deg) translate(calc(calc( 180px - (calc(1 * 10px * 2) + calc( 1 * 6px * 2 )) ) / 2 - 10px / 2));
}
@keyframes rotate-ring-cap-2 {
  67% {
    transform: translate(-50%, -50%) rotate(calc(var(--angle) * (67 / 100) - 90deg)) translate(calc(calc( 180px - (calc(1 * 10px * 2) + calc( 1 * 6px * 2 )) ) / 2 - 10px / 2));
  }
}
.dial-ring:nth-child(3) .dial-ring-cap-end {
  left: 50%;
  top: 50%;
  transform-origin: center;
  z-index: 9999999999999;
  animation-name: rotate-ring-cap-2;
  animation-delay: 0.4s;
  animation-timing-function: ease-in-out;
  animation-duration: 0.6s;
  animation-fill-mode: forwards;
  transform: translate(-50%, -50%) rotate(-90deg) translate(calc(calc( 180px - (calc(1 * 10px * 2) + calc( 1 * 6px * 2 )) ) / 2 - 10px / 2));
}
@keyframes rotate-ring-cap-2 {
  68% {
    transform: translate(-50%, -50%) rotate(calc(var(--angle) * (68 / 100) - 90deg)) translate(calc(calc( 180px - (calc(1 * 10px * 2) + calc( 1 * 6px * 2 )) ) / 2 - 10px / 2));
  }
}
.dial-ring:nth-child(3) .dial-ring-cap-end {
  left: 50%;
  top: 50%;
  transform-origin: center;
  z-index: 9999999999999;
  animation-name: rotate-ring-cap-2;
  animation-delay: 0.4s;
  animation-timing-function: ease-in-out;
  animation-duration: 0.6s;
  animation-fill-mode: forwards;
  transform: translate(-50%, -50%) rotate(-90deg) translate(calc(calc( 180px - (calc(1 * 10px * 2) + calc( 1 * 6px * 2 )) ) / 2 - 10px / 2));
}
@keyframes rotate-ring-cap-2 {
  69% {
    transform: translate(-50%, -50%) rotate(calc(var(--angle) * (69 / 100) - 90deg)) translate(calc(calc( 180px - (calc(1 * 10px * 2) + calc( 1 * 6px * 2 )) ) / 2 - 10px / 2));
  }
}
.dial-ring:nth-child(3) .dial-ring-cap-end {
  left: 50%;
  top: 50%;
  transform-origin: center;
  z-index: 9999999999999;
  animation-name: rotate-ring-cap-2;
  animation-delay: 0.4s;
  animation-timing-function: ease-in-out;
  animation-duration: 0.6s;
  animation-fill-mode: forwards;
  transform: translate(-50%, -50%) rotate(-90deg) translate(calc(calc( 180px - (calc(1 * 10px * 2) + calc( 1 * 6px * 2 )) ) / 2 - 10px / 2));
}
@keyframes rotate-ring-cap-2 {
  70% {
    transform: translate(-50%, -50%) rotate(calc(var(--angle) * (70 / 100) - 90deg)) translate(calc(calc( 180px - (calc(1 * 10px * 2) + calc( 1 * 6px * 2 )) ) / 2 - 10px / 2));
  }
}
.dial-ring:nth-child(3) .dial-ring-cap-end {
  left: 50%;
  top: 50%;
  transform-origin: center;
  z-index: 9999999999999;
  animation-name: rotate-ring-cap-2;
  animation-delay: 0.4s;
  animation-timing-function: ease-in-out;
  animation-duration: 0.6s;
  animation-fill-mode: forwards;
  transform: translate(-50%, -50%) rotate(-90deg) translate(calc(calc( 180px - (calc(1 * 10px * 2) + calc( 1 * 6px * 2 )) ) / 2 - 10px / 2));
}
@keyframes rotate-ring-cap-2 {
  71% {
    transform: translate(-50%, -50%) rotate(calc(var(--angle) * (71 / 100) - 90deg)) translate(calc(calc( 180px - (calc(1 * 10px * 2) + calc( 1 * 6px * 2 )) ) / 2 - 10px / 2));
  }
}
.dial-ring:nth-child(3) .dial-ring-cap-end {
  left: 50%;
  top: 50%;
  transform-origin: center;
  z-index: 9999999999999;
  animation-name: rotate-ring-cap-2;
  animation-delay: 0.4s;
  animation-timing-function: ease-in-out;
  animation-duration: 0.6s;
  animation-fill-mode: forwards;
  transform: translate(-50%, -50%) rotate(-90deg) translate(calc(calc( 180px - (calc(1 * 10px * 2) + calc( 1 * 6px * 2 )) ) / 2 - 10px / 2));
}
@keyframes rotate-ring-cap-2 {
  72% {
    transform: translate(-50%, -50%) rotate(calc(var(--angle) * (72 / 100) - 90deg)) translate(calc(calc( 180px - (calc(1 * 10px * 2) + calc( 1 * 6px * 2 )) ) / 2 - 10px / 2));
  }
}
.dial-ring:nth-child(3) .dial-ring-cap-end {
  left: 50%;
  top: 50%;
  transform-origin: center;
  z-index: 9999999999999;
  animation-name: rotate-ring-cap-2;
  animation-delay: 0.4s;
  animation-timing-function: ease-in-out;
  animation-duration: 0.6s;
  animation-fill-mode: forwards;
  transform: translate(-50%, -50%) rotate(-90deg) translate(calc(calc( 180px - (calc(1 * 10px * 2) + calc( 1 * 6px * 2 )) ) / 2 - 10px / 2));
}
@keyframes rotate-ring-cap-2 {
  73% {
    transform: translate(-50%, -50%) rotate(calc(var(--angle) * (73 / 100) - 90deg)) translate(calc(calc( 180px - (calc(1 * 10px * 2) + calc( 1 * 6px * 2 )) ) / 2 - 10px / 2));
  }
}
.dial-ring:nth-child(3) .dial-ring-cap-end {
  left: 50%;
  top: 50%;
  transform-origin: center;
  z-index: 9999999999999;
  animation-name: rotate-ring-cap-2;
  animation-delay: 0.4s;
  animation-timing-function: ease-in-out;
  animation-duration: 0.6s;
  animation-fill-mode: forwards;
  transform: translate(-50%, -50%) rotate(-90deg) translate(calc(calc( 180px - (calc(1 * 10px * 2) + calc( 1 * 6px * 2 )) ) / 2 - 10px / 2));
}
@keyframes rotate-ring-cap-2 {
  74% {
    transform: translate(-50%, -50%) rotate(calc(var(--angle) * (74 / 100) - 90deg)) translate(calc(calc( 180px - (calc(1 * 10px * 2) + calc( 1 * 6px * 2 )) ) / 2 - 10px / 2));
  }
}
.dial-ring:nth-child(3) .dial-ring-cap-end {
  left: 50%;
  top: 50%;
  transform-origin: center;
  z-index: 9999999999999;
  animation-name: rotate-ring-cap-2;
  animation-delay: 0.4s;
  animation-timing-function: ease-in-out;
  animation-duration: 0.6s;
  animation-fill-mode: forwards;
  transform: translate(-50%, -50%) rotate(-90deg) translate(calc(calc( 180px - (calc(1 * 10px * 2) + calc( 1 * 6px * 2 )) ) / 2 - 10px / 2));
}
@keyframes rotate-ring-cap-2 {
  75% {
    transform: translate(-50%, -50%) rotate(calc(var(--angle) * (75 / 100) - 90deg)) translate(calc(calc( 180px - (calc(1 * 10px * 2) + calc( 1 * 6px * 2 )) ) / 2 - 10px / 2));
  }
}
.dial-ring:nth-child(3) .dial-ring-cap-end {
  left: 50%;
  top: 50%;
  transform-origin: center;
  z-index: 9999999999999;
  animation-name: rotate-ring-cap-2;
  animation-delay: 0.4s;
  animation-timing-function: ease-in-out;
  animation-duration: 0.6s;
  animation-fill-mode: forwards;
  transform: translate(-50%, -50%) rotate(-90deg) translate(calc(calc( 180px - (calc(1 * 10px * 2) + calc( 1 * 6px * 2 )) ) / 2 - 10px / 2));
}
@keyframes rotate-ring-cap-2 {
  76% {
    transform: translate(-50%, -50%) rotate(calc(var(--angle) * (76 / 100) - 90deg)) translate(calc(calc( 180px - (calc(1 * 10px * 2) + calc( 1 * 6px * 2 )) ) / 2 - 10px / 2));
  }
}
.dial-ring:nth-child(3) .dial-ring-cap-end {
  left: 50%;
  top: 50%;
  transform-origin: center;
  z-index: 9999999999999;
  animation-name: rotate-ring-cap-2;
  animation-delay: 0.4s;
  animation-timing-function: ease-in-out;
  animation-duration: 0.6s;
  animation-fill-mode: forwards;
  transform: translate(-50%, -50%) rotate(-90deg) translate(calc(calc( 180px - (calc(1 * 10px * 2) + calc( 1 * 6px * 2 )) ) / 2 - 10px / 2));
}
@keyframes rotate-ring-cap-2 {
  77% {
    transform: translate(-50%, -50%) rotate(calc(var(--angle) * (77 / 100) - 90deg)) translate(calc(calc( 180px - (calc(1 * 10px * 2) + calc( 1 * 6px * 2 )) ) / 2 - 10px / 2));
  }
}
.dial-ring:nth-child(3) .dial-ring-cap-end {
  left: 50%;
  top: 50%;
  transform-origin: center;
  z-index: 9999999999999;
  animation-name: rotate-ring-cap-2;
  animation-delay: 0.4s;
  animation-timing-function: ease-in-out;
  animation-duration: 0.6s;
  animation-fill-mode: forwards;
  transform: translate(-50%, -50%) rotate(-90deg) translate(calc(calc( 180px - (calc(1 * 10px * 2) + calc( 1 * 6px * 2 )) ) / 2 - 10px / 2));
}
@keyframes rotate-ring-cap-2 {
  78% {
    transform: translate(-50%, -50%) rotate(calc(var(--angle) * (78 / 100) - 90deg)) translate(calc(calc( 180px - (calc(1 * 10px * 2) + calc( 1 * 6px * 2 )) ) / 2 - 10px / 2));
  }
}
.dial-ring:nth-child(3) .dial-ring-cap-end {
  left: 50%;
  top: 50%;
  transform-origin: center;
  z-index: 9999999999999;
  animation-name: rotate-ring-cap-2;
  animation-delay: 0.4s;
  animation-timing-function: ease-in-out;
  animation-duration: 0.6s;
  animation-fill-mode: forwards;
  transform: translate(-50%, -50%) rotate(-90deg) translate(calc(calc( 180px - (calc(1 * 10px * 2) + calc( 1 * 6px * 2 )) ) / 2 - 10px / 2));
}
@keyframes rotate-ring-cap-2 {
  79% {
    transform: translate(-50%, -50%) rotate(calc(var(--angle) * (79 / 100) - 90deg)) translate(calc(calc( 180px - (calc(1 * 10px * 2) + calc( 1 * 6px * 2 )) ) / 2 - 10px / 2));
  }
}
.dial-ring:nth-child(3) .dial-ring-cap-end {
  left: 50%;
  top: 50%;
  transform-origin: center;
  z-index: 9999999999999;
  animation-name: rotate-ring-cap-2;
  animation-delay: 0.4s;
  animation-timing-function: ease-in-out;
  animation-duration: 0.6s;
  animation-fill-mode: forwards;
  transform: translate(-50%, -50%) rotate(-90deg) translate(calc(calc( 180px - (calc(1 * 10px * 2) + calc( 1 * 6px * 2 )) ) / 2 - 10px / 2));
}
@keyframes rotate-ring-cap-2 {
  80% {
    transform: translate(-50%, -50%) rotate(calc(var(--angle) * (80 / 100) - 90deg)) translate(calc(calc( 180px - (calc(1 * 10px * 2) + calc( 1 * 6px * 2 )) ) / 2 - 10px / 2));
  }
}
.dial-ring:nth-child(3) .dial-ring-cap-end {
  left: 50%;
  top: 50%;
  transform-origin: center;
  z-index: 9999999999999;
  animation-name: rotate-ring-cap-2;
  animation-delay: 0.4s;
  animation-timing-function: ease-in-out;
  animation-duration: 0.6s;
  animation-fill-mode: forwards;
  transform: translate(-50%, -50%) rotate(-90deg) translate(calc(calc( 180px - (calc(1 * 10px * 2) + calc( 1 * 6px * 2 )) ) / 2 - 10px / 2));
}
@keyframes rotate-ring-cap-2 {
  81% {
    transform: translate(-50%, -50%) rotate(calc(var(--angle) * (81 / 100) - 90deg)) translate(calc(calc( 180px - (calc(1 * 10px * 2) + calc( 1 * 6px * 2 )) ) / 2 - 10px / 2));
  }
}
.dial-ring:nth-child(3) .dial-ring-cap-end {
  left: 50%;
  top: 50%;
  transform-origin: center;
  z-index: 9999999999999;
  animation-name: rotate-ring-cap-2;
  animation-delay: 0.4s;
  animation-timing-function: ease-in-out;
  animation-duration: 0.6s;
  animation-fill-mode: forwards;
  transform: translate(-50%, -50%) rotate(-90deg) translate(calc(calc( 180px - (calc(1 * 10px * 2) + calc( 1 * 6px * 2 )) ) / 2 - 10px / 2));
}
@keyframes rotate-ring-cap-2 {
  82% {
    transform: translate(-50%, -50%) rotate(calc(var(--angle) * (82 / 100) - 90deg)) translate(calc(calc( 180px - (calc(1 * 10px * 2) + calc( 1 * 6px * 2 )) ) / 2 - 10px / 2));
  }
}
.dial-ring:nth-child(3) .dial-ring-cap-end {
  left: 50%;
  top: 50%;
  transform-origin: center;
  z-index: 9999999999999;
  animation-name: rotate-ring-cap-2;
  animation-delay: 0.4s;
  animation-timing-function: ease-in-out;
  animation-duration: 0.6s;
  animation-fill-mode: forwards;
  transform: translate(-50%, -50%) rotate(-90deg) translate(calc(calc( 180px - (calc(1 * 10px * 2) + calc( 1 * 6px * 2 )) ) / 2 - 10px / 2));
}
@keyframes rotate-ring-cap-2 {
  83% {
    transform: translate(-50%, -50%) rotate(calc(var(--angle) * (83 / 100) - 90deg)) translate(calc(calc( 180px - (calc(1 * 10px * 2) + calc( 1 * 6px * 2 )) ) / 2 - 10px / 2));
  }
}
.dial-ring:nth-child(3) .dial-ring-cap-end {
  left: 50%;
  top: 50%;
  transform-origin: center;
  z-index: 9999999999999;
  animation-name: rotate-ring-cap-2;
  animation-delay: 0.4s;
  animation-timing-function: ease-in-out;
  animation-duration: 0.6s;
  animation-fill-mode: forwards;
  transform: translate(-50%, -50%) rotate(-90deg) translate(calc(calc( 180px - (calc(1 * 10px * 2) + calc( 1 * 6px * 2 )) ) / 2 - 10px / 2));
}
@keyframes rotate-ring-cap-2 {
  84% {
    transform: translate(-50%, -50%) rotate(calc(var(--angle) * (84 / 100) - 90deg)) translate(calc(calc( 180px - (calc(1 * 10px * 2) + calc( 1 * 6px * 2 )) ) / 2 - 10px / 2));
  }
}
.dial-ring:nth-child(3) .dial-ring-cap-end {
  left: 50%;
  top: 50%;
  transform-origin: center;
  z-index: 9999999999999;
  animation-name: rotate-ring-cap-2;
  animation-delay: 0.4s;
  animation-timing-function: ease-in-out;
  animation-duration: 0.6s;
  animation-fill-mode: forwards;
  transform: translate(-50%, -50%) rotate(-90deg) translate(calc(calc( 180px - (calc(1 * 10px * 2) + calc( 1 * 6px * 2 )) ) / 2 - 10px / 2));
}
@keyframes rotate-ring-cap-2 {
  85% {
    transform: translate(-50%, -50%) rotate(calc(var(--angle) * (85 / 100) - 90deg)) translate(calc(calc( 180px - (calc(1 * 10px * 2) + calc( 1 * 6px * 2 )) ) / 2 - 10px / 2));
  }
}
.dial-ring:nth-child(3) .dial-ring-cap-end {
  left: 50%;
  top: 50%;
  transform-origin: center;
  z-index: 9999999999999;
  animation-name: rotate-ring-cap-2;
  animation-delay: 0.4s;
  animation-timing-function: ease-in-out;
  animation-duration: 0.6s;
  animation-fill-mode: forwards;
  transform: translate(-50%, -50%) rotate(-90deg) translate(calc(calc( 180px - (calc(1 * 10px * 2) + calc( 1 * 6px * 2 )) ) / 2 - 10px / 2));
}
@keyframes rotate-ring-cap-2 {
  86% {
    transform: translate(-50%, -50%) rotate(calc(var(--angle) * (86 / 100) - 90deg)) translate(calc(calc( 180px - (calc(1 * 10px * 2) + calc( 1 * 6px * 2 )) ) / 2 - 10px / 2));
  }
}
.dial-ring:nth-child(3) .dial-ring-cap-end {
  left: 50%;
  top: 50%;
  transform-origin: center;
  z-index: 9999999999999;
  animation-name: rotate-ring-cap-2;
  animation-delay: 0.4s;
  animation-timing-function: ease-in-out;
  animation-duration: 0.6s;
  animation-fill-mode: forwards;
  transform: translate(-50%, -50%) rotate(-90deg) translate(calc(calc( 180px - (calc(1 * 10px * 2) + calc( 1 * 6px * 2 )) ) / 2 - 10px / 2));
}
@keyframes rotate-ring-cap-2 {
  87% {
    transform: translate(-50%, -50%) rotate(calc(var(--angle) * (87 / 100) - 90deg)) translate(calc(calc( 180px - (calc(1 * 10px * 2) + calc( 1 * 6px * 2 )) ) / 2 - 10px / 2));
  }
}
.dial-ring:nth-child(3) .dial-ring-cap-end {
  left: 50%;
  top: 50%;
  transform-origin: center;
  z-index: 9999999999999;
  animation-name: rotate-ring-cap-2;
  animation-delay: 0.4s;
  animation-timing-function: ease-in-out;
  animation-duration: 0.6s;
  animation-fill-mode: forwards;
  transform: translate(-50%, -50%) rotate(-90deg) translate(calc(calc( 180px - (calc(1 * 10px * 2) + calc( 1 * 6px * 2 )) ) / 2 - 10px / 2));
}
@keyframes rotate-ring-cap-2 {
  88% {
    transform: translate(-50%, -50%) rotate(calc(var(--angle) * (88 / 100) - 90deg)) translate(calc(calc( 180px - (calc(1 * 10px * 2) + calc( 1 * 6px * 2 )) ) / 2 - 10px / 2));
  }
}
.dial-ring:nth-child(3) .dial-ring-cap-end {
  left: 50%;
  top: 50%;
  transform-origin: center;
  z-index: 9999999999999;
  animation-name: rotate-ring-cap-2;
  animation-delay: 0.4s;
  animation-timing-function: ease-in-out;
  animation-duration: 0.6s;
  animation-fill-mode: forwards;
  transform: translate(-50%, -50%) rotate(-90deg) translate(calc(calc( 180px - (calc(1 * 10px * 2) + calc( 1 * 6px * 2 )) ) / 2 - 10px / 2));
}
@keyframes rotate-ring-cap-2 {
  89% {
    transform: translate(-50%, -50%) rotate(calc(var(--angle) * (89 / 100) - 90deg)) translate(calc(calc( 180px - (calc(1 * 10px * 2) + calc( 1 * 6px * 2 )) ) / 2 - 10px / 2));
  }
}
.dial-ring:nth-child(3) .dial-ring-cap-end {
  left: 50%;
  top: 50%;
  transform-origin: center;
  z-index: 9999999999999;
  animation-name: rotate-ring-cap-2;
  animation-delay: 0.4s;
  animation-timing-function: ease-in-out;
  animation-duration: 0.6s;
  animation-fill-mode: forwards;
  transform: translate(-50%, -50%) rotate(-90deg) translate(calc(calc( 180px - (calc(1 * 10px * 2) + calc( 1 * 6px * 2 )) ) / 2 - 10px / 2));
}
@keyframes rotate-ring-cap-2 {
  90% {
    transform: translate(-50%, -50%) rotate(calc(var(--angle) * (90 / 100) - 90deg)) translate(calc(calc( 180px - (calc(1 * 10px * 2) + calc( 1 * 6px * 2 )) ) / 2 - 10px / 2));
  }
}
.dial-ring:nth-child(3) .dial-ring-cap-end {
  left: 50%;
  top: 50%;
  transform-origin: center;
  z-index: 9999999999999;
  animation-name: rotate-ring-cap-2;
  animation-delay: 0.4s;
  animation-timing-function: ease-in-out;
  animation-duration: 0.6s;
  animation-fill-mode: forwards;
  transform: translate(-50%, -50%) rotate(-90deg) translate(calc(calc( 180px - (calc(1 * 10px * 2) + calc( 1 * 6px * 2 )) ) / 2 - 10px / 2));
}
@keyframes rotate-ring-cap-2 {
  91% {
    transform: translate(-50%, -50%) rotate(calc(var(--angle) * (91 / 100) - 90deg)) translate(calc(calc( 180px - (calc(1 * 10px * 2) + calc( 1 * 6px * 2 )) ) / 2 - 10px / 2));
  }
}
.dial-ring:nth-child(3) .dial-ring-cap-end {
  left: 50%;
  top: 50%;
  transform-origin: center;
  z-index: 9999999999999;
  animation-name: rotate-ring-cap-2;
  animation-delay: 0.4s;
  animation-timing-function: ease-in-out;
  animation-duration: 0.6s;
  animation-fill-mode: forwards;
  transform: translate(-50%, -50%) rotate(-90deg) translate(calc(calc( 180px - (calc(1 * 10px * 2) + calc( 1 * 6px * 2 )) ) / 2 - 10px / 2));
}
@keyframes rotate-ring-cap-2 {
  92% {
    transform: translate(-50%, -50%) rotate(calc(var(--angle) * (92 / 100) - 90deg)) translate(calc(calc( 180px - (calc(1 * 10px * 2) + calc( 1 * 6px * 2 )) ) / 2 - 10px / 2));
  }
}
.dial-ring:nth-child(3) .dial-ring-cap-end {
  left: 50%;
  top: 50%;
  transform-origin: center;
  z-index: 9999999999999;
  animation-name: rotate-ring-cap-2;
  animation-delay: 0.4s;
  animation-timing-function: ease-in-out;
  animation-duration: 0.6s;
  animation-fill-mode: forwards;
  transform: translate(-50%, -50%) rotate(-90deg) translate(calc(calc( 180px - (calc(1 * 10px * 2) + calc( 1 * 6px * 2 )) ) / 2 - 10px / 2));
}
@keyframes rotate-ring-cap-2 {
  93% {
    transform: translate(-50%, -50%) rotate(calc(var(--angle) * (93 / 100) - 90deg)) translate(calc(calc( 180px - (calc(1 * 10px * 2) + calc( 1 * 6px * 2 )) ) / 2 - 10px / 2));
  }
}
.dial-ring:nth-child(3) .dial-ring-cap-end {
  left: 50%;
  top: 50%;
  transform-origin: center;
  z-index: 9999999999999;
  animation-name: rotate-ring-cap-2;
  animation-delay: 0.4s;
  animation-timing-function: ease-in-out;
  animation-duration: 0.6s;
  animation-fill-mode: forwards;
  transform: translate(-50%, -50%) rotate(-90deg) translate(calc(calc( 180px - (calc(1 * 10px * 2) + calc( 1 * 6px * 2 )) ) / 2 - 10px / 2));
}
@keyframes rotate-ring-cap-2 {
  94% {
    transform: translate(-50%, -50%) rotate(calc(var(--angle) * (94 / 100) - 90deg)) translate(calc(calc( 180px - (calc(1 * 10px * 2) + calc( 1 * 6px * 2 )) ) / 2 - 10px / 2));
  }
}
.dial-ring:nth-child(3) .dial-ring-cap-end {
  left: 50%;
  top: 50%;
  transform-origin: center;
  z-index: 9999999999999;
  animation-name: rotate-ring-cap-2;
  animation-delay: 0.4s;
  animation-timing-function: ease-in-out;
  animation-duration: 0.6s;
  animation-fill-mode: forwards;
  transform: translate(-50%, -50%) rotate(-90deg) translate(calc(calc( 180px - (calc(1 * 10px * 2) + calc( 1 * 6px * 2 )) ) / 2 - 10px / 2));
}
@keyframes rotate-ring-cap-2 {
  95% {
    transform: translate(-50%, -50%) rotate(calc(var(--angle) * (95 / 100) - 90deg)) translate(calc(calc( 180px - (calc(1 * 10px * 2) + calc( 1 * 6px * 2 )) ) / 2 - 10px / 2));
  }
}
.dial-ring:nth-child(3) .dial-ring-cap-end {
  left: 50%;
  top: 50%;
  transform-origin: center;
  z-index: 9999999999999;
  animation-name: rotate-ring-cap-2;
  animation-delay: 0.4s;
  animation-timing-function: ease-in-out;
  animation-duration: 0.6s;
  animation-fill-mode: forwards;
  transform: translate(-50%, -50%) rotate(-90deg) translate(calc(calc( 180px - (calc(1 * 10px * 2) + calc( 1 * 6px * 2 )) ) / 2 - 10px / 2));
}
@keyframes rotate-ring-cap-2 {
  96% {
    transform: translate(-50%, -50%) rotate(calc(var(--angle) * (96 / 100) - 90deg)) translate(calc(calc( 180px - (calc(1 * 10px * 2) + calc( 1 * 6px * 2 )) ) / 2 - 10px / 2));
  }
}
.dial-ring:nth-child(3) .dial-ring-cap-end {
  left: 50%;
  top: 50%;
  transform-origin: center;
  z-index: 9999999999999;
  animation-name: rotate-ring-cap-2;
  animation-delay: 0.4s;
  animation-timing-function: ease-in-out;
  animation-duration: 0.6s;
  animation-fill-mode: forwards;
  transform: translate(-50%, -50%) rotate(-90deg) translate(calc(calc( 180px - (calc(1 * 10px * 2) + calc( 1 * 6px * 2 )) ) / 2 - 10px / 2));
}
@keyframes rotate-ring-cap-2 {
  97% {
    transform: translate(-50%, -50%) rotate(calc(var(--angle) * (97 / 100) - 90deg)) translate(calc(calc( 180px - (calc(1 * 10px * 2) + calc( 1 * 6px * 2 )) ) / 2 - 10px / 2));
  }
}
.dial-ring:nth-child(3) .dial-ring-cap-end {
  left: 50%;
  top: 50%;
  transform-origin: center;
  z-index: 9999999999999;
  animation-name: rotate-ring-cap-2;
  animation-delay: 0.4s;
  animation-timing-function: ease-in-out;
  animation-duration: 0.6s;
  animation-fill-mode: forwards;
  transform: translate(-50%, -50%) rotate(-90deg) translate(calc(calc( 180px - (calc(1 * 10px * 2) + calc( 1 * 6px * 2 )) ) / 2 - 10px / 2));
}
@keyframes rotate-ring-cap-2 {
  98% {
    transform: translate(-50%, -50%) rotate(calc(var(--angle) * (98 / 100) - 90deg)) translate(calc(calc( 180px - (calc(1 * 10px * 2) + calc( 1 * 6px * 2 )) ) / 2 - 10px / 2));
  }
}
.dial-ring:nth-child(3) .dial-ring-cap-end {
  left: 50%;
  top: 50%;
  transform-origin: center;
  z-index: 9999999999999;
  animation-name: rotate-ring-cap-2;
  animation-delay: 0.4s;
  animation-timing-function: ease-in-out;
  animation-duration: 0.6s;
  animation-fill-mode: forwards;
  transform: translate(-50%, -50%) rotate(-90deg) translate(calc(calc( 180px - (calc(1 * 10px * 2) + calc( 1 * 6px * 2 )) ) / 2 - 10px / 2));
}
@keyframes rotate-ring-cap-2 {
  99% {
    transform: translate(-50%, -50%) rotate(calc(var(--angle) * (99 / 100) - 90deg)) translate(calc(calc( 180px - (calc(1 * 10px * 2) + calc( 1 * 6px * 2 )) ) / 2 - 10px / 2));
  }
}
.dial-ring:nth-child(3) .dial-ring-cap-end {
  left: 50%;
  top: 50%;
  transform-origin: center;
  z-index: 9999999999999;
  animation-name: rotate-ring-cap-2;
  animation-delay: 0.4s;
  animation-timing-function: ease-in-out;
  animation-duration: 0.6s;
  animation-fill-mode: forwards;
  transform: translate(-50%, -50%) rotate(-90deg) translate(calc(calc( 180px - (calc(1 * 10px * 2) + calc( 1 * 6px * 2 )) ) / 2 - 10px / 2));
}
@keyframes rotate-ring-cap-2 {
  100% {
    transform: translate(-50%, -50%) rotate(calc(var(--angle) * (100 / 100) - 90deg)) translate(calc(calc( 180px - (calc(1 * 10px * 2) + calc( 1 * 6px * 2 )) ) / 2 - 10px / 2));
  }
}
.dial-ring--spacer:not(:last-child):nth-child(6) {
  position: absolute;
  border-radius: 50%;
  left: 50%;
  top: 50%;
  transform: translateX(-50%) translateY(-50%);
  width: calc(180px - calc(
        3 *
          10px *
          2 +
          ((3 - 1) * 6px * 2)
      ));
  height: calc(180px - calc(
        3 *
          10px *
          2 +
          ((3 - 1) * 6px * 2)
      ));
  background: #272727;
}
.dial-ring:nth-child(5) {
  width: calc(
        180px - (calc(2 * 10px * 2) + calc(
        2 * 6px * 2
      ))
      );
  height: calc(
        180px - (calc(2 * 10px * 2) + calc(
        2 * 6px * 2
      ))
      );
}
.dial-ring:nth-child(5) .dial-ring-progress:before {
  animation-delay: 0.4s;
  animation-timing-function: ease-in-out;
  animation-duration: 0.6s;
  animation-name: rotation-3;
  animation-fill-mode: forwards;
}
@keyframes rotation-3 {
  to {
    transform: rotate(var(--angle));
  }
}
.dial-ring:nth-child(5) .dial-ring-progress:not(.dial-ring-progress--less-than-halfway) {
  animation-name: show-left-hemisphere-3;
}
.dial-ring:nth-child(5) .dial-ring-progress:not(.dial-ring-progress--less-than-halfway), .dial-ring:nth-child(5) .dial-ring-progress:not(.dial-ring-progress--less-than-halfway):after {
  animation-timing-function: linear;
  animation-duration: 0s;
  animation-fill-mode: forwards;
}
.dial-ring:nth-child(5) .dial-ring-progress:not(.dial-ring-progress--less-than-halfway).dial-ring-progress--segments-3, .dial-ring:nth-child(5) .dial-ring-progress:not(.dial-ring-progress--less-than-halfway).dial-ring-progress--segments-3:after {
  animation-delay: calc(calc(
            var(--percent-complete-at-half-filled) *
              0.6s +
              0.4s
          ) - 0.075s);
}
.dial-ring:nth-child(5) .dial-ring-progress:not(.dial-ring-progress--less-than-halfway).dial-ring-progress--segments-4, .dial-ring:nth-child(5) .dial-ring-progress:not(.dial-ring-progress--less-than-halfway).dial-ring-progress--segments-4:after {
  animation-delay: calc(calc(
            var(--percent-complete-at-half-filled) *
              0.6s +
              0.4s
          ) - 0.03s);
}
.dial-ring:nth-child(5) .dial-ring-progress:not(.dial-ring-progress--less-than-halfway).dial-ring-progress--segments-5, .dial-ring:nth-child(5) .dial-ring-progress:not(.dial-ring-progress--less-than-halfway).dial-ring-progress--segments-5:after {
  animation-delay: calc(
            var(--percent-complete-at-half-filled) *
              0.6s +
              0.4s
          );
}
@keyframes show-left-hemisphere-3 {
  to {
    clip-path: none;
  }
}
.dial-ring:nth-child(5) .dial-ring-progress:not(.dial-ring-progress--less-than-halfway):after {
  animation-name: fill-right-hemisphere-3;
}
@keyframes fill-right-hemisphere-3 {
  to {
    opacity: 1;
  }
}
.dial-ring:nth-child(5) .dial-ring-cap-end {
  left: 50%;
  top: 50%;
  transform-origin: center;
  z-index: 9999999999999;
  animation-name: rotate-ring-cap-3;
  animation-delay: 0.4s;
  animation-timing-function: ease-in-out;
  animation-duration: 0.6s;
  animation-fill-mode: forwards;
  transform: translate(-50%, -50%) rotate(-90deg) translate(calc(calc( 180px - (calc(2 * 10px * 2) + calc( 2 * 6px * 2 )) ) / 2 - 10px / 2));
}
@keyframes rotate-ring-cap-3 {
  0% {
    transform: translate(-50%, -50%) rotate(calc(var(--angle) * (0 / 100) - 90deg)) translate(calc(calc( 180px - (calc(2 * 10px * 2) + calc( 2 * 6px * 2 )) ) / 2 - 10px / 2));
  }
}
.dial-ring:nth-child(5) .dial-ring-cap-end {
  left: 50%;
  top: 50%;
  transform-origin: center;
  z-index: 9999999999999;
  animation-name: rotate-ring-cap-3;
  animation-delay: 0.4s;
  animation-timing-function: ease-in-out;
  animation-duration: 0.6s;
  animation-fill-mode: forwards;
  transform: translate(-50%, -50%) rotate(-90deg) translate(calc(calc( 180px - (calc(2 * 10px * 2) + calc( 2 * 6px * 2 )) ) / 2 - 10px / 2));
}
@keyframes rotate-ring-cap-3 {
  1% {
    transform: translate(-50%, -50%) rotate(calc(var(--angle) * (1 / 100) - 90deg)) translate(calc(calc( 180px - (calc(2 * 10px * 2) + calc( 2 * 6px * 2 )) ) / 2 - 10px / 2));
  }
}
.dial-ring:nth-child(5) .dial-ring-cap-end {
  left: 50%;
  top: 50%;
  transform-origin: center;
  z-index: 9999999999999;
  animation-name: rotate-ring-cap-3;
  animation-delay: 0.4s;
  animation-timing-function: ease-in-out;
  animation-duration: 0.6s;
  animation-fill-mode: forwards;
  transform: translate(-50%, -50%) rotate(-90deg) translate(calc(calc( 180px - (calc(2 * 10px * 2) + calc( 2 * 6px * 2 )) ) / 2 - 10px / 2));
}
@keyframes rotate-ring-cap-3 {
  2% {
    transform: translate(-50%, -50%) rotate(calc(var(--angle) * (2 / 100) - 90deg)) translate(calc(calc( 180px - (calc(2 * 10px * 2) + calc( 2 * 6px * 2 )) ) / 2 - 10px / 2));
  }
}
.dial-ring:nth-child(5) .dial-ring-cap-end {
  left: 50%;
  top: 50%;
  transform-origin: center;
  z-index: 9999999999999;
  animation-name: rotate-ring-cap-3;
  animation-delay: 0.4s;
  animation-timing-function: ease-in-out;
  animation-duration: 0.6s;
  animation-fill-mode: forwards;
  transform: translate(-50%, -50%) rotate(-90deg) translate(calc(calc( 180px - (calc(2 * 10px * 2) + calc( 2 * 6px * 2 )) ) / 2 - 10px / 2));
}
@keyframes rotate-ring-cap-3 {
  3% {
    transform: translate(-50%, -50%) rotate(calc(var(--angle) * (3 / 100) - 90deg)) translate(calc(calc( 180px - (calc(2 * 10px * 2) + calc( 2 * 6px * 2 )) ) / 2 - 10px / 2));
  }
}
.dial-ring:nth-child(5) .dial-ring-cap-end {
  left: 50%;
  top: 50%;
  transform-origin: center;
  z-index: 9999999999999;
  animation-name: rotate-ring-cap-3;
  animation-delay: 0.4s;
  animation-timing-function: ease-in-out;
  animation-duration: 0.6s;
  animation-fill-mode: forwards;
  transform: translate(-50%, -50%) rotate(-90deg) translate(calc(calc( 180px - (calc(2 * 10px * 2) + calc( 2 * 6px * 2 )) ) / 2 - 10px / 2));
}
@keyframes rotate-ring-cap-3 {
  4% {
    transform: translate(-50%, -50%) rotate(calc(var(--angle) * (4 / 100) - 90deg)) translate(calc(calc( 180px - (calc(2 * 10px * 2) + calc( 2 * 6px * 2 )) ) / 2 - 10px / 2));
  }
}
.dial-ring:nth-child(5) .dial-ring-cap-end {
  left: 50%;
  top: 50%;
  transform-origin: center;
  z-index: 9999999999999;
  animation-name: rotate-ring-cap-3;
  animation-delay: 0.4s;
  animation-timing-function: ease-in-out;
  animation-duration: 0.6s;
  animation-fill-mode: forwards;
  transform: translate(-50%, -50%) rotate(-90deg) translate(calc(calc( 180px - (calc(2 * 10px * 2) + calc( 2 * 6px * 2 )) ) / 2 - 10px / 2));
}
@keyframes rotate-ring-cap-3 {
  5% {
    transform: translate(-50%, -50%) rotate(calc(var(--angle) * (5 / 100) - 90deg)) translate(calc(calc( 180px - (calc(2 * 10px * 2) + calc( 2 * 6px * 2 )) ) / 2 - 10px / 2));
  }
}
.dial-ring:nth-child(5) .dial-ring-cap-end {
  left: 50%;
  top: 50%;
  transform-origin: center;
  z-index: 9999999999999;
  animation-name: rotate-ring-cap-3;
  animation-delay: 0.4s;
  animation-timing-function: ease-in-out;
  animation-duration: 0.6s;
  animation-fill-mode: forwards;
  transform: translate(-50%, -50%) rotate(-90deg) translate(calc(calc( 180px - (calc(2 * 10px * 2) + calc( 2 * 6px * 2 )) ) / 2 - 10px / 2));
}
@keyframes rotate-ring-cap-3 {
  6% {
    transform: translate(-50%, -50%) rotate(calc(var(--angle) * (6 / 100) - 90deg)) translate(calc(calc( 180px - (calc(2 * 10px * 2) + calc( 2 * 6px * 2 )) ) / 2 - 10px / 2));
  }
}
.dial-ring:nth-child(5) .dial-ring-cap-end {
  left: 50%;
  top: 50%;
  transform-origin: center;
  z-index: 9999999999999;
  animation-name: rotate-ring-cap-3;
  animation-delay: 0.4s;
  animation-timing-function: ease-in-out;
  animation-duration: 0.6s;
  animation-fill-mode: forwards;
  transform: translate(-50%, -50%) rotate(-90deg) translate(calc(calc( 180px - (calc(2 * 10px * 2) + calc( 2 * 6px * 2 )) ) / 2 - 10px / 2));
}
@keyframes rotate-ring-cap-3 {
  7% {
    transform: translate(-50%, -50%) rotate(calc(var(--angle) * (7 / 100) - 90deg)) translate(calc(calc( 180px - (calc(2 * 10px * 2) + calc( 2 * 6px * 2 )) ) / 2 - 10px / 2));
  }
}
.dial-ring:nth-child(5) .dial-ring-cap-end {
  left: 50%;
  top: 50%;
  transform-origin: center;
  z-index: 9999999999999;
  animation-name: rotate-ring-cap-3;
  animation-delay: 0.4s;
  animation-timing-function: ease-in-out;
  animation-duration: 0.6s;
  animation-fill-mode: forwards;
  transform: translate(-50%, -50%) rotate(-90deg) translate(calc(calc( 180px - (calc(2 * 10px * 2) + calc( 2 * 6px * 2 )) ) / 2 - 10px / 2));
}
@keyframes rotate-ring-cap-3 {
  8% {
    transform: translate(-50%, -50%) rotate(calc(var(--angle) * (8 / 100) - 90deg)) translate(calc(calc( 180px - (calc(2 * 10px * 2) + calc( 2 * 6px * 2 )) ) / 2 - 10px / 2));
  }
}
.dial-ring:nth-child(5) .dial-ring-cap-end {
  left: 50%;
  top: 50%;
  transform-origin: center;
  z-index: 9999999999999;
  animation-name: rotate-ring-cap-3;
  animation-delay: 0.4s;
  animation-timing-function: ease-in-out;
  animation-duration: 0.6s;
  animation-fill-mode: forwards;
  transform: translate(-50%, -50%) rotate(-90deg) translate(calc(calc( 180px - (calc(2 * 10px * 2) + calc( 2 * 6px * 2 )) ) / 2 - 10px / 2));
}
@keyframes rotate-ring-cap-3 {
  9% {
    transform: translate(-50%, -50%) rotate(calc(var(--angle) * (9 / 100) - 90deg)) translate(calc(calc( 180px - (calc(2 * 10px * 2) + calc( 2 * 6px * 2 )) ) / 2 - 10px / 2));
  }
}
.dial-ring:nth-child(5) .dial-ring-cap-end {
  left: 50%;
  top: 50%;
  transform-origin: center;
  z-index: 9999999999999;
  animation-name: rotate-ring-cap-3;
  animation-delay: 0.4s;
  animation-timing-function: ease-in-out;
  animation-duration: 0.6s;
  animation-fill-mode: forwards;
  transform: translate(-50%, -50%) rotate(-90deg) translate(calc(calc( 180px - (calc(2 * 10px * 2) + calc( 2 * 6px * 2 )) ) / 2 - 10px / 2));
}
@keyframes rotate-ring-cap-3 {
  10% {
    transform: translate(-50%, -50%) rotate(calc(var(--angle) * (10 / 100) - 90deg)) translate(calc(calc( 180px - (calc(2 * 10px * 2) + calc( 2 * 6px * 2 )) ) / 2 - 10px / 2));
  }
}
.dial-ring:nth-child(5) .dial-ring-cap-end {
  left: 50%;
  top: 50%;
  transform-origin: center;
  z-index: 9999999999999;
  animation-name: rotate-ring-cap-3;
  animation-delay: 0.4s;
  animation-timing-function: ease-in-out;
  animation-duration: 0.6s;
  animation-fill-mode: forwards;
  transform: translate(-50%, -50%) rotate(-90deg) translate(calc(calc( 180px - (calc(2 * 10px * 2) + calc( 2 * 6px * 2 )) ) / 2 - 10px / 2));
}
@keyframes rotate-ring-cap-3 {
  11% {
    transform: translate(-50%, -50%) rotate(calc(var(--angle) * (11 / 100) - 90deg)) translate(calc(calc( 180px - (calc(2 * 10px * 2) + calc( 2 * 6px * 2 )) ) / 2 - 10px / 2));
  }
}
.dial-ring:nth-child(5) .dial-ring-cap-end {
  left: 50%;
  top: 50%;
  transform-origin: center;
  z-index: 9999999999999;
  animation-name: rotate-ring-cap-3;
  animation-delay: 0.4s;
  animation-timing-function: ease-in-out;
  animation-duration: 0.6s;
  animation-fill-mode: forwards;
  transform: translate(-50%, -50%) rotate(-90deg) translate(calc(calc( 180px - (calc(2 * 10px * 2) + calc( 2 * 6px * 2 )) ) / 2 - 10px / 2));
}
@keyframes rotate-ring-cap-3 {
  12% {
    transform: translate(-50%, -50%) rotate(calc(var(--angle) * (12 / 100) - 90deg)) translate(calc(calc( 180px - (calc(2 * 10px * 2) + calc( 2 * 6px * 2 )) ) / 2 - 10px / 2));
  }
}
.dial-ring:nth-child(5) .dial-ring-cap-end {
  left: 50%;
  top: 50%;
  transform-origin: center;
  z-index: 9999999999999;
  animation-name: rotate-ring-cap-3;
  animation-delay: 0.4s;
  animation-timing-function: ease-in-out;
  animation-duration: 0.6s;
  animation-fill-mode: forwards;
  transform: translate(-50%, -50%) rotate(-90deg) translate(calc(calc( 180px - (calc(2 * 10px * 2) + calc( 2 * 6px * 2 )) ) / 2 - 10px / 2));
}
@keyframes rotate-ring-cap-3 {
  13% {
    transform: translate(-50%, -50%) rotate(calc(var(--angle) * (13 / 100) - 90deg)) translate(calc(calc( 180px - (calc(2 * 10px * 2) + calc( 2 * 6px * 2 )) ) / 2 - 10px / 2));
  }
}
.dial-ring:nth-child(5) .dial-ring-cap-end {
  left: 50%;
  top: 50%;
  transform-origin: center;
  z-index: 9999999999999;
  animation-name: rotate-ring-cap-3;
  animation-delay: 0.4s;
  animation-timing-function: ease-in-out;
  animation-duration: 0.6s;
  animation-fill-mode: forwards;
  transform: translate(-50%, -50%) rotate(-90deg) translate(calc(calc( 180px - (calc(2 * 10px * 2) + calc( 2 * 6px * 2 )) ) / 2 - 10px / 2));
}
@keyframes rotate-ring-cap-3 {
  14% {
    transform: translate(-50%, -50%) rotate(calc(var(--angle) * (14 / 100) - 90deg)) translate(calc(calc( 180px - (calc(2 * 10px * 2) + calc( 2 * 6px * 2 )) ) / 2 - 10px / 2));
  }
}
.dial-ring:nth-child(5) .dial-ring-cap-end {
  left: 50%;
  top: 50%;
  transform-origin: center;
  z-index: 9999999999999;
  animation-name: rotate-ring-cap-3;
  animation-delay: 0.4s;
  animation-timing-function: ease-in-out;
  animation-duration: 0.6s;
  animation-fill-mode: forwards;
  transform: translate(-50%, -50%) rotate(-90deg) translate(calc(calc( 180px - (calc(2 * 10px * 2) + calc( 2 * 6px * 2 )) ) / 2 - 10px / 2));
}
@keyframes rotate-ring-cap-3 {
  15% {
    transform: translate(-50%, -50%) rotate(calc(var(--angle) * (15 / 100) - 90deg)) translate(calc(calc( 180px - (calc(2 * 10px * 2) + calc( 2 * 6px * 2 )) ) / 2 - 10px / 2));
  }
}
.dial-ring:nth-child(5) .dial-ring-cap-end {
  left: 50%;
  top: 50%;
  transform-origin: center;
  z-index: 9999999999999;
  animation-name: rotate-ring-cap-3;
  animation-delay: 0.4s;
  animation-timing-function: ease-in-out;
  animation-duration: 0.6s;
  animation-fill-mode: forwards;
  transform: translate(-50%, -50%) rotate(-90deg) translate(calc(calc( 180px - (calc(2 * 10px * 2) + calc( 2 * 6px * 2 )) ) / 2 - 10px / 2));
}
@keyframes rotate-ring-cap-3 {
  16% {
    transform: translate(-50%, -50%) rotate(calc(var(--angle) * (16 / 100) - 90deg)) translate(calc(calc( 180px - (calc(2 * 10px * 2) + calc( 2 * 6px * 2 )) ) / 2 - 10px / 2));
  }
}
.dial-ring:nth-child(5) .dial-ring-cap-end {
  left: 50%;
  top: 50%;
  transform-origin: center;
  z-index: 9999999999999;
  animation-name: rotate-ring-cap-3;
  animation-delay: 0.4s;
  animation-timing-function: ease-in-out;
  animation-duration: 0.6s;
  animation-fill-mode: forwards;
  transform: translate(-50%, -50%) rotate(-90deg) translate(calc(calc( 180px - (calc(2 * 10px * 2) + calc( 2 * 6px * 2 )) ) / 2 - 10px / 2));
}
@keyframes rotate-ring-cap-3 {
  17% {
    transform: translate(-50%, -50%) rotate(calc(var(--angle) * (17 / 100) - 90deg)) translate(calc(calc( 180px - (calc(2 * 10px * 2) + calc( 2 * 6px * 2 )) ) / 2 - 10px / 2));
  }
}
.dial-ring:nth-child(5) .dial-ring-cap-end {
  left: 50%;
  top: 50%;
  transform-origin: center;
  z-index: 9999999999999;
  animation-name: rotate-ring-cap-3;
  animation-delay: 0.4s;
  animation-timing-function: ease-in-out;
  animation-duration: 0.6s;
  animation-fill-mode: forwards;
  transform: translate(-50%, -50%) rotate(-90deg) translate(calc(calc( 180px - (calc(2 * 10px * 2) + calc( 2 * 6px * 2 )) ) / 2 - 10px / 2));
}
@keyframes rotate-ring-cap-3 {
  18% {
    transform: translate(-50%, -50%) rotate(calc(var(--angle) * (18 / 100) - 90deg)) translate(calc(calc( 180px - (calc(2 * 10px * 2) + calc( 2 * 6px * 2 )) ) / 2 - 10px / 2));
  }
}
.dial-ring:nth-child(5) .dial-ring-cap-end {
  left: 50%;
  top: 50%;
  transform-origin: center;
  z-index: 9999999999999;
  animation-name: rotate-ring-cap-3;
  animation-delay: 0.4s;
  animation-timing-function: ease-in-out;
  animation-duration: 0.6s;
  animation-fill-mode: forwards;
  transform: translate(-50%, -50%) rotate(-90deg) translate(calc(calc( 180px - (calc(2 * 10px * 2) + calc( 2 * 6px * 2 )) ) / 2 - 10px / 2));
}
@keyframes rotate-ring-cap-3 {
  19% {
    transform: translate(-50%, -50%) rotate(calc(var(--angle) * (19 / 100) - 90deg)) translate(calc(calc( 180px - (calc(2 * 10px * 2) + calc( 2 * 6px * 2 )) ) / 2 - 10px / 2));
  }
}
.dial-ring:nth-child(5) .dial-ring-cap-end {
  left: 50%;
  top: 50%;
  transform-origin: center;
  z-index: 9999999999999;
  animation-name: rotate-ring-cap-3;
  animation-delay: 0.4s;
  animation-timing-function: ease-in-out;
  animation-duration: 0.6s;
  animation-fill-mode: forwards;
  transform: translate(-50%, -50%) rotate(-90deg) translate(calc(calc( 180px - (calc(2 * 10px * 2) + calc( 2 * 6px * 2 )) ) / 2 - 10px / 2));
}
@keyframes rotate-ring-cap-3 {
  20% {
    transform: translate(-50%, -50%) rotate(calc(var(--angle) * (20 / 100) - 90deg)) translate(calc(calc( 180px - (calc(2 * 10px * 2) + calc( 2 * 6px * 2 )) ) / 2 - 10px / 2));
  }
}
.dial-ring:nth-child(5) .dial-ring-cap-end {
  left: 50%;
  top: 50%;
  transform-origin: center;
  z-index: 9999999999999;
  animation-name: rotate-ring-cap-3;
  animation-delay: 0.4s;
  animation-timing-function: ease-in-out;
  animation-duration: 0.6s;
  animation-fill-mode: forwards;
  transform: translate(-50%, -50%) rotate(-90deg) translate(calc(calc( 180px - (calc(2 * 10px * 2) + calc( 2 * 6px * 2 )) ) / 2 - 10px / 2));
}
@keyframes rotate-ring-cap-3 {
  21% {
    transform: translate(-50%, -50%) rotate(calc(var(--angle) * (21 / 100) - 90deg)) translate(calc(calc( 180px - (calc(2 * 10px * 2) + calc( 2 * 6px * 2 )) ) / 2 - 10px / 2));
  }
}
.dial-ring:nth-child(5) .dial-ring-cap-end {
  left: 50%;
  top: 50%;
  transform-origin: center;
  z-index: 9999999999999;
  animation-name: rotate-ring-cap-3;
  animation-delay: 0.4s;
  animation-timing-function: ease-in-out;
  animation-duration: 0.6s;
  animation-fill-mode: forwards;
  transform: translate(-50%, -50%) rotate(-90deg) translate(calc(calc( 180px - (calc(2 * 10px * 2) + calc( 2 * 6px * 2 )) ) / 2 - 10px / 2));
}
@keyframes rotate-ring-cap-3 {
  22% {
    transform: translate(-50%, -50%) rotate(calc(var(--angle) * (22 / 100) - 90deg)) translate(calc(calc( 180px - (calc(2 * 10px * 2) + calc( 2 * 6px * 2 )) ) / 2 - 10px / 2));
  }
}
.dial-ring:nth-child(5) .dial-ring-cap-end {
  left: 50%;
  top: 50%;
  transform-origin: center;
  z-index: 9999999999999;
  animation-name: rotate-ring-cap-3;
  animation-delay: 0.4s;
  animation-timing-function: ease-in-out;
  animation-duration: 0.6s;
  animation-fill-mode: forwards;
  transform: translate(-50%, -50%) rotate(-90deg) translate(calc(calc( 180px - (calc(2 * 10px * 2) + calc( 2 * 6px * 2 )) ) / 2 - 10px / 2));
}
@keyframes rotate-ring-cap-3 {
  23% {
    transform: translate(-50%, -50%) rotate(calc(var(--angle) * (23 / 100) - 90deg)) translate(calc(calc( 180px - (calc(2 * 10px * 2) + calc( 2 * 6px * 2 )) ) / 2 - 10px / 2));
  }
}
.dial-ring:nth-child(5) .dial-ring-cap-end {
  left: 50%;
  top: 50%;
  transform-origin: center;
  z-index: 9999999999999;
  animation-name: rotate-ring-cap-3;
  animation-delay: 0.4s;
  animation-timing-function: ease-in-out;
  animation-duration: 0.6s;
  animation-fill-mode: forwards;
  transform: translate(-50%, -50%) rotate(-90deg) translate(calc(calc( 180px - (calc(2 * 10px * 2) + calc( 2 * 6px * 2 )) ) / 2 - 10px / 2));
}
@keyframes rotate-ring-cap-3 {
  24% {
    transform: translate(-50%, -50%) rotate(calc(var(--angle) * (24 / 100) - 90deg)) translate(calc(calc( 180px - (calc(2 * 10px * 2) + calc( 2 * 6px * 2 )) ) / 2 - 10px / 2));
  }
}
.dial-ring:nth-child(5) .dial-ring-cap-end {
  left: 50%;
  top: 50%;
  transform-origin: center;
  z-index: 9999999999999;
  animation-name: rotate-ring-cap-3;
  animation-delay: 0.4s;
  animation-timing-function: ease-in-out;
  animation-duration: 0.6s;
  animation-fill-mode: forwards;
  transform: translate(-50%, -50%) rotate(-90deg) translate(calc(calc( 180px - (calc(2 * 10px * 2) + calc( 2 * 6px * 2 )) ) / 2 - 10px / 2));
}
@keyframes rotate-ring-cap-3 {
  25% {
    transform: translate(-50%, -50%) rotate(calc(var(--angle) * (25 / 100) - 90deg)) translate(calc(calc( 180px - (calc(2 * 10px * 2) + calc( 2 * 6px * 2 )) ) / 2 - 10px / 2));
  }
}
.dial-ring:nth-child(5) .dial-ring-cap-end {
  left: 50%;
  top: 50%;
  transform-origin: center;
  z-index: 9999999999999;
  animation-name: rotate-ring-cap-3;
  animation-delay: 0.4s;
  animation-timing-function: ease-in-out;
  animation-duration: 0.6s;
  animation-fill-mode: forwards;
  transform: translate(-50%, -50%) rotate(-90deg) translate(calc(calc( 180px - (calc(2 * 10px * 2) + calc( 2 * 6px * 2 )) ) / 2 - 10px / 2));
}
@keyframes rotate-ring-cap-3 {
  26% {
    transform: translate(-50%, -50%) rotate(calc(var(--angle) * (26 / 100) - 90deg)) translate(calc(calc( 180px - (calc(2 * 10px * 2) + calc( 2 * 6px * 2 )) ) / 2 - 10px / 2));
  }
}
.dial-ring:nth-child(5) .dial-ring-cap-end {
  left: 50%;
  top: 50%;
  transform-origin: center;
  z-index: 9999999999999;
  animation-name: rotate-ring-cap-3;
  animation-delay: 0.4s;
  animation-timing-function: ease-in-out;
  animation-duration: 0.6s;
  animation-fill-mode: forwards;
  transform: translate(-50%, -50%) rotate(-90deg) translate(calc(calc( 180px - (calc(2 * 10px * 2) + calc( 2 * 6px * 2 )) ) / 2 - 10px / 2));
}
@keyframes rotate-ring-cap-3 {
  27% {
    transform: translate(-50%, -50%) rotate(calc(var(--angle) * (27 / 100) - 90deg)) translate(calc(calc( 180px - (calc(2 * 10px * 2) + calc( 2 * 6px * 2 )) ) / 2 - 10px / 2));
  }
}
.dial-ring:nth-child(5) .dial-ring-cap-end {
  left: 50%;
  top: 50%;
  transform-origin: center;
  z-index: 9999999999999;
  animation-name: rotate-ring-cap-3;
  animation-delay: 0.4s;
  animation-timing-function: ease-in-out;
  animation-duration: 0.6s;
  animation-fill-mode: forwards;
  transform: translate(-50%, -50%) rotate(-90deg) translate(calc(calc( 180px - (calc(2 * 10px * 2) + calc( 2 * 6px * 2 )) ) / 2 - 10px / 2));
}
@keyframes rotate-ring-cap-3 {
  28% {
    transform: translate(-50%, -50%) rotate(calc(var(--angle) * (28 / 100) - 90deg)) translate(calc(calc( 180px - (calc(2 * 10px * 2) + calc( 2 * 6px * 2 )) ) / 2 - 10px / 2));
  }
}
.dial-ring:nth-child(5) .dial-ring-cap-end {
  left: 50%;
  top: 50%;
  transform-origin: center;
  z-index: 9999999999999;
  animation-name: rotate-ring-cap-3;
  animation-delay: 0.4s;
  animation-timing-function: ease-in-out;
  animation-duration: 0.6s;
  animation-fill-mode: forwards;
  transform: translate(-50%, -50%) rotate(-90deg) translate(calc(calc( 180px - (calc(2 * 10px * 2) + calc( 2 * 6px * 2 )) ) / 2 - 10px / 2));
}
@keyframes rotate-ring-cap-3 {
  29% {
    transform: translate(-50%, -50%) rotate(calc(var(--angle) * (29 / 100) - 90deg)) translate(calc(calc( 180px - (calc(2 * 10px * 2) + calc( 2 * 6px * 2 )) ) / 2 - 10px / 2));
  }
}
.dial-ring:nth-child(5) .dial-ring-cap-end {
  left: 50%;
  top: 50%;
  transform-origin: center;
  z-index: 9999999999999;
  animation-name: rotate-ring-cap-3;
  animation-delay: 0.4s;
  animation-timing-function: ease-in-out;
  animation-duration: 0.6s;
  animation-fill-mode: forwards;
  transform: translate(-50%, -50%) rotate(-90deg) translate(calc(calc( 180px - (calc(2 * 10px * 2) + calc( 2 * 6px * 2 )) ) / 2 - 10px / 2));
}
@keyframes rotate-ring-cap-3 {
  30% {
    transform: translate(-50%, -50%) rotate(calc(var(--angle) * (30 / 100) - 90deg)) translate(calc(calc( 180px - (calc(2 * 10px * 2) + calc( 2 * 6px * 2 )) ) / 2 - 10px / 2));
  }
}
.dial-ring:nth-child(5) .dial-ring-cap-end {
  left: 50%;
  top: 50%;
  transform-origin: center;
  z-index: 9999999999999;
  animation-name: rotate-ring-cap-3;
  animation-delay: 0.4s;
  animation-timing-function: ease-in-out;
  animation-duration: 0.6s;
  animation-fill-mode: forwards;
  transform: translate(-50%, -50%) rotate(-90deg) translate(calc(calc( 180px - (calc(2 * 10px * 2) + calc( 2 * 6px * 2 )) ) / 2 - 10px / 2));
}
@keyframes rotate-ring-cap-3 {
  31% {
    transform: translate(-50%, -50%) rotate(calc(var(--angle) * (31 / 100) - 90deg)) translate(calc(calc( 180px - (calc(2 * 10px * 2) + calc( 2 * 6px * 2 )) ) / 2 - 10px / 2));
  }
}
.dial-ring:nth-child(5) .dial-ring-cap-end {
  left: 50%;
  top: 50%;
  transform-origin: center;
  z-index: 9999999999999;
  animation-name: rotate-ring-cap-3;
  animation-delay: 0.4s;
  animation-timing-function: ease-in-out;
  animation-duration: 0.6s;
  animation-fill-mode: forwards;
  transform: translate(-50%, -50%) rotate(-90deg) translate(calc(calc( 180px - (calc(2 * 10px * 2) + calc( 2 * 6px * 2 )) ) / 2 - 10px / 2));
}
@keyframes rotate-ring-cap-3 {
  32% {
    transform: translate(-50%, -50%) rotate(calc(var(--angle) * (32 / 100) - 90deg)) translate(calc(calc( 180px - (calc(2 * 10px * 2) + calc( 2 * 6px * 2 )) ) / 2 - 10px / 2));
  }
}
.dial-ring:nth-child(5) .dial-ring-cap-end {
  left: 50%;
  top: 50%;
  transform-origin: center;
  z-index: 9999999999999;
  animation-name: rotate-ring-cap-3;
  animation-delay: 0.4s;
  animation-timing-function: ease-in-out;
  animation-duration: 0.6s;
  animation-fill-mode: forwards;
  transform: translate(-50%, -50%) rotate(-90deg) translate(calc(calc( 180px - (calc(2 * 10px * 2) + calc( 2 * 6px * 2 )) ) / 2 - 10px / 2));
}
@keyframes rotate-ring-cap-3 {
  33% {
    transform: translate(-50%, -50%) rotate(calc(var(--angle) * (33 / 100) - 90deg)) translate(calc(calc( 180px - (calc(2 * 10px * 2) + calc( 2 * 6px * 2 )) ) / 2 - 10px / 2));
  }
}
.dial-ring:nth-child(5) .dial-ring-cap-end {
  left: 50%;
  top: 50%;
  transform-origin: center;
  z-index: 9999999999999;
  animation-name: rotate-ring-cap-3;
  animation-delay: 0.4s;
  animation-timing-function: ease-in-out;
  animation-duration: 0.6s;
  animation-fill-mode: forwards;
  transform: translate(-50%, -50%) rotate(-90deg) translate(calc(calc( 180px - (calc(2 * 10px * 2) + calc( 2 * 6px * 2 )) ) / 2 - 10px / 2));
}
@keyframes rotate-ring-cap-3 {
  34% {
    transform: translate(-50%, -50%) rotate(calc(var(--angle) * (34 / 100) - 90deg)) translate(calc(calc( 180px - (calc(2 * 10px * 2) + calc( 2 * 6px * 2 )) ) / 2 - 10px / 2));
  }
}
.dial-ring:nth-child(5) .dial-ring-cap-end {
  left: 50%;
  top: 50%;
  transform-origin: center;
  z-index: 9999999999999;
  animation-name: rotate-ring-cap-3;
  animation-delay: 0.4s;
  animation-timing-function: ease-in-out;
  animation-duration: 0.6s;
  animation-fill-mode: forwards;
  transform: translate(-50%, -50%) rotate(-90deg) translate(calc(calc( 180px - (calc(2 * 10px * 2) + calc( 2 * 6px * 2 )) ) / 2 - 10px / 2));
}
@keyframes rotate-ring-cap-3 {
  35% {
    transform: translate(-50%, -50%) rotate(calc(var(--angle) * (35 / 100) - 90deg)) translate(calc(calc( 180px - (calc(2 * 10px * 2) + calc( 2 * 6px * 2 )) ) / 2 - 10px / 2));
  }
}
.dial-ring:nth-child(5) .dial-ring-cap-end {
  left: 50%;
  top: 50%;
  transform-origin: center;
  z-index: 9999999999999;
  animation-name: rotate-ring-cap-3;
  animation-delay: 0.4s;
  animation-timing-function: ease-in-out;
  animation-duration: 0.6s;
  animation-fill-mode: forwards;
  transform: translate(-50%, -50%) rotate(-90deg) translate(calc(calc( 180px - (calc(2 * 10px * 2) + calc( 2 * 6px * 2 )) ) / 2 - 10px / 2));
}
@keyframes rotate-ring-cap-3 {
  36% {
    transform: translate(-50%, -50%) rotate(calc(var(--angle) * (36 / 100) - 90deg)) translate(calc(calc( 180px - (calc(2 * 10px * 2) + calc( 2 * 6px * 2 )) ) / 2 - 10px / 2));
  }
}
.dial-ring:nth-child(5) .dial-ring-cap-end {
  left: 50%;
  top: 50%;
  transform-origin: center;
  z-index: 9999999999999;
  animation-name: rotate-ring-cap-3;
  animation-delay: 0.4s;
  animation-timing-function: ease-in-out;
  animation-duration: 0.6s;
  animation-fill-mode: forwards;
  transform: translate(-50%, -50%) rotate(-90deg) translate(calc(calc( 180px - (calc(2 * 10px * 2) + calc( 2 * 6px * 2 )) ) / 2 - 10px / 2));
}
@keyframes rotate-ring-cap-3 {
  37% {
    transform: translate(-50%, -50%) rotate(calc(var(--angle) * (37 / 100) - 90deg)) translate(calc(calc( 180px - (calc(2 * 10px * 2) + calc( 2 * 6px * 2 )) ) / 2 - 10px / 2));
  }
}
.dial-ring:nth-child(5) .dial-ring-cap-end {
  left: 50%;
  top: 50%;
  transform-origin: center;
  z-index: 9999999999999;
  animation-name: rotate-ring-cap-3;
  animation-delay: 0.4s;
  animation-timing-function: ease-in-out;
  animation-duration: 0.6s;
  animation-fill-mode: forwards;
  transform: translate(-50%, -50%) rotate(-90deg) translate(calc(calc( 180px - (calc(2 * 10px * 2) + calc( 2 * 6px * 2 )) ) / 2 - 10px / 2));
}
@keyframes rotate-ring-cap-3 {
  38% {
    transform: translate(-50%, -50%) rotate(calc(var(--angle) * (38 / 100) - 90deg)) translate(calc(calc( 180px - (calc(2 * 10px * 2) + calc( 2 * 6px * 2 )) ) / 2 - 10px / 2));
  }
}
.dial-ring:nth-child(5) .dial-ring-cap-end {
  left: 50%;
  top: 50%;
  transform-origin: center;
  z-index: 9999999999999;
  animation-name: rotate-ring-cap-3;
  animation-delay: 0.4s;
  animation-timing-function: ease-in-out;
  animation-duration: 0.6s;
  animation-fill-mode: forwards;
  transform: translate(-50%, -50%) rotate(-90deg) translate(calc(calc( 180px - (calc(2 * 10px * 2) + calc( 2 * 6px * 2 )) ) / 2 - 10px / 2));
}
@keyframes rotate-ring-cap-3 {
  39% {
    transform: translate(-50%, -50%) rotate(calc(var(--angle) * (39 / 100) - 90deg)) translate(calc(calc( 180px - (calc(2 * 10px * 2) + calc( 2 * 6px * 2 )) ) / 2 - 10px / 2));
  }
}
.dial-ring:nth-child(5) .dial-ring-cap-end {
  left: 50%;
  top: 50%;
  transform-origin: center;
  z-index: 9999999999999;
  animation-name: rotate-ring-cap-3;
  animation-delay: 0.4s;
  animation-timing-function: ease-in-out;
  animation-duration: 0.6s;
  animation-fill-mode: forwards;
  transform: translate(-50%, -50%) rotate(-90deg) translate(calc(calc( 180px - (calc(2 * 10px * 2) + calc( 2 * 6px * 2 )) ) / 2 - 10px / 2));
}
@keyframes rotate-ring-cap-3 {
  40% {
    transform: translate(-50%, -50%) rotate(calc(var(--angle) * (40 / 100) - 90deg)) translate(calc(calc( 180px - (calc(2 * 10px * 2) + calc( 2 * 6px * 2 )) ) / 2 - 10px / 2));
  }
}
.dial-ring:nth-child(5) .dial-ring-cap-end {
  left: 50%;
  top: 50%;
  transform-origin: center;
  z-index: 9999999999999;
  animation-name: rotate-ring-cap-3;
  animation-delay: 0.4s;
  animation-timing-function: ease-in-out;
  animation-duration: 0.6s;
  animation-fill-mode: forwards;
  transform: translate(-50%, -50%) rotate(-90deg) translate(calc(calc( 180px - (calc(2 * 10px * 2) + calc( 2 * 6px * 2 )) ) / 2 - 10px / 2));
}
@keyframes rotate-ring-cap-3 {
  41% {
    transform: translate(-50%, -50%) rotate(calc(var(--angle) * (41 / 100) - 90deg)) translate(calc(calc( 180px - (calc(2 * 10px * 2) + calc( 2 * 6px * 2 )) ) / 2 - 10px / 2));
  }
}
.dial-ring:nth-child(5) .dial-ring-cap-end {
  left: 50%;
  top: 50%;
  transform-origin: center;
  z-index: 9999999999999;
  animation-name: rotate-ring-cap-3;
  animation-delay: 0.4s;
  animation-timing-function: ease-in-out;
  animation-duration: 0.6s;
  animation-fill-mode: forwards;
  transform: translate(-50%, -50%) rotate(-90deg) translate(calc(calc( 180px - (calc(2 * 10px * 2) + calc( 2 * 6px * 2 )) ) / 2 - 10px / 2));
}
@keyframes rotate-ring-cap-3 {
  42% {
    transform: translate(-50%, -50%) rotate(calc(var(--angle) * (42 / 100) - 90deg)) translate(calc(calc( 180px - (calc(2 * 10px * 2) + calc( 2 * 6px * 2 )) ) / 2 - 10px / 2));
  }
}
.dial-ring:nth-child(5) .dial-ring-cap-end {
  left: 50%;
  top: 50%;
  transform-origin: center;
  z-index: 9999999999999;
  animation-name: rotate-ring-cap-3;
  animation-delay: 0.4s;
  animation-timing-function: ease-in-out;
  animation-duration: 0.6s;
  animation-fill-mode: forwards;
  transform: translate(-50%, -50%) rotate(-90deg) translate(calc(calc( 180px - (calc(2 * 10px * 2) + calc( 2 * 6px * 2 )) ) / 2 - 10px / 2));
}
@keyframes rotate-ring-cap-3 {
  43% {
    transform: translate(-50%, -50%) rotate(calc(var(--angle) * (43 / 100) - 90deg)) translate(calc(calc( 180px - (calc(2 * 10px * 2) + calc( 2 * 6px * 2 )) ) / 2 - 10px / 2));
  }
}
.dial-ring:nth-child(5) .dial-ring-cap-end {
  left: 50%;
  top: 50%;
  transform-origin: center;
  z-index: 9999999999999;
  animation-name: rotate-ring-cap-3;
  animation-delay: 0.4s;
  animation-timing-function: ease-in-out;
  animation-duration: 0.6s;
  animation-fill-mode: forwards;
  transform: translate(-50%, -50%) rotate(-90deg) translate(calc(calc( 180px - (calc(2 * 10px * 2) + calc( 2 * 6px * 2 )) ) / 2 - 10px / 2));
}
@keyframes rotate-ring-cap-3 {
  44% {
    transform: translate(-50%, -50%) rotate(calc(var(--angle) * (44 / 100) - 90deg)) translate(calc(calc( 180px - (calc(2 * 10px * 2) + calc( 2 * 6px * 2 )) ) / 2 - 10px / 2));
  }
}
.dial-ring:nth-child(5) .dial-ring-cap-end {
  left: 50%;
  top: 50%;
  transform-origin: center;
  z-index: 9999999999999;
  animation-name: rotate-ring-cap-3;
  animation-delay: 0.4s;
  animation-timing-function: ease-in-out;
  animation-duration: 0.6s;
  animation-fill-mode: forwards;
  transform: translate(-50%, -50%) rotate(-90deg) translate(calc(calc( 180px - (calc(2 * 10px * 2) + calc( 2 * 6px * 2 )) ) / 2 - 10px / 2));
}
@keyframes rotate-ring-cap-3 {
  45% {
    transform: translate(-50%, -50%) rotate(calc(var(--angle) * (45 / 100) - 90deg)) translate(calc(calc( 180px - (calc(2 * 10px * 2) + calc( 2 * 6px * 2 )) ) / 2 - 10px / 2));
  }
}
.dial-ring:nth-child(5) .dial-ring-cap-end {
  left: 50%;
  top: 50%;
  transform-origin: center;
  z-index: 9999999999999;
  animation-name: rotate-ring-cap-3;
  animation-delay: 0.4s;
  animation-timing-function: ease-in-out;
  animation-duration: 0.6s;
  animation-fill-mode: forwards;
  transform: translate(-50%, -50%) rotate(-90deg) translate(calc(calc( 180px - (calc(2 * 10px * 2) + calc( 2 * 6px * 2 )) ) / 2 - 10px / 2));
}
@keyframes rotate-ring-cap-3 {
  46% {
    transform: translate(-50%, -50%) rotate(calc(var(--angle) * (46 / 100) - 90deg)) translate(calc(calc( 180px - (calc(2 * 10px * 2) + calc( 2 * 6px * 2 )) ) / 2 - 10px / 2));
  }
}
.dial-ring:nth-child(5) .dial-ring-cap-end {
  left: 50%;
  top: 50%;
  transform-origin: center;
  z-index: 9999999999999;
  animation-name: rotate-ring-cap-3;
  animation-delay: 0.4s;
  animation-timing-function: ease-in-out;
  animation-duration: 0.6s;
  animation-fill-mode: forwards;
  transform: translate(-50%, -50%) rotate(-90deg) translate(calc(calc( 180px - (calc(2 * 10px * 2) + calc( 2 * 6px * 2 )) ) / 2 - 10px / 2));
}
@keyframes rotate-ring-cap-3 {
  47% {
    transform: translate(-50%, -50%) rotate(calc(var(--angle) * (47 / 100) - 90deg)) translate(calc(calc( 180px - (calc(2 * 10px * 2) + calc( 2 * 6px * 2 )) ) / 2 - 10px / 2));
  }
}
.dial-ring:nth-child(5) .dial-ring-cap-end {
  left: 50%;
  top: 50%;
  transform-origin: center;
  z-index: 9999999999999;
  animation-name: rotate-ring-cap-3;
  animation-delay: 0.4s;
  animation-timing-function: ease-in-out;
  animation-duration: 0.6s;
  animation-fill-mode: forwards;
  transform: translate(-50%, -50%) rotate(-90deg) translate(calc(calc( 180px - (calc(2 * 10px * 2) + calc( 2 * 6px * 2 )) ) / 2 - 10px / 2));
}
@keyframes rotate-ring-cap-3 {
  48% {
    transform: translate(-50%, -50%) rotate(calc(var(--angle) * (48 / 100) - 90deg)) translate(calc(calc( 180px - (calc(2 * 10px * 2) + calc( 2 * 6px * 2 )) ) / 2 - 10px / 2));
  }
}
.dial-ring:nth-child(5) .dial-ring-cap-end {
  left: 50%;
  top: 50%;
  transform-origin: center;
  z-index: 9999999999999;
  animation-name: rotate-ring-cap-3;
  animation-delay: 0.4s;
  animation-timing-function: ease-in-out;
  animation-duration: 0.6s;
  animation-fill-mode: forwards;
  transform: translate(-50%, -50%) rotate(-90deg) translate(calc(calc( 180px - (calc(2 * 10px * 2) + calc( 2 * 6px * 2 )) ) / 2 - 10px / 2));
}
@keyframes rotate-ring-cap-3 {
  49% {
    transform: translate(-50%, -50%) rotate(calc(var(--angle) * (49 / 100) - 90deg)) translate(calc(calc( 180px - (calc(2 * 10px * 2) + calc( 2 * 6px * 2 )) ) / 2 - 10px / 2));
  }
}
.dial-ring:nth-child(5) .dial-ring-cap-end {
  left: 50%;
  top: 50%;
  transform-origin: center;
  z-index: 9999999999999;
  animation-name: rotate-ring-cap-3;
  animation-delay: 0.4s;
  animation-timing-function: ease-in-out;
  animation-duration: 0.6s;
  animation-fill-mode: forwards;
  transform: translate(-50%, -50%) rotate(-90deg) translate(calc(calc( 180px - (calc(2 * 10px * 2) + calc( 2 * 6px * 2 )) ) / 2 - 10px / 2));
}
@keyframes rotate-ring-cap-3 {
  50% {
    transform: translate(-50%, -50%) rotate(calc(var(--angle) * (50 / 100) - 90deg)) translate(calc(calc( 180px - (calc(2 * 10px * 2) + calc( 2 * 6px * 2 )) ) / 2 - 10px / 2));
  }
}
.dial-ring:nth-child(5) .dial-ring-cap-end {
  left: 50%;
  top: 50%;
  transform-origin: center;
  z-index: 9999999999999;
  animation-name: rotate-ring-cap-3;
  animation-delay: 0.4s;
  animation-timing-function: ease-in-out;
  animation-duration: 0.6s;
  animation-fill-mode: forwards;
  transform: translate(-50%, -50%) rotate(-90deg) translate(calc(calc( 180px - (calc(2 * 10px * 2) + calc( 2 * 6px * 2 )) ) / 2 - 10px / 2));
}
@keyframes rotate-ring-cap-3 {
  51% {
    transform: translate(-50%, -50%) rotate(calc(var(--angle) * (51 / 100) - 90deg)) translate(calc(calc( 180px - (calc(2 * 10px * 2) + calc( 2 * 6px * 2 )) ) / 2 - 10px / 2));
  }
}
.dial-ring:nth-child(5) .dial-ring-cap-end {
  left: 50%;
  top: 50%;
  transform-origin: center;
  z-index: 9999999999999;
  animation-name: rotate-ring-cap-3;
  animation-delay: 0.4s;
  animation-timing-function: ease-in-out;
  animation-duration: 0.6s;
  animation-fill-mode: forwards;
  transform: translate(-50%, -50%) rotate(-90deg) translate(calc(calc( 180px - (calc(2 * 10px * 2) + calc( 2 * 6px * 2 )) ) / 2 - 10px / 2));
}
@keyframes rotate-ring-cap-3 {
  52% {
    transform: translate(-50%, -50%) rotate(calc(var(--angle) * (52 / 100) - 90deg)) translate(calc(calc( 180px - (calc(2 * 10px * 2) + calc( 2 * 6px * 2 )) ) / 2 - 10px / 2));
  }
}
.dial-ring:nth-child(5) .dial-ring-cap-end {
  left: 50%;
  top: 50%;
  transform-origin: center;
  z-index: 9999999999999;
  animation-name: rotate-ring-cap-3;
  animation-delay: 0.4s;
  animation-timing-function: ease-in-out;
  animation-duration: 0.6s;
  animation-fill-mode: forwards;
  transform: translate(-50%, -50%) rotate(-90deg) translate(calc(calc( 180px - (calc(2 * 10px * 2) + calc( 2 * 6px * 2 )) ) / 2 - 10px / 2));
}
@keyframes rotate-ring-cap-3 {
  53% {
    transform: translate(-50%, -50%) rotate(calc(var(--angle) * (53 / 100) - 90deg)) translate(calc(calc( 180px - (calc(2 * 10px * 2) + calc( 2 * 6px * 2 )) ) / 2 - 10px / 2));
  }
}
.dial-ring:nth-child(5) .dial-ring-cap-end {
  left: 50%;
  top: 50%;
  transform-origin: center;
  z-index: 9999999999999;
  animation-name: rotate-ring-cap-3;
  animation-delay: 0.4s;
  animation-timing-function: ease-in-out;
  animation-duration: 0.6s;
  animation-fill-mode: forwards;
  transform: translate(-50%, -50%) rotate(-90deg) translate(calc(calc( 180px - (calc(2 * 10px * 2) + calc( 2 * 6px * 2 )) ) / 2 - 10px / 2));
}
@keyframes rotate-ring-cap-3 {
  54% {
    transform: translate(-50%, -50%) rotate(calc(var(--angle) * (54 / 100) - 90deg)) translate(calc(calc( 180px - (calc(2 * 10px * 2) + calc( 2 * 6px * 2 )) ) / 2 - 10px / 2));
  }
}
.dial-ring:nth-child(5) .dial-ring-cap-end {
  left: 50%;
  top: 50%;
  transform-origin: center;
  z-index: 9999999999999;
  animation-name: rotate-ring-cap-3;
  animation-delay: 0.4s;
  animation-timing-function: ease-in-out;
  animation-duration: 0.6s;
  animation-fill-mode: forwards;
  transform: translate(-50%, -50%) rotate(-90deg) translate(calc(calc( 180px - (calc(2 * 10px * 2) + calc( 2 * 6px * 2 )) ) / 2 - 10px / 2));
}
@keyframes rotate-ring-cap-3 {
  55% {
    transform: translate(-50%, -50%) rotate(calc(var(--angle) * (55 / 100) - 90deg)) translate(calc(calc( 180px - (calc(2 * 10px * 2) + calc( 2 * 6px * 2 )) ) / 2 - 10px / 2));
  }
}
.dial-ring:nth-child(5) .dial-ring-cap-end {
  left: 50%;
  top: 50%;
  transform-origin: center;
  z-index: 9999999999999;
  animation-name: rotate-ring-cap-3;
  animation-delay: 0.4s;
  animation-timing-function: ease-in-out;
  animation-duration: 0.6s;
  animation-fill-mode: forwards;
  transform: translate(-50%, -50%) rotate(-90deg) translate(calc(calc( 180px - (calc(2 * 10px * 2) + calc( 2 * 6px * 2 )) ) / 2 - 10px / 2));
}
@keyframes rotate-ring-cap-3 {
  56% {
    transform: translate(-50%, -50%) rotate(calc(var(--angle) * (56 / 100) - 90deg)) translate(calc(calc( 180px - (calc(2 * 10px * 2) + calc( 2 * 6px * 2 )) ) / 2 - 10px / 2));
  }
}
.dial-ring:nth-child(5) .dial-ring-cap-end {
  left: 50%;
  top: 50%;
  transform-origin: center;
  z-index: 9999999999999;
  animation-name: rotate-ring-cap-3;
  animation-delay: 0.4s;
  animation-timing-function: ease-in-out;
  animation-duration: 0.6s;
  animation-fill-mode: forwards;
  transform: translate(-50%, -50%) rotate(-90deg) translate(calc(calc( 180px - (calc(2 * 10px * 2) + calc( 2 * 6px * 2 )) ) / 2 - 10px / 2));
}
@keyframes rotate-ring-cap-3 {
  57% {
    transform: translate(-50%, -50%) rotate(calc(var(--angle) * (57 / 100) - 90deg)) translate(calc(calc( 180px - (calc(2 * 10px * 2) + calc( 2 * 6px * 2 )) ) / 2 - 10px / 2));
  }
}
.dial-ring:nth-child(5) .dial-ring-cap-end {
  left: 50%;
  top: 50%;
  transform-origin: center;
  z-index: 9999999999999;
  animation-name: rotate-ring-cap-3;
  animation-delay: 0.4s;
  animation-timing-function: ease-in-out;
  animation-duration: 0.6s;
  animation-fill-mode: forwards;
  transform: translate(-50%, -50%) rotate(-90deg) translate(calc(calc( 180px - (calc(2 * 10px * 2) + calc( 2 * 6px * 2 )) ) / 2 - 10px / 2));
}
@keyframes rotate-ring-cap-3 {
  58% {
    transform: translate(-50%, -50%) rotate(calc(var(--angle) * (58 / 100) - 90deg)) translate(calc(calc( 180px - (calc(2 * 10px * 2) + calc( 2 * 6px * 2 )) ) / 2 - 10px / 2));
  }
}
.dial-ring:nth-child(5) .dial-ring-cap-end {
  left: 50%;
  top: 50%;
  transform-origin: center;
  z-index: 9999999999999;
  animation-name: rotate-ring-cap-3;
  animation-delay: 0.4s;
  animation-timing-function: ease-in-out;
  animation-duration: 0.6s;
  animation-fill-mode: forwards;
  transform: translate(-50%, -50%) rotate(-90deg) translate(calc(calc( 180px - (calc(2 * 10px * 2) + calc( 2 * 6px * 2 )) ) / 2 - 10px / 2));
}
@keyframes rotate-ring-cap-3 {
  59% {
    transform: translate(-50%, -50%) rotate(calc(var(--angle) * (59 / 100) - 90deg)) translate(calc(calc( 180px - (calc(2 * 10px * 2) + calc( 2 * 6px * 2 )) ) / 2 - 10px / 2));
  }
}
.dial-ring:nth-child(5) .dial-ring-cap-end {
  left: 50%;
  top: 50%;
  transform-origin: center;
  z-index: 9999999999999;
  animation-name: rotate-ring-cap-3;
  animation-delay: 0.4s;
  animation-timing-function: ease-in-out;
  animation-duration: 0.6s;
  animation-fill-mode: forwards;
  transform: translate(-50%, -50%) rotate(-90deg) translate(calc(calc( 180px - (calc(2 * 10px * 2) + calc( 2 * 6px * 2 )) ) / 2 - 10px / 2));
}
@keyframes rotate-ring-cap-3 {
  60% {
    transform: translate(-50%, -50%) rotate(calc(var(--angle) * (60 / 100) - 90deg)) translate(calc(calc( 180px - (calc(2 * 10px * 2) + calc( 2 * 6px * 2 )) ) / 2 - 10px / 2));
  }
}
.dial-ring:nth-child(5) .dial-ring-cap-end {
  left: 50%;
  top: 50%;
  transform-origin: center;
  z-index: 9999999999999;
  animation-name: rotate-ring-cap-3;
  animation-delay: 0.4s;
  animation-timing-function: ease-in-out;
  animation-duration: 0.6s;
  animation-fill-mode: forwards;
  transform: translate(-50%, -50%) rotate(-90deg) translate(calc(calc( 180px - (calc(2 * 10px * 2) + calc( 2 * 6px * 2 )) ) / 2 - 10px / 2));
}
@keyframes rotate-ring-cap-3 {
  61% {
    transform: translate(-50%, -50%) rotate(calc(var(--angle) * (61 / 100) - 90deg)) translate(calc(calc( 180px - (calc(2 * 10px * 2) + calc( 2 * 6px * 2 )) ) / 2 - 10px / 2));
  }
}
.dial-ring:nth-child(5) .dial-ring-cap-end {
  left: 50%;
  top: 50%;
  transform-origin: center;
  z-index: 9999999999999;
  animation-name: rotate-ring-cap-3;
  animation-delay: 0.4s;
  animation-timing-function: ease-in-out;
  animation-duration: 0.6s;
  animation-fill-mode: forwards;
  transform: translate(-50%, -50%) rotate(-90deg) translate(calc(calc( 180px - (calc(2 * 10px * 2) + calc( 2 * 6px * 2 )) ) / 2 - 10px / 2));
}
@keyframes rotate-ring-cap-3 {
  62% {
    transform: translate(-50%, -50%) rotate(calc(var(--angle) * (62 / 100) - 90deg)) translate(calc(calc( 180px - (calc(2 * 10px * 2) + calc( 2 * 6px * 2 )) ) / 2 - 10px / 2));
  }
}
.dial-ring:nth-child(5) .dial-ring-cap-end {
  left: 50%;
  top: 50%;
  transform-origin: center;
  z-index: 9999999999999;
  animation-name: rotate-ring-cap-3;
  animation-delay: 0.4s;
  animation-timing-function: ease-in-out;
  animation-duration: 0.6s;
  animation-fill-mode: forwards;
  transform: translate(-50%, -50%) rotate(-90deg) translate(calc(calc( 180px - (calc(2 * 10px * 2) + calc( 2 * 6px * 2 )) ) / 2 - 10px / 2));
}
@keyframes rotate-ring-cap-3 {
  63% {
    transform: translate(-50%, -50%) rotate(calc(var(--angle) * (63 / 100) - 90deg)) translate(calc(calc( 180px - (calc(2 * 10px * 2) + calc( 2 * 6px * 2 )) ) / 2 - 10px / 2));
  }
}
.dial-ring:nth-child(5) .dial-ring-cap-end {
  left: 50%;
  top: 50%;
  transform-origin: center;
  z-index: 9999999999999;
  animation-name: rotate-ring-cap-3;
  animation-delay: 0.4s;
  animation-timing-function: ease-in-out;
  animation-duration: 0.6s;
  animation-fill-mode: forwards;
  transform: translate(-50%, -50%) rotate(-90deg) translate(calc(calc( 180px - (calc(2 * 10px * 2) + calc( 2 * 6px * 2 )) ) / 2 - 10px / 2));
}
@keyframes rotate-ring-cap-3 {
  64% {
    transform: translate(-50%, -50%) rotate(calc(var(--angle) * (64 / 100) - 90deg)) translate(calc(calc( 180px - (calc(2 * 10px * 2) + calc( 2 * 6px * 2 )) ) / 2 - 10px / 2));
  }
}
.dial-ring:nth-child(5) .dial-ring-cap-end {
  left: 50%;
  top: 50%;
  transform-origin: center;
  z-index: 9999999999999;
  animation-name: rotate-ring-cap-3;
  animation-delay: 0.4s;
  animation-timing-function: ease-in-out;
  animation-duration: 0.6s;
  animation-fill-mode: forwards;
  transform: translate(-50%, -50%) rotate(-90deg) translate(calc(calc( 180px - (calc(2 * 10px * 2) + calc( 2 * 6px * 2 )) ) / 2 - 10px / 2));
}
@keyframes rotate-ring-cap-3 {
  65% {
    transform: translate(-50%, -50%) rotate(calc(var(--angle) * (65 / 100) - 90deg)) translate(calc(calc( 180px - (calc(2 * 10px * 2) + calc( 2 * 6px * 2 )) ) / 2 - 10px / 2));
  }
}
.dial-ring:nth-child(5) .dial-ring-cap-end {
  left: 50%;
  top: 50%;
  transform-origin: center;
  z-index: 9999999999999;
  animation-name: rotate-ring-cap-3;
  animation-delay: 0.4s;
  animation-timing-function: ease-in-out;
  animation-duration: 0.6s;
  animation-fill-mode: forwards;
  transform: translate(-50%, -50%) rotate(-90deg) translate(calc(calc( 180px - (calc(2 * 10px * 2) + calc( 2 * 6px * 2 )) ) / 2 - 10px / 2));
}
@keyframes rotate-ring-cap-3 {
  66% {
    transform: translate(-50%, -50%) rotate(calc(var(--angle) * (66 / 100) - 90deg)) translate(calc(calc( 180px - (calc(2 * 10px * 2) + calc( 2 * 6px * 2 )) ) / 2 - 10px / 2));
  }
}
.dial-ring:nth-child(5) .dial-ring-cap-end {
  left: 50%;
  top: 50%;
  transform-origin: center;
  z-index: 9999999999999;
  animation-name: rotate-ring-cap-3;
  animation-delay: 0.4s;
  animation-timing-function: ease-in-out;
  animation-duration: 0.6s;
  animation-fill-mode: forwards;
  transform: translate(-50%, -50%) rotate(-90deg) translate(calc(calc( 180px - (calc(2 * 10px * 2) + calc( 2 * 6px * 2 )) ) / 2 - 10px / 2));
}
@keyframes rotate-ring-cap-3 {
  67% {
    transform: translate(-50%, -50%) rotate(calc(var(--angle) * (67 / 100) - 90deg)) translate(calc(calc( 180px - (calc(2 * 10px * 2) + calc( 2 * 6px * 2 )) ) / 2 - 10px / 2));
  }
}
.dial-ring:nth-child(5) .dial-ring-cap-end {
  left: 50%;
  top: 50%;
  transform-origin: center;
  z-index: 9999999999999;
  animation-name: rotate-ring-cap-3;
  animation-delay: 0.4s;
  animation-timing-function: ease-in-out;
  animation-duration: 0.6s;
  animation-fill-mode: forwards;
  transform: translate(-50%, -50%) rotate(-90deg) translate(calc(calc( 180px - (calc(2 * 10px * 2) + calc( 2 * 6px * 2 )) ) / 2 - 10px / 2));
}
@keyframes rotate-ring-cap-3 {
  68% {
    transform: translate(-50%, -50%) rotate(calc(var(--angle) * (68 / 100) - 90deg)) translate(calc(calc( 180px - (calc(2 * 10px * 2) + calc( 2 * 6px * 2 )) ) / 2 - 10px / 2));
  }
}
.dial-ring:nth-child(5) .dial-ring-cap-end {
  left: 50%;
  top: 50%;
  transform-origin: center;
  z-index: 9999999999999;
  animation-name: rotate-ring-cap-3;
  animation-delay: 0.4s;
  animation-timing-function: ease-in-out;
  animation-duration: 0.6s;
  animation-fill-mode: forwards;
  transform: translate(-50%, -50%) rotate(-90deg) translate(calc(calc( 180px - (calc(2 * 10px * 2) + calc( 2 * 6px * 2 )) ) / 2 - 10px / 2));
}
@keyframes rotate-ring-cap-3 {
  69% {
    transform: translate(-50%, -50%) rotate(calc(var(--angle) * (69 / 100) - 90deg)) translate(calc(calc( 180px - (calc(2 * 10px * 2) + calc( 2 * 6px * 2 )) ) / 2 - 10px / 2));
  }
}
.dial-ring:nth-child(5) .dial-ring-cap-end {
  left: 50%;
  top: 50%;
  transform-origin: center;
  z-index: 9999999999999;
  animation-name: rotate-ring-cap-3;
  animation-delay: 0.4s;
  animation-timing-function: ease-in-out;
  animation-duration: 0.6s;
  animation-fill-mode: forwards;
  transform: translate(-50%, -50%) rotate(-90deg) translate(calc(calc( 180px - (calc(2 * 10px * 2) + calc( 2 * 6px * 2 )) ) / 2 - 10px / 2));
}
@keyframes rotate-ring-cap-3 {
  70% {
    transform: translate(-50%, -50%) rotate(calc(var(--angle) * (70 / 100) - 90deg)) translate(calc(calc( 180px - (calc(2 * 10px * 2) + calc( 2 * 6px * 2 )) ) / 2 - 10px / 2));
  }
}
.dial-ring:nth-child(5) .dial-ring-cap-end {
  left: 50%;
  top: 50%;
  transform-origin: center;
  z-index: 9999999999999;
  animation-name: rotate-ring-cap-3;
  animation-delay: 0.4s;
  animation-timing-function: ease-in-out;
  animation-duration: 0.6s;
  animation-fill-mode: forwards;
  transform: translate(-50%, -50%) rotate(-90deg) translate(calc(calc( 180px - (calc(2 * 10px * 2) + calc( 2 * 6px * 2 )) ) / 2 - 10px / 2));
}
@keyframes rotate-ring-cap-3 {
  71% {
    transform: translate(-50%, -50%) rotate(calc(var(--angle) * (71 / 100) - 90deg)) translate(calc(calc( 180px - (calc(2 * 10px * 2) + calc( 2 * 6px * 2 )) ) / 2 - 10px / 2));
  }
}
.dial-ring:nth-child(5) .dial-ring-cap-end {
  left: 50%;
  top: 50%;
  transform-origin: center;
  z-index: 9999999999999;
  animation-name: rotate-ring-cap-3;
  animation-delay: 0.4s;
  animation-timing-function: ease-in-out;
  animation-duration: 0.6s;
  animation-fill-mode: forwards;
  transform: translate(-50%, -50%) rotate(-90deg) translate(calc(calc( 180px - (calc(2 * 10px * 2) + calc( 2 * 6px * 2 )) ) / 2 - 10px / 2));
}
@keyframes rotate-ring-cap-3 {
  72% {
    transform: translate(-50%, -50%) rotate(calc(var(--angle) * (72 / 100) - 90deg)) translate(calc(calc( 180px - (calc(2 * 10px * 2) + calc( 2 * 6px * 2 )) ) / 2 - 10px / 2));
  }
}
.dial-ring:nth-child(5) .dial-ring-cap-end {
  left: 50%;
  top: 50%;
  transform-origin: center;
  z-index: 9999999999999;
  animation-name: rotate-ring-cap-3;
  animation-delay: 0.4s;
  animation-timing-function: ease-in-out;
  animation-duration: 0.6s;
  animation-fill-mode: forwards;
  transform: translate(-50%, -50%) rotate(-90deg) translate(calc(calc( 180px - (calc(2 * 10px * 2) + calc( 2 * 6px * 2 )) ) / 2 - 10px / 2));
}
@keyframes rotate-ring-cap-3 {
  73% {
    transform: translate(-50%, -50%) rotate(calc(var(--angle) * (73 / 100) - 90deg)) translate(calc(calc( 180px - (calc(2 * 10px * 2) + calc( 2 * 6px * 2 )) ) / 2 - 10px / 2));
  }
}
.dial-ring:nth-child(5) .dial-ring-cap-end {
  left: 50%;
  top: 50%;
  transform-origin: center;
  z-index: 9999999999999;
  animation-name: rotate-ring-cap-3;
  animation-delay: 0.4s;
  animation-timing-function: ease-in-out;
  animation-duration: 0.6s;
  animation-fill-mode: forwards;
  transform: translate(-50%, -50%) rotate(-90deg) translate(calc(calc( 180px - (calc(2 * 10px * 2) + calc( 2 * 6px * 2 )) ) / 2 - 10px / 2));
}
@keyframes rotate-ring-cap-3 {
  74% {
    transform: translate(-50%, -50%) rotate(calc(var(--angle) * (74 / 100) - 90deg)) translate(calc(calc( 180px - (calc(2 * 10px * 2) + calc( 2 * 6px * 2 )) ) / 2 - 10px / 2));
  }
}
.dial-ring:nth-child(5) .dial-ring-cap-end {
  left: 50%;
  top: 50%;
  transform-origin: center;
  z-index: 9999999999999;
  animation-name: rotate-ring-cap-3;
  animation-delay: 0.4s;
  animation-timing-function: ease-in-out;
  animation-duration: 0.6s;
  animation-fill-mode: forwards;
  transform: translate(-50%, -50%) rotate(-90deg) translate(calc(calc( 180px - (calc(2 * 10px * 2) + calc( 2 * 6px * 2 )) ) / 2 - 10px / 2));
}
@keyframes rotate-ring-cap-3 {
  75% {
    transform: translate(-50%, -50%) rotate(calc(var(--angle) * (75 / 100) - 90deg)) translate(calc(calc( 180px - (calc(2 * 10px * 2) + calc( 2 * 6px * 2 )) ) / 2 - 10px / 2));
  }
}
.dial-ring:nth-child(5) .dial-ring-cap-end {
  left: 50%;
  top: 50%;
  transform-origin: center;
  z-index: 9999999999999;
  animation-name: rotate-ring-cap-3;
  animation-delay: 0.4s;
  animation-timing-function: ease-in-out;
  animation-duration: 0.6s;
  animation-fill-mode: forwards;
  transform: translate(-50%, -50%) rotate(-90deg) translate(calc(calc( 180px - (calc(2 * 10px * 2) + calc( 2 * 6px * 2 )) ) / 2 - 10px / 2));
}
@keyframes rotate-ring-cap-3 {
  76% {
    transform: translate(-50%, -50%) rotate(calc(var(--angle) * (76 / 100) - 90deg)) translate(calc(calc( 180px - (calc(2 * 10px * 2) + calc( 2 * 6px * 2 )) ) / 2 - 10px / 2));
  }
}
.dial-ring:nth-child(5) .dial-ring-cap-end {
  left: 50%;
  top: 50%;
  transform-origin: center;
  z-index: 9999999999999;
  animation-name: rotate-ring-cap-3;
  animation-delay: 0.4s;
  animation-timing-function: ease-in-out;
  animation-duration: 0.6s;
  animation-fill-mode: forwards;
  transform: translate(-50%, -50%) rotate(-90deg) translate(calc(calc( 180px - (calc(2 * 10px * 2) + calc( 2 * 6px * 2 )) ) / 2 - 10px / 2));
}
@keyframes rotate-ring-cap-3 {
  77% {
    transform: translate(-50%, -50%) rotate(calc(var(--angle) * (77 / 100) - 90deg)) translate(calc(calc( 180px - (calc(2 * 10px * 2) + calc( 2 * 6px * 2 )) ) / 2 - 10px / 2));
  }
}
.dial-ring:nth-child(5) .dial-ring-cap-end {
  left: 50%;
  top: 50%;
  transform-origin: center;
  z-index: 9999999999999;
  animation-name: rotate-ring-cap-3;
  animation-delay: 0.4s;
  animation-timing-function: ease-in-out;
  animation-duration: 0.6s;
  animation-fill-mode: forwards;
  transform: translate(-50%, -50%) rotate(-90deg) translate(calc(calc( 180px - (calc(2 * 10px * 2) + calc( 2 * 6px * 2 )) ) / 2 - 10px / 2));
}
@keyframes rotate-ring-cap-3 {
  78% {
    transform: translate(-50%, -50%) rotate(calc(var(--angle) * (78 / 100) - 90deg)) translate(calc(calc( 180px - (calc(2 * 10px * 2) + calc( 2 * 6px * 2 )) ) / 2 - 10px / 2));
  }
}
.dial-ring:nth-child(5) .dial-ring-cap-end {
  left: 50%;
  top: 50%;
  transform-origin: center;
  z-index: 9999999999999;
  animation-name: rotate-ring-cap-3;
  animation-delay: 0.4s;
  animation-timing-function: ease-in-out;
  animation-duration: 0.6s;
  animation-fill-mode: forwards;
  transform: translate(-50%, -50%) rotate(-90deg) translate(calc(calc( 180px - (calc(2 * 10px * 2) + calc( 2 * 6px * 2 )) ) / 2 - 10px / 2));
}
@keyframes rotate-ring-cap-3 {
  79% {
    transform: translate(-50%, -50%) rotate(calc(var(--angle) * (79 / 100) - 90deg)) translate(calc(calc( 180px - (calc(2 * 10px * 2) + calc( 2 * 6px * 2 )) ) / 2 - 10px / 2));
  }
}
.dial-ring:nth-child(5) .dial-ring-cap-end {
  left: 50%;
  top: 50%;
  transform-origin: center;
  z-index: 9999999999999;
  animation-name: rotate-ring-cap-3;
  animation-delay: 0.4s;
  animation-timing-function: ease-in-out;
  animation-duration: 0.6s;
  animation-fill-mode: forwards;
  transform: translate(-50%, -50%) rotate(-90deg) translate(calc(calc( 180px - (calc(2 * 10px * 2) + calc( 2 * 6px * 2 )) ) / 2 - 10px / 2));
}
@keyframes rotate-ring-cap-3 {
  80% {
    transform: translate(-50%, -50%) rotate(calc(var(--angle) * (80 / 100) - 90deg)) translate(calc(calc( 180px - (calc(2 * 10px * 2) + calc( 2 * 6px * 2 )) ) / 2 - 10px / 2));
  }
}
.dial-ring:nth-child(5) .dial-ring-cap-end {
  left: 50%;
  top: 50%;
  transform-origin: center;
  z-index: 9999999999999;
  animation-name: rotate-ring-cap-3;
  animation-delay: 0.4s;
  animation-timing-function: ease-in-out;
  animation-duration: 0.6s;
  animation-fill-mode: forwards;
  transform: translate(-50%, -50%) rotate(-90deg) translate(calc(calc( 180px - (calc(2 * 10px * 2) + calc( 2 * 6px * 2 )) ) / 2 - 10px / 2));
}
@keyframes rotate-ring-cap-3 {
  81% {
    transform: translate(-50%, -50%) rotate(calc(var(--angle) * (81 / 100) - 90deg)) translate(calc(calc( 180px - (calc(2 * 10px * 2) + calc( 2 * 6px * 2 )) ) / 2 - 10px / 2));
  }
}
.dial-ring:nth-child(5) .dial-ring-cap-end {
  left: 50%;
  top: 50%;
  transform-origin: center;
  z-index: 9999999999999;
  animation-name: rotate-ring-cap-3;
  animation-delay: 0.4s;
  animation-timing-function: ease-in-out;
  animation-duration: 0.6s;
  animation-fill-mode: forwards;
  transform: translate(-50%, -50%) rotate(-90deg) translate(calc(calc( 180px - (calc(2 * 10px * 2) + calc( 2 * 6px * 2 )) ) / 2 - 10px / 2));
}
@keyframes rotate-ring-cap-3 {
  82% {
    transform: translate(-50%, -50%) rotate(calc(var(--angle) * (82 / 100) - 90deg)) translate(calc(calc( 180px - (calc(2 * 10px * 2) + calc( 2 * 6px * 2 )) ) / 2 - 10px / 2));
  }
}
.dial-ring:nth-child(5) .dial-ring-cap-end {
  left: 50%;
  top: 50%;
  transform-origin: center;
  z-index: 9999999999999;
  animation-name: rotate-ring-cap-3;
  animation-delay: 0.4s;
  animation-timing-function: ease-in-out;
  animation-duration: 0.6s;
  animation-fill-mode: forwards;
  transform: translate(-50%, -50%) rotate(-90deg) translate(calc(calc( 180px - (calc(2 * 10px * 2) + calc( 2 * 6px * 2 )) ) / 2 - 10px / 2));
}
@keyframes rotate-ring-cap-3 {
  83% {
    transform: translate(-50%, -50%) rotate(calc(var(--angle) * (83 / 100) - 90deg)) translate(calc(calc( 180px - (calc(2 * 10px * 2) + calc( 2 * 6px * 2 )) ) / 2 - 10px / 2));
  }
}
.dial-ring:nth-child(5) .dial-ring-cap-end {
  left: 50%;
  top: 50%;
  transform-origin: center;
  z-index: 9999999999999;
  animation-name: rotate-ring-cap-3;
  animation-delay: 0.4s;
  animation-timing-function: ease-in-out;
  animation-duration: 0.6s;
  animation-fill-mode: forwards;
  transform: translate(-50%, -50%) rotate(-90deg) translate(calc(calc( 180px - (calc(2 * 10px * 2) + calc( 2 * 6px * 2 )) ) / 2 - 10px / 2));
}
@keyframes rotate-ring-cap-3 {
  84% {
    transform: translate(-50%, -50%) rotate(calc(var(--angle) * (84 / 100) - 90deg)) translate(calc(calc( 180px - (calc(2 * 10px * 2) + calc( 2 * 6px * 2 )) ) / 2 - 10px / 2));
  }
}
.dial-ring:nth-child(5) .dial-ring-cap-end {
  left: 50%;
  top: 50%;
  transform-origin: center;
  z-index: 9999999999999;
  animation-name: rotate-ring-cap-3;
  animation-delay: 0.4s;
  animation-timing-function: ease-in-out;
  animation-duration: 0.6s;
  animation-fill-mode: forwards;
  transform: translate(-50%, -50%) rotate(-90deg) translate(calc(calc( 180px - (calc(2 * 10px * 2) + calc( 2 * 6px * 2 )) ) / 2 - 10px / 2));
}
@keyframes rotate-ring-cap-3 {
  85% {
    transform: translate(-50%, -50%) rotate(calc(var(--angle) * (85 / 100) - 90deg)) translate(calc(calc( 180px - (calc(2 * 10px * 2) + calc( 2 * 6px * 2 )) ) / 2 - 10px / 2));
  }
}
.dial-ring:nth-child(5) .dial-ring-cap-end {
  left: 50%;
  top: 50%;
  transform-origin: center;
  z-index: 9999999999999;
  animation-name: rotate-ring-cap-3;
  animation-delay: 0.4s;
  animation-timing-function: ease-in-out;
  animation-duration: 0.6s;
  animation-fill-mode: forwards;
  transform: translate(-50%, -50%) rotate(-90deg) translate(calc(calc( 180px - (calc(2 * 10px * 2) + calc( 2 * 6px * 2 )) ) / 2 - 10px / 2));
}
@keyframes rotate-ring-cap-3 {
  86% {
    transform: translate(-50%, -50%) rotate(calc(var(--angle) * (86 / 100) - 90deg)) translate(calc(calc( 180px - (calc(2 * 10px * 2) + calc( 2 * 6px * 2 )) ) / 2 - 10px / 2));
  }
}
.dial-ring:nth-child(5) .dial-ring-cap-end {
  left: 50%;
  top: 50%;
  transform-origin: center;
  z-index: 9999999999999;
  animation-name: rotate-ring-cap-3;
  animation-delay: 0.4s;
  animation-timing-function: ease-in-out;
  animation-duration: 0.6s;
  animation-fill-mode: forwards;
  transform: translate(-50%, -50%) rotate(-90deg) translate(calc(calc( 180px - (calc(2 * 10px * 2) + calc( 2 * 6px * 2 )) ) / 2 - 10px / 2));
}
@keyframes rotate-ring-cap-3 {
  87% {
    transform: translate(-50%, -50%) rotate(calc(var(--angle) * (87 / 100) - 90deg)) translate(calc(calc( 180px - (calc(2 * 10px * 2) + calc( 2 * 6px * 2 )) ) / 2 - 10px / 2));
  }
}
.dial-ring:nth-child(5) .dial-ring-cap-end {
  left: 50%;
  top: 50%;
  transform-origin: center;
  z-index: 9999999999999;
  animation-name: rotate-ring-cap-3;
  animation-delay: 0.4s;
  animation-timing-function: ease-in-out;
  animation-duration: 0.6s;
  animation-fill-mode: forwards;
  transform: translate(-50%, -50%) rotate(-90deg) translate(calc(calc( 180px - (calc(2 * 10px * 2) + calc( 2 * 6px * 2 )) ) / 2 - 10px / 2));
}
@keyframes rotate-ring-cap-3 {
  88% {
    transform: translate(-50%, -50%) rotate(calc(var(--angle) * (88 / 100) - 90deg)) translate(calc(calc( 180px - (calc(2 * 10px * 2) + calc( 2 * 6px * 2 )) ) / 2 - 10px / 2));
  }
}
.dial-ring:nth-child(5) .dial-ring-cap-end {
  left: 50%;
  top: 50%;
  transform-origin: center;
  z-index: 9999999999999;
  animation-name: rotate-ring-cap-3;
  animation-delay: 0.4s;
  animation-timing-function: ease-in-out;
  animation-duration: 0.6s;
  animation-fill-mode: forwards;
  transform: translate(-50%, -50%) rotate(-90deg) translate(calc(calc( 180px - (calc(2 * 10px * 2) + calc( 2 * 6px * 2 )) ) / 2 - 10px / 2));
}
@keyframes rotate-ring-cap-3 {
  89% {
    transform: translate(-50%, -50%) rotate(calc(var(--angle) * (89 / 100) - 90deg)) translate(calc(calc( 180px - (calc(2 * 10px * 2) + calc( 2 * 6px * 2 )) ) / 2 - 10px / 2));
  }
}
.dial-ring:nth-child(5) .dial-ring-cap-end {
  left: 50%;
  top: 50%;
  transform-origin: center;
  z-index: 9999999999999;
  animation-name: rotate-ring-cap-3;
  animation-delay: 0.4s;
  animation-timing-function: ease-in-out;
  animation-duration: 0.6s;
  animation-fill-mode: forwards;
  transform: translate(-50%, -50%) rotate(-90deg) translate(calc(calc( 180px - (calc(2 * 10px * 2) + calc( 2 * 6px * 2 )) ) / 2 - 10px / 2));
}
@keyframes rotate-ring-cap-3 {
  90% {
    transform: translate(-50%, -50%) rotate(calc(var(--angle) * (90 / 100) - 90deg)) translate(calc(calc( 180px - (calc(2 * 10px * 2) + calc( 2 * 6px * 2 )) ) / 2 - 10px / 2));
  }
}
.dial-ring:nth-child(5) .dial-ring-cap-end {
  left: 50%;
  top: 50%;
  transform-origin: center;
  z-index: 9999999999999;
  animation-name: rotate-ring-cap-3;
  animation-delay: 0.4s;
  animation-timing-function: ease-in-out;
  animation-duration: 0.6s;
  animation-fill-mode: forwards;
  transform: translate(-50%, -50%) rotate(-90deg) translate(calc(calc( 180px - (calc(2 * 10px * 2) + calc( 2 * 6px * 2 )) ) / 2 - 10px / 2));
}
@keyframes rotate-ring-cap-3 {
  91% {
    transform: translate(-50%, -50%) rotate(calc(var(--angle) * (91 / 100) - 90deg)) translate(calc(calc( 180px - (calc(2 * 10px * 2) + calc( 2 * 6px * 2 )) ) / 2 - 10px / 2));
  }
}
.dial-ring:nth-child(5) .dial-ring-cap-end {
  left: 50%;
  top: 50%;
  transform-origin: center;
  z-index: 9999999999999;
  animation-name: rotate-ring-cap-3;
  animation-delay: 0.4s;
  animation-timing-function: ease-in-out;
  animation-duration: 0.6s;
  animation-fill-mode: forwards;
  transform: translate(-50%, -50%) rotate(-90deg) translate(calc(calc( 180px - (calc(2 * 10px * 2) + calc( 2 * 6px * 2 )) ) / 2 - 10px / 2));
}
@keyframes rotate-ring-cap-3 {
  92% {
    transform: translate(-50%, -50%) rotate(calc(var(--angle) * (92 / 100) - 90deg)) translate(calc(calc( 180px - (calc(2 * 10px * 2) + calc( 2 * 6px * 2 )) ) / 2 - 10px / 2));
  }
}
.dial-ring:nth-child(5) .dial-ring-cap-end {
  left: 50%;
  top: 50%;
  transform-origin: center;
  z-index: 9999999999999;
  animation-name: rotate-ring-cap-3;
  animation-delay: 0.4s;
  animation-timing-function: ease-in-out;
  animation-duration: 0.6s;
  animation-fill-mode: forwards;
  transform: translate(-50%, -50%) rotate(-90deg) translate(calc(calc( 180px - (calc(2 * 10px * 2) + calc( 2 * 6px * 2 )) ) / 2 - 10px / 2));
}
@keyframes rotate-ring-cap-3 {
  93% {
    transform: translate(-50%, -50%) rotate(calc(var(--angle) * (93 / 100) - 90deg)) translate(calc(calc( 180px - (calc(2 * 10px * 2) + calc( 2 * 6px * 2 )) ) / 2 - 10px / 2));
  }
}
.dial-ring:nth-child(5) .dial-ring-cap-end {
  left: 50%;
  top: 50%;
  transform-origin: center;
  z-index: 9999999999999;
  animation-name: rotate-ring-cap-3;
  animation-delay: 0.4s;
  animation-timing-function: ease-in-out;
  animation-duration: 0.6s;
  animation-fill-mode: forwards;
  transform: translate(-50%, -50%) rotate(-90deg) translate(calc(calc( 180px - (calc(2 * 10px * 2) + calc( 2 * 6px * 2 )) ) / 2 - 10px / 2));
}
@keyframes rotate-ring-cap-3 {
  94% {
    transform: translate(-50%, -50%) rotate(calc(var(--angle) * (94 / 100) - 90deg)) translate(calc(calc( 180px - (calc(2 * 10px * 2) + calc( 2 * 6px * 2 )) ) / 2 - 10px / 2));
  }
}
.dial-ring:nth-child(5) .dial-ring-cap-end {
  left: 50%;
  top: 50%;
  transform-origin: center;
  z-index: 9999999999999;
  animation-name: rotate-ring-cap-3;
  animation-delay: 0.4s;
  animation-timing-function: ease-in-out;
  animation-duration: 0.6s;
  animation-fill-mode: forwards;
  transform: translate(-50%, -50%) rotate(-90deg) translate(calc(calc( 180px - (calc(2 * 10px * 2) + calc( 2 * 6px * 2 )) ) / 2 - 10px / 2));
}
@keyframes rotate-ring-cap-3 {
  95% {
    transform: translate(-50%, -50%) rotate(calc(var(--angle) * (95 / 100) - 90deg)) translate(calc(calc( 180px - (calc(2 * 10px * 2) + calc( 2 * 6px * 2 )) ) / 2 - 10px / 2));
  }
}
.dial-ring:nth-child(5) .dial-ring-cap-end {
  left: 50%;
  top: 50%;
  transform-origin: center;
  z-index: 9999999999999;
  animation-name: rotate-ring-cap-3;
  animation-delay: 0.4s;
  animation-timing-function: ease-in-out;
  animation-duration: 0.6s;
  animation-fill-mode: forwards;
  transform: translate(-50%, -50%) rotate(-90deg) translate(calc(calc( 180px - (calc(2 * 10px * 2) + calc( 2 * 6px * 2 )) ) / 2 - 10px / 2));
}
@keyframes rotate-ring-cap-3 {
  96% {
    transform: translate(-50%, -50%) rotate(calc(var(--angle) * (96 / 100) - 90deg)) translate(calc(calc( 180px - (calc(2 * 10px * 2) + calc( 2 * 6px * 2 )) ) / 2 - 10px / 2));
  }
}
.dial-ring:nth-child(5) .dial-ring-cap-end {
  left: 50%;
  top: 50%;
  transform-origin: center;
  z-index: 9999999999999;
  animation-name: rotate-ring-cap-3;
  animation-delay: 0.4s;
  animation-timing-function: ease-in-out;
  animation-duration: 0.6s;
  animation-fill-mode: forwards;
  transform: translate(-50%, -50%) rotate(-90deg) translate(calc(calc( 180px - (calc(2 * 10px * 2) + calc( 2 * 6px * 2 )) ) / 2 - 10px / 2));
}
@keyframes rotate-ring-cap-3 {
  97% {
    transform: translate(-50%, -50%) rotate(calc(var(--angle) * (97 / 100) - 90deg)) translate(calc(calc( 180px - (calc(2 * 10px * 2) + calc( 2 * 6px * 2 )) ) / 2 - 10px / 2));
  }
}
.dial-ring:nth-child(5) .dial-ring-cap-end {
  left: 50%;
  top: 50%;
  transform-origin: center;
  z-index: 9999999999999;
  animation-name: rotate-ring-cap-3;
  animation-delay: 0.4s;
  animation-timing-function: ease-in-out;
  animation-duration: 0.6s;
  animation-fill-mode: forwards;
  transform: translate(-50%, -50%) rotate(-90deg) translate(calc(calc( 180px - (calc(2 * 10px * 2) + calc( 2 * 6px * 2 )) ) / 2 - 10px / 2));
}
@keyframes rotate-ring-cap-3 {
  98% {
    transform: translate(-50%, -50%) rotate(calc(var(--angle) * (98 / 100) - 90deg)) translate(calc(calc( 180px - (calc(2 * 10px * 2) + calc( 2 * 6px * 2 )) ) / 2 - 10px / 2));
  }
}
.dial-ring:nth-child(5) .dial-ring-cap-end {
  left: 50%;
  top: 50%;
  transform-origin: center;
  z-index: 9999999999999;
  animation-name: rotate-ring-cap-3;
  animation-delay: 0.4s;
  animation-timing-function: ease-in-out;
  animation-duration: 0.6s;
  animation-fill-mode: forwards;
  transform: translate(-50%, -50%) rotate(-90deg) translate(calc(calc( 180px - (calc(2 * 10px * 2) + calc( 2 * 6px * 2 )) ) / 2 - 10px / 2));
}
@keyframes rotate-ring-cap-3 {
  99% {
    transform: translate(-50%, -50%) rotate(calc(var(--angle) * (99 / 100) - 90deg)) translate(calc(calc( 180px - (calc(2 * 10px * 2) + calc( 2 * 6px * 2 )) ) / 2 - 10px / 2));
  }
}
.dial-ring:nth-child(5) .dial-ring-cap-end {
  left: 50%;
  top: 50%;
  transform-origin: center;
  z-index: 9999999999999;
  animation-name: rotate-ring-cap-3;
  animation-delay: 0.4s;
  animation-timing-function: ease-in-out;
  animation-duration: 0.6s;
  animation-fill-mode: forwards;
  transform: translate(-50%, -50%) rotate(-90deg) translate(calc(calc( 180px - (calc(2 * 10px * 2) + calc( 2 * 6px * 2 )) ) / 2 - 10px / 2));
}
@keyframes rotate-ring-cap-3 {
  100% {
    transform: translate(-50%, -50%) rotate(calc(var(--angle) * (100 / 100) - 90deg)) translate(calc(calc( 180px - (calc(2 * 10px * 2) + calc( 2 * 6px * 2 )) ) / 2 - 10px / 2));
  }
}
.dial-ring--spacer:not(:last-child):nth-child(8) {
  position: absolute;
  border-radius: 50%;
  left: 50%;
  top: 50%;
  transform: translateX(-50%) translateY(-50%);
  width: calc(180px - calc(
        4 *
          10px *
          2 +
          ((4 - 1) * 6px * 2)
      ));
  height: calc(180px - calc(
        4 *
          10px *
          2 +
          ((4 - 1) * 6px * 2)
      ));
  background: #272727;
}
.dial-ring:nth-child(7) {
  width: calc(
        180px - (calc(3 * 10px * 2) + calc(
        3 * 6px * 2
      ))
      );
  height: calc(
        180px - (calc(3 * 10px * 2) + calc(
        3 * 6px * 2
      ))
      );
}
.dial-ring:nth-child(7) .dial-ring-progress:before {
  animation-delay: 0.4s;
  animation-timing-function: ease-in-out;
  animation-duration: 0.6s;
  animation-name: rotation-4;
  animation-fill-mode: forwards;
}
@keyframes rotation-4 {
  to {
    transform: rotate(var(--angle));
  }
}
.dial-ring:nth-child(7) .dial-ring-progress:not(.dial-ring-progress--less-than-halfway) {
  animation-name: show-left-hemisphere-4;
}
.dial-ring:nth-child(7) .dial-ring-progress:not(.dial-ring-progress--less-than-halfway), .dial-ring:nth-child(7) .dial-ring-progress:not(.dial-ring-progress--less-than-halfway):after {
  animation-timing-function: linear;
  animation-duration: 0s;
  animation-fill-mode: forwards;
}
.dial-ring:nth-child(7) .dial-ring-progress:not(.dial-ring-progress--less-than-halfway).dial-ring-progress--segments-3, .dial-ring:nth-child(7) .dial-ring-progress:not(.dial-ring-progress--less-than-halfway).dial-ring-progress--segments-3:after {
  animation-delay: calc(calc(
            var(--percent-complete-at-half-filled) *
              0.6s +
              0.4s
          ) - 0.075s);
}
.dial-ring:nth-child(7) .dial-ring-progress:not(.dial-ring-progress--less-than-halfway).dial-ring-progress--segments-4, .dial-ring:nth-child(7) .dial-ring-progress:not(.dial-ring-progress--less-than-halfway).dial-ring-progress--segments-4:after {
  animation-delay: calc(calc(
            var(--percent-complete-at-half-filled) *
              0.6s +
              0.4s
          ) - 0.03s);
}
.dial-ring:nth-child(7) .dial-ring-progress:not(.dial-ring-progress--less-than-halfway).dial-ring-progress--segments-5, .dial-ring:nth-child(7) .dial-ring-progress:not(.dial-ring-progress--less-than-halfway).dial-ring-progress--segments-5:after {
  animation-delay: calc(
            var(--percent-complete-at-half-filled) *
              0.6s +
              0.4s
          );
}
@keyframes show-left-hemisphere-4 {
  to {
    clip-path: none;
  }
}
.dial-ring:nth-child(7) .dial-ring-progress:not(.dial-ring-progress--less-than-halfway):after {
  animation-name: fill-right-hemisphere-4;
}
@keyframes fill-right-hemisphere-4 {
  to {
    opacity: 1;
  }
}
.dial-ring:nth-child(7) .dial-ring-cap-end {
  left: 50%;
  top: 50%;
  transform-origin: center;
  z-index: 9999999999999;
  animation-name: rotate-ring-cap-4;
  animation-delay: 0.4s;
  animation-timing-function: ease-in-out;
  animation-duration: 0.6s;
  animation-fill-mode: forwards;
  transform: translate(-50%, -50%) rotate(-90deg) translate(calc(calc( 180px - (calc(3 * 10px * 2) + calc( 3 * 6px * 2 )) ) / 2 - 10px / 2));
}
@keyframes rotate-ring-cap-4 {
  0% {
    transform: translate(-50%, -50%) rotate(calc(var(--angle) * (0 / 100) - 90deg)) translate(calc(calc( 180px - (calc(3 * 10px * 2) + calc( 3 * 6px * 2 )) ) / 2 - 10px / 2));
  }
}
.dial-ring:nth-child(7) .dial-ring-cap-end {
  left: 50%;
  top: 50%;
  transform-origin: center;
  z-index: 9999999999999;
  animation-name: rotate-ring-cap-4;
  animation-delay: 0.4s;
  animation-timing-function: ease-in-out;
  animation-duration: 0.6s;
  animation-fill-mode: forwards;
  transform: translate(-50%, -50%) rotate(-90deg) translate(calc(calc( 180px - (calc(3 * 10px * 2) + calc( 3 * 6px * 2 )) ) / 2 - 10px / 2));
}
@keyframes rotate-ring-cap-4 {
  1% {
    transform: translate(-50%, -50%) rotate(calc(var(--angle) * (1 / 100) - 90deg)) translate(calc(calc( 180px - (calc(3 * 10px * 2) + calc( 3 * 6px * 2 )) ) / 2 - 10px / 2));
  }
}
.dial-ring:nth-child(7) .dial-ring-cap-end {
  left: 50%;
  top: 50%;
  transform-origin: center;
  z-index: 9999999999999;
  animation-name: rotate-ring-cap-4;
  animation-delay: 0.4s;
  animation-timing-function: ease-in-out;
  animation-duration: 0.6s;
  animation-fill-mode: forwards;
  transform: translate(-50%, -50%) rotate(-90deg) translate(calc(calc( 180px - (calc(3 * 10px * 2) + calc( 3 * 6px * 2 )) ) / 2 - 10px / 2));
}
@keyframes rotate-ring-cap-4 {
  2% {
    transform: translate(-50%, -50%) rotate(calc(var(--angle) * (2 / 100) - 90deg)) translate(calc(calc( 180px - (calc(3 * 10px * 2) + calc( 3 * 6px * 2 )) ) / 2 - 10px / 2));
  }
}
.dial-ring:nth-child(7) .dial-ring-cap-end {
  left: 50%;
  top: 50%;
  transform-origin: center;
  z-index: 9999999999999;
  animation-name: rotate-ring-cap-4;
  animation-delay: 0.4s;
  animation-timing-function: ease-in-out;
  animation-duration: 0.6s;
  animation-fill-mode: forwards;
  transform: translate(-50%, -50%) rotate(-90deg) translate(calc(calc( 180px - (calc(3 * 10px * 2) + calc( 3 * 6px * 2 )) ) / 2 - 10px / 2));
}
@keyframes rotate-ring-cap-4 {
  3% {
    transform: translate(-50%, -50%) rotate(calc(var(--angle) * (3 / 100) - 90deg)) translate(calc(calc( 180px - (calc(3 * 10px * 2) + calc( 3 * 6px * 2 )) ) / 2 - 10px / 2));
  }
}
.dial-ring:nth-child(7) .dial-ring-cap-end {
  left: 50%;
  top: 50%;
  transform-origin: center;
  z-index: 9999999999999;
  animation-name: rotate-ring-cap-4;
  animation-delay: 0.4s;
  animation-timing-function: ease-in-out;
  animation-duration: 0.6s;
  animation-fill-mode: forwards;
  transform: translate(-50%, -50%) rotate(-90deg) translate(calc(calc( 180px - (calc(3 * 10px * 2) + calc( 3 * 6px * 2 )) ) / 2 - 10px / 2));
}
@keyframes rotate-ring-cap-4 {
  4% {
    transform: translate(-50%, -50%) rotate(calc(var(--angle) * (4 / 100) - 90deg)) translate(calc(calc( 180px - (calc(3 * 10px * 2) + calc( 3 * 6px * 2 )) ) / 2 - 10px / 2));
  }
}
.dial-ring:nth-child(7) .dial-ring-cap-end {
  left: 50%;
  top: 50%;
  transform-origin: center;
  z-index: 9999999999999;
  animation-name: rotate-ring-cap-4;
  animation-delay: 0.4s;
  animation-timing-function: ease-in-out;
  animation-duration: 0.6s;
  animation-fill-mode: forwards;
  transform: translate(-50%, -50%) rotate(-90deg) translate(calc(calc( 180px - (calc(3 * 10px * 2) + calc( 3 * 6px * 2 )) ) / 2 - 10px / 2));
}
@keyframes rotate-ring-cap-4 {
  5% {
    transform: translate(-50%, -50%) rotate(calc(var(--angle) * (5 / 100) - 90deg)) translate(calc(calc( 180px - (calc(3 * 10px * 2) + calc( 3 * 6px * 2 )) ) / 2 - 10px / 2));
  }
}
.dial-ring:nth-child(7) .dial-ring-cap-end {
  left: 50%;
  top: 50%;
  transform-origin: center;
  z-index: 9999999999999;
  animation-name: rotate-ring-cap-4;
  animation-delay: 0.4s;
  animation-timing-function: ease-in-out;
  animation-duration: 0.6s;
  animation-fill-mode: forwards;
  transform: translate(-50%, -50%) rotate(-90deg) translate(calc(calc( 180px - (calc(3 * 10px * 2) + calc( 3 * 6px * 2 )) ) / 2 - 10px / 2));
}
@keyframes rotate-ring-cap-4 {
  6% {
    transform: translate(-50%, -50%) rotate(calc(var(--angle) * (6 / 100) - 90deg)) translate(calc(calc( 180px - (calc(3 * 10px * 2) + calc( 3 * 6px * 2 )) ) / 2 - 10px / 2));
  }
}
.dial-ring:nth-child(7) .dial-ring-cap-end {
  left: 50%;
  top: 50%;
  transform-origin: center;
  z-index: 9999999999999;
  animation-name: rotate-ring-cap-4;
  animation-delay: 0.4s;
  animation-timing-function: ease-in-out;
  animation-duration: 0.6s;
  animation-fill-mode: forwards;
  transform: translate(-50%, -50%) rotate(-90deg) translate(calc(calc( 180px - (calc(3 * 10px * 2) + calc( 3 * 6px * 2 )) ) / 2 - 10px / 2));
}
@keyframes rotate-ring-cap-4 {
  7% {
    transform: translate(-50%, -50%) rotate(calc(var(--angle) * (7 / 100) - 90deg)) translate(calc(calc( 180px - (calc(3 * 10px * 2) + calc( 3 * 6px * 2 )) ) / 2 - 10px / 2));
  }
}
.dial-ring:nth-child(7) .dial-ring-cap-end {
  left: 50%;
  top: 50%;
  transform-origin: center;
  z-index: 9999999999999;
  animation-name: rotate-ring-cap-4;
  animation-delay: 0.4s;
  animation-timing-function: ease-in-out;
  animation-duration: 0.6s;
  animation-fill-mode: forwards;
  transform: translate(-50%, -50%) rotate(-90deg) translate(calc(calc( 180px - (calc(3 * 10px * 2) + calc( 3 * 6px * 2 )) ) / 2 - 10px / 2));
}
@keyframes rotate-ring-cap-4 {
  8% {
    transform: translate(-50%, -50%) rotate(calc(var(--angle) * (8 / 100) - 90deg)) translate(calc(calc( 180px - (calc(3 * 10px * 2) + calc( 3 * 6px * 2 )) ) / 2 - 10px / 2));
  }
}
.dial-ring:nth-child(7) .dial-ring-cap-end {
  left: 50%;
  top: 50%;
  transform-origin: center;
  z-index: 9999999999999;
  animation-name: rotate-ring-cap-4;
  animation-delay: 0.4s;
  animation-timing-function: ease-in-out;
  animation-duration: 0.6s;
  animation-fill-mode: forwards;
  transform: translate(-50%, -50%) rotate(-90deg) translate(calc(calc( 180px - (calc(3 * 10px * 2) + calc( 3 * 6px * 2 )) ) / 2 - 10px / 2));
}
@keyframes rotate-ring-cap-4 {
  9% {
    transform: translate(-50%, -50%) rotate(calc(var(--angle) * (9 / 100) - 90deg)) translate(calc(calc( 180px - (calc(3 * 10px * 2) + calc( 3 * 6px * 2 )) ) / 2 - 10px / 2));
  }
}
.dial-ring:nth-child(7) .dial-ring-cap-end {
  left: 50%;
  top: 50%;
  transform-origin: center;
  z-index: 9999999999999;
  animation-name: rotate-ring-cap-4;
  animation-delay: 0.4s;
  animation-timing-function: ease-in-out;
  animation-duration: 0.6s;
  animation-fill-mode: forwards;
  transform: translate(-50%, -50%) rotate(-90deg) translate(calc(calc( 180px - (calc(3 * 10px * 2) + calc( 3 * 6px * 2 )) ) / 2 - 10px / 2));
}
@keyframes rotate-ring-cap-4 {
  10% {
    transform: translate(-50%, -50%) rotate(calc(var(--angle) * (10 / 100) - 90deg)) translate(calc(calc( 180px - (calc(3 * 10px * 2) + calc( 3 * 6px * 2 )) ) / 2 - 10px / 2));
  }
}
.dial-ring:nth-child(7) .dial-ring-cap-end {
  left: 50%;
  top: 50%;
  transform-origin: center;
  z-index: 9999999999999;
  animation-name: rotate-ring-cap-4;
  animation-delay: 0.4s;
  animation-timing-function: ease-in-out;
  animation-duration: 0.6s;
  animation-fill-mode: forwards;
  transform: translate(-50%, -50%) rotate(-90deg) translate(calc(calc( 180px - (calc(3 * 10px * 2) + calc( 3 * 6px * 2 )) ) / 2 - 10px / 2));
}
@keyframes rotate-ring-cap-4 {
  11% {
    transform: translate(-50%, -50%) rotate(calc(var(--angle) * (11 / 100) - 90deg)) translate(calc(calc( 180px - (calc(3 * 10px * 2) + calc( 3 * 6px * 2 )) ) / 2 - 10px / 2));
  }
}
.dial-ring:nth-child(7) .dial-ring-cap-end {
  left: 50%;
  top: 50%;
  transform-origin: center;
  z-index: 9999999999999;
  animation-name: rotate-ring-cap-4;
  animation-delay: 0.4s;
  animation-timing-function: ease-in-out;
  animation-duration: 0.6s;
  animation-fill-mode: forwards;
  transform: translate(-50%, -50%) rotate(-90deg) translate(calc(calc( 180px - (calc(3 * 10px * 2) + calc( 3 * 6px * 2 )) ) / 2 - 10px / 2));
}
@keyframes rotate-ring-cap-4 {
  12% {
    transform: translate(-50%, -50%) rotate(calc(var(--angle) * (12 / 100) - 90deg)) translate(calc(calc( 180px - (calc(3 * 10px * 2) + calc( 3 * 6px * 2 )) ) / 2 - 10px / 2));
  }
}
.dial-ring:nth-child(7) .dial-ring-cap-end {
  left: 50%;
  top: 50%;
  transform-origin: center;
  z-index: 9999999999999;
  animation-name: rotate-ring-cap-4;
  animation-delay: 0.4s;
  animation-timing-function: ease-in-out;
  animation-duration: 0.6s;
  animation-fill-mode: forwards;
  transform: translate(-50%, -50%) rotate(-90deg) translate(calc(calc( 180px - (calc(3 * 10px * 2) + calc( 3 * 6px * 2 )) ) / 2 - 10px / 2));
}
@keyframes rotate-ring-cap-4 {
  13% {
    transform: translate(-50%, -50%) rotate(calc(var(--angle) * (13 / 100) - 90deg)) translate(calc(calc( 180px - (calc(3 * 10px * 2) + calc( 3 * 6px * 2 )) ) / 2 - 10px / 2));
  }
}
.dial-ring:nth-child(7) .dial-ring-cap-end {
  left: 50%;
  top: 50%;
  transform-origin: center;
  z-index: 9999999999999;
  animation-name: rotate-ring-cap-4;
  animation-delay: 0.4s;
  animation-timing-function: ease-in-out;
  animation-duration: 0.6s;
  animation-fill-mode: forwards;
  transform: translate(-50%, -50%) rotate(-90deg) translate(calc(calc( 180px - (calc(3 * 10px * 2) + calc( 3 * 6px * 2 )) ) / 2 - 10px / 2));
}
@keyframes rotate-ring-cap-4 {
  14% {
    transform: translate(-50%, -50%) rotate(calc(var(--angle) * (14 / 100) - 90deg)) translate(calc(calc( 180px - (calc(3 * 10px * 2) + calc( 3 * 6px * 2 )) ) / 2 - 10px / 2));
  }
}
.dial-ring:nth-child(7) .dial-ring-cap-end {
  left: 50%;
  top: 50%;
  transform-origin: center;
  z-index: 9999999999999;
  animation-name: rotate-ring-cap-4;
  animation-delay: 0.4s;
  animation-timing-function: ease-in-out;
  animation-duration: 0.6s;
  animation-fill-mode: forwards;
  transform: translate(-50%, -50%) rotate(-90deg) translate(calc(calc( 180px - (calc(3 * 10px * 2) + calc( 3 * 6px * 2 )) ) / 2 - 10px / 2));
}
@keyframes rotate-ring-cap-4 {
  15% {
    transform: translate(-50%, -50%) rotate(calc(var(--angle) * (15 / 100) - 90deg)) translate(calc(calc( 180px - (calc(3 * 10px * 2) + calc( 3 * 6px * 2 )) ) / 2 - 10px / 2));
  }
}
.dial-ring:nth-child(7) .dial-ring-cap-end {
  left: 50%;
  top: 50%;
  transform-origin: center;
  z-index: 9999999999999;
  animation-name: rotate-ring-cap-4;
  animation-delay: 0.4s;
  animation-timing-function: ease-in-out;
  animation-duration: 0.6s;
  animation-fill-mode: forwards;
  transform: translate(-50%, -50%) rotate(-90deg) translate(calc(calc( 180px - (calc(3 * 10px * 2) + calc( 3 * 6px * 2 )) ) / 2 - 10px / 2));
}
@keyframes rotate-ring-cap-4 {
  16% {
    transform: translate(-50%, -50%) rotate(calc(var(--angle) * (16 / 100) - 90deg)) translate(calc(calc( 180px - (calc(3 * 10px * 2) + calc( 3 * 6px * 2 )) ) / 2 - 10px / 2));
  }
}
.dial-ring:nth-child(7) .dial-ring-cap-end {
  left: 50%;
  top: 50%;
  transform-origin: center;
  z-index: 9999999999999;
  animation-name: rotate-ring-cap-4;
  animation-delay: 0.4s;
  animation-timing-function: ease-in-out;
  animation-duration: 0.6s;
  animation-fill-mode: forwards;
  transform: translate(-50%, -50%) rotate(-90deg) translate(calc(calc( 180px - (calc(3 * 10px * 2) + calc( 3 * 6px * 2 )) ) / 2 - 10px / 2));
}
@keyframes rotate-ring-cap-4 {
  17% {
    transform: translate(-50%, -50%) rotate(calc(var(--angle) * (17 / 100) - 90deg)) translate(calc(calc( 180px - (calc(3 * 10px * 2) + calc( 3 * 6px * 2 )) ) / 2 - 10px / 2));
  }
}
.dial-ring:nth-child(7) .dial-ring-cap-end {
  left: 50%;
  top: 50%;
  transform-origin: center;
  z-index: 9999999999999;
  animation-name: rotate-ring-cap-4;
  animation-delay: 0.4s;
  animation-timing-function: ease-in-out;
  animation-duration: 0.6s;
  animation-fill-mode: forwards;
  transform: translate(-50%, -50%) rotate(-90deg) translate(calc(calc( 180px - (calc(3 * 10px * 2) + calc( 3 * 6px * 2 )) ) / 2 - 10px / 2));
}
@keyframes rotate-ring-cap-4 {
  18% {
    transform: translate(-50%, -50%) rotate(calc(var(--angle) * (18 / 100) - 90deg)) translate(calc(calc( 180px - (calc(3 * 10px * 2) + calc( 3 * 6px * 2 )) ) / 2 - 10px / 2));
  }
}
.dial-ring:nth-child(7) .dial-ring-cap-end {
  left: 50%;
  top: 50%;
  transform-origin: center;
  z-index: 9999999999999;
  animation-name: rotate-ring-cap-4;
  animation-delay: 0.4s;
  animation-timing-function: ease-in-out;
  animation-duration: 0.6s;
  animation-fill-mode: forwards;
  transform: translate(-50%, -50%) rotate(-90deg) translate(calc(calc( 180px - (calc(3 * 10px * 2) + calc( 3 * 6px * 2 )) ) / 2 - 10px / 2));
}
@keyframes rotate-ring-cap-4 {
  19% {
    transform: translate(-50%, -50%) rotate(calc(var(--angle) * (19 / 100) - 90deg)) translate(calc(calc( 180px - (calc(3 * 10px * 2) + calc( 3 * 6px * 2 )) ) / 2 - 10px / 2));
  }
}
.dial-ring:nth-child(7) .dial-ring-cap-end {
  left: 50%;
  top: 50%;
  transform-origin: center;
  z-index: 9999999999999;
  animation-name: rotate-ring-cap-4;
  animation-delay: 0.4s;
  animation-timing-function: ease-in-out;
  animation-duration: 0.6s;
  animation-fill-mode: forwards;
  transform: translate(-50%, -50%) rotate(-90deg) translate(calc(calc( 180px - (calc(3 * 10px * 2) + calc( 3 * 6px * 2 )) ) / 2 - 10px / 2));
}
@keyframes rotate-ring-cap-4 {
  20% {
    transform: translate(-50%, -50%) rotate(calc(var(--angle) * (20 / 100) - 90deg)) translate(calc(calc( 180px - (calc(3 * 10px * 2) + calc( 3 * 6px * 2 )) ) / 2 - 10px / 2));
  }
}
.dial-ring:nth-child(7) .dial-ring-cap-end {
  left: 50%;
  top: 50%;
  transform-origin: center;
  z-index: 9999999999999;
  animation-name: rotate-ring-cap-4;
  animation-delay: 0.4s;
  animation-timing-function: ease-in-out;
  animation-duration: 0.6s;
  animation-fill-mode: forwards;
  transform: translate(-50%, -50%) rotate(-90deg) translate(calc(calc( 180px - (calc(3 * 10px * 2) + calc( 3 * 6px * 2 )) ) / 2 - 10px / 2));
}
@keyframes rotate-ring-cap-4 {
  21% {
    transform: translate(-50%, -50%) rotate(calc(var(--angle) * (21 / 100) - 90deg)) translate(calc(calc( 180px - (calc(3 * 10px * 2) + calc( 3 * 6px * 2 )) ) / 2 - 10px / 2));
  }
}
.dial-ring:nth-child(7) .dial-ring-cap-end {
  left: 50%;
  top: 50%;
  transform-origin: center;
  z-index: 9999999999999;
  animation-name: rotate-ring-cap-4;
  animation-delay: 0.4s;
  animation-timing-function: ease-in-out;
  animation-duration: 0.6s;
  animation-fill-mode: forwards;
  transform: translate(-50%, -50%) rotate(-90deg) translate(calc(calc( 180px - (calc(3 * 10px * 2) + calc( 3 * 6px * 2 )) ) / 2 - 10px / 2));
}
@keyframes rotate-ring-cap-4 {
  22% {
    transform: translate(-50%, -50%) rotate(calc(var(--angle) * (22 / 100) - 90deg)) translate(calc(calc( 180px - (calc(3 * 10px * 2) + calc( 3 * 6px * 2 )) ) / 2 - 10px / 2));
  }
}
.dial-ring:nth-child(7) .dial-ring-cap-end {
  left: 50%;
  top: 50%;
  transform-origin: center;
  z-index: 9999999999999;
  animation-name: rotate-ring-cap-4;
  animation-delay: 0.4s;
  animation-timing-function: ease-in-out;
  animation-duration: 0.6s;
  animation-fill-mode: forwards;
  transform: translate(-50%, -50%) rotate(-90deg) translate(calc(calc( 180px - (calc(3 * 10px * 2) + calc( 3 * 6px * 2 )) ) / 2 - 10px / 2));
}
@keyframes rotate-ring-cap-4 {
  23% {
    transform: translate(-50%, -50%) rotate(calc(var(--angle) * (23 / 100) - 90deg)) translate(calc(calc( 180px - (calc(3 * 10px * 2) + calc( 3 * 6px * 2 )) ) / 2 - 10px / 2));
  }
}
.dial-ring:nth-child(7) .dial-ring-cap-end {
  left: 50%;
  top: 50%;
  transform-origin: center;
  z-index: 9999999999999;
  animation-name: rotate-ring-cap-4;
  animation-delay: 0.4s;
  animation-timing-function: ease-in-out;
  animation-duration: 0.6s;
  animation-fill-mode: forwards;
  transform: translate(-50%, -50%) rotate(-90deg) translate(calc(calc( 180px - (calc(3 * 10px * 2) + calc( 3 * 6px * 2 )) ) / 2 - 10px / 2));
}
@keyframes rotate-ring-cap-4 {
  24% {
    transform: translate(-50%, -50%) rotate(calc(var(--angle) * (24 / 100) - 90deg)) translate(calc(calc( 180px - (calc(3 * 10px * 2) + calc( 3 * 6px * 2 )) ) / 2 - 10px / 2));
  }
}
.dial-ring:nth-child(7) .dial-ring-cap-end {
  left: 50%;
  top: 50%;
  transform-origin: center;
  z-index: 9999999999999;
  animation-name: rotate-ring-cap-4;
  animation-delay: 0.4s;
  animation-timing-function: ease-in-out;
  animation-duration: 0.6s;
  animation-fill-mode: forwards;
  transform: translate(-50%, -50%) rotate(-90deg) translate(calc(calc( 180px - (calc(3 * 10px * 2) + calc( 3 * 6px * 2 )) ) / 2 - 10px / 2));
}
@keyframes rotate-ring-cap-4 {
  25% {
    transform: translate(-50%, -50%) rotate(calc(var(--angle) * (25 / 100) - 90deg)) translate(calc(calc( 180px - (calc(3 * 10px * 2) + calc( 3 * 6px * 2 )) ) / 2 - 10px / 2));
  }
}
.dial-ring:nth-child(7) .dial-ring-cap-end {
  left: 50%;
  top: 50%;
  transform-origin: center;
  z-index: 9999999999999;
  animation-name: rotate-ring-cap-4;
  animation-delay: 0.4s;
  animation-timing-function: ease-in-out;
  animation-duration: 0.6s;
  animation-fill-mode: forwards;
  transform: translate(-50%, -50%) rotate(-90deg) translate(calc(calc( 180px - (calc(3 * 10px * 2) + calc( 3 * 6px * 2 )) ) / 2 - 10px / 2));
}
@keyframes rotate-ring-cap-4 {
  26% {
    transform: translate(-50%, -50%) rotate(calc(var(--angle) * (26 / 100) - 90deg)) translate(calc(calc( 180px - (calc(3 * 10px * 2) + calc( 3 * 6px * 2 )) ) / 2 - 10px / 2));
  }
}
.dial-ring:nth-child(7) .dial-ring-cap-end {
  left: 50%;
  top: 50%;
  transform-origin: center;
  z-index: 9999999999999;
  animation-name: rotate-ring-cap-4;
  animation-delay: 0.4s;
  animation-timing-function: ease-in-out;
  animation-duration: 0.6s;
  animation-fill-mode: forwards;
  transform: translate(-50%, -50%) rotate(-90deg) translate(calc(calc( 180px - (calc(3 * 10px * 2) + calc( 3 * 6px * 2 )) ) / 2 - 10px / 2));
}
@keyframes rotate-ring-cap-4 {
  27% {
    transform: translate(-50%, -50%) rotate(calc(var(--angle) * (27 / 100) - 90deg)) translate(calc(calc( 180px - (calc(3 * 10px * 2) + calc( 3 * 6px * 2 )) ) / 2 - 10px / 2));
  }
}
.dial-ring:nth-child(7) .dial-ring-cap-end {
  left: 50%;
  top: 50%;
  transform-origin: center;
  z-index: 9999999999999;
  animation-name: rotate-ring-cap-4;
  animation-delay: 0.4s;
  animation-timing-function: ease-in-out;
  animation-duration: 0.6s;
  animation-fill-mode: forwards;
  transform: translate(-50%, -50%) rotate(-90deg) translate(calc(calc( 180px - (calc(3 * 10px * 2) + calc( 3 * 6px * 2 )) ) / 2 - 10px / 2));
}
@keyframes rotate-ring-cap-4 {
  28% {
    transform: translate(-50%, -50%) rotate(calc(var(--angle) * (28 / 100) - 90deg)) translate(calc(calc( 180px - (calc(3 * 10px * 2) + calc( 3 * 6px * 2 )) ) / 2 - 10px / 2));
  }
}
.dial-ring:nth-child(7) .dial-ring-cap-end {
  left: 50%;
  top: 50%;
  transform-origin: center;
  z-index: 9999999999999;
  animation-name: rotate-ring-cap-4;
  animation-delay: 0.4s;
  animation-timing-function: ease-in-out;
  animation-duration: 0.6s;
  animation-fill-mode: forwards;
  transform: translate(-50%, -50%) rotate(-90deg) translate(calc(calc( 180px - (calc(3 * 10px * 2) + calc( 3 * 6px * 2 )) ) / 2 - 10px / 2));
}
@keyframes rotate-ring-cap-4 {
  29% {
    transform: translate(-50%, -50%) rotate(calc(var(--angle) * (29 / 100) - 90deg)) translate(calc(calc( 180px - (calc(3 * 10px * 2) + calc( 3 * 6px * 2 )) ) / 2 - 10px / 2));
  }
}
.dial-ring:nth-child(7) .dial-ring-cap-end {
  left: 50%;
  top: 50%;
  transform-origin: center;
  z-index: 9999999999999;
  animation-name: rotate-ring-cap-4;
  animation-delay: 0.4s;
  animation-timing-function: ease-in-out;
  animation-duration: 0.6s;
  animation-fill-mode: forwards;
  transform: translate(-50%, -50%) rotate(-90deg) translate(calc(calc( 180px - (calc(3 * 10px * 2) + calc( 3 * 6px * 2 )) ) / 2 - 10px / 2));
}
@keyframes rotate-ring-cap-4 {
  30% {
    transform: translate(-50%, -50%) rotate(calc(var(--angle) * (30 / 100) - 90deg)) translate(calc(calc( 180px - (calc(3 * 10px * 2) + calc( 3 * 6px * 2 )) ) / 2 - 10px / 2));
  }
}
.dial-ring:nth-child(7) .dial-ring-cap-end {
  left: 50%;
  top: 50%;
  transform-origin: center;
  z-index: 9999999999999;
  animation-name: rotate-ring-cap-4;
  animation-delay: 0.4s;
  animation-timing-function: ease-in-out;
  animation-duration: 0.6s;
  animation-fill-mode: forwards;
  transform: translate(-50%, -50%) rotate(-90deg) translate(calc(calc( 180px - (calc(3 * 10px * 2) + calc( 3 * 6px * 2 )) ) / 2 - 10px / 2));
}
@keyframes rotate-ring-cap-4 {
  31% {
    transform: translate(-50%, -50%) rotate(calc(var(--angle) * (31 / 100) - 90deg)) translate(calc(calc( 180px - (calc(3 * 10px * 2) + calc( 3 * 6px * 2 )) ) / 2 - 10px / 2));
  }
}
.dial-ring:nth-child(7) .dial-ring-cap-end {
  left: 50%;
  top: 50%;
  transform-origin: center;
  z-index: 9999999999999;
  animation-name: rotate-ring-cap-4;
  animation-delay: 0.4s;
  animation-timing-function: ease-in-out;
  animation-duration: 0.6s;
  animation-fill-mode: forwards;
  transform: translate(-50%, -50%) rotate(-90deg) translate(calc(calc( 180px - (calc(3 * 10px * 2) + calc( 3 * 6px * 2 )) ) / 2 - 10px / 2));
}
@keyframes rotate-ring-cap-4 {
  32% {
    transform: translate(-50%, -50%) rotate(calc(var(--angle) * (32 / 100) - 90deg)) translate(calc(calc( 180px - (calc(3 * 10px * 2) + calc( 3 * 6px * 2 )) ) / 2 - 10px / 2));
  }
}
.dial-ring:nth-child(7) .dial-ring-cap-end {
  left: 50%;
  top: 50%;
  transform-origin: center;
  z-index: 9999999999999;
  animation-name: rotate-ring-cap-4;
  animation-delay: 0.4s;
  animation-timing-function: ease-in-out;
  animation-duration: 0.6s;
  animation-fill-mode: forwards;
  transform: translate(-50%, -50%) rotate(-90deg) translate(calc(calc( 180px - (calc(3 * 10px * 2) + calc( 3 * 6px * 2 )) ) / 2 - 10px / 2));
}
@keyframes rotate-ring-cap-4 {
  33% {
    transform: translate(-50%, -50%) rotate(calc(var(--angle) * (33 / 100) - 90deg)) translate(calc(calc( 180px - (calc(3 * 10px * 2) + calc( 3 * 6px * 2 )) ) / 2 - 10px / 2));
  }
}
.dial-ring:nth-child(7) .dial-ring-cap-end {
  left: 50%;
  top: 50%;
  transform-origin: center;
  z-index: 9999999999999;
  animation-name: rotate-ring-cap-4;
  animation-delay: 0.4s;
  animation-timing-function: ease-in-out;
  animation-duration: 0.6s;
  animation-fill-mode: forwards;
  transform: translate(-50%, -50%) rotate(-90deg) translate(calc(calc( 180px - (calc(3 * 10px * 2) + calc( 3 * 6px * 2 )) ) / 2 - 10px / 2));
}
@keyframes rotate-ring-cap-4 {
  34% {
    transform: translate(-50%, -50%) rotate(calc(var(--angle) * (34 / 100) - 90deg)) translate(calc(calc( 180px - (calc(3 * 10px * 2) + calc( 3 * 6px * 2 )) ) / 2 - 10px / 2));
  }
}
.dial-ring:nth-child(7) .dial-ring-cap-end {
  left: 50%;
  top: 50%;
  transform-origin: center;
  z-index: 9999999999999;
  animation-name: rotate-ring-cap-4;
  animation-delay: 0.4s;
  animation-timing-function: ease-in-out;
  animation-duration: 0.6s;
  animation-fill-mode: forwards;
  transform: translate(-50%, -50%) rotate(-90deg) translate(calc(calc( 180px - (calc(3 * 10px * 2) + calc( 3 * 6px * 2 )) ) / 2 - 10px / 2));
}
@keyframes rotate-ring-cap-4 {
  35% {
    transform: translate(-50%, -50%) rotate(calc(var(--angle) * (35 / 100) - 90deg)) translate(calc(calc( 180px - (calc(3 * 10px * 2) + calc( 3 * 6px * 2 )) ) / 2 - 10px / 2));
  }
}
.dial-ring:nth-child(7) .dial-ring-cap-end {
  left: 50%;
  top: 50%;
  transform-origin: center;
  z-index: 9999999999999;
  animation-name: rotate-ring-cap-4;
  animation-delay: 0.4s;
  animation-timing-function: ease-in-out;
  animation-duration: 0.6s;
  animation-fill-mode: forwards;
  transform: translate(-50%, -50%) rotate(-90deg) translate(calc(calc( 180px - (calc(3 * 10px * 2) + calc( 3 * 6px * 2 )) ) / 2 - 10px / 2));
}
@keyframes rotate-ring-cap-4 {
  36% {
    transform: translate(-50%, -50%) rotate(calc(var(--angle) * (36 / 100) - 90deg)) translate(calc(calc( 180px - (calc(3 * 10px * 2) + calc( 3 * 6px * 2 )) ) / 2 - 10px / 2));
  }
}
.dial-ring:nth-child(7) .dial-ring-cap-end {
  left: 50%;
  top: 50%;
  transform-origin: center;
  z-index: 9999999999999;
  animation-name: rotate-ring-cap-4;
  animation-delay: 0.4s;
  animation-timing-function: ease-in-out;
  animation-duration: 0.6s;
  animation-fill-mode: forwards;
  transform: translate(-50%, -50%) rotate(-90deg) translate(calc(calc( 180px - (calc(3 * 10px * 2) + calc( 3 * 6px * 2 )) ) / 2 - 10px / 2));
}
@keyframes rotate-ring-cap-4 {
  37% {
    transform: translate(-50%, -50%) rotate(calc(var(--angle) * (37 / 100) - 90deg)) translate(calc(calc( 180px - (calc(3 * 10px * 2) + calc( 3 * 6px * 2 )) ) / 2 - 10px / 2));
  }
}
.dial-ring:nth-child(7) .dial-ring-cap-end {
  left: 50%;
  top: 50%;
  transform-origin: center;
  z-index: 9999999999999;
  animation-name: rotate-ring-cap-4;
  animation-delay: 0.4s;
  animation-timing-function: ease-in-out;
  animation-duration: 0.6s;
  animation-fill-mode: forwards;
  transform: translate(-50%, -50%) rotate(-90deg) translate(calc(calc( 180px - (calc(3 * 10px * 2) + calc( 3 * 6px * 2 )) ) / 2 - 10px / 2));
}
@keyframes rotate-ring-cap-4 {
  38% {
    transform: translate(-50%, -50%) rotate(calc(var(--angle) * (38 / 100) - 90deg)) translate(calc(calc( 180px - (calc(3 * 10px * 2) + calc( 3 * 6px * 2 )) ) / 2 - 10px / 2));
  }
}
.dial-ring:nth-child(7) .dial-ring-cap-end {
  left: 50%;
  top: 50%;
  transform-origin: center;
  z-index: 9999999999999;
  animation-name: rotate-ring-cap-4;
  animation-delay: 0.4s;
  animation-timing-function: ease-in-out;
  animation-duration: 0.6s;
  animation-fill-mode: forwards;
  transform: translate(-50%, -50%) rotate(-90deg) translate(calc(calc( 180px - (calc(3 * 10px * 2) + calc( 3 * 6px * 2 )) ) / 2 - 10px / 2));
}
@keyframes rotate-ring-cap-4 {
  39% {
    transform: translate(-50%, -50%) rotate(calc(var(--angle) * (39 / 100) - 90deg)) translate(calc(calc( 180px - (calc(3 * 10px * 2) + calc( 3 * 6px * 2 )) ) / 2 - 10px / 2));
  }
}
.dial-ring:nth-child(7) .dial-ring-cap-end {
  left: 50%;
  top: 50%;
  transform-origin: center;
  z-index: 9999999999999;
  animation-name: rotate-ring-cap-4;
  animation-delay: 0.4s;
  animation-timing-function: ease-in-out;
  animation-duration: 0.6s;
  animation-fill-mode: forwards;
  transform: translate(-50%, -50%) rotate(-90deg) translate(calc(calc( 180px - (calc(3 * 10px * 2) + calc( 3 * 6px * 2 )) ) / 2 - 10px / 2));
}
@keyframes rotate-ring-cap-4 {
  40% {
    transform: translate(-50%, -50%) rotate(calc(var(--angle) * (40 / 100) - 90deg)) translate(calc(calc( 180px - (calc(3 * 10px * 2) + calc( 3 * 6px * 2 )) ) / 2 - 10px / 2));
  }
}
.dial-ring:nth-child(7) .dial-ring-cap-end {
  left: 50%;
  top: 50%;
  transform-origin: center;
  z-index: 9999999999999;
  animation-name: rotate-ring-cap-4;
  animation-delay: 0.4s;
  animation-timing-function: ease-in-out;
  animation-duration: 0.6s;
  animation-fill-mode: forwards;
  transform: translate(-50%, -50%) rotate(-90deg) translate(calc(calc( 180px - (calc(3 * 10px * 2) + calc( 3 * 6px * 2 )) ) / 2 - 10px / 2));
}
@keyframes rotate-ring-cap-4 {
  41% {
    transform: translate(-50%, -50%) rotate(calc(var(--angle) * (41 / 100) - 90deg)) translate(calc(calc( 180px - (calc(3 * 10px * 2) + calc( 3 * 6px * 2 )) ) / 2 - 10px / 2));
  }
}
.dial-ring:nth-child(7) .dial-ring-cap-end {
  left: 50%;
  top: 50%;
  transform-origin: center;
  z-index: 9999999999999;
  animation-name: rotate-ring-cap-4;
  animation-delay: 0.4s;
  animation-timing-function: ease-in-out;
  animation-duration: 0.6s;
  animation-fill-mode: forwards;
  transform: translate(-50%, -50%) rotate(-90deg) translate(calc(calc( 180px - (calc(3 * 10px * 2) + calc( 3 * 6px * 2 )) ) / 2 - 10px / 2));
}
@keyframes rotate-ring-cap-4 {
  42% {
    transform: translate(-50%, -50%) rotate(calc(var(--angle) * (42 / 100) - 90deg)) translate(calc(calc( 180px - (calc(3 * 10px * 2) + calc( 3 * 6px * 2 )) ) / 2 - 10px / 2));
  }
}
.dial-ring:nth-child(7) .dial-ring-cap-end {
  left: 50%;
  top: 50%;
  transform-origin: center;
  z-index: 9999999999999;
  animation-name: rotate-ring-cap-4;
  animation-delay: 0.4s;
  animation-timing-function: ease-in-out;
  animation-duration: 0.6s;
  animation-fill-mode: forwards;
  transform: translate(-50%, -50%) rotate(-90deg) translate(calc(calc( 180px - (calc(3 * 10px * 2) + calc( 3 * 6px * 2 )) ) / 2 - 10px / 2));
}
@keyframes rotate-ring-cap-4 {
  43% {
    transform: translate(-50%, -50%) rotate(calc(var(--angle) * (43 / 100) - 90deg)) translate(calc(calc( 180px - (calc(3 * 10px * 2) + calc( 3 * 6px * 2 )) ) / 2 - 10px / 2));
  }
}
.dial-ring:nth-child(7) .dial-ring-cap-end {
  left: 50%;
  top: 50%;
  transform-origin: center;
  z-index: 9999999999999;
  animation-name: rotate-ring-cap-4;
  animation-delay: 0.4s;
  animation-timing-function: ease-in-out;
  animation-duration: 0.6s;
  animation-fill-mode: forwards;
  transform: translate(-50%, -50%) rotate(-90deg) translate(calc(calc( 180px - (calc(3 * 10px * 2) + calc( 3 * 6px * 2 )) ) / 2 - 10px / 2));
}
@keyframes rotate-ring-cap-4 {
  44% {
    transform: translate(-50%, -50%) rotate(calc(var(--angle) * (44 / 100) - 90deg)) translate(calc(calc( 180px - (calc(3 * 10px * 2) + calc( 3 * 6px * 2 )) ) / 2 - 10px / 2));
  }
}
.dial-ring:nth-child(7) .dial-ring-cap-end {
  left: 50%;
  top: 50%;
  transform-origin: center;
  z-index: 9999999999999;
  animation-name: rotate-ring-cap-4;
  animation-delay: 0.4s;
  animation-timing-function: ease-in-out;
  animation-duration: 0.6s;
  animation-fill-mode: forwards;
  transform: translate(-50%, -50%) rotate(-90deg) translate(calc(calc( 180px - (calc(3 * 10px * 2) + calc( 3 * 6px * 2 )) ) / 2 - 10px / 2));
}
@keyframes rotate-ring-cap-4 {
  45% {
    transform: translate(-50%, -50%) rotate(calc(var(--angle) * (45 / 100) - 90deg)) translate(calc(calc( 180px - (calc(3 * 10px * 2) + calc( 3 * 6px * 2 )) ) / 2 - 10px / 2));
  }
}
.dial-ring:nth-child(7) .dial-ring-cap-end {
  left: 50%;
  top: 50%;
  transform-origin: center;
  z-index: 9999999999999;
  animation-name: rotate-ring-cap-4;
  animation-delay: 0.4s;
  animation-timing-function: ease-in-out;
  animation-duration: 0.6s;
  animation-fill-mode: forwards;
  transform: translate(-50%, -50%) rotate(-90deg) translate(calc(calc( 180px - (calc(3 * 10px * 2) + calc( 3 * 6px * 2 )) ) / 2 - 10px / 2));
}
@keyframes rotate-ring-cap-4 {
  46% {
    transform: translate(-50%, -50%) rotate(calc(var(--angle) * (46 / 100) - 90deg)) translate(calc(calc( 180px - (calc(3 * 10px * 2) + calc( 3 * 6px * 2 )) ) / 2 - 10px / 2));
  }
}
.dial-ring:nth-child(7) .dial-ring-cap-end {
  left: 50%;
  top: 50%;
  transform-origin: center;
  z-index: 9999999999999;
  animation-name: rotate-ring-cap-4;
  animation-delay: 0.4s;
  animation-timing-function: ease-in-out;
  animation-duration: 0.6s;
  animation-fill-mode: forwards;
  transform: translate(-50%, -50%) rotate(-90deg) translate(calc(calc( 180px - (calc(3 * 10px * 2) + calc( 3 * 6px * 2 )) ) / 2 - 10px / 2));
}
@keyframes rotate-ring-cap-4 {
  47% {
    transform: translate(-50%, -50%) rotate(calc(var(--angle) * (47 / 100) - 90deg)) translate(calc(calc( 180px - (calc(3 * 10px * 2) + calc( 3 * 6px * 2 )) ) / 2 - 10px / 2));
  }
}
.dial-ring:nth-child(7) .dial-ring-cap-end {
  left: 50%;
  top: 50%;
  transform-origin: center;
  z-index: 9999999999999;
  animation-name: rotate-ring-cap-4;
  animation-delay: 0.4s;
  animation-timing-function: ease-in-out;
  animation-duration: 0.6s;
  animation-fill-mode: forwards;
  transform: translate(-50%, -50%) rotate(-90deg) translate(calc(calc( 180px - (calc(3 * 10px * 2) + calc( 3 * 6px * 2 )) ) / 2 - 10px / 2));
}
@keyframes rotate-ring-cap-4 {
  48% {
    transform: translate(-50%, -50%) rotate(calc(var(--angle) * (48 / 100) - 90deg)) translate(calc(calc( 180px - (calc(3 * 10px * 2) + calc( 3 * 6px * 2 )) ) / 2 - 10px / 2));
  }
}
.dial-ring:nth-child(7) .dial-ring-cap-end {
  left: 50%;
  top: 50%;
  transform-origin: center;
  z-index: 9999999999999;
  animation-name: rotate-ring-cap-4;
  animation-delay: 0.4s;
  animation-timing-function: ease-in-out;
  animation-duration: 0.6s;
  animation-fill-mode: forwards;
  transform: translate(-50%, -50%) rotate(-90deg) translate(calc(calc( 180px - (calc(3 * 10px * 2) + calc( 3 * 6px * 2 )) ) / 2 - 10px / 2));
}
@keyframes rotate-ring-cap-4 {
  49% {
    transform: translate(-50%, -50%) rotate(calc(var(--angle) * (49 / 100) - 90deg)) translate(calc(calc( 180px - (calc(3 * 10px * 2) + calc( 3 * 6px * 2 )) ) / 2 - 10px / 2));
  }
}
.dial-ring:nth-child(7) .dial-ring-cap-end {
  left: 50%;
  top: 50%;
  transform-origin: center;
  z-index: 9999999999999;
  animation-name: rotate-ring-cap-4;
  animation-delay: 0.4s;
  animation-timing-function: ease-in-out;
  animation-duration: 0.6s;
  animation-fill-mode: forwards;
  transform: translate(-50%, -50%) rotate(-90deg) translate(calc(calc( 180px - (calc(3 * 10px * 2) + calc( 3 * 6px * 2 )) ) / 2 - 10px / 2));
}
@keyframes rotate-ring-cap-4 {
  50% {
    transform: translate(-50%, -50%) rotate(calc(var(--angle) * (50 / 100) - 90deg)) translate(calc(calc( 180px - (calc(3 * 10px * 2) + calc( 3 * 6px * 2 )) ) / 2 - 10px / 2));
  }
}
.dial-ring:nth-child(7) .dial-ring-cap-end {
  left: 50%;
  top: 50%;
  transform-origin: center;
  z-index: 9999999999999;
  animation-name: rotate-ring-cap-4;
  animation-delay: 0.4s;
  animation-timing-function: ease-in-out;
  animation-duration: 0.6s;
  animation-fill-mode: forwards;
  transform: translate(-50%, -50%) rotate(-90deg) translate(calc(calc( 180px - (calc(3 * 10px * 2) + calc( 3 * 6px * 2 )) ) / 2 - 10px / 2));
}
@keyframes rotate-ring-cap-4 {
  51% {
    transform: translate(-50%, -50%) rotate(calc(var(--angle) * (51 / 100) - 90deg)) translate(calc(calc( 180px - (calc(3 * 10px * 2) + calc( 3 * 6px * 2 )) ) / 2 - 10px / 2));
  }
}
.dial-ring:nth-child(7) .dial-ring-cap-end {
  left: 50%;
  top: 50%;
  transform-origin: center;
  z-index: 9999999999999;
  animation-name: rotate-ring-cap-4;
  animation-delay: 0.4s;
  animation-timing-function: ease-in-out;
  animation-duration: 0.6s;
  animation-fill-mode: forwards;
  transform: translate(-50%, -50%) rotate(-90deg) translate(calc(calc( 180px - (calc(3 * 10px * 2) + calc( 3 * 6px * 2 )) ) / 2 - 10px / 2));
}
@keyframes rotate-ring-cap-4 {
  52% {
    transform: translate(-50%, -50%) rotate(calc(var(--angle) * (52 / 100) - 90deg)) translate(calc(calc( 180px - (calc(3 * 10px * 2) + calc( 3 * 6px * 2 )) ) / 2 - 10px / 2));
  }
}
.dial-ring:nth-child(7) .dial-ring-cap-end {
  left: 50%;
  top: 50%;
  transform-origin: center;
  z-index: 9999999999999;
  animation-name: rotate-ring-cap-4;
  animation-delay: 0.4s;
  animation-timing-function: ease-in-out;
  animation-duration: 0.6s;
  animation-fill-mode: forwards;
  transform: translate(-50%, -50%) rotate(-90deg) translate(calc(calc( 180px - (calc(3 * 10px * 2) + calc( 3 * 6px * 2 )) ) / 2 - 10px / 2));
}
@keyframes rotate-ring-cap-4 {
  53% {
    transform: translate(-50%, -50%) rotate(calc(var(--angle) * (53 / 100) - 90deg)) translate(calc(calc( 180px - (calc(3 * 10px * 2) + calc( 3 * 6px * 2 )) ) / 2 - 10px / 2));
  }
}
.dial-ring:nth-child(7) .dial-ring-cap-end {
  left: 50%;
  top: 50%;
  transform-origin: center;
  z-index: 9999999999999;
  animation-name: rotate-ring-cap-4;
  animation-delay: 0.4s;
  animation-timing-function: ease-in-out;
  animation-duration: 0.6s;
  animation-fill-mode: forwards;
  transform: translate(-50%, -50%) rotate(-90deg) translate(calc(calc( 180px - (calc(3 * 10px * 2) + calc( 3 * 6px * 2 )) ) / 2 - 10px / 2));
}
@keyframes rotate-ring-cap-4 {
  54% {
    transform: translate(-50%, -50%) rotate(calc(var(--angle) * (54 / 100) - 90deg)) translate(calc(calc( 180px - (calc(3 * 10px * 2) + calc( 3 * 6px * 2 )) ) / 2 - 10px / 2));
  }
}
.dial-ring:nth-child(7) .dial-ring-cap-end {
  left: 50%;
  top: 50%;
  transform-origin: center;
  z-index: 9999999999999;
  animation-name: rotate-ring-cap-4;
  animation-delay: 0.4s;
  animation-timing-function: ease-in-out;
  animation-duration: 0.6s;
  animation-fill-mode: forwards;
  transform: translate(-50%, -50%) rotate(-90deg) translate(calc(calc( 180px - (calc(3 * 10px * 2) + calc( 3 * 6px * 2 )) ) / 2 - 10px / 2));
}
@keyframes rotate-ring-cap-4 {
  55% {
    transform: translate(-50%, -50%) rotate(calc(var(--angle) * (55 / 100) - 90deg)) translate(calc(calc( 180px - (calc(3 * 10px * 2) + calc( 3 * 6px * 2 )) ) / 2 - 10px / 2));
  }
}
.dial-ring:nth-child(7) .dial-ring-cap-end {
  left: 50%;
  top: 50%;
  transform-origin: center;
  z-index: 9999999999999;
  animation-name: rotate-ring-cap-4;
  animation-delay: 0.4s;
  animation-timing-function: ease-in-out;
  animation-duration: 0.6s;
  animation-fill-mode: forwards;
  transform: translate(-50%, -50%) rotate(-90deg) translate(calc(calc( 180px - (calc(3 * 10px * 2) + calc( 3 * 6px * 2 )) ) / 2 - 10px / 2));
}
@keyframes rotate-ring-cap-4 {
  56% {
    transform: translate(-50%, -50%) rotate(calc(var(--angle) * (56 / 100) - 90deg)) translate(calc(calc( 180px - (calc(3 * 10px * 2) + calc( 3 * 6px * 2 )) ) / 2 - 10px / 2));
  }
}
.dial-ring:nth-child(7) .dial-ring-cap-end {
  left: 50%;
  top: 50%;
  transform-origin: center;
  z-index: 9999999999999;
  animation-name: rotate-ring-cap-4;
  animation-delay: 0.4s;
  animation-timing-function: ease-in-out;
  animation-duration: 0.6s;
  animation-fill-mode: forwards;
  transform: translate(-50%, -50%) rotate(-90deg) translate(calc(calc( 180px - (calc(3 * 10px * 2) + calc( 3 * 6px * 2 )) ) / 2 - 10px / 2));
}
@keyframes rotate-ring-cap-4 {
  57% {
    transform: translate(-50%, -50%) rotate(calc(var(--angle) * (57 / 100) - 90deg)) translate(calc(calc( 180px - (calc(3 * 10px * 2) + calc( 3 * 6px * 2 )) ) / 2 - 10px / 2));
  }
}
.dial-ring:nth-child(7) .dial-ring-cap-end {
  left: 50%;
  top: 50%;
  transform-origin: center;
  z-index: 9999999999999;
  animation-name: rotate-ring-cap-4;
  animation-delay: 0.4s;
  animation-timing-function: ease-in-out;
  animation-duration: 0.6s;
  animation-fill-mode: forwards;
  transform: translate(-50%, -50%) rotate(-90deg) translate(calc(calc( 180px - (calc(3 * 10px * 2) + calc( 3 * 6px * 2 )) ) / 2 - 10px / 2));
}
@keyframes rotate-ring-cap-4 {
  58% {
    transform: translate(-50%, -50%) rotate(calc(var(--angle) * (58 / 100) - 90deg)) translate(calc(calc( 180px - (calc(3 * 10px * 2) + calc( 3 * 6px * 2 )) ) / 2 - 10px / 2));
  }
}
.dial-ring:nth-child(7) .dial-ring-cap-end {
  left: 50%;
  top: 50%;
  transform-origin: center;
  z-index: 9999999999999;
  animation-name: rotate-ring-cap-4;
  animation-delay: 0.4s;
  animation-timing-function: ease-in-out;
  animation-duration: 0.6s;
  animation-fill-mode: forwards;
  transform: translate(-50%, -50%) rotate(-90deg) translate(calc(calc( 180px - (calc(3 * 10px * 2) + calc( 3 * 6px * 2 )) ) / 2 - 10px / 2));
}
@keyframes rotate-ring-cap-4 {
  59% {
    transform: translate(-50%, -50%) rotate(calc(var(--angle) * (59 / 100) - 90deg)) translate(calc(calc( 180px - (calc(3 * 10px * 2) + calc( 3 * 6px * 2 )) ) / 2 - 10px / 2));
  }
}
.dial-ring:nth-child(7) .dial-ring-cap-end {
  left: 50%;
  top: 50%;
  transform-origin: center;
  z-index: 9999999999999;
  animation-name: rotate-ring-cap-4;
  animation-delay: 0.4s;
  animation-timing-function: ease-in-out;
  animation-duration: 0.6s;
  animation-fill-mode: forwards;
  transform: translate(-50%, -50%) rotate(-90deg) translate(calc(calc( 180px - (calc(3 * 10px * 2) + calc( 3 * 6px * 2 )) ) / 2 - 10px / 2));
}
@keyframes rotate-ring-cap-4 {
  60% {
    transform: translate(-50%, -50%) rotate(calc(var(--angle) * (60 / 100) - 90deg)) translate(calc(calc( 180px - (calc(3 * 10px * 2) + calc( 3 * 6px * 2 )) ) / 2 - 10px / 2));
  }
}
.dial-ring:nth-child(7) .dial-ring-cap-end {
  left: 50%;
  top: 50%;
  transform-origin: center;
  z-index: 9999999999999;
  animation-name: rotate-ring-cap-4;
  animation-delay: 0.4s;
  animation-timing-function: ease-in-out;
  animation-duration: 0.6s;
  animation-fill-mode: forwards;
  transform: translate(-50%, -50%) rotate(-90deg) translate(calc(calc( 180px - (calc(3 * 10px * 2) + calc( 3 * 6px * 2 )) ) / 2 - 10px / 2));
}
@keyframes rotate-ring-cap-4 {
  61% {
    transform: translate(-50%, -50%) rotate(calc(var(--angle) * (61 / 100) - 90deg)) translate(calc(calc( 180px - (calc(3 * 10px * 2) + calc( 3 * 6px * 2 )) ) / 2 - 10px / 2));
  }
}
.dial-ring:nth-child(7) .dial-ring-cap-end {
  left: 50%;
  top: 50%;
  transform-origin: center;
  z-index: 9999999999999;
  animation-name: rotate-ring-cap-4;
  animation-delay: 0.4s;
  animation-timing-function: ease-in-out;
  animation-duration: 0.6s;
  animation-fill-mode: forwards;
  transform: translate(-50%, -50%) rotate(-90deg) translate(calc(calc( 180px - (calc(3 * 10px * 2) + calc( 3 * 6px * 2 )) ) / 2 - 10px / 2));
}
@keyframes rotate-ring-cap-4 {
  62% {
    transform: translate(-50%, -50%) rotate(calc(var(--angle) * (62 / 100) - 90deg)) translate(calc(calc( 180px - (calc(3 * 10px * 2) + calc( 3 * 6px * 2 )) ) / 2 - 10px / 2));
  }
}
.dial-ring:nth-child(7) .dial-ring-cap-end {
  left: 50%;
  top: 50%;
  transform-origin: center;
  z-index: 9999999999999;
  animation-name: rotate-ring-cap-4;
  animation-delay: 0.4s;
  animation-timing-function: ease-in-out;
  animation-duration: 0.6s;
  animation-fill-mode: forwards;
  transform: translate(-50%, -50%) rotate(-90deg) translate(calc(calc( 180px - (calc(3 * 10px * 2) + calc( 3 * 6px * 2 )) ) / 2 - 10px / 2));
}
@keyframes rotate-ring-cap-4 {
  63% {
    transform: translate(-50%, -50%) rotate(calc(var(--angle) * (63 / 100) - 90deg)) translate(calc(calc( 180px - (calc(3 * 10px * 2) + calc( 3 * 6px * 2 )) ) / 2 - 10px / 2));
  }
}
.dial-ring:nth-child(7) .dial-ring-cap-end {
  left: 50%;
  top: 50%;
  transform-origin: center;
  z-index: 9999999999999;
  animation-name: rotate-ring-cap-4;
  animation-delay: 0.4s;
  animation-timing-function: ease-in-out;
  animation-duration: 0.6s;
  animation-fill-mode: forwards;
  transform: translate(-50%, -50%) rotate(-90deg) translate(calc(calc( 180px - (calc(3 * 10px * 2) + calc( 3 * 6px * 2 )) ) / 2 - 10px / 2));
}
@keyframes rotate-ring-cap-4 {
  64% {
    transform: translate(-50%, -50%) rotate(calc(var(--angle) * (64 / 100) - 90deg)) translate(calc(calc( 180px - (calc(3 * 10px * 2) + calc( 3 * 6px * 2 )) ) / 2 - 10px / 2));
  }
}
.dial-ring:nth-child(7) .dial-ring-cap-end {
  left: 50%;
  top: 50%;
  transform-origin: center;
  z-index: 9999999999999;
  animation-name: rotate-ring-cap-4;
  animation-delay: 0.4s;
  animation-timing-function: ease-in-out;
  animation-duration: 0.6s;
  animation-fill-mode: forwards;
  transform: translate(-50%, -50%) rotate(-90deg) translate(calc(calc( 180px - (calc(3 * 10px * 2) + calc( 3 * 6px * 2 )) ) / 2 - 10px / 2));
}
@keyframes rotate-ring-cap-4 {
  65% {
    transform: translate(-50%, -50%) rotate(calc(var(--angle) * (65 / 100) - 90deg)) translate(calc(calc( 180px - (calc(3 * 10px * 2) + calc( 3 * 6px * 2 )) ) / 2 - 10px / 2));
  }
}
.dial-ring:nth-child(7) .dial-ring-cap-end {
  left: 50%;
  top: 50%;
  transform-origin: center;
  z-index: 9999999999999;
  animation-name: rotate-ring-cap-4;
  animation-delay: 0.4s;
  animation-timing-function: ease-in-out;
  animation-duration: 0.6s;
  animation-fill-mode: forwards;
  transform: translate(-50%, -50%) rotate(-90deg) translate(calc(calc( 180px - (calc(3 * 10px * 2) + calc( 3 * 6px * 2 )) ) / 2 - 10px / 2));
}
@keyframes rotate-ring-cap-4 {
  66% {
    transform: translate(-50%, -50%) rotate(calc(var(--angle) * (66 / 100) - 90deg)) translate(calc(calc( 180px - (calc(3 * 10px * 2) + calc( 3 * 6px * 2 )) ) / 2 - 10px / 2));
  }
}
.dial-ring:nth-child(7) .dial-ring-cap-end {
  left: 50%;
  top: 50%;
  transform-origin: center;
  z-index: 9999999999999;
  animation-name: rotate-ring-cap-4;
  animation-delay: 0.4s;
  animation-timing-function: ease-in-out;
  animation-duration: 0.6s;
  animation-fill-mode: forwards;
  transform: translate(-50%, -50%) rotate(-90deg) translate(calc(calc( 180px - (calc(3 * 10px * 2) + calc( 3 * 6px * 2 )) ) / 2 - 10px / 2));
}
@keyframes rotate-ring-cap-4 {
  67% {
    transform: translate(-50%, -50%) rotate(calc(var(--angle) * (67 / 100) - 90deg)) translate(calc(calc( 180px - (calc(3 * 10px * 2) + calc( 3 * 6px * 2 )) ) / 2 - 10px / 2));
  }
}
.dial-ring:nth-child(7) .dial-ring-cap-end {
  left: 50%;
  top: 50%;
  transform-origin: center;
  z-index: 9999999999999;
  animation-name: rotate-ring-cap-4;
  animation-delay: 0.4s;
  animation-timing-function: ease-in-out;
  animation-duration: 0.6s;
  animation-fill-mode: forwards;
  transform: translate(-50%, -50%) rotate(-90deg) translate(calc(calc( 180px - (calc(3 * 10px * 2) + calc( 3 * 6px * 2 )) ) / 2 - 10px / 2));
}
@keyframes rotate-ring-cap-4 {
  68% {
    transform: translate(-50%, -50%) rotate(calc(var(--angle) * (68 / 100) - 90deg)) translate(calc(calc( 180px - (calc(3 * 10px * 2) + calc( 3 * 6px * 2 )) ) / 2 - 10px / 2));
  }
}
.dial-ring:nth-child(7) .dial-ring-cap-end {
  left: 50%;
  top: 50%;
  transform-origin: center;
  z-index: 9999999999999;
  animation-name: rotate-ring-cap-4;
  animation-delay: 0.4s;
  animation-timing-function: ease-in-out;
  animation-duration: 0.6s;
  animation-fill-mode: forwards;
  transform: translate(-50%, -50%) rotate(-90deg) translate(calc(calc( 180px - (calc(3 * 10px * 2) + calc( 3 * 6px * 2 )) ) / 2 - 10px / 2));
}
@keyframes rotate-ring-cap-4 {
  69% {
    transform: translate(-50%, -50%) rotate(calc(var(--angle) * (69 / 100) - 90deg)) translate(calc(calc( 180px - (calc(3 * 10px * 2) + calc( 3 * 6px * 2 )) ) / 2 - 10px / 2));
  }
}
.dial-ring:nth-child(7) .dial-ring-cap-end {
  left: 50%;
  top: 50%;
  transform-origin: center;
  z-index: 9999999999999;
  animation-name: rotate-ring-cap-4;
  animation-delay: 0.4s;
  animation-timing-function: ease-in-out;
  animation-duration: 0.6s;
  animation-fill-mode: forwards;
  transform: translate(-50%, -50%) rotate(-90deg) translate(calc(calc( 180px - (calc(3 * 10px * 2) + calc( 3 * 6px * 2 )) ) / 2 - 10px / 2));
}
@keyframes rotate-ring-cap-4 {
  70% {
    transform: translate(-50%, -50%) rotate(calc(var(--angle) * (70 / 100) - 90deg)) translate(calc(calc( 180px - (calc(3 * 10px * 2) + calc( 3 * 6px * 2 )) ) / 2 - 10px / 2));
  }
}
.dial-ring:nth-child(7) .dial-ring-cap-end {
  left: 50%;
  top: 50%;
  transform-origin: center;
  z-index: 9999999999999;
  animation-name: rotate-ring-cap-4;
  animation-delay: 0.4s;
  animation-timing-function: ease-in-out;
  animation-duration: 0.6s;
  animation-fill-mode: forwards;
  transform: translate(-50%, -50%) rotate(-90deg) translate(calc(calc( 180px - (calc(3 * 10px * 2) + calc( 3 * 6px * 2 )) ) / 2 - 10px / 2));
}
@keyframes rotate-ring-cap-4 {
  71% {
    transform: translate(-50%, -50%) rotate(calc(var(--angle) * (71 / 100) - 90deg)) translate(calc(calc( 180px - (calc(3 * 10px * 2) + calc( 3 * 6px * 2 )) ) / 2 - 10px / 2));
  }
}
.dial-ring:nth-child(7) .dial-ring-cap-end {
  left: 50%;
  top: 50%;
  transform-origin: center;
  z-index: 9999999999999;
  animation-name: rotate-ring-cap-4;
  animation-delay: 0.4s;
  animation-timing-function: ease-in-out;
  animation-duration: 0.6s;
  animation-fill-mode: forwards;
  transform: translate(-50%, -50%) rotate(-90deg) translate(calc(calc( 180px - (calc(3 * 10px * 2) + calc( 3 * 6px * 2 )) ) / 2 - 10px / 2));
}
@keyframes rotate-ring-cap-4 {
  72% {
    transform: translate(-50%, -50%) rotate(calc(var(--angle) * (72 / 100) - 90deg)) translate(calc(calc( 180px - (calc(3 * 10px * 2) + calc( 3 * 6px * 2 )) ) / 2 - 10px / 2));
  }
}
.dial-ring:nth-child(7) .dial-ring-cap-end {
  left: 50%;
  top: 50%;
  transform-origin: center;
  z-index: 9999999999999;
  animation-name: rotate-ring-cap-4;
  animation-delay: 0.4s;
  animation-timing-function: ease-in-out;
  animation-duration: 0.6s;
  animation-fill-mode: forwards;
  transform: translate(-50%, -50%) rotate(-90deg) translate(calc(calc( 180px - (calc(3 * 10px * 2) + calc( 3 * 6px * 2 )) ) / 2 - 10px / 2));
}
@keyframes rotate-ring-cap-4 {
  73% {
    transform: translate(-50%, -50%) rotate(calc(var(--angle) * (73 / 100) - 90deg)) translate(calc(calc( 180px - (calc(3 * 10px * 2) + calc( 3 * 6px * 2 )) ) / 2 - 10px / 2));
  }
}
.dial-ring:nth-child(7) .dial-ring-cap-end {
  left: 50%;
  top: 50%;
  transform-origin: center;
  z-index: 9999999999999;
  animation-name: rotate-ring-cap-4;
  animation-delay: 0.4s;
  animation-timing-function: ease-in-out;
  animation-duration: 0.6s;
  animation-fill-mode: forwards;
  transform: translate(-50%, -50%) rotate(-90deg) translate(calc(calc( 180px - (calc(3 * 10px * 2) + calc( 3 * 6px * 2 )) ) / 2 - 10px / 2));
}
@keyframes rotate-ring-cap-4 {
  74% {
    transform: translate(-50%, -50%) rotate(calc(var(--angle) * (74 / 100) - 90deg)) translate(calc(calc( 180px - (calc(3 * 10px * 2) + calc( 3 * 6px * 2 )) ) / 2 - 10px / 2));
  }
}
.dial-ring:nth-child(7) .dial-ring-cap-end {
  left: 50%;
  top: 50%;
  transform-origin: center;
  z-index: 9999999999999;
  animation-name: rotate-ring-cap-4;
  animation-delay: 0.4s;
  animation-timing-function: ease-in-out;
  animation-duration: 0.6s;
  animation-fill-mode: forwards;
  transform: translate(-50%, -50%) rotate(-90deg) translate(calc(calc( 180px - (calc(3 * 10px * 2) + calc( 3 * 6px * 2 )) ) / 2 - 10px / 2));
}
@keyframes rotate-ring-cap-4 {
  75% {
    transform: translate(-50%, -50%) rotate(calc(var(--angle) * (75 / 100) - 90deg)) translate(calc(calc( 180px - (calc(3 * 10px * 2) + calc( 3 * 6px * 2 )) ) / 2 - 10px / 2));
  }
}
.dial-ring:nth-child(7) .dial-ring-cap-end {
  left: 50%;
  top: 50%;
  transform-origin: center;
  z-index: 9999999999999;
  animation-name: rotate-ring-cap-4;
  animation-delay: 0.4s;
  animation-timing-function: ease-in-out;
  animation-duration: 0.6s;
  animation-fill-mode: forwards;
  transform: translate(-50%, -50%) rotate(-90deg) translate(calc(calc( 180px - (calc(3 * 10px * 2) + calc( 3 * 6px * 2 )) ) / 2 - 10px / 2));
}
@keyframes rotate-ring-cap-4 {
  76% {
    transform: translate(-50%, -50%) rotate(calc(var(--angle) * (76 / 100) - 90deg)) translate(calc(calc( 180px - (calc(3 * 10px * 2) + calc( 3 * 6px * 2 )) ) / 2 - 10px / 2));
  }
}
.dial-ring:nth-child(7) .dial-ring-cap-end {
  left: 50%;
  top: 50%;
  transform-origin: center;
  z-index: 9999999999999;
  animation-name: rotate-ring-cap-4;
  animation-delay: 0.4s;
  animation-timing-function: ease-in-out;
  animation-duration: 0.6s;
  animation-fill-mode: forwards;
  transform: translate(-50%, -50%) rotate(-90deg) translate(calc(calc( 180px - (calc(3 * 10px * 2) + calc( 3 * 6px * 2 )) ) / 2 - 10px / 2));
}
@keyframes rotate-ring-cap-4 {
  77% {
    transform: translate(-50%, -50%) rotate(calc(var(--angle) * (77 / 100) - 90deg)) translate(calc(calc( 180px - (calc(3 * 10px * 2) + calc( 3 * 6px * 2 )) ) / 2 - 10px / 2));
  }
}
.dial-ring:nth-child(7) .dial-ring-cap-end {
  left: 50%;
  top: 50%;
  transform-origin: center;
  z-index: 9999999999999;
  animation-name: rotate-ring-cap-4;
  animation-delay: 0.4s;
  animation-timing-function: ease-in-out;
  animation-duration: 0.6s;
  animation-fill-mode: forwards;
  transform: translate(-50%, -50%) rotate(-90deg) translate(calc(calc( 180px - (calc(3 * 10px * 2) + calc( 3 * 6px * 2 )) ) / 2 - 10px / 2));
}
@keyframes rotate-ring-cap-4 {
  78% {
    transform: translate(-50%, -50%) rotate(calc(var(--angle) * (78 / 100) - 90deg)) translate(calc(calc( 180px - (calc(3 * 10px * 2) + calc( 3 * 6px * 2 )) ) / 2 - 10px / 2));
  }
}
.dial-ring:nth-child(7) .dial-ring-cap-end {
  left: 50%;
  top: 50%;
  transform-origin: center;
  z-index: 9999999999999;
  animation-name: rotate-ring-cap-4;
  animation-delay: 0.4s;
  animation-timing-function: ease-in-out;
  animation-duration: 0.6s;
  animation-fill-mode: forwards;
  transform: translate(-50%, -50%) rotate(-90deg) translate(calc(calc( 180px - (calc(3 * 10px * 2) + calc( 3 * 6px * 2 )) ) / 2 - 10px / 2));
}
@keyframes rotate-ring-cap-4 {
  79% {
    transform: translate(-50%, -50%) rotate(calc(var(--angle) * (79 / 100) - 90deg)) translate(calc(calc( 180px - (calc(3 * 10px * 2) + calc( 3 * 6px * 2 )) ) / 2 - 10px / 2));
  }
}
.dial-ring:nth-child(7) .dial-ring-cap-end {
  left: 50%;
  top: 50%;
  transform-origin: center;
  z-index: 9999999999999;
  animation-name: rotate-ring-cap-4;
  animation-delay: 0.4s;
  animation-timing-function: ease-in-out;
  animation-duration: 0.6s;
  animation-fill-mode: forwards;
  transform: translate(-50%, -50%) rotate(-90deg) translate(calc(calc( 180px - (calc(3 * 10px * 2) + calc( 3 * 6px * 2 )) ) / 2 - 10px / 2));
}
@keyframes rotate-ring-cap-4 {
  80% {
    transform: translate(-50%, -50%) rotate(calc(var(--angle) * (80 / 100) - 90deg)) translate(calc(calc( 180px - (calc(3 * 10px * 2) + calc( 3 * 6px * 2 )) ) / 2 - 10px / 2));
  }
}
.dial-ring:nth-child(7) .dial-ring-cap-end {
  left: 50%;
  top: 50%;
  transform-origin: center;
  z-index: 9999999999999;
  animation-name: rotate-ring-cap-4;
  animation-delay: 0.4s;
  animation-timing-function: ease-in-out;
  animation-duration: 0.6s;
  animation-fill-mode: forwards;
  transform: translate(-50%, -50%) rotate(-90deg) translate(calc(calc( 180px - (calc(3 * 10px * 2) + calc( 3 * 6px * 2 )) ) / 2 - 10px / 2));
}
@keyframes rotate-ring-cap-4 {
  81% {
    transform: translate(-50%, -50%) rotate(calc(var(--angle) * (81 / 100) - 90deg)) translate(calc(calc( 180px - (calc(3 * 10px * 2) + calc( 3 * 6px * 2 )) ) / 2 - 10px / 2));
  }
}
.dial-ring:nth-child(7) .dial-ring-cap-end {
  left: 50%;
  top: 50%;
  transform-origin: center;
  z-index: 9999999999999;
  animation-name: rotate-ring-cap-4;
  animation-delay: 0.4s;
  animation-timing-function: ease-in-out;
  animation-duration: 0.6s;
  animation-fill-mode: forwards;
  transform: translate(-50%, -50%) rotate(-90deg) translate(calc(calc( 180px - (calc(3 * 10px * 2) + calc( 3 * 6px * 2 )) ) / 2 - 10px / 2));
}
@keyframes rotate-ring-cap-4 {
  82% {
    transform: translate(-50%, -50%) rotate(calc(var(--angle) * (82 / 100) - 90deg)) translate(calc(calc( 180px - (calc(3 * 10px * 2) + calc( 3 * 6px * 2 )) ) / 2 - 10px / 2));
  }
}
.dial-ring:nth-child(7) .dial-ring-cap-end {
  left: 50%;
  top: 50%;
  transform-origin: center;
  z-index: 9999999999999;
  animation-name: rotate-ring-cap-4;
  animation-delay: 0.4s;
  animation-timing-function: ease-in-out;
  animation-duration: 0.6s;
  animation-fill-mode: forwards;
  transform: translate(-50%, -50%) rotate(-90deg) translate(calc(calc( 180px - (calc(3 * 10px * 2) + calc( 3 * 6px * 2 )) ) / 2 - 10px / 2));
}
@keyframes rotate-ring-cap-4 {
  83% {
    transform: translate(-50%, -50%) rotate(calc(var(--angle) * (83 / 100) - 90deg)) translate(calc(calc( 180px - (calc(3 * 10px * 2) + calc( 3 * 6px * 2 )) ) / 2 - 10px / 2));
  }
}
.dial-ring:nth-child(7) .dial-ring-cap-end {
  left: 50%;
  top: 50%;
  transform-origin: center;
  z-index: 9999999999999;
  animation-name: rotate-ring-cap-4;
  animation-delay: 0.4s;
  animation-timing-function: ease-in-out;
  animation-duration: 0.6s;
  animation-fill-mode: forwards;
  transform: translate(-50%, -50%) rotate(-90deg) translate(calc(calc( 180px - (calc(3 * 10px * 2) + calc( 3 * 6px * 2 )) ) / 2 - 10px / 2));
}
@keyframes rotate-ring-cap-4 {
  84% {
    transform: translate(-50%, -50%) rotate(calc(var(--angle) * (84 / 100) - 90deg)) translate(calc(calc( 180px - (calc(3 * 10px * 2) + calc( 3 * 6px * 2 )) ) / 2 - 10px / 2));
  }
}
.dial-ring:nth-child(7) .dial-ring-cap-end {
  left: 50%;
  top: 50%;
  transform-origin: center;
  z-index: 9999999999999;
  animation-name: rotate-ring-cap-4;
  animation-delay: 0.4s;
  animation-timing-function: ease-in-out;
  animation-duration: 0.6s;
  animation-fill-mode: forwards;
  transform: translate(-50%, -50%) rotate(-90deg) translate(calc(calc( 180px - (calc(3 * 10px * 2) + calc( 3 * 6px * 2 )) ) / 2 - 10px / 2));
}
@keyframes rotate-ring-cap-4 {
  85% {
    transform: translate(-50%, -50%) rotate(calc(var(--angle) * (85 / 100) - 90deg)) translate(calc(calc( 180px - (calc(3 * 10px * 2) + calc( 3 * 6px * 2 )) ) / 2 - 10px / 2));
  }
}
.dial-ring:nth-child(7) .dial-ring-cap-end {
  left: 50%;
  top: 50%;
  transform-origin: center;
  z-index: 9999999999999;
  animation-name: rotate-ring-cap-4;
  animation-delay: 0.4s;
  animation-timing-function: ease-in-out;
  animation-duration: 0.6s;
  animation-fill-mode: forwards;
  transform: translate(-50%, -50%) rotate(-90deg) translate(calc(calc( 180px - (calc(3 * 10px * 2) + calc( 3 * 6px * 2 )) ) / 2 - 10px / 2));
}
@keyframes rotate-ring-cap-4 {
  86% {
    transform: translate(-50%, -50%) rotate(calc(var(--angle) * (86 / 100) - 90deg)) translate(calc(calc( 180px - (calc(3 * 10px * 2) + calc( 3 * 6px * 2 )) ) / 2 - 10px / 2));
  }
}
.dial-ring:nth-child(7) .dial-ring-cap-end {
  left: 50%;
  top: 50%;
  transform-origin: center;
  z-index: 9999999999999;
  animation-name: rotate-ring-cap-4;
  animation-delay: 0.4s;
  animation-timing-function: ease-in-out;
  animation-duration: 0.6s;
  animation-fill-mode: forwards;
  transform: translate(-50%, -50%) rotate(-90deg) translate(calc(calc( 180px - (calc(3 * 10px * 2) + calc( 3 * 6px * 2 )) ) / 2 - 10px / 2));
}
@keyframes rotate-ring-cap-4 {
  87% {
    transform: translate(-50%, -50%) rotate(calc(var(--angle) * (87 / 100) - 90deg)) translate(calc(calc( 180px - (calc(3 * 10px * 2) + calc( 3 * 6px * 2 )) ) / 2 - 10px / 2));
  }
}
.dial-ring:nth-child(7) .dial-ring-cap-end {
  left: 50%;
  top: 50%;
  transform-origin: center;
  z-index: 9999999999999;
  animation-name: rotate-ring-cap-4;
  animation-delay: 0.4s;
  animation-timing-function: ease-in-out;
  animation-duration: 0.6s;
  animation-fill-mode: forwards;
  transform: translate(-50%, -50%) rotate(-90deg) translate(calc(calc( 180px - (calc(3 * 10px * 2) + calc( 3 * 6px * 2 )) ) / 2 - 10px / 2));
}
@keyframes rotate-ring-cap-4 {
  88% {
    transform: translate(-50%, -50%) rotate(calc(var(--angle) * (88 / 100) - 90deg)) translate(calc(calc( 180px - (calc(3 * 10px * 2) + calc( 3 * 6px * 2 )) ) / 2 - 10px / 2));
  }
}
.dial-ring:nth-child(7) .dial-ring-cap-end {
  left: 50%;
  top: 50%;
  transform-origin: center;
  z-index: 9999999999999;
  animation-name: rotate-ring-cap-4;
  animation-delay: 0.4s;
  animation-timing-function: ease-in-out;
  animation-duration: 0.6s;
  animation-fill-mode: forwards;
  transform: translate(-50%, -50%) rotate(-90deg) translate(calc(calc( 180px - (calc(3 * 10px * 2) + calc( 3 * 6px * 2 )) ) / 2 - 10px / 2));
}
@keyframes rotate-ring-cap-4 {
  89% {
    transform: translate(-50%, -50%) rotate(calc(var(--angle) * (89 / 100) - 90deg)) translate(calc(calc( 180px - (calc(3 * 10px * 2) + calc( 3 * 6px * 2 )) ) / 2 - 10px / 2));
  }
}
.dial-ring:nth-child(7) .dial-ring-cap-end {
  left: 50%;
  top: 50%;
  transform-origin: center;
  z-index: 9999999999999;
  animation-name: rotate-ring-cap-4;
  animation-delay: 0.4s;
  animation-timing-function: ease-in-out;
  animation-duration: 0.6s;
  animation-fill-mode: forwards;
  transform: translate(-50%, -50%) rotate(-90deg) translate(calc(calc( 180px - (calc(3 * 10px * 2) + calc( 3 * 6px * 2 )) ) / 2 - 10px / 2));
}
@keyframes rotate-ring-cap-4 {
  90% {
    transform: translate(-50%, -50%) rotate(calc(var(--angle) * (90 / 100) - 90deg)) translate(calc(calc( 180px - (calc(3 * 10px * 2) + calc( 3 * 6px * 2 )) ) / 2 - 10px / 2));
  }
}
.dial-ring:nth-child(7) .dial-ring-cap-end {
  left: 50%;
  top: 50%;
  transform-origin: center;
  z-index: 9999999999999;
  animation-name: rotate-ring-cap-4;
  animation-delay: 0.4s;
  animation-timing-function: ease-in-out;
  animation-duration: 0.6s;
  animation-fill-mode: forwards;
  transform: translate(-50%, -50%) rotate(-90deg) translate(calc(calc( 180px - (calc(3 * 10px * 2) + calc( 3 * 6px * 2 )) ) / 2 - 10px / 2));
}
@keyframes rotate-ring-cap-4 {
  91% {
    transform: translate(-50%, -50%) rotate(calc(var(--angle) * (91 / 100) - 90deg)) translate(calc(calc( 180px - (calc(3 * 10px * 2) + calc( 3 * 6px * 2 )) ) / 2 - 10px / 2));
  }
}
.dial-ring:nth-child(7) .dial-ring-cap-end {
  left: 50%;
  top: 50%;
  transform-origin: center;
  z-index: 9999999999999;
  animation-name: rotate-ring-cap-4;
  animation-delay: 0.4s;
  animation-timing-function: ease-in-out;
  animation-duration: 0.6s;
  animation-fill-mode: forwards;
  transform: translate(-50%, -50%) rotate(-90deg) translate(calc(calc( 180px - (calc(3 * 10px * 2) + calc( 3 * 6px * 2 )) ) / 2 - 10px / 2));
}
@keyframes rotate-ring-cap-4 {
  92% {
    transform: translate(-50%, -50%) rotate(calc(var(--angle) * (92 / 100) - 90deg)) translate(calc(calc( 180px - (calc(3 * 10px * 2) + calc( 3 * 6px * 2 )) ) / 2 - 10px / 2));
  }
}
.dial-ring:nth-child(7) .dial-ring-cap-end {
  left: 50%;
  top: 50%;
  transform-origin: center;
  z-index: 9999999999999;
  animation-name: rotate-ring-cap-4;
  animation-delay: 0.4s;
  animation-timing-function: ease-in-out;
  animation-duration: 0.6s;
  animation-fill-mode: forwards;
  transform: translate(-50%, -50%) rotate(-90deg) translate(calc(calc( 180px - (calc(3 * 10px * 2) + calc( 3 * 6px * 2 )) ) / 2 - 10px / 2));
}
@keyframes rotate-ring-cap-4 {
  93% {
    transform: translate(-50%, -50%) rotate(calc(var(--angle) * (93 / 100) - 90deg)) translate(calc(calc( 180px - (calc(3 * 10px * 2) + calc( 3 * 6px * 2 )) ) / 2 - 10px / 2));
  }
}
.dial-ring:nth-child(7) .dial-ring-cap-end {
  left: 50%;
  top: 50%;
  transform-origin: center;
  z-index: 9999999999999;
  animation-name: rotate-ring-cap-4;
  animation-delay: 0.4s;
  animation-timing-function: ease-in-out;
  animation-duration: 0.6s;
  animation-fill-mode: forwards;
  transform: translate(-50%, -50%) rotate(-90deg) translate(calc(calc( 180px - (calc(3 * 10px * 2) + calc( 3 * 6px * 2 )) ) / 2 - 10px / 2));
}
@keyframes rotate-ring-cap-4 {
  94% {
    transform: translate(-50%, -50%) rotate(calc(var(--angle) * (94 / 100) - 90deg)) translate(calc(calc( 180px - (calc(3 * 10px * 2) + calc( 3 * 6px * 2 )) ) / 2 - 10px / 2));
  }
}
.dial-ring:nth-child(7) .dial-ring-cap-end {
  left: 50%;
  top: 50%;
  transform-origin: center;
  z-index: 9999999999999;
  animation-name: rotate-ring-cap-4;
  animation-delay: 0.4s;
  animation-timing-function: ease-in-out;
  animation-duration: 0.6s;
  animation-fill-mode: forwards;
  transform: translate(-50%, -50%) rotate(-90deg) translate(calc(calc( 180px - (calc(3 * 10px * 2) + calc( 3 * 6px * 2 )) ) / 2 - 10px / 2));
}
@keyframes rotate-ring-cap-4 {
  95% {
    transform: translate(-50%, -50%) rotate(calc(var(--angle) * (95 / 100) - 90deg)) translate(calc(calc( 180px - (calc(3 * 10px * 2) + calc( 3 * 6px * 2 )) ) / 2 - 10px / 2));
  }
}
.dial-ring:nth-child(7) .dial-ring-cap-end {
  left: 50%;
  top: 50%;
  transform-origin: center;
  z-index: 9999999999999;
  animation-name: rotate-ring-cap-4;
  animation-delay: 0.4s;
  animation-timing-function: ease-in-out;
  animation-duration: 0.6s;
  animation-fill-mode: forwards;
  transform: translate(-50%, -50%) rotate(-90deg) translate(calc(calc( 180px - (calc(3 * 10px * 2) + calc( 3 * 6px * 2 )) ) / 2 - 10px / 2));
}
@keyframes rotate-ring-cap-4 {
  96% {
    transform: translate(-50%, -50%) rotate(calc(var(--angle) * (96 / 100) - 90deg)) translate(calc(calc( 180px - (calc(3 * 10px * 2) + calc( 3 * 6px * 2 )) ) / 2 - 10px / 2));
  }
}
.dial-ring:nth-child(7) .dial-ring-cap-end {
  left: 50%;
  top: 50%;
  transform-origin: center;
  z-index: 9999999999999;
  animation-name: rotate-ring-cap-4;
  animation-delay: 0.4s;
  animation-timing-function: ease-in-out;
  animation-duration: 0.6s;
  animation-fill-mode: forwards;
  transform: translate(-50%, -50%) rotate(-90deg) translate(calc(calc( 180px - (calc(3 * 10px * 2) + calc( 3 * 6px * 2 )) ) / 2 - 10px / 2));
}
@keyframes rotate-ring-cap-4 {
  97% {
    transform: translate(-50%, -50%) rotate(calc(var(--angle) * (97 / 100) - 90deg)) translate(calc(calc( 180px - (calc(3 * 10px * 2) + calc( 3 * 6px * 2 )) ) / 2 - 10px / 2));
  }
}
.dial-ring:nth-child(7) .dial-ring-cap-end {
  left: 50%;
  top: 50%;
  transform-origin: center;
  z-index: 9999999999999;
  animation-name: rotate-ring-cap-4;
  animation-delay: 0.4s;
  animation-timing-function: ease-in-out;
  animation-duration: 0.6s;
  animation-fill-mode: forwards;
  transform: translate(-50%, -50%) rotate(-90deg) translate(calc(calc( 180px - (calc(3 * 10px * 2) + calc( 3 * 6px * 2 )) ) / 2 - 10px / 2));
}
@keyframes rotate-ring-cap-4 {
  98% {
    transform: translate(-50%, -50%) rotate(calc(var(--angle) * (98 / 100) - 90deg)) translate(calc(calc( 180px - (calc(3 * 10px * 2) + calc( 3 * 6px * 2 )) ) / 2 - 10px / 2));
  }
}
.dial-ring:nth-child(7) .dial-ring-cap-end {
  left: 50%;
  top: 50%;
  transform-origin: center;
  z-index: 9999999999999;
  animation-name: rotate-ring-cap-4;
  animation-delay: 0.4s;
  animation-timing-function: ease-in-out;
  animation-duration: 0.6s;
  animation-fill-mode: forwards;
  transform: translate(-50%, -50%) rotate(-90deg) translate(calc(calc( 180px - (calc(3 * 10px * 2) + calc( 3 * 6px * 2 )) ) / 2 - 10px / 2));
}
@keyframes rotate-ring-cap-4 {
  99% {
    transform: translate(-50%, -50%) rotate(calc(var(--angle) * (99 / 100) - 90deg)) translate(calc(calc( 180px - (calc(3 * 10px * 2) + calc( 3 * 6px * 2 )) ) / 2 - 10px / 2));
  }
}
.dial-ring:nth-child(7) .dial-ring-cap-end {
  left: 50%;
  top: 50%;
  transform-origin: center;
  z-index: 9999999999999;
  animation-name: rotate-ring-cap-4;
  animation-delay: 0.4s;
  animation-timing-function: ease-in-out;
  animation-duration: 0.6s;
  animation-fill-mode: forwards;
  transform: translate(-50%, -50%) rotate(-90deg) translate(calc(calc( 180px - (calc(3 * 10px * 2) + calc( 3 * 6px * 2 )) ) / 2 - 10px / 2));
}
@keyframes rotate-ring-cap-4 {
  100% {
    transform: translate(-50%, -50%) rotate(calc(var(--angle) * (100 / 100) - 90deg)) translate(calc(calc( 180px - (calc(3 * 10px * 2) + calc( 3 * 6px * 2 )) ) / 2 - 10px / 2));
  }
}
.dial-ring--spacer:not(:last-child):nth-child(10) {
  position: absolute;
  border-radius: 50%;
  left: 50%;
  top: 50%;
  transform: translateX(-50%) translateY(-50%);
  width: calc(180px - calc(
        5 *
          10px *
          2 +
          ((5 - 1) * 6px * 2)
      ));
  height: calc(180px - calc(
        5 *
          10px *
          2 +
          ((5 - 1) * 6px * 2)
      ));
  background: #272727;
}
.dial-ring:nth-child(9) {
  width: calc(
        180px - (calc(4 * 10px * 2) + calc(
        4 * 6px * 2
      ))
      );
  height: calc(
        180px - (calc(4 * 10px * 2) + calc(
        4 * 6px * 2
      ))
      );
}
.dial-ring:nth-child(9) .dial-ring-progress:before {
  animation-delay: 0.4s;
  animation-timing-function: ease-in-out;
  animation-duration: 0.6s;
  animation-name: rotation-5;
  animation-fill-mode: forwards;
}
@keyframes rotation-5 {
  to {
    transform: rotate(var(--angle));
  }
}
.dial-ring:nth-child(9) .dial-ring-progress:not(.dial-ring-progress--less-than-halfway) {
  animation-name: show-left-hemisphere-5;
}
.dial-ring:nth-child(9) .dial-ring-progress:not(.dial-ring-progress--less-than-halfway), .dial-ring:nth-child(9) .dial-ring-progress:not(.dial-ring-progress--less-than-halfway):after {
  animation-timing-function: linear;
  animation-duration: 0s;
  animation-fill-mode: forwards;
}
.dial-ring:nth-child(9) .dial-ring-progress:not(.dial-ring-progress--less-than-halfway).dial-ring-progress--segments-3, .dial-ring:nth-child(9) .dial-ring-progress:not(.dial-ring-progress--less-than-halfway).dial-ring-progress--segments-3:after {
  animation-delay: calc(calc(
            var(--percent-complete-at-half-filled) *
              0.6s +
              0.4s
          ) - 0.075s);
}
.dial-ring:nth-child(9) .dial-ring-progress:not(.dial-ring-progress--less-than-halfway).dial-ring-progress--segments-4, .dial-ring:nth-child(9) .dial-ring-progress:not(.dial-ring-progress--less-than-halfway).dial-ring-progress--segments-4:after {
  animation-delay: calc(calc(
            var(--percent-complete-at-half-filled) *
              0.6s +
              0.4s
          ) - 0.03s);
}
.dial-ring:nth-child(9) .dial-ring-progress:not(.dial-ring-progress--less-than-halfway).dial-ring-progress--segments-5, .dial-ring:nth-child(9) .dial-ring-progress:not(.dial-ring-progress--less-than-halfway).dial-ring-progress--segments-5:after {
  animation-delay: calc(
            var(--percent-complete-at-half-filled) *
              0.6s +
              0.4s
          );
}
@keyframes show-left-hemisphere-5 {
  to {
    clip-path: none;
  }
}
.dial-ring:nth-child(9) .dial-ring-progress:not(.dial-ring-progress--less-than-halfway):after {
  animation-name: fill-right-hemisphere-5;
}
@keyframes fill-right-hemisphere-5 {
  to {
    opacity: 1;
  }
}
.dial-ring:nth-child(9) .dial-ring-cap-end {
  left: 50%;
  top: 50%;
  transform-origin: center;
  z-index: 9999999999999;
  animation-name: rotate-ring-cap-5;
  animation-delay: 0.4s;
  animation-timing-function: ease-in-out;
  animation-duration: 0.6s;
  animation-fill-mode: forwards;
  transform: translate(-50%, -50%) rotate(-90deg) translate(calc(calc( 180px - (calc(4 * 10px * 2) + calc( 4 * 6px * 2 )) ) / 2 - 10px / 2));
}
@keyframes rotate-ring-cap-5 {
  0% {
    transform: translate(-50%, -50%) rotate(calc(var(--angle) * (0 / 100) - 90deg)) translate(calc(calc( 180px - (calc(4 * 10px * 2) + calc( 4 * 6px * 2 )) ) / 2 - 10px / 2));
  }
}
.dial-ring:nth-child(9) .dial-ring-cap-end {
  left: 50%;
  top: 50%;
  transform-origin: center;
  z-index: 9999999999999;
  animation-name: rotate-ring-cap-5;
  animation-delay: 0.4s;
  animation-timing-function: ease-in-out;
  animation-duration: 0.6s;
  animation-fill-mode: forwards;
  transform: translate(-50%, -50%) rotate(-90deg) translate(calc(calc( 180px - (calc(4 * 10px * 2) + calc( 4 * 6px * 2 )) ) / 2 - 10px / 2));
}
@keyframes rotate-ring-cap-5 {
  1% {
    transform: translate(-50%, -50%) rotate(calc(var(--angle) * (1 / 100) - 90deg)) translate(calc(calc( 180px - (calc(4 * 10px * 2) + calc( 4 * 6px * 2 )) ) / 2 - 10px / 2));
  }
}
.dial-ring:nth-child(9) .dial-ring-cap-end {
  left: 50%;
  top: 50%;
  transform-origin: center;
  z-index: 9999999999999;
  animation-name: rotate-ring-cap-5;
  animation-delay: 0.4s;
  animation-timing-function: ease-in-out;
  animation-duration: 0.6s;
  animation-fill-mode: forwards;
  transform: translate(-50%, -50%) rotate(-90deg) translate(calc(calc( 180px - (calc(4 * 10px * 2) + calc( 4 * 6px * 2 )) ) / 2 - 10px / 2));
}
@keyframes rotate-ring-cap-5 {
  2% {
    transform: translate(-50%, -50%) rotate(calc(var(--angle) * (2 / 100) - 90deg)) translate(calc(calc( 180px - (calc(4 * 10px * 2) + calc( 4 * 6px * 2 )) ) / 2 - 10px / 2));
  }
}
.dial-ring:nth-child(9) .dial-ring-cap-end {
  left: 50%;
  top: 50%;
  transform-origin: center;
  z-index: 9999999999999;
  animation-name: rotate-ring-cap-5;
  animation-delay: 0.4s;
  animation-timing-function: ease-in-out;
  animation-duration: 0.6s;
  animation-fill-mode: forwards;
  transform: translate(-50%, -50%) rotate(-90deg) translate(calc(calc( 180px - (calc(4 * 10px * 2) + calc( 4 * 6px * 2 )) ) / 2 - 10px / 2));
}
@keyframes rotate-ring-cap-5 {
  3% {
    transform: translate(-50%, -50%) rotate(calc(var(--angle) * (3 / 100) - 90deg)) translate(calc(calc( 180px - (calc(4 * 10px * 2) + calc( 4 * 6px * 2 )) ) / 2 - 10px / 2));
  }
}
.dial-ring:nth-child(9) .dial-ring-cap-end {
  left: 50%;
  top: 50%;
  transform-origin: center;
  z-index: 9999999999999;
  animation-name: rotate-ring-cap-5;
  animation-delay: 0.4s;
  animation-timing-function: ease-in-out;
  animation-duration: 0.6s;
  animation-fill-mode: forwards;
  transform: translate(-50%, -50%) rotate(-90deg) translate(calc(calc( 180px - (calc(4 * 10px * 2) + calc( 4 * 6px * 2 )) ) / 2 - 10px / 2));
}
@keyframes rotate-ring-cap-5 {
  4% {
    transform: translate(-50%, -50%) rotate(calc(var(--angle) * (4 / 100) - 90deg)) translate(calc(calc( 180px - (calc(4 * 10px * 2) + calc( 4 * 6px * 2 )) ) / 2 - 10px / 2));
  }
}
.dial-ring:nth-child(9) .dial-ring-cap-end {
  left: 50%;
  top: 50%;
  transform-origin: center;
  z-index: 9999999999999;
  animation-name: rotate-ring-cap-5;
  animation-delay: 0.4s;
  animation-timing-function: ease-in-out;
  animation-duration: 0.6s;
  animation-fill-mode: forwards;
  transform: translate(-50%, -50%) rotate(-90deg) translate(calc(calc( 180px - (calc(4 * 10px * 2) + calc( 4 * 6px * 2 )) ) / 2 - 10px / 2));
}
@keyframes rotate-ring-cap-5 {
  5% {
    transform: translate(-50%, -50%) rotate(calc(var(--angle) * (5 / 100) - 90deg)) translate(calc(calc( 180px - (calc(4 * 10px * 2) + calc( 4 * 6px * 2 )) ) / 2 - 10px / 2));
  }
}
.dial-ring:nth-child(9) .dial-ring-cap-end {
  left: 50%;
  top: 50%;
  transform-origin: center;
  z-index: 9999999999999;
  animation-name: rotate-ring-cap-5;
  animation-delay: 0.4s;
  animation-timing-function: ease-in-out;
  animation-duration: 0.6s;
  animation-fill-mode: forwards;
  transform: translate(-50%, -50%) rotate(-90deg) translate(calc(calc( 180px - (calc(4 * 10px * 2) + calc( 4 * 6px * 2 )) ) / 2 - 10px / 2));
}
@keyframes rotate-ring-cap-5 {
  6% {
    transform: translate(-50%, -50%) rotate(calc(var(--angle) * (6 / 100) - 90deg)) translate(calc(calc( 180px - (calc(4 * 10px * 2) + calc( 4 * 6px * 2 )) ) / 2 - 10px / 2));
  }
}
.dial-ring:nth-child(9) .dial-ring-cap-end {
  left: 50%;
  top: 50%;
  transform-origin: center;
  z-index: 9999999999999;
  animation-name: rotate-ring-cap-5;
  animation-delay: 0.4s;
  animation-timing-function: ease-in-out;
  animation-duration: 0.6s;
  animation-fill-mode: forwards;
  transform: translate(-50%, -50%) rotate(-90deg) translate(calc(calc( 180px - (calc(4 * 10px * 2) + calc( 4 * 6px * 2 )) ) / 2 - 10px / 2));
}
@keyframes rotate-ring-cap-5 {
  7% {
    transform: translate(-50%, -50%) rotate(calc(var(--angle) * (7 / 100) - 90deg)) translate(calc(calc( 180px - (calc(4 * 10px * 2) + calc( 4 * 6px * 2 )) ) / 2 - 10px / 2));
  }
}
.dial-ring:nth-child(9) .dial-ring-cap-end {
  left: 50%;
  top: 50%;
  transform-origin: center;
  z-index: 9999999999999;
  animation-name: rotate-ring-cap-5;
  animation-delay: 0.4s;
  animation-timing-function: ease-in-out;
  animation-duration: 0.6s;
  animation-fill-mode: forwards;
  transform: translate(-50%, -50%) rotate(-90deg) translate(calc(calc( 180px - (calc(4 * 10px * 2) + calc( 4 * 6px * 2 )) ) / 2 - 10px / 2));
}
@keyframes rotate-ring-cap-5 {
  8% {
    transform: translate(-50%, -50%) rotate(calc(var(--angle) * (8 / 100) - 90deg)) translate(calc(calc( 180px - (calc(4 * 10px * 2) + calc( 4 * 6px * 2 )) ) / 2 - 10px / 2));
  }
}
.dial-ring:nth-child(9) .dial-ring-cap-end {
  left: 50%;
  top: 50%;
  transform-origin: center;
  z-index: 9999999999999;
  animation-name: rotate-ring-cap-5;
  animation-delay: 0.4s;
  animation-timing-function: ease-in-out;
  animation-duration: 0.6s;
  animation-fill-mode: forwards;
  transform: translate(-50%, -50%) rotate(-90deg) translate(calc(calc( 180px - (calc(4 * 10px * 2) + calc( 4 * 6px * 2 )) ) / 2 - 10px / 2));
}
@keyframes rotate-ring-cap-5 {
  9% {
    transform: translate(-50%, -50%) rotate(calc(var(--angle) * (9 / 100) - 90deg)) translate(calc(calc( 180px - (calc(4 * 10px * 2) + calc( 4 * 6px * 2 )) ) / 2 - 10px / 2));
  }
}
.dial-ring:nth-child(9) .dial-ring-cap-end {
  left: 50%;
  top: 50%;
  transform-origin: center;
  z-index: 9999999999999;
  animation-name: rotate-ring-cap-5;
  animation-delay: 0.4s;
  animation-timing-function: ease-in-out;
  animation-duration: 0.6s;
  animation-fill-mode: forwards;
  transform: translate(-50%, -50%) rotate(-90deg) translate(calc(calc( 180px - (calc(4 * 10px * 2) + calc( 4 * 6px * 2 )) ) / 2 - 10px / 2));
}
@keyframes rotate-ring-cap-5 {
  10% {
    transform: translate(-50%, -50%) rotate(calc(var(--angle) * (10 / 100) - 90deg)) translate(calc(calc( 180px - (calc(4 * 10px * 2) + calc( 4 * 6px * 2 )) ) / 2 - 10px / 2));
  }
}
.dial-ring:nth-child(9) .dial-ring-cap-end {
  left: 50%;
  top: 50%;
  transform-origin: center;
  z-index: 9999999999999;
  animation-name: rotate-ring-cap-5;
  animation-delay: 0.4s;
  animation-timing-function: ease-in-out;
  animation-duration: 0.6s;
  animation-fill-mode: forwards;
  transform: translate(-50%, -50%) rotate(-90deg) translate(calc(calc( 180px - (calc(4 * 10px * 2) + calc( 4 * 6px * 2 )) ) / 2 - 10px / 2));
}
@keyframes rotate-ring-cap-5 {
  11% {
    transform: translate(-50%, -50%) rotate(calc(var(--angle) * (11 / 100) - 90deg)) translate(calc(calc( 180px - (calc(4 * 10px * 2) + calc( 4 * 6px * 2 )) ) / 2 - 10px / 2));
  }
}
.dial-ring:nth-child(9) .dial-ring-cap-end {
  left: 50%;
  top: 50%;
  transform-origin: center;
  z-index: 9999999999999;
  animation-name: rotate-ring-cap-5;
  animation-delay: 0.4s;
  animation-timing-function: ease-in-out;
  animation-duration: 0.6s;
  animation-fill-mode: forwards;
  transform: translate(-50%, -50%) rotate(-90deg) translate(calc(calc( 180px - (calc(4 * 10px * 2) + calc( 4 * 6px * 2 )) ) / 2 - 10px / 2));
}
@keyframes rotate-ring-cap-5 {
  12% {
    transform: translate(-50%, -50%) rotate(calc(var(--angle) * (12 / 100) - 90deg)) translate(calc(calc( 180px - (calc(4 * 10px * 2) + calc( 4 * 6px * 2 )) ) / 2 - 10px / 2));
  }
}
.dial-ring:nth-child(9) .dial-ring-cap-end {
  left: 50%;
  top: 50%;
  transform-origin: center;
  z-index: 9999999999999;
  animation-name: rotate-ring-cap-5;
  animation-delay: 0.4s;
  animation-timing-function: ease-in-out;
  animation-duration: 0.6s;
  animation-fill-mode: forwards;
  transform: translate(-50%, -50%) rotate(-90deg) translate(calc(calc( 180px - (calc(4 * 10px * 2) + calc( 4 * 6px * 2 )) ) / 2 - 10px / 2));
}
@keyframes rotate-ring-cap-5 {
  13% {
    transform: translate(-50%, -50%) rotate(calc(var(--angle) * (13 / 100) - 90deg)) translate(calc(calc( 180px - (calc(4 * 10px * 2) + calc( 4 * 6px * 2 )) ) / 2 - 10px / 2));
  }
}
.dial-ring:nth-child(9) .dial-ring-cap-end {
  left: 50%;
  top: 50%;
  transform-origin: center;
  z-index: 9999999999999;
  animation-name: rotate-ring-cap-5;
  animation-delay: 0.4s;
  animation-timing-function: ease-in-out;
  animation-duration: 0.6s;
  animation-fill-mode: forwards;
  transform: translate(-50%, -50%) rotate(-90deg) translate(calc(calc( 180px - (calc(4 * 10px * 2) + calc( 4 * 6px * 2 )) ) / 2 - 10px / 2));
}
@keyframes rotate-ring-cap-5 {
  14% {
    transform: translate(-50%, -50%) rotate(calc(var(--angle) * (14 / 100) - 90deg)) translate(calc(calc( 180px - (calc(4 * 10px * 2) + calc( 4 * 6px * 2 )) ) / 2 - 10px / 2));
  }
}
.dial-ring:nth-child(9) .dial-ring-cap-end {
  left: 50%;
  top: 50%;
  transform-origin: center;
  z-index: 9999999999999;
  animation-name: rotate-ring-cap-5;
  animation-delay: 0.4s;
  animation-timing-function: ease-in-out;
  animation-duration: 0.6s;
  animation-fill-mode: forwards;
  transform: translate(-50%, -50%) rotate(-90deg) translate(calc(calc( 180px - (calc(4 * 10px * 2) + calc( 4 * 6px * 2 )) ) / 2 - 10px / 2));
}
@keyframes rotate-ring-cap-5 {
  15% {
    transform: translate(-50%, -50%) rotate(calc(var(--angle) * (15 / 100) - 90deg)) translate(calc(calc( 180px - (calc(4 * 10px * 2) + calc( 4 * 6px * 2 )) ) / 2 - 10px / 2));
  }
}
.dial-ring:nth-child(9) .dial-ring-cap-end {
  left: 50%;
  top: 50%;
  transform-origin: center;
  z-index: 9999999999999;
  animation-name: rotate-ring-cap-5;
  animation-delay: 0.4s;
  animation-timing-function: ease-in-out;
  animation-duration: 0.6s;
  animation-fill-mode: forwards;
  transform: translate(-50%, -50%) rotate(-90deg) translate(calc(calc( 180px - (calc(4 * 10px * 2) + calc( 4 * 6px * 2 )) ) / 2 - 10px / 2));
}
@keyframes rotate-ring-cap-5 {
  16% {
    transform: translate(-50%, -50%) rotate(calc(var(--angle) * (16 / 100) - 90deg)) translate(calc(calc( 180px - (calc(4 * 10px * 2) + calc( 4 * 6px * 2 )) ) / 2 - 10px / 2));
  }
}
.dial-ring:nth-child(9) .dial-ring-cap-end {
  left: 50%;
  top: 50%;
  transform-origin: center;
  z-index: 9999999999999;
  animation-name: rotate-ring-cap-5;
  animation-delay: 0.4s;
  animation-timing-function: ease-in-out;
  animation-duration: 0.6s;
  animation-fill-mode: forwards;
  transform: translate(-50%, -50%) rotate(-90deg) translate(calc(calc( 180px - (calc(4 * 10px * 2) + calc( 4 * 6px * 2 )) ) / 2 - 10px / 2));
}
@keyframes rotate-ring-cap-5 {
  17% {
    transform: translate(-50%, -50%) rotate(calc(var(--angle) * (17 / 100) - 90deg)) translate(calc(calc( 180px - (calc(4 * 10px * 2) + calc( 4 * 6px * 2 )) ) / 2 - 10px / 2));
  }
}
.dial-ring:nth-child(9) .dial-ring-cap-end {
  left: 50%;
  top: 50%;
  transform-origin: center;
  z-index: 9999999999999;
  animation-name: rotate-ring-cap-5;
  animation-delay: 0.4s;
  animation-timing-function: ease-in-out;
  animation-duration: 0.6s;
  animation-fill-mode: forwards;
  transform: translate(-50%, -50%) rotate(-90deg) translate(calc(calc( 180px - (calc(4 * 10px * 2) + calc( 4 * 6px * 2 )) ) / 2 - 10px / 2));
}
@keyframes rotate-ring-cap-5 {
  18% {
    transform: translate(-50%, -50%) rotate(calc(var(--angle) * (18 / 100) - 90deg)) translate(calc(calc( 180px - (calc(4 * 10px * 2) + calc( 4 * 6px * 2 )) ) / 2 - 10px / 2));
  }
}
.dial-ring:nth-child(9) .dial-ring-cap-end {
  left: 50%;
  top: 50%;
  transform-origin: center;
  z-index: 9999999999999;
  animation-name: rotate-ring-cap-5;
  animation-delay: 0.4s;
  animation-timing-function: ease-in-out;
  animation-duration: 0.6s;
  animation-fill-mode: forwards;
  transform: translate(-50%, -50%) rotate(-90deg) translate(calc(calc( 180px - (calc(4 * 10px * 2) + calc( 4 * 6px * 2 )) ) / 2 - 10px / 2));
}
@keyframes rotate-ring-cap-5 {
  19% {
    transform: translate(-50%, -50%) rotate(calc(var(--angle) * (19 / 100) - 90deg)) translate(calc(calc( 180px - (calc(4 * 10px * 2) + calc( 4 * 6px * 2 )) ) / 2 - 10px / 2));
  }
}
.dial-ring:nth-child(9) .dial-ring-cap-end {
  left: 50%;
  top: 50%;
  transform-origin: center;
  z-index: 9999999999999;
  animation-name: rotate-ring-cap-5;
  animation-delay: 0.4s;
  animation-timing-function: ease-in-out;
  animation-duration: 0.6s;
  animation-fill-mode: forwards;
  transform: translate(-50%, -50%) rotate(-90deg) translate(calc(calc( 180px - (calc(4 * 10px * 2) + calc( 4 * 6px * 2 )) ) / 2 - 10px / 2));
}
@keyframes rotate-ring-cap-5 {
  20% {
    transform: translate(-50%, -50%) rotate(calc(var(--angle) * (20 / 100) - 90deg)) translate(calc(calc( 180px - (calc(4 * 10px * 2) + calc( 4 * 6px * 2 )) ) / 2 - 10px / 2));
  }
}
.dial-ring:nth-child(9) .dial-ring-cap-end {
  left: 50%;
  top: 50%;
  transform-origin: center;
  z-index: 9999999999999;
  animation-name: rotate-ring-cap-5;
  animation-delay: 0.4s;
  animation-timing-function: ease-in-out;
  animation-duration: 0.6s;
  animation-fill-mode: forwards;
  transform: translate(-50%, -50%) rotate(-90deg) translate(calc(calc( 180px - (calc(4 * 10px * 2) + calc( 4 * 6px * 2 )) ) / 2 - 10px / 2));
}
@keyframes rotate-ring-cap-5 {
  21% {
    transform: translate(-50%, -50%) rotate(calc(var(--angle) * (21 / 100) - 90deg)) translate(calc(calc( 180px - (calc(4 * 10px * 2) + calc( 4 * 6px * 2 )) ) / 2 - 10px / 2));
  }
}
.dial-ring:nth-child(9) .dial-ring-cap-end {
  left: 50%;
  top: 50%;
  transform-origin: center;
  z-index: 9999999999999;
  animation-name: rotate-ring-cap-5;
  animation-delay: 0.4s;
  animation-timing-function: ease-in-out;
  animation-duration: 0.6s;
  animation-fill-mode: forwards;
  transform: translate(-50%, -50%) rotate(-90deg) translate(calc(calc( 180px - (calc(4 * 10px * 2) + calc( 4 * 6px * 2 )) ) / 2 - 10px / 2));
}
@keyframes rotate-ring-cap-5 {
  22% {
    transform: translate(-50%, -50%) rotate(calc(var(--angle) * (22 / 100) - 90deg)) translate(calc(calc( 180px - (calc(4 * 10px * 2) + calc( 4 * 6px * 2 )) ) / 2 - 10px / 2));
  }
}
.dial-ring:nth-child(9) .dial-ring-cap-end {
  left: 50%;
  top: 50%;
  transform-origin: center;
  z-index: 9999999999999;
  animation-name: rotate-ring-cap-5;
  animation-delay: 0.4s;
  animation-timing-function: ease-in-out;
  animation-duration: 0.6s;
  animation-fill-mode: forwards;
  transform: translate(-50%, -50%) rotate(-90deg) translate(calc(calc( 180px - (calc(4 * 10px * 2) + calc( 4 * 6px * 2 )) ) / 2 - 10px / 2));
}
@keyframes rotate-ring-cap-5 {
  23% {
    transform: translate(-50%, -50%) rotate(calc(var(--angle) * (23 / 100) - 90deg)) translate(calc(calc( 180px - (calc(4 * 10px * 2) + calc( 4 * 6px * 2 )) ) / 2 - 10px / 2));
  }
}
.dial-ring:nth-child(9) .dial-ring-cap-end {
  left: 50%;
  top: 50%;
  transform-origin: center;
  z-index: 9999999999999;
  animation-name: rotate-ring-cap-5;
  animation-delay: 0.4s;
  animation-timing-function: ease-in-out;
  animation-duration: 0.6s;
  animation-fill-mode: forwards;
  transform: translate(-50%, -50%) rotate(-90deg) translate(calc(calc( 180px - (calc(4 * 10px * 2) + calc( 4 * 6px * 2 )) ) / 2 - 10px / 2));
}
@keyframes rotate-ring-cap-5 {
  24% {
    transform: translate(-50%, -50%) rotate(calc(var(--angle) * (24 / 100) - 90deg)) translate(calc(calc( 180px - (calc(4 * 10px * 2) + calc( 4 * 6px * 2 )) ) / 2 - 10px / 2));
  }
}
.dial-ring:nth-child(9) .dial-ring-cap-end {
  left: 50%;
  top: 50%;
  transform-origin: center;
  z-index: 9999999999999;
  animation-name: rotate-ring-cap-5;
  animation-delay: 0.4s;
  animation-timing-function: ease-in-out;
  animation-duration: 0.6s;
  animation-fill-mode: forwards;
  transform: translate(-50%, -50%) rotate(-90deg) translate(calc(calc( 180px - (calc(4 * 10px * 2) + calc( 4 * 6px * 2 )) ) / 2 - 10px / 2));
}
@keyframes rotate-ring-cap-5 {
  25% {
    transform: translate(-50%, -50%) rotate(calc(var(--angle) * (25 / 100) - 90deg)) translate(calc(calc( 180px - (calc(4 * 10px * 2) + calc( 4 * 6px * 2 )) ) / 2 - 10px / 2));
  }
}
.dial-ring:nth-child(9) .dial-ring-cap-end {
  left: 50%;
  top: 50%;
  transform-origin: center;
  z-index: 9999999999999;
  animation-name: rotate-ring-cap-5;
  animation-delay: 0.4s;
  animation-timing-function: ease-in-out;
  animation-duration: 0.6s;
  animation-fill-mode: forwards;
  transform: translate(-50%, -50%) rotate(-90deg) translate(calc(calc( 180px - (calc(4 * 10px * 2) + calc( 4 * 6px * 2 )) ) / 2 - 10px / 2));
}
@keyframes rotate-ring-cap-5 {
  26% {
    transform: translate(-50%, -50%) rotate(calc(var(--angle) * (26 / 100) - 90deg)) translate(calc(calc( 180px - (calc(4 * 10px * 2) + calc( 4 * 6px * 2 )) ) / 2 - 10px / 2));
  }
}
.dial-ring:nth-child(9) .dial-ring-cap-end {
  left: 50%;
  top: 50%;
  transform-origin: center;
  z-index: 9999999999999;
  animation-name: rotate-ring-cap-5;
  animation-delay: 0.4s;
  animation-timing-function: ease-in-out;
  animation-duration: 0.6s;
  animation-fill-mode: forwards;
  transform: translate(-50%, -50%) rotate(-90deg) translate(calc(calc( 180px - (calc(4 * 10px * 2) + calc( 4 * 6px * 2 )) ) / 2 - 10px / 2));
}
@keyframes rotate-ring-cap-5 {
  27% {
    transform: translate(-50%, -50%) rotate(calc(var(--angle) * (27 / 100) - 90deg)) translate(calc(calc( 180px - (calc(4 * 10px * 2) + calc( 4 * 6px * 2 )) ) / 2 - 10px / 2));
  }
}
.dial-ring:nth-child(9) .dial-ring-cap-end {
  left: 50%;
  top: 50%;
  transform-origin: center;
  z-index: 9999999999999;
  animation-name: rotate-ring-cap-5;
  animation-delay: 0.4s;
  animation-timing-function: ease-in-out;
  animation-duration: 0.6s;
  animation-fill-mode: forwards;
  transform: translate(-50%, -50%) rotate(-90deg) translate(calc(calc( 180px - (calc(4 * 10px * 2) + calc( 4 * 6px * 2 )) ) / 2 - 10px / 2));
}
@keyframes rotate-ring-cap-5 {
  28% {
    transform: translate(-50%, -50%) rotate(calc(var(--angle) * (28 / 100) - 90deg)) translate(calc(calc( 180px - (calc(4 * 10px * 2) + calc( 4 * 6px * 2 )) ) / 2 - 10px / 2));
  }
}
.dial-ring:nth-child(9) .dial-ring-cap-end {
  left: 50%;
  top: 50%;
  transform-origin: center;
  z-index: 9999999999999;
  animation-name: rotate-ring-cap-5;
  animation-delay: 0.4s;
  animation-timing-function: ease-in-out;
  animation-duration: 0.6s;
  animation-fill-mode: forwards;
  transform: translate(-50%, -50%) rotate(-90deg) translate(calc(calc( 180px - (calc(4 * 10px * 2) + calc( 4 * 6px * 2 )) ) / 2 - 10px / 2));
}
@keyframes rotate-ring-cap-5 {
  29% {
    transform: translate(-50%, -50%) rotate(calc(var(--angle) * (29 / 100) - 90deg)) translate(calc(calc( 180px - (calc(4 * 10px * 2) + calc( 4 * 6px * 2 )) ) / 2 - 10px / 2));
  }
}
.dial-ring:nth-child(9) .dial-ring-cap-end {
  left: 50%;
  top: 50%;
  transform-origin: center;
  z-index: 9999999999999;
  animation-name: rotate-ring-cap-5;
  animation-delay: 0.4s;
  animation-timing-function: ease-in-out;
  animation-duration: 0.6s;
  animation-fill-mode: forwards;
  transform: translate(-50%, -50%) rotate(-90deg) translate(calc(calc( 180px - (calc(4 * 10px * 2) + calc( 4 * 6px * 2 )) ) / 2 - 10px / 2));
}
@keyframes rotate-ring-cap-5 {
  30% {
    transform: translate(-50%, -50%) rotate(calc(var(--angle) * (30 / 100) - 90deg)) translate(calc(calc( 180px - (calc(4 * 10px * 2) + calc( 4 * 6px * 2 )) ) / 2 - 10px / 2));
  }
}
.dial-ring:nth-child(9) .dial-ring-cap-end {
  left: 50%;
  top: 50%;
  transform-origin: center;
  z-index: 9999999999999;
  animation-name: rotate-ring-cap-5;
  animation-delay: 0.4s;
  animation-timing-function: ease-in-out;
  animation-duration: 0.6s;
  animation-fill-mode: forwards;
  transform: translate(-50%, -50%) rotate(-90deg) translate(calc(calc( 180px - (calc(4 * 10px * 2) + calc( 4 * 6px * 2 )) ) / 2 - 10px / 2));
}
@keyframes rotate-ring-cap-5 {
  31% {
    transform: translate(-50%, -50%) rotate(calc(var(--angle) * (31 / 100) - 90deg)) translate(calc(calc( 180px - (calc(4 * 10px * 2) + calc( 4 * 6px * 2 )) ) / 2 - 10px / 2));
  }
}
.dial-ring:nth-child(9) .dial-ring-cap-end {
  left: 50%;
  top: 50%;
  transform-origin: center;
  z-index: 9999999999999;
  animation-name: rotate-ring-cap-5;
  animation-delay: 0.4s;
  animation-timing-function: ease-in-out;
  animation-duration: 0.6s;
  animation-fill-mode: forwards;
  transform: translate(-50%, -50%) rotate(-90deg) translate(calc(calc( 180px - (calc(4 * 10px * 2) + calc( 4 * 6px * 2 )) ) / 2 - 10px / 2));
}
@keyframes rotate-ring-cap-5 {
  32% {
    transform: translate(-50%, -50%) rotate(calc(var(--angle) * (32 / 100) - 90deg)) translate(calc(calc( 180px - (calc(4 * 10px * 2) + calc( 4 * 6px * 2 )) ) / 2 - 10px / 2));
  }
}
.dial-ring:nth-child(9) .dial-ring-cap-end {
  left: 50%;
  top: 50%;
  transform-origin: center;
  z-index: 9999999999999;
  animation-name: rotate-ring-cap-5;
  animation-delay: 0.4s;
  animation-timing-function: ease-in-out;
  animation-duration: 0.6s;
  animation-fill-mode: forwards;
  transform: translate(-50%, -50%) rotate(-90deg) translate(calc(calc( 180px - (calc(4 * 10px * 2) + calc( 4 * 6px * 2 )) ) / 2 - 10px / 2));
}
@keyframes rotate-ring-cap-5 {
  33% {
    transform: translate(-50%, -50%) rotate(calc(var(--angle) * (33 / 100) - 90deg)) translate(calc(calc( 180px - (calc(4 * 10px * 2) + calc( 4 * 6px * 2 )) ) / 2 - 10px / 2));
  }
}
.dial-ring:nth-child(9) .dial-ring-cap-end {
  left: 50%;
  top: 50%;
  transform-origin: center;
  z-index: 9999999999999;
  animation-name: rotate-ring-cap-5;
  animation-delay: 0.4s;
  animation-timing-function: ease-in-out;
  animation-duration: 0.6s;
  animation-fill-mode: forwards;
  transform: translate(-50%, -50%) rotate(-90deg) translate(calc(calc( 180px - (calc(4 * 10px * 2) + calc( 4 * 6px * 2 )) ) / 2 - 10px / 2));
}
@keyframes rotate-ring-cap-5 {
  34% {
    transform: translate(-50%, -50%) rotate(calc(var(--angle) * (34 / 100) - 90deg)) translate(calc(calc( 180px - (calc(4 * 10px * 2) + calc( 4 * 6px * 2 )) ) / 2 - 10px / 2));
  }
}
.dial-ring:nth-child(9) .dial-ring-cap-end {
  left: 50%;
  top: 50%;
  transform-origin: center;
  z-index: 9999999999999;
  animation-name: rotate-ring-cap-5;
  animation-delay: 0.4s;
  animation-timing-function: ease-in-out;
  animation-duration: 0.6s;
  animation-fill-mode: forwards;
  transform: translate(-50%, -50%) rotate(-90deg) translate(calc(calc( 180px - (calc(4 * 10px * 2) + calc( 4 * 6px * 2 )) ) / 2 - 10px / 2));
}
@keyframes rotate-ring-cap-5 {
  35% {
    transform: translate(-50%, -50%) rotate(calc(var(--angle) * (35 / 100) - 90deg)) translate(calc(calc( 180px - (calc(4 * 10px * 2) + calc( 4 * 6px * 2 )) ) / 2 - 10px / 2));
  }
}
.dial-ring:nth-child(9) .dial-ring-cap-end {
  left: 50%;
  top: 50%;
  transform-origin: center;
  z-index: 9999999999999;
  animation-name: rotate-ring-cap-5;
  animation-delay: 0.4s;
  animation-timing-function: ease-in-out;
  animation-duration: 0.6s;
  animation-fill-mode: forwards;
  transform: translate(-50%, -50%) rotate(-90deg) translate(calc(calc( 180px - (calc(4 * 10px * 2) + calc( 4 * 6px * 2 )) ) / 2 - 10px / 2));
}
@keyframes rotate-ring-cap-5 {
  36% {
    transform: translate(-50%, -50%) rotate(calc(var(--angle) * (36 / 100) - 90deg)) translate(calc(calc( 180px - (calc(4 * 10px * 2) + calc( 4 * 6px * 2 )) ) / 2 - 10px / 2));
  }
}
.dial-ring:nth-child(9) .dial-ring-cap-end {
  left: 50%;
  top: 50%;
  transform-origin: center;
  z-index: 9999999999999;
  animation-name: rotate-ring-cap-5;
  animation-delay: 0.4s;
  animation-timing-function: ease-in-out;
  animation-duration: 0.6s;
  animation-fill-mode: forwards;
  transform: translate(-50%, -50%) rotate(-90deg) translate(calc(calc( 180px - (calc(4 * 10px * 2) + calc( 4 * 6px * 2 )) ) / 2 - 10px / 2));
}
@keyframes rotate-ring-cap-5 {
  37% {
    transform: translate(-50%, -50%) rotate(calc(var(--angle) * (37 / 100) - 90deg)) translate(calc(calc( 180px - (calc(4 * 10px * 2) + calc( 4 * 6px * 2 )) ) / 2 - 10px / 2));
  }
}
.dial-ring:nth-child(9) .dial-ring-cap-end {
  left: 50%;
  top: 50%;
  transform-origin: center;
  z-index: 9999999999999;
  animation-name: rotate-ring-cap-5;
  animation-delay: 0.4s;
  animation-timing-function: ease-in-out;
  animation-duration: 0.6s;
  animation-fill-mode: forwards;
  transform: translate(-50%, -50%) rotate(-90deg) translate(calc(calc( 180px - (calc(4 * 10px * 2) + calc( 4 * 6px * 2 )) ) / 2 - 10px / 2));
}
@keyframes rotate-ring-cap-5 {
  38% {
    transform: translate(-50%, -50%) rotate(calc(var(--angle) * (38 / 100) - 90deg)) translate(calc(calc( 180px - (calc(4 * 10px * 2) + calc( 4 * 6px * 2 )) ) / 2 - 10px / 2));
  }
}
.dial-ring:nth-child(9) .dial-ring-cap-end {
  left: 50%;
  top: 50%;
  transform-origin: center;
  z-index: 9999999999999;
  animation-name: rotate-ring-cap-5;
  animation-delay: 0.4s;
  animation-timing-function: ease-in-out;
  animation-duration: 0.6s;
  animation-fill-mode: forwards;
  transform: translate(-50%, -50%) rotate(-90deg) translate(calc(calc( 180px - (calc(4 * 10px * 2) + calc( 4 * 6px * 2 )) ) / 2 - 10px / 2));
}
@keyframes rotate-ring-cap-5 {
  39% {
    transform: translate(-50%, -50%) rotate(calc(var(--angle) * (39 / 100) - 90deg)) translate(calc(calc( 180px - (calc(4 * 10px * 2) + calc( 4 * 6px * 2 )) ) / 2 - 10px / 2));
  }
}
.dial-ring:nth-child(9) .dial-ring-cap-end {
  left: 50%;
  top: 50%;
  transform-origin: center;
  z-index: 9999999999999;
  animation-name: rotate-ring-cap-5;
  animation-delay: 0.4s;
  animation-timing-function: ease-in-out;
  animation-duration: 0.6s;
  animation-fill-mode: forwards;
  transform: translate(-50%, -50%) rotate(-90deg) translate(calc(calc( 180px - (calc(4 * 10px * 2) + calc( 4 * 6px * 2 )) ) / 2 - 10px / 2));
}
@keyframes rotate-ring-cap-5 {
  40% {
    transform: translate(-50%, -50%) rotate(calc(var(--angle) * (40 / 100) - 90deg)) translate(calc(calc( 180px - (calc(4 * 10px * 2) + calc( 4 * 6px * 2 )) ) / 2 - 10px / 2));
  }
}
.dial-ring:nth-child(9) .dial-ring-cap-end {
  left: 50%;
  top: 50%;
  transform-origin: center;
  z-index: 9999999999999;
  animation-name: rotate-ring-cap-5;
  animation-delay: 0.4s;
  animation-timing-function: ease-in-out;
  animation-duration: 0.6s;
  animation-fill-mode: forwards;
  transform: translate(-50%, -50%) rotate(-90deg) translate(calc(calc( 180px - (calc(4 * 10px * 2) + calc( 4 * 6px * 2 )) ) / 2 - 10px / 2));
}
@keyframes rotate-ring-cap-5 {
  41% {
    transform: translate(-50%, -50%) rotate(calc(var(--angle) * (41 / 100) - 90deg)) translate(calc(calc( 180px - (calc(4 * 10px * 2) + calc( 4 * 6px * 2 )) ) / 2 - 10px / 2));
  }
}
.dial-ring:nth-child(9) .dial-ring-cap-end {
  left: 50%;
  top: 50%;
  transform-origin: center;
  z-index: 9999999999999;
  animation-name: rotate-ring-cap-5;
  animation-delay: 0.4s;
  animation-timing-function: ease-in-out;
  animation-duration: 0.6s;
  animation-fill-mode: forwards;
  transform: translate(-50%, -50%) rotate(-90deg) translate(calc(calc( 180px - (calc(4 * 10px * 2) + calc( 4 * 6px * 2 )) ) / 2 - 10px / 2));
}
@keyframes rotate-ring-cap-5 {
  42% {
    transform: translate(-50%, -50%) rotate(calc(var(--angle) * (42 / 100) - 90deg)) translate(calc(calc( 180px - (calc(4 * 10px * 2) + calc( 4 * 6px * 2 )) ) / 2 - 10px / 2));
  }
}
.dial-ring:nth-child(9) .dial-ring-cap-end {
  left: 50%;
  top: 50%;
  transform-origin: center;
  z-index: 9999999999999;
  animation-name: rotate-ring-cap-5;
  animation-delay: 0.4s;
  animation-timing-function: ease-in-out;
  animation-duration: 0.6s;
  animation-fill-mode: forwards;
  transform: translate(-50%, -50%) rotate(-90deg) translate(calc(calc( 180px - (calc(4 * 10px * 2) + calc( 4 * 6px * 2 )) ) / 2 - 10px / 2));
}
@keyframes rotate-ring-cap-5 {
  43% {
    transform: translate(-50%, -50%) rotate(calc(var(--angle) * (43 / 100) - 90deg)) translate(calc(calc( 180px - (calc(4 * 10px * 2) + calc( 4 * 6px * 2 )) ) / 2 - 10px / 2));
  }
}
.dial-ring:nth-child(9) .dial-ring-cap-end {
  left: 50%;
  top: 50%;
  transform-origin: center;
  z-index: 9999999999999;
  animation-name: rotate-ring-cap-5;
  animation-delay: 0.4s;
  animation-timing-function: ease-in-out;
  animation-duration: 0.6s;
  animation-fill-mode: forwards;
  transform: translate(-50%, -50%) rotate(-90deg) translate(calc(calc( 180px - (calc(4 * 10px * 2) + calc( 4 * 6px * 2 )) ) / 2 - 10px / 2));
}
@keyframes rotate-ring-cap-5 {
  44% {
    transform: translate(-50%, -50%) rotate(calc(var(--angle) * (44 / 100) - 90deg)) translate(calc(calc( 180px - (calc(4 * 10px * 2) + calc( 4 * 6px * 2 )) ) / 2 - 10px / 2));
  }
}
.dial-ring:nth-child(9) .dial-ring-cap-end {
  left: 50%;
  top: 50%;
  transform-origin: center;
  z-index: 9999999999999;
  animation-name: rotate-ring-cap-5;
  animation-delay: 0.4s;
  animation-timing-function: ease-in-out;
  animation-duration: 0.6s;
  animation-fill-mode: forwards;
  transform: translate(-50%, -50%) rotate(-90deg) translate(calc(calc( 180px - (calc(4 * 10px * 2) + calc( 4 * 6px * 2 )) ) / 2 - 10px / 2));
}
@keyframes rotate-ring-cap-5 {
  45% {
    transform: translate(-50%, -50%) rotate(calc(var(--angle) * (45 / 100) - 90deg)) translate(calc(calc( 180px - (calc(4 * 10px * 2) + calc( 4 * 6px * 2 )) ) / 2 - 10px / 2));
  }
}
.dial-ring:nth-child(9) .dial-ring-cap-end {
  left: 50%;
  top: 50%;
  transform-origin: center;
  z-index: 9999999999999;
  animation-name: rotate-ring-cap-5;
  animation-delay: 0.4s;
  animation-timing-function: ease-in-out;
  animation-duration: 0.6s;
  animation-fill-mode: forwards;
  transform: translate(-50%, -50%) rotate(-90deg) translate(calc(calc( 180px - (calc(4 * 10px * 2) + calc( 4 * 6px * 2 )) ) / 2 - 10px / 2));
}
@keyframes rotate-ring-cap-5 {
  46% {
    transform: translate(-50%, -50%) rotate(calc(var(--angle) * (46 / 100) - 90deg)) translate(calc(calc( 180px - (calc(4 * 10px * 2) + calc( 4 * 6px * 2 )) ) / 2 - 10px / 2));
  }
}
.dial-ring:nth-child(9) .dial-ring-cap-end {
  left: 50%;
  top: 50%;
  transform-origin: center;
  z-index: 9999999999999;
  animation-name: rotate-ring-cap-5;
  animation-delay: 0.4s;
  animation-timing-function: ease-in-out;
  animation-duration: 0.6s;
  animation-fill-mode: forwards;
  transform: translate(-50%, -50%) rotate(-90deg) translate(calc(calc( 180px - (calc(4 * 10px * 2) + calc( 4 * 6px * 2 )) ) / 2 - 10px / 2));
}
@keyframes rotate-ring-cap-5 {
  47% {
    transform: translate(-50%, -50%) rotate(calc(var(--angle) * (47 / 100) - 90deg)) translate(calc(calc( 180px - (calc(4 * 10px * 2) + calc( 4 * 6px * 2 )) ) / 2 - 10px / 2));
  }
}
.dial-ring:nth-child(9) .dial-ring-cap-end {
  left: 50%;
  top: 50%;
  transform-origin: center;
  z-index: 9999999999999;
  animation-name: rotate-ring-cap-5;
  animation-delay: 0.4s;
  animation-timing-function: ease-in-out;
  animation-duration: 0.6s;
  animation-fill-mode: forwards;
  transform: translate(-50%, -50%) rotate(-90deg) translate(calc(calc( 180px - (calc(4 * 10px * 2) + calc( 4 * 6px * 2 )) ) / 2 - 10px / 2));
}
@keyframes rotate-ring-cap-5 {
  48% {
    transform: translate(-50%, -50%) rotate(calc(var(--angle) * (48 / 100) - 90deg)) translate(calc(calc( 180px - (calc(4 * 10px * 2) + calc( 4 * 6px * 2 )) ) / 2 - 10px / 2));
  }
}
.dial-ring:nth-child(9) .dial-ring-cap-end {
  left: 50%;
  top: 50%;
  transform-origin: center;
  z-index: 9999999999999;
  animation-name: rotate-ring-cap-5;
  animation-delay: 0.4s;
  animation-timing-function: ease-in-out;
  animation-duration: 0.6s;
  animation-fill-mode: forwards;
  transform: translate(-50%, -50%) rotate(-90deg) translate(calc(calc( 180px - (calc(4 * 10px * 2) + calc( 4 * 6px * 2 )) ) / 2 - 10px / 2));
}
@keyframes rotate-ring-cap-5 {
  49% {
    transform: translate(-50%, -50%) rotate(calc(var(--angle) * (49 / 100) - 90deg)) translate(calc(calc( 180px - (calc(4 * 10px * 2) + calc( 4 * 6px * 2 )) ) / 2 - 10px / 2));
  }
}
.dial-ring:nth-child(9) .dial-ring-cap-end {
  left: 50%;
  top: 50%;
  transform-origin: center;
  z-index: 9999999999999;
  animation-name: rotate-ring-cap-5;
  animation-delay: 0.4s;
  animation-timing-function: ease-in-out;
  animation-duration: 0.6s;
  animation-fill-mode: forwards;
  transform: translate(-50%, -50%) rotate(-90deg) translate(calc(calc( 180px - (calc(4 * 10px * 2) + calc( 4 * 6px * 2 )) ) / 2 - 10px / 2));
}
@keyframes rotate-ring-cap-5 {
  50% {
    transform: translate(-50%, -50%) rotate(calc(var(--angle) * (50 / 100) - 90deg)) translate(calc(calc( 180px - (calc(4 * 10px * 2) + calc( 4 * 6px * 2 )) ) / 2 - 10px / 2));
  }
}
.dial-ring:nth-child(9) .dial-ring-cap-end {
  left: 50%;
  top: 50%;
  transform-origin: center;
  z-index: 9999999999999;
  animation-name: rotate-ring-cap-5;
  animation-delay: 0.4s;
  animation-timing-function: ease-in-out;
  animation-duration: 0.6s;
  animation-fill-mode: forwards;
  transform: translate(-50%, -50%) rotate(-90deg) translate(calc(calc( 180px - (calc(4 * 10px * 2) + calc( 4 * 6px * 2 )) ) / 2 - 10px / 2));
}
@keyframes rotate-ring-cap-5 {
  51% {
    transform: translate(-50%, -50%) rotate(calc(var(--angle) * (51 / 100) - 90deg)) translate(calc(calc( 180px - (calc(4 * 10px * 2) + calc( 4 * 6px * 2 )) ) / 2 - 10px / 2));
  }
}
.dial-ring:nth-child(9) .dial-ring-cap-end {
  left: 50%;
  top: 50%;
  transform-origin: center;
  z-index: 9999999999999;
  animation-name: rotate-ring-cap-5;
  animation-delay: 0.4s;
  animation-timing-function: ease-in-out;
  animation-duration: 0.6s;
  animation-fill-mode: forwards;
  transform: translate(-50%, -50%) rotate(-90deg) translate(calc(calc( 180px - (calc(4 * 10px * 2) + calc( 4 * 6px * 2 )) ) / 2 - 10px / 2));
}
@keyframes rotate-ring-cap-5 {
  52% {
    transform: translate(-50%, -50%) rotate(calc(var(--angle) * (52 / 100) - 90deg)) translate(calc(calc( 180px - (calc(4 * 10px * 2) + calc( 4 * 6px * 2 )) ) / 2 - 10px / 2));
  }
}
.dial-ring:nth-child(9) .dial-ring-cap-end {
  left: 50%;
  top: 50%;
  transform-origin: center;
  z-index: 9999999999999;
  animation-name: rotate-ring-cap-5;
  animation-delay: 0.4s;
  animation-timing-function: ease-in-out;
  animation-duration: 0.6s;
  animation-fill-mode: forwards;
  transform: translate(-50%, -50%) rotate(-90deg) translate(calc(calc( 180px - (calc(4 * 10px * 2) + calc( 4 * 6px * 2 )) ) / 2 - 10px / 2));
}
@keyframes rotate-ring-cap-5 {
  53% {
    transform: translate(-50%, -50%) rotate(calc(var(--angle) * (53 / 100) - 90deg)) translate(calc(calc( 180px - (calc(4 * 10px * 2) + calc( 4 * 6px * 2 )) ) / 2 - 10px / 2));
  }
}
.dial-ring:nth-child(9) .dial-ring-cap-end {
  left: 50%;
  top: 50%;
  transform-origin: center;
  z-index: 9999999999999;
  animation-name: rotate-ring-cap-5;
  animation-delay: 0.4s;
  animation-timing-function: ease-in-out;
  animation-duration: 0.6s;
  animation-fill-mode: forwards;
  transform: translate(-50%, -50%) rotate(-90deg) translate(calc(calc( 180px - (calc(4 * 10px * 2) + calc( 4 * 6px * 2 )) ) / 2 - 10px / 2));
}
@keyframes rotate-ring-cap-5 {
  54% {
    transform: translate(-50%, -50%) rotate(calc(var(--angle) * (54 / 100) - 90deg)) translate(calc(calc( 180px - (calc(4 * 10px * 2) + calc( 4 * 6px * 2 )) ) / 2 - 10px / 2));
  }
}
.dial-ring:nth-child(9) .dial-ring-cap-end {
  left: 50%;
  top: 50%;
  transform-origin: center;
  z-index: 9999999999999;
  animation-name: rotate-ring-cap-5;
  animation-delay: 0.4s;
  animation-timing-function: ease-in-out;
  animation-duration: 0.6s;
  animation-fill-mode: forwards;
  transform: translate(-50%, -50%) rotate(-90deg) translate(calc(calc( 180px - (calc(4 * 10px * 2) + calc( 4 * 6px * 2 )) ) / 2 - 10px / 2));
}
@keyframes rotate-ring-cap-5 {
  55% {
    transform: translate(-50%, -50%) rotate(calc(var(--angle) * (55 / 100) - 90deg)) translate(calc(calc( 180px - (calc(4 * 10px * 2) + calc( 4 * 6px * 2 )) ) / 2 - 10px / 2));
  }
}
.dial-ring:nth-child(9) .dial-ring-cap-end {
  left: 50%;
  top: 50%;
  transform-origin: center;
  z-index: 9999999999999;
  animation-name: rotate-ring-cap-5;
  animation-delay: 0.4s;
  animation-timing-function: ease-in-out;
  animation-duration: 0.6s;
  animation-fill-mode: forwards;
  transform: translate(-50%, -50%) rotate(-90deg) translate(calc(calc( 180px - (calc(4 * 10px * 2) + calc( 4 * 6px * 2 )) ) / 2 - 10px / 2));
}
@keyframes rotate-ring-cap-5 {
  56% {
    transform: translate(-50%, -50%) rotate(calc(var(--angle) * (56 / 100) - 90deg)) translate(calc(calc( 180px - (calc(4 * 10px * 2) + calc( 4 * 6px * 2 )) ) / 2 - 10px / 2));
  }
}
.dial-ring:nth-child(9) .dial-ring-cap-end {
  left: 50%;
  top: 50%;
  transform-origin: center;
  z-index: 9999999999999;
  animation-name: rotate-ring-cap-5;
  animation-delay: 0.4s;
  animation-timing-function: ease-in-out;
  animation-duration: 0.6s;
  animation-fill-mode: forwards;
  transform: translate(-50%, -50%) rotate(-90deg) translate(calc(calc( 180px - (calc(4 * 10px * 2) + calc( 4 * 6px * 2 )) ) / 2 - 10px / 2));
}
@keyframes rotate-ring-cap-5 {
  57% {
    transform: translate(-50%, -50%) rotate(calc(var(--angle) * (57 / 100) - 90deg)) translate(calc(calc( 180px - (calc(4 * 10px * 2) + calc( 4 * 6px * 2 )) ) / 2 - 10px / 2));
  }
}
.dial-ring:nth-child(9) .dial-ring-cap-end {
  left: 50%;
  top: 50%;
  transform-origin: center;
  z-index: 9999999999999;
  animation-name: rotate-ring-cap-5;
  animation-delay: 0.4s;
  animation-timing-function: ease-in-out;
  animation-duration: 0.6s;
  animation-fill-mode: forwards;
  transform: translate(-50%, -50%) rotate(-90deg) translate(calc(calc( 180px - (calc(4 * 10px * 2) + calc( 4 * 6px * 2 )) ) / 2 - 10px / 2));
}
@keyframes rotate-ring-cap-5 {
  58% {
    transform: translate(-50%, -50%) rotate(calc(var(--angle) * (58 / 100) - 90deg)) translate(calc(calc( 180px - (calc(4 * 10px * 2) + calc( 4 * 6px * 2 )) ) / 2 - 10px / 2));
  }
}
.dial-ring:nth-child(9) .dial-ring-cap-end {
  left: 50%;
  top: 50%;
  transform-origin: center;
  z-index: 9999999999999;
  animation-name: rotate-ring-cap-5;
  animation-delay: 0.4s;
  animation-timing-function: ease-in-out;
  animation-duration: 0.6s;
  animation-fill-mode: forwards;
  transform: translate(-50%, -50%) rotate(-90deg) translate(calc(calc( 180px - (calc(4 * 10px * 2) + calc( 4 * 6px * 2 )) ) / 2 - 10px / 2));
}
@keyframes rotate-ring-cap-5 {
  59% {
    transform: translate(-50%, -50%) rotate(calc(var(--angle) * (59 / 100) - 90deg)) translate(calc(calc( 180px - (calc(4 * 10px * 2) + calc( 4 * 6px * 2 )) ) / 2 - 10px / 2));
  }
}
.dial-ring:nth-child(9) .dial-ring-cap-end {
  left: 50%;
  top: 50%;
  transform-origin: center;
  z-index: 9999999999999;
  animation-name: rotate-ring-cap-5;
  animation-delay: 0.4s;
  animation-timing-function: ease-in-out;
  animation-duration: 0.6s;
  animation-fill-mode: forwards;
  transform: translate(-50%, -50%) rotate(-90deg) translate(calc(calc( 180px - (calc(4 * 10px * 2) + calc( 4 * 6px * 2 )) ) / 2 - 10px / 2));
}
@keyframes rotate-ring-cap-5 {
  60% {
    transform: translate(-50%, -50%) rotate(calc(var(--angle) * (60 / 100) - 90deg)) translate(calc(calc( 180px - (calc(4 * 10px * 2) + calc( 4 * 6px * 2 )) ) / 2 - 10px / 2));
  }
}
.dial-ring:nth-child(9) .dial-ring-cap-end {
  left: 50%;
  top: 50%;
  transform-origin: center;
  z-index: 9999999999999;
  animation-name: rotate-ring-cap-5;
  animation-delay: 0.4s;
  animation-timing-function: ease-in-out;
  animation-duration: 0.6s;
  animation-fill-mode: forwards;
  transform: translate(-50%, -50%) rotate(-90deg) translate(calc(calc( 180px - (calc(4 * 10px * 2) + calc( 4 * 6px * 2 )) ) / 2 - 10px / 2));
}
@keyframes rotate-ring-cap-5 {
  61% {
    transform: translate(-50%, -50%) rotate(calc(var(--angle) * (61 / 100) - 90deg)) translate(calc(calc( 180px - (calc(4 * 10px * 2) + calc( 4 * 6px * 2 )) ) / 2 - 10px / 2));
  }
}
.dial-ring:nth-child(9) .dial-ring-cap-end {
  left: 50%;
  top: 50%;
  transform-origin: center;
  z-index: 9999999999999;
  animation-name: rotate-ring-cap-5;
  animation-delay: 0.4s;
  animation-timing-function: ease-in-out;
  animation-duration: 0.6s;
  animation-fill-mode: forwards;
  transform: translate(-50%, -50%) rotate(-90deg) translate(calc(calc( 180px - (calc(4 * 10px * 2) + calc( 4 * 6px * 2 )) ) / 2 - 10px / 2));
}
@keyframes rotate-ring-cap-5 {
  62% {
    transform: translate(-50%, -50%) rotate(calc(var(--angle) * (62 / 100) - 90deg)) translate(calc(calc( 180px - (calc(4 * 10px * 2) + calc( 4 * 6px * 2 )) ) / 2 - 10px / 2));
  }
}
.dial-ring:nth-child(9) .dial-ring-cap-end {
  left: 50%;
  top: 50%;
  transform-origin: center;
  z-index: 9999999999999;
  animation-name: rotate-ring-cap-5;
  animation-delay: 0.4s;
  animation-timing-function: ease-in-out;
  animation-duration: 0.6s;
  animation-fill-mode: forwards;
  transform: translate(-50%, -50%) rotate(-90deg) translate(calc(calc( 180px - (calc(4 * 10px * 2) + calc( 4 * 6px * 2 )) ) / 2 - 10px / 2));
}
@keyframes rotate-ring-cap-5 {
  63% {
    transform: translate(-50%, -50%) rotate(calc(var(--angle) * (63 / 100) - 90deg)) translate(calc(calc( 180px - (calc(4 * 10px * 2) + calc( 4 * 6px * 2 )) ) / 2 - 10px / 2));
  }
}
.dial-ring:nth-child(9) .dial-ring-cap-end {
  left: 50%;
  top: 50%;
  transform-origin: center;
  z-index: 9999999999999;
  animation-name: rotate-ring-cap-5;
  animation-delay: 0.4s;
  animation-timing-function: ease-in-out;
  animation-duration: 0.6s;
  animation-fill-mode: forwards;
  transform: translate(-50%, -50%) rotate(-90deg) translate(calc(calc( 180px - (calc(4 * 10px * 2) + calc( 4 * 6px * 2 )) ) / 2 - 10px / 2));
}
@keyframes rotate-ring-cap-5 {
  64% {
    transform: translate(-50%, -50%) rotate(calc(var(--angle) * (64 / 100) - 90deg)) translate(calc(calc( 180px - (calc(4 * 10px * 2) + calc( 4 * 6px * 2 )) ) / 2 - 10px / 2));
  }
}
.dial-ring:nth-child(9) .dial-ring-cap-end {
  left: 50%;
  top: 50%;
  transform-origin: center;
  z-index: 9999999999999;
  animation-name: rotate-ring-cap-5;
  animation-delay: 0.4s;
  animation-timing-function: ease-in-out;
  animation-duration: 0.6s;
  animation-fill-mode: forwards;
  transform: translate(-50%, -50%) rotate(-90deg) translate(calc(calc( 180px - (calc(4 * 10px * 2) + calc( 4 * 6px * 2 )) ) / 2 - 10px / 2));
}
@keyframes rotate-ring-cap-5 {
  65% {
    transform: translate(-50%, -50%) rotate(calc(var(--angle) * (65 / 100) - 90deg)) translate(calc(calc( 180px - (calc(4 * 10px * 2) + calc( 4 * 6px * 2 )) ) / 2 - 10px / 2));
  }
}
.dial-ring:nth-child(9) .dial-ring-cap-end {
  left: 50%;
  top: 50%;
  transform-origin: center;
  z-index: 9999999999999;
  animation-name: rotate-ring-cap-5;
  animation-delay: 0.4s;
  animation-timing-function: ease-in-out;
  animation-duration: 0.6s;
  animation-fill-mode: forwards;
  transform: translate(-50%, -50%) rotate(-90deg) translate(calc(calc( 180px - (calc(4 * 10px * 2) + calc( 4 * 6px * 2 )) ) / 2 - 10px / 2));
}
@keyframes rotate-ring-cap-5 {
  66% {
    transform: translate(-50%, -50%) rotate(calc(var(--angle) * (66 / 100) - 90deg)) translate(calc(calc( 180px - (calc(4 * 10px * 2) + calc( 4 * 6px * 2 )) ) / 2 - 10px / 2));
  }
}
.dial-ring:nth-child(9) .dial-ring-cap-end {
  left: 50%;
  top: 50%;
  transform-origin: center;
  z-index: 9999999999999;
  animation-name: rotate-ring-cap-5;
  animation-delay: 0.4s;
  animation-timing-function: ease-in-out;
  animation-duration: 0.6s;
  animation-fill-mode: forwards;
  transform: translate(-50%, -50%) rotate(-90deg) translate(calc(calc( 180px - (calc(4 * 10px * 2) + calc( 4 * 6px * 2 )) ) / 2 - 10px / 2));
}
@keyframes rotate-ring-cap-5 {
  67% {
    transform: translate(-50%, -50%) rotate(calc(var(--angle) * (67 / 100) - 90deg)) translate(calc(calc( 180px - (calc(4 * 10px * 2) + calc( 4 * 6px * 2 )) ) / 2 - 10px / 2));
  }
}
.dial-ring:nth-child(9) .dial-ring-cap-end {
  left: 50%;
  top: 50%;
  transform-origin: center;
  z-index: 9999999999999;
  animation-name: rotate-ring-cap-5;
  animation-delay: 0.4s;
  animation-timing-function: ease-in-out;
  animation-duration: 0.6s;
  animation-fill-mode: forwards;
  transform: translate(-50%, -50%) rotate(-90deg) translate(calc(calc( 180px - (calc(4 * 10px * 2) + calc( 4 * 6px * 2 )) ) / 2 - 10px / 2));
}
@keyframes rotate-ring-cap-5 {
  68% {
    transform: translate(-50%, -50%) rotate(calc(var(--angle) * (68 / 100) - 90deg)) translate(calc(calc( 180px - (calc(4 * 10px * 2) + calc( 4 * 6px * 2 )) ) / 2 - 10px / 2));
  }
}
.dial-ring:nth-child(9) .dial-ring-cap-end {
  left: 50%;
  top: 50%;
  transform-origin: center;
  z-index: 9999999999999;
  animation-name: rotate-ring-cap-5;
  animation-delay: 0.4s;
  animation-timing-function: ease-in-out;
  animation-duration: 0.6s;
  animation-fill-mode: forwards;
  transform: translate(-50%, -50%) rotate(-90deg) translate(calc(calc( 180px - (calc(4 * 10px * 2) + calc( 4 * 6px * 2 )) ) / 2 - 10px / 2));
}
@keyframes rotate-ring-cap-5 {
  69% {
    transform: translate(-50%, -50%) rotate(calc(var(--angle) * (69 / 100) - 90deg)) translate(calc(calc( 180px - (calc(4 * 10px * 2) + calc( 4 * 6px * 2 )) ) / 2 - 10px / 2));
  }
}
.dial-ring:nth-child(9) .dial-ring-cap-end {
  left: 50%;
  top: 50%;
  transform-origin: center;
  z-index: 9999999999999;
  animation-name: rotate-ring-cap-5;
  animation-delay: 0.4s;
  animation-timing-function: ease-in-out;
  animation-duration: 0.6s;
  animation-fill-mode: forwards;
  transform: translate(-50%, -50%) rotate(-90deg) translate(calc(calc( 180px - (calc(4 * 10px * 2) + calc( 4 * 6px * 2 )) ) / 2 - 10px / 2));
}
@keyframes rotate-ring-cap-5 {
  70% {
    transform: translate(-50%, -50%) rotate(calc(var(--angle) * (70 / 100) - 90deg)) translate(calc(calc( 180px - (calc(4 * 10px * 2) + calc( 4 * 6px * 2 )) ) / 2 - 10px / 2));
  }
}
.dial-ring:nth-child(9) .dial-ring-cap-end {
  left: 50%;
  top: 50%;
  transform-origin: center;
  z-index: 9999999999999;
  animation-name: rotate-ring-cap-5;
  animation-delay: 0.4s;
  animation-timing-function: ease-in-out;
  animation-duration: 0.6s;
  animation-fill-mode: forwards;
  transform: translate(-50%, -50%) rotate(-90deg) translate(calc(calc( 180px - (calc(4 * 10px * 2) + calc( 4 * 6px * 2 )) ) / 2 - 10px / 2));
}
@keyframes rotate-ring-cap-5 {
  71% {
    transform: translate(-50%, -50%) rotate(calc(var(--angle) * (71 / 100) - 90deg)) translate(calc(calc( 180px - (calc(4 * 10px * 2) + calc( 4 * 6px * 2 )) ) / 2 - 10px / 2));
  }
}
.dial-ring:nth-child(9) .dial-ring-cap-end {
  left: 50%;
  top: 50%;
  transform-origin: center;
  z-index: 9999999999999;
  animation-name: rotate-ring-cap-5;
  animation-delay: 0.4s;
  animation-timing-function: ease-in-out;
  animation-duration: 0.6s;
  animation-fill-mode: forwards;
  transform: translate(-50%, -50%) rotate(-90deg) translate(calc(calc( 180px - (calc(4 * 10px * 2) + calc( 4 * 6px * 2 )) ) / 2 - 10px / 2));
}
@keyframes rotate-ring-cap-5 {
  72% {
    transform: translate(-50%, -50%) rotate(calc(var(--angle) * (72 / 100) - 90deg)) translate(calc(calc( 180px - (calc(4 * 10px * 2) + calc( 4 * 6px * 2 )) ) / 2 - 10px / 2));
  }
}
.dial-ring:nth-child(9) .dial-ring-cap-end {
  left: 50%;
  top: 50%;
  transform-origin: center;
  z-index: 9999999999999;
  animation-name: rotate-ring-cap-5;
  animation-delay: 0.4s;
  animation-timing-function: ease-in-out;
  animation-duration: 0.6s;
  animation-fill-mode: forwards;
  transform: translate(-50%, -50%) rotate(-90deg) translate(calc(calc( 180px - (calc(4 * 10px * 2) + calc( 4 * 6px * 2 )) ) / 2 - 10px / 2));
}
@keyframes rotate-ring-cap-5 {
  73% {
    transform: translate(-50%, -50%) rotate(calc(var(--angle) * (73 / 100) - 90deg)) translate(calc(calc( 180px - (calc(4 * 10px * 2) + calc( 4 * 6px * 2 )) ) / 2 - 10px / 2));
  }
}
.dial-ring:nth-child(9) .dial-ring-cap-end {
  left: 50%;
  top: 50%;
  transform-origin: center;
  z-index: 9999999999999;
  animation-name: rotate-ring-cap-5;
  animation-delay: 0.4s;
  animation-timing-function: ease-in-out;
  animation-duration: 0.6s;
  animation-fill-mode: forwards;
  transform: translate(-50%, -50%) rotate(-90deg) translate(calc(calc( 180px - (calc(4 * 10px * 2) + calc( 4 * 6px * 2 )) ) / 2 - 10px / 2));
}
@keyframes rotate-ring-cap-5 {
  74% {
    transform: translate(-50%, -50%) rotate(calc(var(--angle) * (74 / 100) - 90deg)) translate(calc(calc( 180px - (calc(4 * 10px * 2) + calc( 4 * 6px * 2 )) ) / 2 - 10px / 2));
  }
}
.dial-ring:nth-child(9) .dial-ring-cap-end {
  left: 50%;
  top: 50%;
  transform-origin: center;
  z-index: 9999999999999;
  animation-name: rotate-ring-cap-5;
  animation-delay: 0.4s;
  animation-timing-function: ease-in-out;
  animation-duration: 0.6s;
  animation-fill-mode: forwards;
  transform: translate(-50%, -50%) rotate(-90deg) translate(calc(calc( 180px - (calc(4 * 10px * 2) + calc( 4 * 6px * 2 )) ) / 2 - 10px / 2));
}
@keyframes rotate-ring-cap-5 {
  75% {
    transform: translate(-50%, -50%) rotate(calc(var(--angle) * (75 / 100) - 90deg)) translate(calc(calc( 180px - (calc(4 * 10px * 2) + calc( 4 * 6px * 2 )) ) / 2 - 10px / 2));
  }
}
.dial-ring:nth-child(9) .dial-ring-cap-end {
  left: 50%;
  top: 50%;
  transform-origin: center;
  z-index: 9999999999999;
  animation-name: rotate-ring-cap-5;
  animation-delay: 0.4s;
  animation-timing-function: ease-in-out;
  animation-duration: 0.6s;
  animation-fill-mode: forwards;
  transform: translate(-50%, -50%) rotate(-90deg) translate(calc(calc( 180px - (calc(4 * 10px * 2) + calc( 4 * 6px * 2 )) ) / 2 - 10px / 2));
}
@keyframes rotate-ring-cap-5 {
  76% {
    transform: translate(-50%, -50%) rotate(calc(var(--angle) * (76 / 100) - 90deg)) translate(calc(calc( 180px - (calc(4 * 10px * 2) + calc( 4 * 6px * 2 )) ) / 2 - 10px / 2));
  }
}
.dial-ring:nth-child(9) .dial-ring-cap-end {
  left: 50%;
  top: 50%;
  transform-origin: center;
  z-index: 9999999999999;
  animation-name: rotate-ring-cap-5;
  animation-delay: 0.4s;
  animation-timing-function: ease-in-out;
  animation-duration: 0.6s;
  animation-fill-mode: forwards;
  transform: translate(-50%, -50%) rotate(-90deg) translate(calc(calc( 180px - (calc(4 * 10px * 2) + calc( 4 * 6px * 2 )) ) / 2 - 10px / 2));
}
@keyframes rotate-ring-cap-5 {
  77% {
    transform: translate(-50%, -50%) rotate(calc(var(--angle) * (77 / 100) - 90deg)) translate(calc(calc( 180px - (calc(4 * 10px * 2) + calc( 4 * 6px * 2 )) ) / 2 - 10px / 2));
  }
}
.dial-ring:nth-child(9) .dial-ring-cap-end {
  left: 50%;
  top: 50%;
  transform-origin: center;
  z-index: 9999999999999;
  animation-name: rotate-ring-cap-5;
  animation-delay: 0.4s;
  animation-timing-function: ease-in-out;
  animation-duration: 0.6s;
  animation-fill-mode: forwards;
  transform: translate(-50%, -50%) rotate(-90deg) translate(calc(calc( 180px - (calc(4 * 10px * 2) + calc( 4 * 6px * 2 )) ) / 2 - 10px / 2));
}
@keyframes rotate-ring-cap-5 {
  78% {
    transform: translate(-50%, -50%) rotate(calc(var(--angle) * (78 / 100) - 90deg)) translate(calc(calc( 180px - (calc(4 * 10px * 2) + calc( 4 * 6px * 2 )) ) / 2 - 10px / 2));
  }
}
.dial-ring:nth-child(9) .dial-ring-cap-end {
  left: 50%;
  top: 50%;
  transform-origin: center;
  z-index: 9999999999999;
  animation-name: rotate-ring-cap-5;
  animation-delay: 0.4s;
  animation-timing-function: ease-in-out;
  animation-duration: 0.6s;
  animation-fill-mode: forwards;
  transform: translate(-50%, -50%) rotate(-90deg) translate(calc(calc( 180px - (calc(4 * 10px * 2) + calc( 4 * 6px * 2 )) ) / 2 - 10px / 2));
}
@keyframes rotate-ring-cap-5 {
  79% {
    transform: translate(-50%, -50%) rotate(calc(var(--angle) * (79 / 100) - 90deg)) translate(calc(calc( 180px - (calc(4 * 10px * 2) + calc( 4 * 6px * 2 )) ) / 2 - 10px / 2));
  }
}
.dial-ring:nth-child(9) .dial-ring-cap-end {
  left: 50%;
  top: 50%;
  transform-origin: center;
  z-index: 9999999999999;
  animation-name: rotate-ring-cap-5;
  animation-delay: 0.4s;
  animation-timing-function: ease-in-out;
  animation-duration: 0.6s;
  animation-fill-mode: forwards;
  transform: translate(-50%, -50%) rotate(-90deg) translate(calc(calc( 180px - (calc(4 * 10px * 2) + calc( 4 * 6px * 2 )) ) / 2 - 10px / 2));
}
@keyframes rotate-ring-cap-5 {
  80% {
    transform: translate(-50%, -50%) rotate(calc(var(--angle) * (80 / 100) - 90deg)) translate(calc(calc( 180px - (calc(4 * 10px * 2) + calc( 4 * 6px * 2 )) ) / 2 - 10px / 2));
  }
}
.dial-ring:nth-child(9) .dial-ring-cap-end {
  left: 50%;
  top: 50%;
  transform-origin: center;
  z-index: 9999999999999;
  animation-name: rotate-ring-cap-5;
  animation-delay: 0.4s;
  animation-timing-function: ease-in-out;
  animation-duration: 0.6s;
  animation-fill-mode: forwards;
  transform: translate(-50%, -50%) rotate(-90deg) translate(calc(calc( 180px - (calc(4 * 10px * 2) + calc( 4 * 6px * 2 )) ) / 2 - 10px / 2));
}
@keyframes rotate-ring-cap-5 {
  81% {
    transform: translate(-50%, -50%) rotate(calc(var(--angle) * (81 / 100) - 90deg)) translate(calc(calc( 180px - (calc(4 * 10px * 2) + calc( 4 * 6px * 2 )) ) / 2 - 10px / 2));
  }
}
.dial-ring:nth-child(9) .dial-ring-cap-end {
  left: 50%;
  top: 50%;
  transform-origin: center;
  z-index: 9999999999999;
  animation-name: rotate-ring-cap-5;
  animation-delay: 0.4s;
  animation-timing-function: ease-in-out;
  animation-duration: 0.6s;
  animation-fill-mode: forwards;
  transform: translate(-50%, -50%) rotate(-90deg) translate(calc(calc( 180px - (calc(4 * 10px * 2) + calc( 4 * 6px * 2 )) ) / 2 - 10px / 2));
}
@keyframes rotate-ring-cap-5 {
  82% {
    transform: translate(-50%, -50%) rotate(calc(var(--angle) * (82 / 100) - 90deg)) translate(calc(calc( 180px - (calc(4 * 10px * 2) + calc( 4 * 6px * 2 )) ) / 2 - 10px / 2));
  }
}
.dial-ring:nth-child(9) .dial-ring-cap-end {
  left: 50%;
  top: 50%;
  transform-origin: center;
  z-index: 9999999999999;
  animation-name: rotate-ring-cap-5;
  animation-delay: 0.4s;
  animation-timing-function: ease-in-out;
  animation-duration: 0.6s;
  animation-fill-mode: forwards;
  transform: translate(-50%, -50%) rotate(-90deg) translate(calc(calc( 180px - (calc(4 * 10px * 2) + calc( 4 * 6px * 2 )) ) / 2 - 10px / 2));
}
@keyframes rotate-ring-cap-5 {
  83% {
    transform: translate(-50%, -50%) rotate(calc(var(--angle) * (83 / 100) - 90deg)) translate(calc(calc( 180px - (calc(4 * 10px * 2) + calc( 4 * 6px * 2 )) ) / 2 - 10px / 2));
  }
}
.dial-ring:nth-child(9) .dial-ring-cap-end {
  left: 50%;
  top: 50%;
  transform-origin: center;
  z-index: 9999999999999;
  animation-name: rotate-ring-cap-5;
  animation-delay: 0.4s;
  animation-timing-function: ease-in-out;
  animation-duration: 0.6s;
  animation-fill-mode: forwards;
  transform: translate(-50%, -50%) rotate(-90deg) translate(calc(calc( 180px - (calc(4 * 10px * 2) + calc( 4 * 6px * 2 )) ) / 2 - 10px / 2));
}
@keyframes rotate-ring-cap-5 {
  84% {
    transform: translate(-50%, -50%) rotate(calc(var(--angle) * (84 / 100) - 90deg)) translate(calc(calc( 180px - (calc(4 * 10px * 2) + calc( 4 * 6px * 2 )) ) / 2 - 10px / 2));
  }
}
.dial-ring:nth-child(9) .dial-ring-cap-end {
  left: 50%;
  top: 50%;
  transform-origin: center;
  z-index: 9999999999999;
  animation-name: rotate-ring-cap-5;
  animation-delay: 0.4s;
  animation-timing-function: ease-in-out;
  animation-duration: 0.6s;
  animation-fill-mode: forwards;
  transform: translate(-50%, -50%) rotate(-90deg) translate(calc(calc( 180px - (calc(4 * 10px * 2) + calc( 4 * 6px * 2 )) ) / 2 - 10px / 2));
}
@keyframes rotate-ring-cap-5 {
  85% {
    transform: translate(-50%, -50%) rotate(calc(var(--angle) * (85 / 100) - 90deg)) translate(calc(calc( 180px - (calc(4 * 10px * 2) + calc( 4 * 6px * 2 )) ) / 2 - 10px / 2));
  }
}
.dial-ring:nth-child(9) .dial-ring-cap-end {
  left: 50%;
  top: 50%;
  transform-origin: center;
  z-index: 9999999999999;
  animation-name: rotate-ring-cap-5;
  animation-delay: 0.4s;
  animation-timing-function: ease-in-out;
  animation-duration: 0.6s;
  animation-fill-mode: forwards;
  transform: translate(-50%, -50%) rotate(-90deg) translate(calc(calc( 180px - (calc(4 * 10px * 2) + calc( 4 * 6px * 2 )) ) / 2 - 10px / 2));
}
@keyframes rotate-ring-cap-5 {
  86% {
    transform: translate(-50%, -50%) rotate(calc(var(--angle) * (86 / 100) - 90deg)) translate(calc(calc( 180px - (calc(4 * 10px * 2) + calc( 4 * 6px * 2 )) ) / 2 - 10px / 2));
  }
}
.dial-ring:nth-child(9) .dial-ring-cap-end {
  left: 50%;
  top: 50%;
  transform-origin: center;
  z-index: 9999999999999;
  animation-name: rotate-ring-cap-5;
  animation-delay: 0.4s;
  animation-timing-function: ease-in-out;
  animation-duration: 0.6s;
  animation-fill-mode: forwards;
  transform: translate(-50%, -50%) rotate(-90deg) translate(calc(calc( 180px - (calc(4 * 10px * 2) + calc( 4 * 6px * 2 )) ) / 2 - 10px / 2));
}
@keyframes rotate-ring-cap-5 {
  87% {
    transform: translate(-50%, -50%) rotate(calc(var(--angle) * (87 / 100) - 90deg)) translate(calc(calc( 180px - (calc(4 * 10px * 2) + calc( 4 * 6px * 2 )) ) / 2 - 10px / 2));
  }
}
.dial-ring:nth-child(9) .dial-ring-cap-end {
  left: 50%;
  top: 50%;
  transform-origin: center;
  z-index: 9999999999999;
  animation-name: rotate-ring-cap-5;
  animation-delay: 0.4s;
  animation-timing-function: ease-in-out;
  animation-duration: 0.6s;
  animation-fill-mode: forwards;
  transform: translate(-50%, -50%) rotate(-90deg) translate(calc(calc( 180px - (calc(4 * 10px * 2) + calc( 4 * 6px * 2 )) ) / 2 - 10px / 2));
}
@keyframes rotate-ring-cap-5 {
  88% {
    transform: translate(-50%, -50%) rotate(calc(var(--angle) * (88 / 100) - 90deg)) translate(calc(calc( 180px - (calc(4 * 10px * 2) + calc( 4 * 6px * 2 )) ) / 2 - 10px / 2));
  }
}
.dial-ring:nth-child(9) .dial-ring-cap-end {
  left: 50%;
  top: 50%;
  transform-origin: center;
  z-index: 9999999999999;
  animation-name: rotate-ring-cap-5;
  animation-delay: 0.4s;
  animation-timing-function: ease-in-out;
  animation-duration: 0.6s;
  animation-fill-mode: forwards;
  transform: translate(-50%, -50%) rotate(-90deg) translate(calc(calc( 180px - (calc(4 * 10px * 2) + calc( 4 * 6px * 2 )) ) / 2 - 10px / 2));
}
@keyframes rotate-ring-cap-5 {
  89% {
    transform: translate(-50%, -50%) rotate(calc(var(--angle) * (89 / 100) - 90deg)) translate(calc(calc( 180px - (calc(4 * 10px * 2) + calc( 4 * 6px * 2 )) ) / 2 - 10px / 2));
  }
}
.dial-ring:nth-child(9) .dial-ring-cap-end {
  left: 50%;
  top: 50%;
  transform-origin: center;
  z-index: 9999999999999;
  animation-name: rotate-ring-cap-5;
  animation-delay: 0.4s;
  animation-timing-function: ease-in-out;
  animation-duration: 0.6s;
  animation-fill-mode: forwards;
  transform: translate(-50%, -50%) rotate(-90deg) translate(calc(calc( 180px - (calc(4 * 10px * 2) + calc( 4 * 6px * 2 )) ) / 2 - 10px / 2));
}
@keyframes rotate-ring-cap-5 {
  90% {
    transform: translate(-50%, -50%) rotate(calc(var(--angle) * (90 / 100) - 90deg)) translate(calc(calc( 180px - (calc(4 * 10px * 2) + calc( 4 * 6px * 2 )) ) / 2 - 10px / 2));
  }
}
.dial-ring:nth-child(9) .dial-ring-cap-end {
  left: 50%;
  top: 50%;
  transform-origin: center;
  z-index: 9999999999999;
  animation-name: rotate-ring-cap-5;
  animation-delay: 0.4s;
  animation-timing-function: ease-in-out;
  animation-duration: 0.6s;
  animation-fill-mode: forwards;
  transform: translate(-50%, -50%) rotate(-90deg) translate(calc(calc( 180px - (calc(4 * 10px * 2) + calc( 4 * 6px * 2 )) ) / 2 - 10px / 2));
}
@keyframes rotate-ring-cap-5 {
  91% {
    transform: translate(-50%, -50%) rotate(calc(var(--angle) * (91 / 100) - 90deg)) translate(calc(calc( 180px - (calc(4 * 10px * 2) + calc( 4 * 6px * 2 )) ) / 2 - 10px / 2));
  }
}
.dial-ring:nth-child(9) .dial-ring-cap-end {
  left: 50%;
  top: 50%;
  transform-origin: center;
  z-index: 9999999999999;
  animation-name: rotate-ring-cap-5;
  animation-delay: 0.4s;
  animation-timing-function: ease-in-out;
  animation-duration: 0.6s;
  animation-fill-mode: forwards;
  transform: translate(-50%, -50%) rotate(-90deg) translate(calc(calc( 180px - (calc(4 * 10px * 2) + calc( 4 * 6px * 2 )) ) / 2 - 10px / 2));
}
@keyframes rotate-ring-cap-5 {
  92% {
    transform: translate(-50%, -50%) rotate(calc(var(--angle) * (92 / 100) - 90deg)) translate(calc(calc( 180px - (calc(4 * 10px * 2) + calc( 4 * 6px * 2 )) ) / 2 - 10px / 2));
  }
}
.dial-ring:nth-child(9) .dial-ring-cap-end {
  left: 50%;
  top: 50%;
  transform-origin: center;
  z-index: 9999999999999;
  animation-name: rotate-ring-cap-5;
  animation-delay: 0.4s;
  animation-timing-function: ease-in-out;
  animation-duration: 0.6s;
  animation-fill-mode: forwards;
  transform: translate(-50%, -50%) rotate(-90deg) translate(calc(calc( 180px - (calc(4 * 10px * 2) + calc( 4 * 6px * 2 )) ) / 2 - 10px / 2));
}
@keyframes rotate-ring-cap-5 {
  93% {
    transform: translate(-50%, -50%) rotate(calc(var(--angle) * (93 / 100) - 90deg)) translate(calc(calc( 180px - (calc(4 * 10px * 2) + calc( 4 * 6px * 2 )) ) / 2 - 10px / 2));
  }
}
.dial-ring:nth-child(9) .dial-ring-cap-end {
  left: 50%;
  top: 50%;
  transform-origin: center;
  z-index: 9999999999999;
  animation-name: rotate-ring-cap-5;
  animation-delay: 0.4s;
  animation-timing-function: ease-in-out;
  animation-duration: 0.6s;
  animation-fill-mode: forwards;
  transform: translate(-50%, -50%) rotate(-90deg) translate(calc(calc( 180px - (calc(4 * 10px * 2) + calc( 4 * 6px * 2 )) ) / 2 - 10px / 2));
}
@keyframes rotate-ring-cap-5 {
  94% {
    transform: translate(-50%, -50%) rotate(calc(var(--angle) * (94 / 100) - 90deg)) translate(calc(calc( 180px - (calc(4 * 10px * 2) + calc( 4 * 6px * 2 )) ) / 2 - 10px / 2));
  }
}
.dial-ring:nth-child(9) .dial-ring-cap-end {
  left: 50%;
  top: 50%;
  transform-origin: center;
  z-index: 9999999999999;
  animation-name: rotate-ring-cap-5;
  animation-delay: 0.4s;
  animation-timing-function: ease-in-out;
  animation-duration: 0.6s;
  animation-fill-mode: forwards;
  transform: translate(-50%, -50%) rotate(-90deg) translate(calc(calc( 180px - (calc(4 * 10px * 2) + calc( 4 * 6px * 2 )) ) / 2 - 10px / 2));
}
@keyframes rotate-ring-cap-5 {
  95% {
    transform: translate(-50%, -50%) rotate(calc(var(--angle) * (95 / 100) - 90deg)) translate(calc(calc( 180px - (calc(4 * 10px * 2) + calc( 4 * 6px * 2 )) ) / 2 - 10px / 2));
  }
}
.dial-ring:nth-child(9) .dial-ring-cap-end {
  left: 50%;
  top: 50%;
  transform-origin: center;
  z-index: 9999999999999;
  animation-name: rotate-ring-cap-5;
  animation-delay: 0.4s;
  animation-timing-function: ease-in-out;
  animation-duration: 0.6s;
  animation-fill-mode: forwards;
  transform: translate(-50%, -50%) rotate(-90deg) translate(calc(calc( 180px - (calc(4 * 10px * 2) + calc( 4 * 6px * 2 )) ) / 2 - 10px / 2));
}
@keyframes rotate-ring-cap-5 {
  96% {
    transform: translate(-50%, -50%) rotate(calc(var(--angle) * (96 / 100) - 90deg)) translate(calc(calc( 180px - (calc(4 * 10px * 2) + calc( 4 * 6px * 2 )) ) / 2 - 10px / 2));
  }
}
.dial-ring:nth-child(9) .dial-ring-cap-end {
  left: 50%;
  top: 50%;
  transform-origin: center;
  z-index: 9999999999999;
  animation-name: rotate-ring-cap-5;
  animation-delay: 0.4s;
  animation-timing-function: ease-in-out;
  animation-duration: 0.6s;
  animation-fill-mode: forwards;
  transform: translate(-50%, -50%) rotate(-90deg) translate(calc(calc( 180px - (calc(4 * 10px * 2) + calc( 4 * 6px * 2 )) ) / 2 - 10px / 2));
}
@keyframes rotate-ring-cap-5 {
  97% {
    transform: translate(-50%, -50%) rotate(calc(var(--angle) * (97 / 100) - 90deg)) translate(calc(calc( 180px - (calc(4 * 10px * 2) + calc( 4 * 6px * 2 )) ) / 2 - 10px / 2));
  }
}
.dial-ring:nth-child(9) .dial-ring-cap-end {
  left: 50%;
  top: 50%;
  transform-origin: center;
  z-index: 9999999999999;
  animation-name: rotate-ring-cap-5;
  animation-delay: 0.4s;
  animation-timing-function: ease-in-out;
  animation-duration: 0.6s;
  animation-fill-mode: forwards;
  transform: translate(-50%, -50%) rotate(-90deg) translate(calc(calc( 180px - (calc(4 * 10px * 2) + calc( 4 * 6px * 2 )) ) / 2 - 10px / 2));
}
@keyframes rotate-ring-cap-5 {
  98% {
    transform: translate(-50%, -50%) rotate(calc(var(--angle) * (98 / 100) - 90deg)) translate(calc(calc( 180px - (calc(4 * 10px * 2) + calc( 4 * 6px * 2 )) ) / 2 - 10px / 2));
  }
}
.dial-ring:nth-child(9) .dial-ring-cap-end {
  left: 50%;
  top: 50%;
  transform-origin: center;
  z-index: 9999999999999;
  animation-name: rotate-ring-cap-5;
  animation-delay: 0.4s;
  animation-timing-function: ease-in-out;
  animation-duration: 0.6s;
  animation-fill-mode: forwards;
  transform: translate(-50%, -50%) rotate(-90deg) translate(calc(calc( 180px - (calc(4 * 10px * 2) + calc( 4 * 6px * 2 )) ) / 2 - 10px / 2));
}
@keyframes rotate-ring-cap-5 {
  99% {
    transform: translate(-50%, -50%) rotate(calc(var(--angle) * (99 / 100) - 90deg)) translate(calc(calc( 180px - (calc(4 * 10px * 2) + calc( 4 * 6px * 2 )) ) / 2 - 10px / 2));
  }
}
.dial-ring:nth-child(9) .dial-ring-cap-end {
  left: 50%;
  top: 50%;
  transform-origin: center;
  z-index: 9999999999999;
  animation-name: rotate-ring-cap-5;
  animation-delay: 0.4s;
  animation-timing-function: ease-in-out;
  animation-duration: 0.6s;
  animation-fill-mode: forwards;
  transform: translate(-50%, -50%) rotate(-90deg) translate(calc(calc( 180px - (calc(4 * 10px * 2) + calc( 4 * 6px * 2 )) ) / 2 - 10px / 2));
}
@keyframes rotate-ring-cap-5 {
  100% {
    transform: translate(-50%, -50%) rotate(calc(var(--angle) * (100 / 100) - 90deg)) translate(calc(calc( 180px - (calc(4 * 10px * 2) + calc( 4 * 6px * 2 )) ) / 2 - 10px / 2));
  }
}