@mixin notice {
  padding-top: .7rem;
  padding-bottom: .7rem;
  color: $white;
  text-align: center;
  text-transform: uppercase;
  font-weight: 600;
  a {
    color: $white;
    text-decoration: underline;
    font-weight: inherit;
  }
}
.notice-success {
  @include notice;
  background: $green;
}
.notice-error {
  @include notice;
  background: $red-rum;
}
