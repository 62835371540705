.hide,
[v-cloak] {
  display: none;
}
.show {
  display: block;
}
.center {
  text-align: center;
}
.text-right {
  text-align: right;
}
.text-ai {
  display: flex;
  align-items: center;
  color: $secondary-accent !important;
  svg {
    margin-right: 0.5rem;
  }
}

@mixin outlined-option($size, $mobile-size) {
  width: $size;
  height: $size;
  display: flex;
  align-items: center;
  justify-content: center;
  @media (max-width: $mobile-width) {
    width: $mobile-size;
    height: $mobile-size;
    line-height: $mobile-size;
  }
}

.js-resultsViewTab:hover svg g,
.js-resultsViewTab.tab--active svg g {
  opacity: 1;
}
.transcript-tab--active {
  color: $white;
  border-bottom: solid 2px $white;
}
