@mixin report-rung-meter($n, $color) {
  display: flex;
  lost-column: 6/9;
  lost-offset: 1/9 row 0;
  .report-rung-meter-bar:nth-child(-n + #{$n}) {
    background: $color;
  }

  @media (max-width: $mobile-width) {
    lost-column: 1/1;
    lost-offset: 0/1 row 0;
  }
}

.report-rung-meter-bar {
  background: $gallery;
  height: 10px;
  flex: 1;
  margin-right: 5px;
}

.report-rung-meter-1 {
  @include report-rung-meter(1, $medium-carmine);
}
.report-rung-meter-2 {
  @include report-rung-meter(2, $cathams-blue);
}
.report-rung-meter-3 {
  @include report-rung-meter(3, $cathams-blue);
}
.report-rung-meter-4 {
  @include report-rung-meter(4, $sea-green);
}
.report-rung-meter-5 {
  @include report-rung-meter(5, $sea-green);
}
