@mixin overlay {
  // background: $white;
  // box-shadow: 0 0 32px rgba(0, 0, 0, 0.5);
}

.modalized {
  width: 100vw;
  position: fixed;
  left: 0;
  top: 0;
  &:before {
    content: " ";
    background: $black;
    opacity: 0.7;
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    z-index: 9999;
  }
  .modal {
    display: block;
    box-shadow: 0 0 32px rgba(0, 0, 0, 0.5);
  }
}

.modal {
  @include overlay;
  @include panel;
  overflow-y: scroll;
  margin: auto;
  padding: 2.25rem;
  max-width: 780px;
  width: 780px;
  min-height: 250px !important;
  max-height: 588px !important;
  box-sizing: border-box;
  z-index: 9999999;
  position: relative;
  @include mobile {
    left: 0;
    right: 0;
    width: auto;
    top: 0;
    bottom: 0;
    box-sizing: border-box;
  }
  &-container {
    display: none;
    position: fixed;
    top: 50%;
    transform: translateY(-50%);
    left: 0;
    right: 0;
    z-index: 999999;
  }
  .close {
    position: absolute;
    right: 2.8125rem;
    top: 2.8125rem;
    color: $french-gray;
    cursor: pointer;
    &:hover svg path {
      fill: $text-white;
    }
  }
  &--wide {
    max-width: 780px;
  }
}

.survey-success-container {
  .modal,
  .modal--closeable {
    text-align: center;
    padding: 8rem 5rem;
  }
}

.modal-header {
  @include header-2;
  font-size: 2.5rem;
  margin-bottom: 2rem;
  &--pretext {
    color: $text-muted;
  }
  &:last-child {
    margin-bottom: 0;
  }
}
