@mixin button($color) {
  padding: 0 3rem;
  height: 3.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1rem;
  font-weight: 600;
  -webkit-appearance: none;
  border-radius: 8px;
  color: $color;
  min-width: 140px;
  box-sizing: border-box;
  border: none;
  @include mobile {
    line-height: 1.5em;
    padding: 0.5rem 1.5rem;
  }
  @include desktop {
    white-space: nowrap;
  }
  &:focus {
    outline: none;
  }
  &:hover {
    cursor: pointer;
  }
  &:disabled {
    opacity: 0.4;
    cursor: default;
  }
  &:last-child {
    margin-right: 0;
  }
}
.button-full {
  width: 100%;
}
.button-primary {
  @include button($text-primary-button);
  background: $accent;
  & + .button-outline:not(.button--no-space) {
    margin-left: 1rem;
  }
}
.button-primary-small {
  @extend .button-primary;
  font-size: 0.9rem;
  padding: 0 1.5rem;
}
.button-secondary {
  @include button($french-gray);
}

.button-solid {
  @include button($crayon-orange);
  border: none;
  padding: 0 2rem;
  & + * {
    margin-left: 0.5rem;
  }
}
.button-solid--primary {
  background: $crayon-orange;
  color: $accent;
}
.button-solid--secondary {
  background: $white;
}
.button-small {
  padding: 0 0.7rem;
}

.button-light {
  @include button($white);
}
.button-outline {
  @include button($accent);
  border: solid 1px $outline;
  background: transparent;
  box-sizing: border-box;
  &:not(:disabled):hover {
    border-width: 2px;
    border-color: $accent;
    color: $accent;
  }
  & + .button-primary:not(.button--no-space),
  & + .button-outline {
    margin-left: 1rem;
  }
}

.button-outline-orange {
  @include button($orange);
}

.tooltip {
  position: relative;
  svg path {
    fill: $text-muted;
  }
  &:after {
    position: absolute;
    width: 236px;
    padding: 1rem;
    border-radius: 8px;
    background: $background-panel-element;
    bottom: calc(100% + 0.5rem);
    left: 50%;
    transform: translateX(-50%);
    color: $white;
    font-weight: 400;
    text-transform: none;
    display: none;
    font-size: 0.75rem;
    line-height: 1rem;
    box-sizing: border-box;
    z-index: 999;
  }
  &:hover {
    svg path {
      fill: $white;
    }
    &:after {
      display: block;
    }
  }

  &--survey-name:after {
    content: "This is the name that is displayed in each survey statement. (e.g. Acme Checkout Flow)";
  }
  &--screenshot:after {
    content: "Displayed at the beginning of the survey to provide context. Recommended image size: 760 x 760";
  }
}

.tabs {
  display: flex;
}

.tab {
  border-radius: 8px 8px 0 0;
  text-align: center;
  flex-grow: 1;
  height: 64px;
  color: $text-muted;
  font-weight: 800;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  position: relative;
  cursor: pointer;
  box-sizing: border-box;
  line-height: 100%;
  svg {
    margin-right: 0.5rem;
  }
  &:hover {
    color: $text-white;
  }
  &--active {
    color: $text-white;
    background: $background-panel-element;
    svg {
      fill: $text-white;
    }
  }
  &--small {
    font-size: 0.75rem;
    height: 2rem;
    padding-left: 1rem;
    padding-right: 1rem;
    flex-grow: 0;
    background: $background-overlay;
  }
  &--menu {
    margin: 0;
    height: calc(2.5rem - 1px);
    border: solid 1px $background-overlay;
    border-bottom-width: 0.5rem;
    margin-bottom: -0.5rem;
    z-index: 9999;
    box-sizing: border-box;
  }

  &-menu-item {
    display: block;
    color: $text-link;
    text-align: left;
    font-size: 1rem;
    font-weight: 600;
    line-height: 1.5;
    &:hover {
      color: $text-white;
    }
    &:not(:last-child) {
      margin-bottom: 1rem;
    }
    &--divider {
      padding-bottom: 1.5rem;
      padding-left: 1.5rem;
      margin-left: -1.5rem;
      margin-right: -1.5rem;
      border-bottom: solid 1px $outline;
    }
  }

  &-content {
    padding: 1.5rem;
    text-align: left;
    z-index: 999;
    .pill-tab-container & {
      position: absolute;
      right: 0;
      top: calc(100% + 0.5rem);
      background: $background-overlay;
      width: 272px;
    }
    &--right-tab {
      border-top-right-radius: 0 !important;
    }
    &--left-tab {
      border-top-left-radius: 0 !important;
    }
  }
}

.pill-tab-container {
  position: relative;
  .tab-content {
    margin-top: -1px;
  }
}
