@mixin complete-ring() {
  stroke-width: 4px;
  stroke: $white;
}

$segmentWidth: 0.143;
$segmentScaleBase: $segmentWidth * 2;
$segmentScale: $segmentScaleBase + $segmentWidth,
  $segmentScaleBase + $segmentWidth * 2, $segmentScaleBase + $segmentWidth * 3,
  $segmentScaleBase + $segmentWidth * 4, $segmentScaleBase + $segmentWidth * 5;

.ux-ring {
  width: 100%;
  height: 100%;

  .label-part {
    fill: $french-gray;
  }
  .ring {
    transition: all 0.35s $ease-pop;
  }
  .slice {
    transition: all 0.35s $ease-pop;
    transform-origin: center;
    transform: scale(0);
  }

  &.bg-white .inner-ring {
    fill: $white;
  }
  &.bg-dark .inner-ring {
    fill: $nile-blue;
  }
}

.rung0 {
  .inner-ring {
    fill: none;
  }
}
.rung1 {
  .inner-ring,
  .ring1 {
    @include complete-ring();
  }
}
.rung2 {
  .inner-ring,
  .ring1,
  .ring2 {
    @include complete-ring();
  }
}
.rung3 {
  .inner-ring,
  .ring1,
  .ring2,
  .ring3 {
    @include complete-ring();
  }
}
.rung4 {
  .inner-ring,
  .ring1,
  .ring2,
  .ring3,
  .ring4 {
    @include complete-ring();
  }
}
.rung5 .ring {
  @include complete-ring();
}

@for $j from 1 through 5 {
  @for $i from 1 through 5 {
    .seg#{$j}-#{$i} {
      .slice#{$j} {
        transform: scale(nth($segmentScale, $i));
        @if $i == 1 {
          fill: $red-rum;
        }
        @if $i >= 2 {
          fill: $bleu-blue;
        }
        @if $i > 3 {
          fill: $sea-green;
        }
      }
    }
  }
}

.product-card-chart {
  .labels {
    display: none;
  }
}

.results-breakdown {
  @media (max-width: $mobile-width) {
    padding-top: 0;
  }
  .ux-ring {
    .labels {
      display: none;
    }
    .ring {
      stroke-width: 1px !important;
      stroke: $gallery !important;
    }
    .dividers line {
      stroke: $gallery;
    }
    .inner-ring {
      fill: white;
    }
  }
}
.chart-wrap {
  @media (max-width: $mobile-width) {
    order: 2;
    flex: 1;
    position: inherit;
    display: none;
  }
}
.result-container {
  @media (max-width: $mobile-width) {
    padding-top: 1.5rem;
    padding-bottom: 1.5rem;
  }
}
.result-content,
.report-comments-container {
  lost-column: 5/7;
  lost-offset: -2/7;

  @media (max-width: $mobile-width) {
    lost-column: 1/1;
    lost-offset: 0;
    order: 1;
  }
}
.result-functional .ux-ring {
  transform: rotate(54deg);
  .slice2,
  .slice3,
  .slice4,
  .slice5 {
    fill: $off-result-gray;
  }
}
.result-usable .ux-ring {
  transform: rotate(-18deg);
  .slice1,
  .slice3,
  .slice4,
  .slice5 {
    fill: $off-result-gray;
  }
}
.result-comfortable .ux-ring {
  transform: rotate(-90deg);
  .slice2,
  .slice1,
  .slice4,
  .slice5 {
    fill: $off-result-gray;
  }
}
.result-delightful .ux-ring {
  transform: rotate(-162deg);
  .slice2,
  .slice3,
  .slice1,
  .slice5 {
    fill: $off-result-gray;
  }
}
.result-meaningful .ux-ring {
  transform: rotate(-234deg);
  .slice2,
  .slice3,
  .slice4,
  .slice1 {
    fill: $off-result-gray;
  }
}

.score-circles {
  display: flex;
  justify-content: space-between;
  margin: 2rem auto;
  @media (max-width: $mobile-width) {
    text-align: center;
    flex-direction: column;
  }
}
.score-circle {
  position: relative;

  @media (max-width: $mobile-width) {
    margin-bottom: 2rem;
    flex-grow: 1;
  }
  .circle-text {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
  }
}
.score-numbers {
  font-size: 1.5rem;
  font-weight: 600;
  display: block;
  white-space: nowrap;
}
.score-label {
  font-size: 0.75rem;
  text-transform: uppercase;
}

@mixin completion-circle($color) {
  width: 135px;
  height: 135px;
  display: block;
  @media (max-width: $mobile-width) {
    margin: 0 auto;
  }
  .circle {
    fill: none;
    stroke: $color;
    stroke-miterlimit: 10;
    stroke-width: 5px;
    stroke-linecap: round;

    &.c-complete {
      stroke-dasharray: 425px;
      transform: rotate(-90deg);
      transform-origin: center;
      stroke-dashoffset: 425px; // 425px = 0%; 0px = 100%
    }

    &.c-base {
      opacity: 0.2;
    }
  }
  &.surveys .c-complete {
    animation: 1s ease-in-out 1.5s 1 score-100 forwards;
  }

  @for $i from 0 through 100 {
    &.score-#{$i} {
      .c-complete {
        //stroke-dashoffset: 425px - ($i * 4.25px);
        animation: 1s ease-in-out 0.5s 1 score-#{$i} forwards;
      }
    }
  }

  @for $i from 1 through 5 {
    &.ring-score-#{$i} {
      .c-complete {
        animation: 1s ease-in-out 1s 1 score-#{$i * 20} forwards;
      }
    }
  }
}

.completion-circle {
  @include completion-circle($crayon-orange);
}

@for $i from 0 through 100 {
  @keyframes score-#{$i} {
    0% {
      stroke-dashoffset: 425px;
    }
    100% {
      stroke-dashoffset: 425px - ($i * 4.25px);
    }
  }
}
