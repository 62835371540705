@mixin ladder-piece {
  background: $outline;
  border-radius: 99999px;
  box-sizing: border-box;
}

$ladder-side-thickness: 4px;
$ladder-bar-thickness: 2px;
$ladder-tiny-side-thickness: 10px;
$ladder-tiny-bar-thickness: 10px;

$ladder-bar-gap: 2px;
$ladder-bar-width: 80px;
$ladder-height: 310px;

$ladder-vertical-offset: 18px;
$ladder-horizontal-offset: 138px;
$ladder-tiny-horizontal-offset: 135px;

$ladder-inner-height: $ladder-height - $ladder-vertical-offset * 2;

$ladder-right-side-offset: calc(
  $ladder-horizontal-offset + $ladder-bar-width + $ladder-bar-gap
);
$ladder-tiny-right-side-offset: calc(
  $ladder-tiny-horizontal-offset + $ladder-bar-width + $ladder-bar-gap
);

.ladder-step-bar-container {
  width: 80px;
}
.ladder-step-bar {
  @include ladder-piece();
  height: $ladder-bar-thickness;
  z-index: 9999;
  flex: 0 0 $ladder-bar-width;
  margin-left: 6px;
  &:not(:first-child) {
    margin-top: 2px;
  }
  &--tiny {
    height: $ladder-tiny-bar-thickness;
    margin-right: -4px;
  }
  &--filled {
    background: $secondary-accent;
  }
  &--highlight {
    background: $accent;
    box-sizing: content-box;
    overflow: visible;
    position: relative;
    margin-bottom: 0px;
  }
}

.ladder:not(.ladder--tiny) {
  .ladder-average-marker-pointer {
    &:hover {
      width: 112px;
    }

    &, &:hover {
      transition: width 0.25s ease-in-out;
    }
  }
}
.ladder-average-marker {
  width: 100px;
  color: $white;
  font-weight: 600;
  font-size: 1.25rem;
  display: flex;
  justify-content: center;
  align-items: center;
  align-content: center;
  position: absolute;
  width: 50px;
  height: 28px;
  right: 50%;
  transform: translateX(50%);
  z-index: 9999;
  &--scale {
    display: none;
  }


  .ladder--tiny & {
    transform: translateX(200%) scale(3.25);
    transform-origin: top center;
    right: 0%;
  }

  &-pointer {
    right: -2rem;
    transform: none;
  }

  &,
  &:before {
    border-radius: 4px;
    background: $accent;
  }
  &-pointer {
    justify-content: flex-start;
    left: 90%;
    width: 44px;
    padding-left: 0.4rem;
    box-sizing: border-box;
    .ladder-average-marker--amount {
      width: 31px;
      text-align: right;
      display: inline-block;
    }


    .ladder-average-marker--scale {
      display: inline;
    }
    &:before {
      position: absolute;
      content: "";
      height: 22px;
      width: 22px;
      top: 50%;
      transform: translateY(-50%) rotate(45deg);
      left: -9px;
    }
  }
  &-label {
    z-index: 99999;
    overflow: hidden;
    white-space: nowrap;
  }
}

.ladder-section {
  display: flex;
}
.ladder-container {
  $spacer: 1.5rem;
  position: relative;
  &:not(:first-child) + & {
    margin-left: calc(2 * $spacer);
  }

  &--hover {
    &:before {
      position: absolute;
      content: "";
      background: $white;
      opacity: 0;
      border-radius: 8px;
      top: -$spacer;
      bottom: -$spacer;
      left: -$spacer;
      right: -$spacer;
    }
    &:hover:before {
      opacity: 0.05;
    }
  }
}
.ladder {
  .ladder-section--individual-response &--small {
    transform-origin: top center;
    margin-bottom: 0;
  }
  display: flex;
  flex-direction: column;
  height: $ladder-height;
  position: relative;
  width: 350px;

  &:before,
  &:after {
    @include ladder-piece();
    position: absolute;
    content: "";
    width: $ladder-side-thickness;
    z-index: 999;
    height: $ladder-height;
  }
  &:before {
    left: $ladder-horizontal-offset;
  }
  &:after {
    left: $ladder-right-side-offset;
  }
  &--no-highlight {
    width: 205px;
    &:before {
      left: calc($ladder-horizontal-offset - 7px);
    }
    &:after {
      left: calc($ladder-right-side-offset - 12px);
    }
  }
  &--no-labels,
  &--no-segment-labels {
    flex: 1 0 $ladder-bar-width;
    margin-left: 0;

    &:after {
      left: calc($ladder-bar-width + $ladder-side-thickness - $ladder-bar-gap);
    }

    &:before {
      left: 0;
    }
  }
  &--no-labels {
    width: calc($ladder-bar-width + $ladder-side-thickness + $ladder-bar-gap);
    
    .ladder-average-marker-pointer {
      left: 80%;
    }
  }
  &--no-segment-labels {
    width: 300px;
  }
  &--tiny {
    transform: scale(0.2);
    transform-origin: top right;
    margin-bottom: -20rem;
    height: 305px;
    width: 225px;

    &:before,
    &:after {
      width: $ladder-tiny-bar-thickness;
    }

    &:before {
      left: $ladder-tiny-horizontal-offset;
    }
    &:after {
      left: $ladder-tiny-right-side-offset;
    }
    .ladder-average-marker--scale {
      display: none;
    }
  }
  &--small {
    $ladder-inner-height: $ladder-inner-height;
    transform: scale(0.55);
    transform-origin: top left;
    margin-bottom: -10rem;
  }
  &--hide-ladder {
    &:before,
    &:after {
      display: none;
    }

    width: 150px;
  }
}
.ladder-section--improve {
  margin-left: 6rem;
  margin-right: 3rem;
  flex: 1 0 auto;
}
.ladder-step-label {
  flex: 0 0 calc($ladder-horizontal-offset - 1.5rem);
  .ladder--tiny & {
    font-size: 4rem;
    color: $accent;
  }
}
.ladder-step-score {
  margin-right: 2rem;
  text-align: right;
}
.ladder-step-name {
  margin-right: 1.125rem;
  text-align: right;
  .ladder--hide-ladder & {
    margin-right: 3rem;
  }
}

.ladder-step {
  display: flex;
  align-items: center;
  z-index: 9999;
  width: 100%;
  flex: 1 1 0;

  .ladder--hide-ladder & {
    justify-content: flex-end;
  }
}
