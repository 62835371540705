$font-primary: "Gotham A", "Gotham B", "Helvetica Neue", "Helvetica", Arial,
  sans-serif;
$font-secondary: "Gotham A", "Gotham B", "Helvetica Neue", "Helvetica", Arial,
  sans-serif;

html,
body,
input,
textarea {
  font-family: $font-primary;
}

body {
  color: $text-white;
}

.bold {
  font-weight: 500;
}

@mixin light-link {
  @include link;
  color: $white;
}

@mixin link {
  color: $text-link;
  cursor: pointer;
  &:hover {
    color: $text-white;
  }
}

@mixin light-link {
  color: $white;
}

@mixin paragraph {
  font-size: 1rem;
  line-height: 1.5rem;
  font-family: $font-primary;
  font-weight: 400;
  margin-bottom: 1rem;
  color: $text-body;
}
@mixin description-paragraph {
  @include paragraph;
  font-size: 1.125rem;
}

@mixin large-paragraph {
  @include paragraph;
  font-size: 1.5rem;
}

.large-paragraph {
  @include large-paragraph;
}

@mixin list-item {
  @include paragraph;
  display: list-item;
  list-style-type: disc;
  margin-left: 1.9rem;
}

@mixin header-1 {
  font-size: 2.5rem;
  font-weight: 800;
  font-family: $font-primary;
  color: $text-white;
  @media (max-width: $mobile-width) {
    font-size: 2.5rem;
  }
}
.header-1 {
  @include header-1;
}

.list--bold {
  color: $storm-dust;
  font-size: 1.1rem;
  li:not(:last-child) {
    margin-bottom: 1rem;
  }
}
.text-green {
  color: $accent;
}

.text-muted {
  color: $text-muted;
}

@mixin header-2 {
  font-size: 1.5rem;
  font-weight: 800;
  line-height: 2.5rem;

  @media (max-width: $mobile-width) {
    font-size: 1.2rem;
    line-height: 1.6rem;
  }
}

.header-2 {
  @include header-2;
  &--small {
    font-size: 1rem;
    line-height: 2.5rem;
  }
}

@mixin header-3 {
  font-size: 1.5rem;
  font-weight: 600;
  line-height: 1.7rem;
  color: $ship-gray;
}

.header-3 {
  @include header-3;
}

@mixin header-4 {
  font-size: 1.1rem;
  font-weight: 600;
  text-transform: uppercase;
  line-height: 1.5rem;
  color: $ship-gray;
}

.header-4 {
  @include header-4;
}

.header-5 {
  color: $dove-gray;
  font-size: 1.2rem;
  font-weight: 600;
  line-height: 2rem;
  margin-bottom: 1rem;
}

a {
  @include link;
}
.link {
  @include link;
}

.link--add {
  background-image: svg-load("../svg/plus.svg");
  padding-left: 2rem;
  background-repeat: no-repeat;
  background-position: left center;
  background-size: 1rem 1rem;
}
.text-service {
  color: $service-gray !important;
}
.text-primary {
  color: $crayon-orange !important;
}
.text-small {
  color: $text-muted;
  font-size: 0.75rem;
  font-weight: 500;
}
.link-secondary {
  @include link;
  color: $french-gray;
}

.btn-link {
  @include link;
}

p,
.baseline-text {
  @include paragraph;
}
ul.list {
  li {
    @include list-item;
  }
  &--condensed {
    margin-bottom: 1.75rem;
    li {
      line-height: 1.75rem;
      margin-bottom: 0;
    }
  }
}
.page-header {
  @include header-1;
  padding: 3rem 0;
  color: $ship-gray;
}
.page-container {
  padding: 2rem 0;
  background: $alabaster;
  h2 {
    @include header-2;
  }
  h2,
  h3,
  h4 {
    margin-bottom: 0.5rem;
    lost-column: 1/1;
  }
}

@mixin card-label {
  font-size: 0.9rem;
  text-transform: uppercase;
  color: $french-gray;
}

.text-center {
  text-align: center;
}

.text-accent {
  color: $accent;
}
