.settings-edit,
.settings-show {
  lost-column: 1/1;
}
.settings-container {
  background: $white;
  padding-top: 2rem;
  padding-bottom: 2rem;
}

.settings-user-name {
  @include header-1;
  margin-bottom: 1rem;
}
.settings-user-email {
  margin-bottom: 2rem;
}
.payment-edit {
  display: none;
  .payment-cancel {
    display: none;
  }
}
.settings-form-container {
  lost-offset: -3/12;
  lost-column: 6/12;
  @media (max-width: $mobile-width) {
    lost-column: 1/1;
    lost-offset: 0;
  }
}
.settings-account-delete {
  color: $red-rum;
  font-weight: bold;
}

.settings-section {
  margin-top: 4rem;
  margin-bottom: 4rem;
}

.preview-survey-link {
  margin-left: 0.5rem;
  cursor: pointer;
  &,
  &:hover {
    color: $accent;
  }
}
