@mixin header {
  padding: 2rem 0;
  @include mobile {
    padding: 1rem 0;
  }
}

.header {
  @include header;
  color: $text-muted;
  .icon-arrow-upward-white {
    display: inline-block;
  }
  .icon-arrow-upward-orange {
    display: none;
  }
  .icon-uxrings-logo-white {
    display: inline-block;
  }
  .icon-uxrings-logo-orange {
    display: none;
  }
  .icon-uxrings-logo-service,
  .icon-uxrings-logo-service-white {
    display: none;
  }
  .button-solid {
    background: $white;
    color: $crayon-orange;
  }
}

.header--service {
  @include header;
  .icon-uxrings-logo-service-white {
    display: inline-block;
  }
  .icon-uxrings-logo-white {
    display: none;
  }
  .icon-uxrings-logo-orange {
    display: none;
  }
  .button-solid {
    background: $white;
    color: $service-offering;
  }
}

.header--process {
  @include header;
  background: $process-offering;
  .icon-uxrings-logo-service-white {
    display: none;
  }
  .icon-uxrings-logo-white {
    display: inline-block;
  }
  .icon-uxrings-logo-orange {
    display: none;
  }
  .button-solid {
    background: $white;
    color: $process-offering;
  }
}

.light-header {
  @include header;
  color: $crayon-orange;
  .mobile-menu-link {
    color: $crayon-orange;
  }
  .icon-arrow-upward-white {
    display: none;
  }
  .icon-arrow-upward-orange {
    display: inline-block;
  }
  .icon-uxrings-logo-white {
    display: none;
  }
  .icon-uxrings-logo-orange {
    display: inline-block;
  }
  .icon-uxrings-logo-service,
  .icon-uxrings-logo-service-white {
    display: none;
  }
}

@mixin marketing-header-nav-link {
  @include link;
  margin-left: 1.5rem;
}

.marketing-header-nav-link {
  @include marketing-header-nav-link;
}
.marketing-header-nav-link-login {
  @include marketing-header-nav-link;

  @media (max-width: $mobile-width) {
    display: none;
  }
}

.marketing-user-controls {
  position: absolute;
  right: 0rem;
  transition: all 0.25s $ease-elegant;

  @media (max-width: $mobile-width) {
    top: 1.5rem;
  }

  :not(.menu-open) &.alt {
    a {
      margin: 0 1.5rem;
    }
    a:last-child {
      margin-right: 0;
    }
  }

  @include mobile {
    display: none;
    opacity: 0;
  }
}

.mobile-menu-link {
  position: absolute;
  top: 0.5rem;
  right: 1rem;
  color: white;
  @include desktop {
    display: none;
  }
}

#mainHeader.menu-open {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: white;
  z-index: 1000;

  .signed-in-container {
    position: static;
  }

  .mobile-menu-link {
    color: $crayon-orange;
    top: 1.5rem;
  }

  .marketing-user-controls {
    display: block;
    opacity: 1;
    position: relative;

    a {
      display: block;
      font-size: 44px;
      font-weight: 100;
      color: $crayon-orange;
      margin-bottom: 2rem;
    }
    .marketing-header-nav-link-signup {
      font-size: 18px;
      font-weight: bold;
    }
  }

  .mobile-contact {
    display: block;
    position: absolute;
    bottom: 0rem;
    padding: 1.5rem 1.5rem 1rem;
    .header-4 {
      color: $french-gray;
      margin-bottom: 0.25rem;
    }
    a {
      font-size: 24px;
      font-weight: 100;
    }
  }
}
.mobile-contact {
  display: none;
}
