.marketing-container {
  background: white;
  color: $ship-gray;
  * {
    box-sizing: border-box;
  }
}
.marketing-signup-cta {
  color: $white;
  position: relative;
  margin-top: 4rem;
  padding-top: 4rem;
  z-index: 1;
  .payment-cancel {
    display: none;
  }
  .site-container {
    z-index: 10;
  }
  &:before {
    content: "";
    background: $orange;
    background-image: linear-gradient(224deg, #ff8447 0%, #ff6417 100%);
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    z-index: -1;
  }
  &:after {
    content: "";
    background-position: center;
    background-repeat: no-repeat;
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    z-index: -1;
    background-image: url("data:image/svg+xml,%0A<svg width='1523px' height='1523px' viewBox='-41 -374 1523 1523' version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'><!-- Generator: Sketch 40.3 %2833839%29 - http://www.bohemiancoding.com/sketch --><desc>Created with Sketch.</desc><defs></defs><g id='Group-3' opacity='0.0299999993' stroke='none' stroke-width='1' fill='none' fill-rule='evenodd' transform='translate%289.000000, -324.000000%29'><circle id='Oval-1' stroke='%23FFFFFF' stroke-width='100' cx='711.5' cy='711.5' r='335.5'></circle><circle id='Oval-1' stroke='%23FFFFFF' stroke-width='100' cx='712' cy='712' r='159'></circle><circle id='Oval-1-Copy' stroke='%23FFFFFF' stroke-width='100' cx='711.5' cy='711.5' r='523.5'></circle><circle id='Oval-1-Copy-2' stroke='%23FFFFFF' stroke-width='100' cx='711.5' cy='711.5' r='711.5'></circle></g></svg>");
  }
}
.marketing-sample-dashboard {
  lost-column: 6/8;
  lost-offset: -1/8;
  margin-bottom: 6rem;
  box-sizing: border-box;
  @media (max-width: $mobile-width) {
    lost-offset: 0;
    lost-column: 1/1;
  }
}
.register-button-container {
  lost-column: 1/1;
  text-align: center;
  margin-bottom: 2rem;
}
.paid-plans-tagline {
  @include paragraph;
  color: $white;
  lost-column: 6/8;
  text-align: center;
  font-size: 1.3rem;
  lost-offset: -1/8;
  margin-bottom: 0;
}
.marketing-promo-tagline {
  color: $orange;
  text-align: center;
  lost-column: 1/1;
  margin-bottom: 4rem;
}
.marketing-trial {
  lost-column: 1/1;
  lost-flex-container: row;
  align-items: center;
  @include tablet {
    .icon-hand {
      display: none;
    }
  }
}
.marketing-trial__description {
  lost-offset: -1/8;
  lost-column: 3/8;
  @include mobile {
    lost-column: 1/1;
    lost-offset: 0;
    margin-bottom: 4rem;
    text-align: center;
  }
}

.marketing-trial__register {
  lost-column: 3/8;
  @include mobile {
    lost-column: 1/1;
    lost-offset: 0;
  }
}
.marketing-trial__title {
  margin-bottom: 1.5rem;
  border-bottom: solid 2px $sea-green;
  padding-bottom: 0.5rem;
  display: inline-block;
}
.marketing-title {
  @include header-1;
  margin-bottom: 3rem;
  @include mobile {
    margin-top: 4rem;
  }
}

.marketing-title--primary {
  color: $storm-dust;
  font-weight: bold;
}

.marketing-tagline {
  @include header-2;
  text-align: center;
  lost-offset: -1/16;
  lost-column: 14/16;

  margin-top: 2rem;
  margin-bottom: 4rem;
  @include mobile {
    margin-bottom: 2rem;
  }
}

.form-container {
  box-sizing: border-box;
  color: $french-gray;
  z-index: 1;
  @media (max-width: $mobile-width) {
    padding: 2rem;
    lost-offset: 0;
    lost-column: 1/1;
  }
}
.form-mini {
  @include overlay;
  padding: 2.5rem;
  background: $white;
}

.controls-helper-container {
  text-align: center;
}

.marketing-video-wrap {
  width: 100%;
  min-height: 180px;
  margin: 4rem auto;
  background: $orange;
}

.feature-row {
  lost-flex-container: row;
  // align-items: top;
  // justify-content: space-between;
  margin: 2rem auto;
  @media (max-width: $mobile-width) {
    lost-flex-container: column;
  }
}
.marketing-feature {
  display: flex;
  align-content: center;
  align-items: center;
  box-sizing: border-box;
  padding: 5rem 0;
  border-top: 1px solid $silver;
  overflow: hidden;
  &:first-child {
    border-top: 0;
  }
  @media (max-width: $mobile-width) {
    flex-direction: column;
    padding: 3rem 0;
  }

  > .col {
    width: 50%;
    @include mobile {
      width: calc(100% - 2rem);
      margin-top: 2rem;
      &:first-child {
        margin-top: 0;
      }
    }
  }
  @include desktop {
    &:nth-child(odd) {
      > .col:first-child {
        order: 2;
      }
    }
    &:nth-child(even) {
      video {
        position: absolute;
        right: 0;
      }
    }
  }
}

.feature-graphic {
  padding: 0;
  margin: 0;
  min-height: 82px;
  text-align: right;
  position: relative;

  img {
    max-width: 100%;
    height: auto;
  }
  video {
    display: inline-block;
    @include mobile {
      max-width: 100%;
      height: auto;
    }
  }

  .feature--system &,
  .feature--scores & {
    text-align: center;
    @include mobile {
      video {
        max-height: 150px;
      }
    }
  }
  @include desktop {
    .feature--tips & {
      min-height: 560px;
    }
    .feature--feedback & {
      min-height: 550px;
    }
    .feature--embed & {
      min-height: 558px;
    }
  }
}
.feature-message {
  width: 100%;
  max-width: 34em;
  margin: 0 auto;
  padding: 0 2rem;
  h4 {
    margin-bottom: 0.5rem;
    @include header-2;
  }
}

.list-faq {
  margin: 3rem auto;
  border-bottom: 1px solid $mercury;
  padding-bottom: 1.5rem;

  @include mobile {
    padding: 0 1.5rem 1.5rem;
    margin: 2rem auto;
  }
}

.faq-question {
  border-top: 1px solid $mercury;
  margin-top: 1.5rem;
  margin-bottom: 1rem;
  padding-top: 2.5rem;
  padding-left: 3rem;
  font-size: 24px;
  font-weight: 600;
  letter-spacing: 1px;
  color: $ship-gray;
  position: relative;
  cursor: pointer;

  @include mobile {
    font-size: 20px;
  }

  &:first-child {
    border-top: 0;
  }

  &:before,
  &:after {
    content: "";
    display: block;
    position: absolute;
    left: 0;
    top: calc(2.5rem + 11px);
    width: 16px;
    height: 3px;
    background: $orange;
    transition: 0.25s all $ease-elegant;
  }
  &:after {
    transform: rotate(90deg);
  }

  &.is-open {
    &:after {
      transform: rotate(0);
    }
    & + .faq-answer {
      max-height: 300px;
      opacity: 1;
      @include mobile {
        max-height: 400px;
      }
    }
  }
}
.faq-answer {
  padding-left: 3rem;
  font-weight: 200;
  font-size: 18px;
  color: $dove-gray;
  line-height: 1.5em;
  overflow: hidden;
  max-height: 0;
  opacity: 0;
  transition: 0.25s all $ease-elegant;
}

.faq-cta {
  text-align: center;
  padding: 6rem 1.5rem;
  h3 {
    @include header-2;
  }
}

.page-header-alt {
  padding: 2.5rem 1.5rem 5.5rem;
  text-align: center;
  color: white;
  background: $crayon-orange;

  @include mobile {
    padding: 3.5rem 1.5rem 4.5rem;
  }

  h1 {
    @include header-1;
  }
  .sub-headline {
    color: white;
    font-size: 1.25rem;
    margin: 1rem auto 2rem;
    font-weight: 300;
  }
}

.rings.pricing,
.create_pricing {
  background-color: $alabaster;
}
