.landing-page {
	background: $black-haze;
	padding: 4rem 1rem;

	@include mobile {
		padding: 1rem;
	}

	&__container {
		max-width: $site-width;
		margin: 0 auto;
	}

	&--service {
		background-image: url('https://s3.amazonaws.com/uxrings/images/service-illustration.svg');
		background-repeat: no-repeat;
		background-position: top right;
		transition: all .25s ease-in-out;
		@include tablet {
			background-size: 60% auto;
			background-position: 140% 30%;
		}
		@include mobile {
			padding-bottom: 300px;
			background-position: right 112%;
			background-size: 300px auto;
		}
	}

	&__form {
		max-width: 45%;
		margin: 3rem 0;
		@include tablet {
			max-width: 80%;
		}
		@include mobile {
			max-width: 100%;
		}

	}
}

.cx-companies {
	max-width: 50%;
	@include mobile {
		max-width: 100%;
		text-align: center;
	}
}
.list-company-logos {
	display: flex;
	align-content: center;
	align-items: center;
	@include mobile {
		justify-content: center;
	}
	li {
		margin-right: 2rem;
		margin-bottom: 1.5rem;
		max-width: 130px;

		&:nth-child(3n) {
			margin-right: 0;
		}

		img {
			display: block;
			width: 100%;
			height: auto;
		}
	}
}

.list-checklist {
	margin: 2rem 0;
	padding: 0;
	li {
		margin: 1.5rem 0;
		padding-left: 1.5rem;
		position: relative;
		font-size: 1.25rem;
		color: $storm-dust;
		@include mobile {
			font-size: 1rem;
		}
		&:before {
			content: '';
			background: svg-load("../svg/checkmark.svg") no-repeat center;
			height: 16px;
			width: 16px;
			background-size: contain;
			position: absolute;
			left: 0;
		}
	}
}

.form-flex-row {
	display: flex;
	justify-content: space-between;

	@include mobile {
		flex-direction: column;
	}

	.text-field {
		margin: 0 1.5rem 0 0;
		flex-basis: 60%;

		@include mobile {
			margin-bottom: 1.5rem;
		}
	}
}