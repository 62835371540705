.report-rung-summary {
  font-weight: 100;
  p {
    margin-bottom: 1rem;
    @include paragraph;
  }
  ul {
    li {
      @include list-item;
    }
  }
}
.report-rung-meaning {
  @include paragraph;
}
.report-rung-next-steps {
  p {
    @include paragraph;
  }
  ul {
    li {
      @include list-item;
    }
  }
}
.result-details {
  display: flex;
  align-items: flex-start;
  .survey-pager {
    margin-bottom: 3rem;
  }
}
.result-details-description {
  flex-grow: 1;
}
.report-rung-details {
  // margin-bottom: 1rem;
  @media (max-width: $mobile-width) {
    lost-column: 1/1;
    margin-bottom: 3rem;
  }
}
.report-rung-name {
  font-weight: 900;
  font-size: 2.5rem;
  color: $text-white;
  text-transform: capitalize;
}

.report-rung-score {
  line-height: 4rem;
  flex: 1;
  margin-right: 1rem;
}
