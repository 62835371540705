.survey-report-card {
  lost-flex-container: row;
}

.survey-report-card-item {
  background: $white;
  padding: 2rem 0;
  lost-column: 1/3;
  text-align: center;
  .survey-report-summary.nps & {
    lost-column: 1/1;
  }

  .survey-reports--inverted & { background: $alabaster; }
}

.survey-report-card-metric {
  font-size: 2.5rem;
  font-weight: 300;
  line-height: 4rem;
  color: $ship-gray;
}

.survey-report-card-label {
	@include card-label;
}
