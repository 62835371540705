$white: #ffffff;
$orange: #ff7a2e;
$crayon-orange: #fc662b;

$dove-gray: #737373;
$french-gray: $dove-gray; //#B9B9C0;
$ship-gray: #4d4d4d;
$alabaster: #f9f9fb;
$sebastian: #f4f4fa;
$athens-gray: #efeff3;
$service-gray: #333333;

$white-lilac: #e5e5e9;
$silver: #c1c1c1;
$jumbo: #868686;
$light-sea-green: #23aea1;
$green: #0eb58b;
$cornflower-blue: #53a0fe;

// Quiz Colors
$keppel: #2fada4;
$nile-blue: #1d3b4b;
$rangoon-green: #1a1919;

// survey options
$medium-carmine: #b3432d;
$cathams-blue: #2a566f;
$celestial-blue: #3b94c6;

$red-rum: #b3432d;
$sea-green: #23aea1;
$bleu-blue: #2a566f;
$off-result-gray: #f7f7f7;

// borders
$gallery: #f0f0f0;

$black-haze: #f7f7f7;
$storm-dust: #636363;

$hint-of-red: #f9f9f9;
$mercury: #e6e6e6;

$service-offering: $service-gray;
$process-offering: #108dea;

$sentiments: "very_bad", "bad", "neutral", "good", "very_good";
$accent: #6ac89b;
$secondary-accent: #ffcb77;
$sentimentColors: #db694e, #ffcb77, #c7c7c7, #5c99c9, #6ac89b;

$success: $accent;
$error: #b3432d;

// new colors
$black: #161616;
$highlighter: $secondary-accent;

$text-white: #ffffff;
$text-body: #C7C7C7;
$text-link: $text-body;
$text-light: #f7f8f9;
$text-muted: #8a8a8a;
$text-dark: #454545; //field outline
$text-primary-button: #272727;
$text-black: $black;

$outline: $text-dark;

$background-layout: #161616;
$background-panel: $text-primary-button;
$background-panel-hover: #323232;
$background-tabs: #404040;
$background-overlay: #363636;
$background-panel-element: #303030;
$background-panel-element-hover: $text-dark;
$background-pill: $text-body;

$panel-element-highlight: $text-muted;

$primary: #c34628;
