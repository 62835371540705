$dial-size: 180px;
$ring-spacer-width: 6px;
$ring-width: 10px;
$ring-fill: $text-muted;

$animation-duration: 0.6s;
$animation-delay: 0.4s;
$animation-function: ease-in-out;

$clip-path-right-hemisphere: polygon(50% 0, 100% 0, 100% 100%, 50% 100%);
$clip-path-left-hemisphere: polygon(0 0, 50% 0, 50% 100%, 0 100%);

@mixin center-ring() {
  position: absolute;
  border-radius: 50%;
  left: 50%;
  top: 50%;
  transform: translateX(-50%) translateY(-50%);
}

.dial {
  position: relative;
  height: $dial-size;
  width: $dial-size;
  margin-bottom: 3rem;

  &--small {
    transform: scale(0.65);
    transform-origin: top right;
  }
  .dial-container--individual-response &--small {
    transform-origin: top center;
  }

  // center circle
  &:before {
    $offset: 10 * $ring-width + 8 * $ring-spacer-width;
    $size: $dial-size - $offset;

    @include center-ring();
    content: "";
    height: $size;
    width: $size;
    background: $background-panel;
    z-index: 99999;
  }
}

// rounded ends of progress ring
.dial-ring-cap-start,
.dial-ring-cap-end {
  content: "";
  position: absolute;
  border-radius: 50%;
  height: $ring-width;
  width: $ring-width;
  background: $ring-fill;
}
.dial-ring-cap-start {
  top: 0%;
  left: 50%;
  transform: translateX(-50%);
}
.dial-ring-progress {
  // only show right semi-circle
  position: relative;
  overflow: hidden;
  width: 100%;
  height: 100%;

  // only show right hemisphere
  clip-path: $clip-path-right-hemisphere;

  &:before,
  &:not(.dial-ring-progress--less-than-halfway):after {
    content: "";
    position: absolute;
    background: $ring-fill;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    overflow: hidden;
    border-radius: 50%;
    transform-origin: center;
  }

  // only show left hemisphere to expose rotation on right hemisphere
  &:before {
    clip-path: $clip-path-left-hemisphere;
  }

  // only show right hemisphere to fill for progress rings more than halfway
  &:not(.dial-ring-progress--less-than-halfway):after {
    opacity: 0;
    clip-path: $clip-path-right-hemisphere;
  }
}
.dial-ring {
  @include center-ring();

  background: $background-panel-element;
  box-sizing: border-box;
  overflow: hidden;

  &--active {
    .dial-ring-progress:before,
    .dial-ring-progress:after,
    .dial-ring-cap-start,
    .dial-ring-cap-end {
      background: $accent;
    }
  }

  @for $ring from 1 through 5 {
    &--spacer:not(:last-child):nth-child(#{2 * $ring}) {
      @include center-ring();
      $spacer-offset: calc(
        #{$ring} *
          #{$ring-width} *
          2 +
          ((#{$ring} - 1) * #{$ring-spacer-width} * 2)
      );
      $spacer-size: calc(#{$dial-size} - #{$spacer-offset});
      width: $spacer-size;
      height: $spacer-size;
      background: $background-panel;
    }

    &:nth-child(#{2 * $ring - 1}) {
      $multiplier: $ring - 1;
      $ring-width-total: calc(#{$multiplier} * #{$ring-width} * 2);
      $ring-spacer-width-total: calc(
        #{$multiplier} * #{$ring-spacer-width} * 2
      );
      $current-size: calc(
        #{$dial-size} - (#{$ring-width-total} + #{$ring-spacer-width-total})
      );
      width: $current-size;
      height: $current-size;

      $reversed-ring-index: calc(5 - #{$ring});
      $ring-animation-delay: $animation-delay;

      .dial-ring-progress {
        &:before {
          $reversed-ring-index: calc(5 - #{$ring});
          animation-delay: $ring-animation-delay;
          animation-timing-function: $animation-function;
          animation-duration: $animation-duration;
          animation-name: rotation-#{$ring};
          animation-fill-mode: forwards;

          @keyframes rotation-#{$ring} {
            to {
              transform: rotate(var(--angle));
            }
          }
        }

        // unhide left side for rungs greater than halfway
        &:not(.dial-ring-progress--less-than-halfway) {
          $adjusted-animation-delay: calc(
            var(--percent-complete-at-half-filled) *
              #{$animation-duration} +
              #{$animation-delay}
          );
          &,
          &:after {
            animation-timing-function: linear;
            animation-duration: 0s;
            animation-fill-mode: forwards;
          }

          // The ease-in-out dial animation function causes a misalignment in the left/right hemisphere switching
          // such that the timing isn't exactly proportional to the progress of the ring
          // With a linear animation function, the problem doesn't exist since the timing lines up perfectly
          // Only segments that cross over to the left hemisphere (more than halfway) are applicable
          &.dial-ring-progress--segments-3 {
            &,
            &:after {
              animation-delay: calc(#{$adjusted-animation-delay} - 0.075s);
            }
          }
          &.dial-ring-progress--segments-4 {
            &,
            &:after {
              animation-delay: calc(#{$adjusted-animation-delay} - 0.03s);
            }
          }
          &.dial-ring-progress--segments-5 {
            &,
            &:after {
              animation-delay: $adjusted-animation-delay;
            }
          }

          animation-name: show-left-hemisphere-#{$ring};
          @keyframes show-left-hemisphere-#{$ring} {
            to {
              clip-path: none;
            }
          }

          // fill right hemisphere after 50% progress
          &:after {
            animation-name: fill-right-hemisphere-#{$ring};
            @keyframes fill-right-hemisphere-#{$ring} {
              to {
                opacity: 1;
              }
            }
          }
        }
      }

      @for $i from 0 through 100 {
        .dial-ring-cap-end {
          left: 50%;
          top: 50%;
          transform-origin: center;
          $offset-size: calc(#{$current-size} / 2 - #{$ring-width} / 2);
          z-index: 9999999999999;

          animation-name: rotate-ring-cap-#{$ring};
          animation-delay: $ring-animation-delay;
          animation-timing-function: $animation-function;
          animation-duration: $animation-duration;
          animation-fill-mode: forwards;

          $end-angle: calc(var(--angle) - 90deg);
          $keyframe-angle: calc(var(--angle) * (#{$i} / 100) - 90deg);

          transform: translate(-50%, -50%)
            rotate(-90deg)
            translate($offset-size);

          @keyframes rotate-ring-cap-#{$ring} {
            #{$i + "%"} {
              transform: translate(-50%, -50%) rotate($keyframe-angle)
                translate($offset-size);
            }
          }
        }
      }
    }
  }
}
