.pagination {
  text-align: center;
  margin: 3rem 0;
  font-size: 1.3rem;

  $color: $orange;

  @mixin boxed {
    @include outlined-option(2rem, 1.8rem);
    border: solid 2px $color;
  }

  @mixin arrow($indicator) {
    content: $indicator;
    position: absolute;
    top: 42%;
    transform: translateY(-50%);
    font-size: 2rem;
    line-height: 2rem;
  }

  .page {
    margin: 0 0.35rem;

    &.current {
      background: $color;
      color: $white;
    }
    &.current, a {
      @include boxed;
    }
    &.gap {
      color: $silver;
    }
  }

  .next {
    margin-left: 3rem;
    a:after {
      @include arrow('›');
      right: 8px;
    }
  }
  .prev {
    margin-right: 3rem;
    a:before {
      @include arrow('‹');
      left: 8px;
    }
  }

  .next, .prev {
    a {
      @include boxed;
      min-width: 9rem;
      position: relative;
    }
    @include mobile {
      display: none;
    }
  }
}
