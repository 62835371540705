.custom-question {
  display: flex;
  align-items: center;
  flex-grow: 1;
  .large-paragraph {
    margin-bottom: 0;
    flex-grow: 1;
    margin-right: 1rem;
  }
  form {
    width: 100%;
  }
  .text-field {
    margin-bottom: 2rem;
  }
  input[type="submit"] {
    margin-right: 2rem;
  }
  &--add {
    color: $text-muted;
    &:hover {
      color: $text-white;
      svg path {
        fill: $white;
      }
    }
  }
}

.custom-question__edit {
  margin-left: 1rem;
}

.custom-questions__actions {
  display: flex;
  align-items: center;
}
.custom-question__archive {
  flex-grow: 1;
}

.custom-question--outline {
  border-style: dashed;
  align-items: center;
  &:hover {
    border-color: $orange;
    border-style: solid;
    cursor: pointer;
  }
}

.custom-question--highlight {
  border-color: $orange;
  border-style: solid;
}

.custom-questions {
  margin: 1rem auto;
}
