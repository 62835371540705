$panel-vertical-padding: 1.75rem;
$panel-horizontal-padding: 1.5rem;

@mixin panel {
  background: $background-panel;
  border-radius: 0.5rem;
  padding: $panel-vertical-padding $panel-horizontal-padding;
}

.panel {
  @include panel;
  box-sizing: border-box;
  max-width: 1180px;
  margin-left: auto;
  margin-right: auto;
  height: auto;

  &>*:last-child {
    margin-bottom: 0;
  }

  &--outline {
    border: 2px dashed $panel-element-highlight;
  }

  &--hover:hover,
  &--highlight {
    background: $background-panel-element-hover;
    cursor: pointer;
  }

  &--card {
    height: 6.75rem;
    background: $background-panel-element;
    padding: 2rem 2.5rem 2rem 2.5rem;
    display: flex;
    align-items: center;

    &-icon {
      border-radius: 100%;
      background: $accent;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 2.5rem;
      height: 2.5rem;
      margin-right: 1.5rem;
    }

    h2 {
      line-height: 1.75rem;
    }

    p {
      line-height: 1.5rem;
    }
  }

  &:not(&--waffle)+& {
    margin-top: 1rem;
  }

  &--main {
    padding: 2.5rem;
    width: 1180px;
    overflow: hidden;
  }

  &--modalized-hide {
    display: none;
  }

  &--alert {
    font-size: 1.25rem;
    font-weight: bold;
    padding-top: 1.25rem;
    padding-bottom: 1.25rem;
    padding-left: 2.5rem;
    margin-bottom: 1.25rem;
    line-height: 1.375;
    @extend .site-container;
  }

  &--alert-success {
    background: $success;
  }

  &--alert-dark {
    color: $text-black;
    padding: 1.125rem;
    // padding-right: 1.125rem;
    font-size: 1rem;
  }

  &--alert-error {
    background: $error;
    color: $white;
  }

  &--waffle {
    margin-top: 0;
    display: block;
    margin: unset;
    max-width: unset;
  }

  &--dark {
    background: $black;
  }

  &--light {
    background: $background-panel-element;
    padding: 1.5rem;
    margin: unset;
  }

  &--thin {
    margin-top: 4rem;
    max-width: 780px;
    margin-left: auto;
    margin-right: auto;
  }

  &--medium {
    min-width: 580px;
  }

  &--content {
    width: 800px;
  }

  &--no-margin {
    margin-top: unset;
    margin-bottom: unset;
  }

  &--inner-section {
    border-top: solid 1px $outline;
    padding: 1.5rem 0;

    &:last-child {
      padding-bottom: 0;
    }
  }

  &--compact {
    padding-top: 1.5rem;
    padding-bottom: 1.5rem;
  }

  &--padded {
    padding: 2.5rem;
  }

  &--padded-y:not(:first-child) {
    padding-top: 2.5rem;
  }

  &--padded-y:not(:last-child) {
    padding-bottom: 0 !important;
    margin-bottom: 2.5rem;

    p:last-child {
      margin-bottom: 0;
    }
  }

  &--release-notes {
    p {
      line-height: 1.75rem;

      &+* {
        margin-bottom: 1.5rem;
      }
    }
  }
}

.panel-section {
  &:not(&--no-outline) {
    &:first-child {
      border-top: solid 1px $outline;
    }
  }

  border-bottom: solid 1px $outline;

  &--no-outline:last-child {
    border-bottom: none;
  }

  margin-left: -$panel-horizontal-padding;
  margin-right: -$panel-horizontal-padding;
  padding: 2rem $panel-vertical-padding;
}

.signed-in-container {
  @extend .site-container;
  align-items: center;
  position: relative;
  display: flex;
  justify-content: space-between;
}

.logo {
  lost-column: 2/5;
  display: flex;
  align-items: flex-end;

  .icon-logo {
    width: 117px;
    height: 24px;
    color: $white;
  }

  &:hover &-text {
    display: block;
  }

  &-text {
    display: none;
    margin-left: 1rem;
    font-weight: 500;
    margin-bottom: 1px;
  }
}

.password-reset-confirmation {
  @include header-2;
  text-align: center;
}

.password-reset-tagline {
  margin-bottom: 2rem;
  color: $text-white;
}

.settings-confirmation {
  @include header-2;
  text-align: center;
  margin-bottom: 2rem;
  color: $crayon-orange;
}

.user-controls {
  cursor: pointer;
  display: flex;
  align-items: center;
  position: relative;
}

.user-controls-container {
  text-align: right;
  position: relative;
}

.user-controls-caret {
  margin-right: 0.5rem;
  font-size: 0.9rem;
  margin-top: 1px;
}

.user-controls-plan {
  color: $white;
  font-size: 0.9rem;
  text-transform: capitalize;
  font-weight: 400;
  margin-top: 0.5rem;
  margin-left: 1.6rem;
}

.user-controls-name {
  @include header-4;
  text-transform: capitalize;
  color: $white;
  display: flex;
  align-items: flex-start;
  justify-content: flex-end;
  text-align: left;
}

.user-controls-expanded {
  display: none;
  position: absolute;
  right: 0;
  top: calc(100% + 0.5rem);
  text-align: left;
  z-index: 1000;
  box-shadow: 0 0 16px 0 rgba(0, 0, 0, 0.25);
}

.user-controls-nav {
  & &--item-divided {
    padding-bottom: 1.5rem;
    padding-left: 1.5rem;
    padding-right: 1.5rem;
    border-bottom: solid 1px $outline;
    margin-left: -1.5rem;
    margin-right: -1.5rem;
    margin-bottom: 1.5rem;
  }

  a {
    display: block;
    margin-bottom: 1rem;
    font-size: 1rem;

    &:last-child {
      margin-bottom: 0;
      line-height: 100%;
    }
  }
}

.user-controls-expanded-name {
  @include header-4;
  color: $text-white;
  text-transform: capitalize;
}

.user-controls-expanded-email {
  @include paragraph;
  font-size: 1rem;
  margin-bottom: 1.5rem;
  font-weight: 300;
  text-transform: lowercase;
}

.products-container {
  @extend .site-container;
  display: block;
}

.products-responses {
  margin-top: 1.5rem;
  font-size: 1.3rem;
}

.product-cards {}

.product-cards-header {
  @include header-3;
  margin-bottom: 1rem;

  @media (max-width: $mobile-width) {
    text-align: center;
  }
}

.products-preview-survey {
  font-size: 1rem;
  margin-left: 1rem;
}

.products-header {
  text-align: center;
  padding-top: 4rem;
  padding-bottom: 4rem;
}

.four04 {
  height: 320px;
  display: block;
  margin: 0 auto 3rem;
  padding: 0;
  position: relative;

  svg {
    position: absolute;
    left: 50%;
  }

  .emoji-404 {
    margin-left: -155px;
    z-index: 100;
    animation: 0.75s ease-out 0s 1 both dropin-emoji,
      2s ease-in-out 1s infinite alternate float-emoji;
  }

  .icon-emoji-shadow {
    bottom: 0;
    margin-left: -56px;
    animation: 0.75s ease-out 0s 1 both shadow-grow,
      2s ease-in-out 1s infinite alternate shadow-shrink;
  }

  .icon-4 {
    margin-left: -80px;
    top: 70px;
    animation: 0.5s $ease-elegant 0.4s 1 both flyout4-left;

    &:last-child {
      animation: 0.5s $ease-elegant 0.425s 1 both flyout4-right;
    }
  }
}

.homelink-404 {
  @include light-link;
  text-transform: uppercase;
  font-weight: bold;
  line-height: 2rem;

  svg {
    fill: white;
    vertical-align: -5px;
    display: inline-block;
    margin-right: 0.5rem;
    transition: all $ease-elegant 0.25s;
  }

  &:hover {
    svg {
      transform: translateX(-5px);
    }
  }
}

.four04-message {
  animation: 0.5s $ease-elegant 1s both message-fadein;
}

@keyframes message-fadein {
  0% {
    transform: translateY(10px);
    opacity: 0;
  }

  100% {
    transform: translateY(0px);
    opacity: 1;
  }
}

@keyframes float-emoji {
  0% {
    transform: translateY(0);
  }

  100% {
    transform: translateY(-6px);
  }
}

@keyframes dropin-emoji {
  0% {
    transform: translateY(-1000px);
  }

  60% {
    transform: translateY(30px);
  }

  80% {
    transform: translateY(-10px);
  }

  100% {
    transform: translateY(0px);
  }
}

@keyframes shadow-shrink {
  0% {
    transform: scale(1);
  }

  100% {
    transform: scale(0.9);
  }
}

@keyframes shadow-grow {
  0% {
    transform: scale(0);
  }

  60% {
    transform: scale(1.1);
  }

  80% {
    transform: scale(0.9);
  }

  100% {
    transform: scale(1);
  }
}

@keyframes flyout4-left {
  0% {
    opacity: 0;
  }

  60% {
    transform: translate(-80px, -20px);
  }

  80% {
    transform: translate(-130px, 10px);
  }

  90% {
    transform: translate(-160px, -5px);
  }

  100% {
    opacity: 1;
    transform: translate(-170px, 0);
  }
}

@keyframes flyout4-right {
  0% {
    opacity: 0;
    transform: translate(0, 0);
  }

  60% {
    transform: translate(80px, -20px);
  }

  80% {
    transform: translate(130px, 10px);
  }

  90% {
    transform: translate(160px, -5px);
  }

  100% {
    opacity: 1;
    transform: translate(170px, 0);
  }
}