@mixin survey-option(
  $color,
  $option-size: 4.5rem,
  $mobile-option-size: 3.3rem
) {
  font-weight: bold;
  text-align: center;
  margin: 0 1rem;
  font-size: 0.75rem;
  @media (max-width: $mobile-width) {
    margin: 0.3rem;
  }
  &:first-child,
  &:last-child {
    display: flex;
    align-items: center;
    .survey-option-name {
      visibility: visible;
      margin-top: 0;
      width: 175px;
      color: $text-muted;
      font-weight: bold;
      @media (max-width: $mobile-width) {
        display: none;
      }
    }
  }

  &:first-child {
    flex-direction: row-reverse;
    .survey-option-name {
      margin-right: 1.75rem;
    }
  }
  &:last-child {
    .survey-option-name {
      margin-left: 1.75rem;
    }
  }
  input[type="radio"] {
    display: none;
    &:checked ~ .survey-option-name {
      color: $text-white !important;
      visibility: visible;
    }
    &:checked + .survey-option-label {
      background: $accent;
      color: $white;
    }
  }
  .survey-option-label {
    @include outlined-option($option-size, $mobile-option-size);
    font-size: calc($option-size / 1.8);
    font-weight: 800;
    border-radius: 50%;
    color: $text-muted;
    background: $background-panel-element;
    @media (max-width: $mobile-width) {
      border-radius: $mobile-option-size;
      font-weight: 100;
    }
    &:hover {
      cursor: pointer;
    }
    &--hover {
      cursor: pointer;
      color: $text-white;
      & + .survey-option-name {
        color: $text-white !important;
      }
    }
  }
}

.survey-question.survey-question {
  text-align: center;
  padding: 5rem 0;

  @media (max-width: $mobile-width) {
    padding-top: 4rem;
    padding-bottom: 3rem;
  }
}

.plugin {
  $option-size: 3rem;
  $mobile-option-size: 1.5rem;
  @for $i from 1 through 5 {
    .survey-option-#{nth($sentiments, $i)} {
      $color: nth($sentimentColors, $i);
      @include survey-option($color, $option-size, $mobile-option-size);
    }
  }
}

@for $i from 1 through 5 {
  .survey-option-#{nth($sentiments, $i)} {
    $color: nth($sentimentColors, $i);
    @include survey-option($color);
  }
}

.survey-option-name {
  visibility: hidden;
  text-transform: uppercase;
  margin-top: 1rem;
  @media (max-width: $mobile-width) {
    display: none;
  }
}

.survey-question-ask {
  color: $text-white;
  margin-bottom: 3rem;
  font-weight: 800;
  font-size: 1.5rem;
  line-height: 1.75rem;
}
.survey-options {
  lost-column: 1/1;
  margin-bottom: 2rem;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: center;
  .plugin & {
    margin-bottom: 0;
  }
  @media (max-width: $mobile-width) {
    margin-bottom: 2rem;
  }
}

.survey-option-range {
  display: none;
  color: $silver;
  text-transform: uppercase;
  font-weight: 600;
  font-size: 1.2rem;
  margin-top: 1rem;
  lost-column: 1/1;
  @media (max-width: $mobile-width) {
    display: flex;
  }
  & > * {
    lost-column: 1/2;
  }
  .survey-option-range-disagree {
    text-align: left;
  }
  .survey-option-range-agree {
    text-align: right;
  }
}

.survey-questions li:first-child .survey-question {
  border-top: none;
  padding-top: 2rem;
}
