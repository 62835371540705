.survey-share-container {
  flex: 1 1 1;
}

.dashboard-product-details-container {
  .dashboard--new & {
    position: relative;
  }
}

.dashboard-product-details-header {
  align-items: center;
  display: flex;
}

.product-name-container {
  lost-column: 5/12;
}

.product-overview-container {
  display: flex;

  @include mobile {
    lost-column: 1/1;
    text-align: center;
    margin-bottom: 2rem;
  }
}

.product-summary-chart {
  display: flex;
  flex-direction: column;
  align-items: center;
  align-content: center;
  flex-grow: 1;
  margin-right: 4rem;

  @include mobile {
    lost-column: 1/1;
    lost-move: 0;
    lost-center: 400px !important;
  }
}

.dashboard-product-name-container {
  @include header-1;
  color: $white;
  margin-bottom: 2.5rem;
  height: 3.5rem;
}

.dashboard-product-survey-stats {
  margin: 0.5rem 0 2rem;
  max-width: 460px;

  @include mobile {
    margin-left: auto;
    margin-right: auto;
  }

  li {
    @include paragraph;
    font-size: 1.125rem;
    padding: 0.5rem 0;
    margin: 0;

    &:last-child {
      border-bottom: 1px solid $white-lilac;
    }
  }

  li+li {
    border-top: 1px solid $white-lilac;
  }
}

.dashboard-product-score-container {
  display: flex;
  align-items: center;
  max-width: 400px;
  flex-wrap: wrap;

  @include mobile {
    margin-left: auto;
    margin-right: auto;
  }
}

.score-block {
  display: block;
  padding: 0.75rem 1rem;
  background-color: $white;
  margin-right: 0.75rem;
  box-sizing: border-box;
  flex-basis: calc(50% - 0.375rem);
  margin-bottom: 0.75rem;

  &:nth-child(even) {
    margin-right: 0;
  }
}

.score-value {
  @include header-3;
  font-weight: 500;
  letter-spacing: -1px;
  margin-right: 0.25rem;
}

.score-label {
  color: $dove-gray;
}

.improve-cta {
  margin-top: 5rem;

  @include mobile {
    margin: 1rem auto 0;
    flex-basis: 100%;
  }
}

.product-score--abridged {
  max-width: 100%;

  @include mobile {
    justify-content: center;
  }

  .score-block {
    flex-basis: auto;
    margin-bottom: 0;
  }
}

.pill-buttons-container {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;

  &:last-child {
    margin-top: 2rem;
  }

  &:not(:last-child) {
    margin-bottom: 2rem;
  }

  p {
    margin-bottom: 0.5rem;
    font-size: 0.875rem;
    color: $french-gray;
    position: relative;
    z-index: 2;
  }
}

.pill-buttons {
  background: $background-tabs;
  height: 40px;
  overflow: hidden;
  display: flex;
  justify-content: center;
  border-radius: 99999px;
  padding: 3px;
  max-width: 40rem;
  position: relative;

  @include mobile {
    max-width: calc(100% - 2rem);
  }
}

.section-label {
  text-transform: uppercase;
  color: $text-muted;
  font-size: 0.75rem;
  margin-bottom: 1rem;
  font-weight: 700;
}

.metric {
  flex: 1 1 0;
}

.section-metric {
  @extend .header-2;
  font-size: 1rem;
  line-height: 1rem;

  &+.section-label {
    margin-top: 2rem;
  }

  &--big {
    font-size: 1.5rem;
  }
}

.results-summary {
  border-radius: 8px;
  width: 92px;
  height: 83px;
  border: solid 4px $outline;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  flex-direction: column;

  &+& {
    margin-left: 12px;
  }

  &-container {
    display: flex;
  }

  &-label {
    font-size: 0.75rem;
    font-weight: 400;
    color: $text-muted;
  }

  &-value {
    color: $white;
    font-size: 1.5rem;
    font-weight: 450;
    margin-bottom: 6px;
    font-weight: 800;
  }
}

.button-pill {
  font-weight: 600;
  border-radius: 99999px;
  white-space: nowrap;
  color: $text-link;
  z-index: 1;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 800;
  padding: 0 2rem;
  box-sizing: border-box;

  @include mobile {
    min-width: 0;
    font-size: 0.8rem;
  }

  &--outline {
    background: none;
    border: solid 1px $outline;
    color: $text-dark;
  }

  &--collapse {
    min-width: unset;
  }

  &:focus {
    outline: 0;
  }

  &:hover:not(.active) {
    cursor: pointer;
    color: $text-white;
  }

  &.active {
    background: $white;
    color: $text-black;

    a,
    a:hover {
      color: unset;
      cursor: none;
    }
  }
}

.dashboard-return {
  margin-left: 0.5rem;
  font-weight: bold;

  @include mobile {
    .dashboard-return-pretext {
      display: none;
    }
  }

  .dashboard:not(.dashboard--new) & {
    display: inline-block;
  }
}

.dashboard-product-name {
  line-height: 1.25em;
  font-size: 2.5rem;

  .dashboard:not(.dashboard--new) & {
    // FLAGGED FOR DEPRECATION
    lost-column: 1/1;
    display: flex;
    align-items: center;

    &>* {
      padding-bottom: 1rem;
    }

    span {
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
      display: block;
    }
  }
}

.product-name-edit {
  font-size: 1.2rem;
  vertical-align: 6px;
  margin-left: 0.5rem;

  &:hover svg path {
    fill: $white;
  }
}

.dashboard-product-name-form {
  display: none;

  // margin-bottom: 0.2rem;
  &>form {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .text-field {
    font-size: 1.5rem;
    font-weight: 600;
    margin-bottom: 0rem;
  }
}

.name-field-input {
  flex-basis: 85%;
}

.dashboard-return-container {
  flex-grow: 3;

  &>a {
    display: flex;
    align-items: center;

    &:hover svg path {
      fill: $white;
    }

    svg {
      margin-right: 0.5rem;
    }
  }
}

.dashboard-subnav-container {
  width: 100%;
}

.dashboard-spacer {
  flex-grow: 3;
}

.dashboard-subnav-container,
.dashboard:not(.dashboard--new) .dashboard-return-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  min-height: 48px;

  .icon-previous {
    font-size: 2rem;
    margin-right: 0.5rem;
  }

  a {
    font-size: 1rem;

    @include mobile {
      font-size: 0.8rem;
    }
  }
}

.survey-share-tabs {
  display: flex;
  align-items: center;

  p {
    @include mobile {
      display: none;
    }

    margin: 0 1rem 0 0;

    .modal & {
      flex-grow: 1;
      text-align: left;

      &.collapsed {
        display: none;
      }

      @include mobile {
        &.collapsed {
          display: block;
        }

        &.expanded {
          display: none;
        }
      }
    }
  }

  .modal & {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    height: 60px;
    padding: 0 1.5rem;
    background: $alabaster;
  }
}

// FLAGGED FOR DEPRECATION  :
.share-tab {
  height: 48px;
  text-transform: uppercase;
  font-weight: bold;
  padding: 0 1rem;
  text-align: center;

  .modal & {
    background: $alabaster;
    height: 100%;
  }

  &:hover {
    cursor: pointer;
  }

  &:focus {
    outline: 1px dotted $silver;
    outline-offset: -3px;
  }

  &.active {
    border-left: 1px solid $mercury;
    border-right: 1px solid $mercury;
    background: $alabaster;

    .modal & {
      background: $white;
    }
  }

  @include mobile {
    padding: 0 0.8rem;
    font-size: 0.9rem;
  }
}

.share-tab-container {
  .modal & {
    margin-bottom: 50px;
  }

  &.hidden {
    display: none;
  }
}

.survey-link-inner-container {
  @extend .site-container;
  align-items: center;
  lost-flex-container: row;
  margin-bottom: 1rem;
}

.survey-link-label,
.survey-embed-label {
  @include header-4;
  margin-bottom: 1rem;
  lost-column: 1/1;

  @media (max-width: $mobile-width) {
    text-align: center;
  }
}

// END FLAG

.upgrade-notice-container {
  @extend .site-container;
}

.upgrade-notice {
  text-align: center;
  padding-top: 4rem;
  padding-bottom: 4rem;
  lost-column: 1/1;
}

.upgrade-notice-header {
  @include header-2;
  margin-bottom: 1rem;
}

.upgrade-notice-tagline {}

.score-circles {
  display: block;
}

.show-delete-product {
  display: none;

  .product-card:hover & {
    display: block;
  }

  &:hover {
    svg path {
      fill: $text-white;
    }
  }
}

.show-delete-survey-response {
  &:hover {
    color: $text-white;

    svg path {
      fill: $text-white;
    }
  }
}

.score-circle {
  display: inline-block;
  margin-right: 2rem;
  margin-bottom: 2rem;

  @media (max-width: $mobile-width) {
    margin-right: 1rem;
    margin-bottom: 1rem;
  }
}

// FLAGGED FOR DEPRECATION :
.survey-link-text {
  lost-column: 8/12;

  .modal & {
    lost-column: 7/12;
  }

  @media (max-width: $mobile-width) {
    lost-column: 1/1;
    text-align: center;
    margin-bottom: 1rem;
  }
}

.survey-link {
  margin-right: 2rem;
}

.survey-link-button {
  @extend .button-primary;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  display: block;
  width: 100%;

  .product-card & {
    width: auto;
    @extend .button-primary-small;
    margin: 0 auto;
  }
}

.survey-link-copy {
  lost-column: 4/12;
  text-align: right;

  .modal & {
    lost-column: 5/12;
  }

  @media (max-width: $mobile-width) {
    lost-column: 1/1;
    text-align: center;
  }
}

// END FLAG

.survey-reports {}

.survey-embed-copy {
  display: flex;
  lost-column: 1/1;
  align-items: center;
  margin-top: 1rem;

  p {
    margin: 0 0 0 1.5rem;
  }

  @include mobile {
    flex-direction: column;

    .survey-embed-button {
      margin-bottom: 1rem;
      width: 100%;
    }

    p {
      margin: 0;
    }
  }
}

.survey-report-chart {
  lost-column: 5/15;

  @media (max-width: $mobile-width) {
    lost-column: 1/1;
    text-align: center;
    margin-bottom: 1rem;
  }
}

.survey-report-summary {
  lost-column: 10/15;

  @media (max-width: $mobile-width) {
    lost-column: 1/1;
  }

  &.nps {
    lost-column: 15/15;
  }
}

.survey-report-comments {
  .survey-report-summary.nps & {
    lost-column: 10/15;
    lost-offset: -5/15;

    @media (max-width: $mobile-width) {
      lost-column: 1/1;
      lost-offset: 0;
    }
  }
}

.survey-report {
  @media (max-width: $mobile-width) {
    padding-bottom: 3rem !important;

    &:last-child {
      margin-bottom: 0;
    }
  }
}

.hide-report-summary-analysis {
  display: none;

  @media (max-width: $mobile-width) {
    text-align: center;
    lost-column: 1/1;
    font-size: 1.5rem;
  }
}

.survey-report-analysis-container {
  lost-flex-container: row;
  display: none;
}

.survey-report-analysis-collapse {
  lost-column: 1/20;
  padding-top: 1rem;
}

.survey-report-analysis {
  lost-column: 19/20;
}

.survey-report-view-container {
  margin-bottom: 2rem;
}

.survey-report-comments-detail {}

.survey-report-comment {
  @include paragraph;

  p:last-child {
    margin: 0;
  }

  margin: 0;
  color: $text-link;
}

.report-rung-analysis-header {
  @include header-4;
  font-size: 1.5rem;
  font-weight: 900;
  color: $text-white;
  text-transform: capitalize;
  margin-bottom: 1rem;
}

.survey-report-comment-question {
  @include header-4;
  text-transform: unset;
  color: $text-white;
  font-size: 1rem;
  font-weight: 900;
}

.survey-report-view-less-comments {
  text-align: right;
}

.survey-report-metadata {
  lost-flex-container: row;
  align-items: center;

  .survey-report-summary.nps & {
    margin-bottom: 0;
  }
}

.survey-report-email {
  @include header-2;
  lost-column: 9/12;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 1.6rem;

  @media (max-width: $mobile-width) {
    lost-column: 1/1;
    margin-bottom: 1rem;
  }

  .survey-report-summary.nps & {
    lost-column: 4/12;

    @media (max-width: $mobile-width) {
      lost-column: 1/1;
    }
  }
}

.survey-report-nps {
  @include header-2;
  lost-column: 5/12;

  @media (max-width: $mobile-width) {
    lost-column: 1/1;
    margin-bottom: 2rem;
  }
}

.survey-report-timestamp {
  @media (max-width: $mobile-width) {
    lost-column: 1/1;
    text-align: left;
  }
}

.survey-reports-empty-headline {
  @include header-2;
  margin-bottom: 1rem;
  lost-column: 1/1;
}

.survey-reports-empty {
  padding: 8rem 0;
  text-align: center;
}

.survey-reports-empty-tagline {
  @include paragraph;
  lost-column: 1/1;
}

.survey-reports-empty-icon {
  color: $crayon-orange;
  text-align: center;
  font-size: 7rem;
  lost-column: 1/1;
  margin-bottom: 2rem;
}

.survey-report-comments-preview {
  position: relative;
  display: table;
  width: 100%;

  .survey-report-comment {
    margin-bottom: 0;
  }
}

.show-survey-report-comments {
  position: absolute;
  right: 0;
  top: 0;
  bottom: 0;
  width: 20rem;
  text-align: right;
  display: flex;
  justify-content: center;
  flex-direction: column;
  background: linear-gradient(to right,
      rgba(255, 255, 255, 0),
      rgba(249, 249, 251, 1),
      rgba(249, 249, 251, 1));
}

.sample-email {
  margin-top: 2rem;
  margin-bottom: 2rem;
  text-align: left;
}

.sample-email-header {
  text-align: center;
  @include header-4;
  margin-bottom: 1rem;
}

.search-description-container {
  @include paragraph;
  display: none;
  flex-grow: 1;
  align-items: center;

  .search-description {
    flex-grow: 1;
  }

  .search-clear {
    font-weight: bold;
    cursor: pointer;
  }
}

.filter-bank-container {
  flex-grow: 1;
  margin-bottom: 1.75rem;
}

.search-container {
  display: flex;
  align-items: center;
  margin-bottom: 0.5rem;
}

.text-field--controls {
  border-radius: 0.5rem;
  padding: 1rem;
  border: 1px solid $outline;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-grow: 1;
  height: 3.5rem;
  box-sizing: border-box;

  &.active {
    border-color: $secondary-accent;
    box-shadow: inset 0 0 0px 1px $secondary-accent;
  }

  &.text-field--controls-standard.active {
    border-color: $accent;
    box-shadow: inset 0 0 0px 1px $accent;
  }

  @include mobile {
    border-width: 1px 0;
    flex-wrap: wrap;
    justify-content: space-between;
  }

  .remove-custom-question-button {
    display: none;
  }

  &-container:hover .remove-custom-question-button {
    display: block;
  }
}

.filter-bank-bar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-top: 1rem;
  transition: all 0.25s $ease-elegant;

  @include mobile {
    flex-wrap: wrap;
    justify-content: flex-start;
  }
}

.saved-filters-label {
  white-space: nowrap;
  color: $text-muted;
  margin: 0;
  font-weight: 700;

  @include mobile {
    padding-left: 0;
    border-left: 0;
    order: 2;
  }
}

.results-shown-label {
  @include header-1;
  color: $white;
  margin-bottom: 2rem;
  font-size: 2rem;

  @include mobile {
    order: 1;
  }
}

.text-field--controls-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-grow: 1;

  @include mobile {
    flex-basis: 100%;
    order: 2;
    margin-right: 0;
  }

  &:focus {
    border-bottom-color: $crayon-orange;
  }

  input {
    border: 0;
    background: none;
    height: 100%;
    flex-grow: 1;

    &:focus {
      outline: 0;

      &+.btn-submit-search {
        display: inline;
        margin-left: 1rem;
      }
    }
  }

  .icon-search {
    margin-right: 0.5rem;
  }
}

.btn-clear-search,
.btn-submit-search,
.btn-save-search {
  display: none;

  &.active {
    display: block;
  }
}

.btn-submit-search {
  @include mobile {
    display: none !important;
  }
}

.btn-save-search {
  padding: 0.5rem 1rem;
  color: $accent;
  font-weight: 900;

  @include mobile {
    flex-basis: 100%;
    order: 3;
    padding: 1rem 0;
    text-align: center;
  }
}

.search-form-input {
  font-size: 1rem;
  font-weight: 600;
  line-height: 42px;
  width: 100%;
  color: $text-white;

  &:focus {
    outline: none;
    border-color: $secondary-accent;
  }

  &.active {
    color: $secondary-accent;
    border-color: $secondary-accent;
  }
}

.uxr-date-picker {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex: 0 0 250px;

  .text-field {
    font-weight: 700;
  }

  &:after {
    content: "";
    height: 12px;
    width: 8px;
    background: svg-load("../svg/caret-left.svg");
    background-size: contain;
    background-repeat: no-repeat;
    transform: rotate(90deg);
    pointer-events: none;
    margin-right: 1.25rem;
  }

  .text-field {
    margin-bottom: 0;
    padding-left: 1rem;
    padding-right: 1rem;
    border-radius: 0.5rem;
    padding: 1rem;
    border: 1px solid $outline;
    margin-right: -4.5rem;

    &:hover {
      cursor: pointer;
    }
  }

  @include mobile {
    order: 1;
    flex-basis: 100%;
    margin: 0 auto 1rem;
    display: flex;
    justify-content: space-between;

    &:after {
      display: none;
    }

    &-desktop-input {
      display: none;
    }
  }

  &-mobile-input {
    display: none;

    @include mobile {
      display: block;
      width: 0;
      flex-basis: 48%;
    }

    input {
      padding: 0.5rem;
      font-size: 0.875rem;
    }

    input::-webkit-calendar-picker-indicator,
    input::-webkit-inner-spin-button {
      display: none;
      -webkit-appearance: none;
    }
  }
}

.search-results-title {
  @include header-4;
  margin: 0;

  @include mobile {
    flex-basis: 75%;
  }
}

.filter-bank {
  margin: 0 1rem;
  flex-grow: 1;
  display: flex;
  flex-wrap: wrap;

  @include mobile {
    margin: 1rem auto 0;
    align-items: flex-start;
    padding: 0;
    flex-basis: 100%;
    order: 3;
  }
}

.filter-tag-wrap {
  position: relative;
  width: auto;
  margin: 0 0.5rem 0 0;

  @include mobile {
    margin-bottom: 0.5rem;
  }

  &:hover .filter-tag {
    padding-right: 2rem;

    &+.filter-remove {
      visibility: visible;
      transform: translateY(-50%) scale(1);
      opacity: 1;
      transition-delay: 0.15s;
    }
  }
}

.filter-tag {
  font-size: 0.75rem;
  height: 30px;
  line-height: 25px;
  border-radius: 99999px;
  background: $background-panel-element;
  padding: 0 1rem;
  color: $white;
  transition: all 0.25s $ease-elegant;
  margin: 0.15rem 0;
  font-weight: bold;

  &:focus,
  &:active {
    outline: 0;
    box-shadow: 0 0 4px rgba($ship-gray, 0.3);
  }

  &.active {
    background: $white;
    color: $text-dark;
  }
}

.filter-remove {
  visibility: hidden;
  opacity: 0;
  position: absolute;
  height: 10px;
  width: 10px;
  right: 0.5rem;
  top: 50%;
  transition: all 0.25s $ease-pop;
  transform: translateY(-50%) scale(0);

  &:hover {
    .icon-close {
      stroke: $white;
    }
  }

  svg {
    height: 100%;
    width: 100%;
    display: block;
  }

  .active+& {
    .icon-close {
      stroke: $text-muted;
    }

    &:hover {
      .icon-close {
        stroke: $text-dark;
      }
    }
  }
}

.filter-bank-close {
  position: relative;

  span {
    position: absolute;
    visibility: hidden;
    opacity: 0;
    font-size: 0.75rem;
    line-height: 1rem;
    color: $french-gray;
    right: 1.25rem;
    overflow: hidden;
    white-space: nowrap;
    text-align: right;
    transition: all 0.25s $ease-elegant;
    transform: translateX(3px);
  }

  &:hover {
    span {
      opacity: 1;
      visibility: visible;
      transform: translateX(0);
    }
  }

  @include mobile {
    order: 2;
    flex-basis: 25%;
    text-align: right;

    span {
      opacity: 1;
      visibility: visible;
      transform: translate(0);
    }
  }
}

@for $i from 1 through 5 {
  .sentiment--#{nth($sentiments, $i)} {
    $color: nth($sentimentColors, $i);
    color: $color;
  }
}

.pill,
.sentiment-pill {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  border-radius: 9999999px;
  line-height: 100%;
  font-weight: 800;
  font-size: 0.75rem;
  min-height: 2rem;
  padding: 0.25rem 1rem;
  box-sizing: border-box;
  position: relative;
}

a.pill:hover {

  svg,
  svg path {
    fill: $text-white;
  }
}

.pill {
  background: rgba($background-pill, 0.2);
  color: $text-link;
  box-sizing: border-box;
  line-height: 1rem;
  text-align: center;

  svg+* {
    margin-left: 0.5rem;
  }

  &--small {
    min-height: 1.5rem;
    padding: 0.25rem 0.75rem;
  }

  &--outline {
    background: none;
    border: solid 1px $outline;
    color: $text-muted;

    svg {
      fill: $text-muted;
    }
  }

  &--tab {
    cursor: pointer;

    &:hover {
      color: $text-white;

      svg {
        fill: $text-white;
      }
    }
  }

  &+& {
    margin-left: 0.5rem;
  }
}

.sentiment-pill {
  &--lg {
    font-size: 1.5rem;
    height: 2.5rem;
  }

  @for $i from 1 through 5 {
    &.#{nth($sentiments, $i)} {
      $color: nth($sentimentColors, $i);
      $red: red($color);
      $green: green($color);
      $blue: blue($color);

      background: rgba($red, $green, $blue, 0.2);
      color: $color;
    }
  }

  @include mobile {
    margin: 0;
    height: 85px;
    //flex-basis: 27%;
    width: 30%;
  }
}

// Question View
.results-view-tab {
  display: none !important;
  border-top-left-radius: 0px;
  border-top-right-radius: 0px;
  margin-top: 0;

  &.active {
    display: block !important;
  }

  >h3 {
    padding-bottom: 1rem;
    border-bottom: 1px solid $white-lilac;
  }
}

.question-result {
  display: block;

  &-sentiment {
    font-size: 1.5rem;
    margin-bottom: 0.5rem;
  }

  &-explainer {
    display: none;
    position: absolute;
    bottom: calc(100% + 0.5rem);
    right: 50%;
    transform: translateX(50%);
    width: 304px;
    box-shadow: 0 0 16px 0 rgba(0, 0, 0, 0.25);
  }

  &-explainer-description {
    margin-bottom: 0.5rem;
  }

  &-explainer-count {
    color: $text-muted;
    font-size: 0.875rem;
    margin: 0;
  }

  &-expander {
    margin-right: 2.25rem;

    &--expanded {
      transform: rotate(90deg);
    }
  }

  &-metabox {
    position: relative;
    margin-left: 2rem;

    &:hover .question-result-explainer {
      display: block;
    }
  }

  &-header {
    display: flex;
    justify-content: space-between;
    align-items: center;

    @include mobile {
      flex-wrap: wrap;
    }

    &:not(&--disabled):hover {
      cursor: pointer;

      svg path {
        fill: $white;
      }
    }
  }

  &-title {
    flex-grow: 1;

    @include mobile {
      flex-basis: 100%;
      margin-bottom: 1.5rem;
    }
  }

  &-nps-label {
    @include header-4;
  }

  &-ask {
    @include header-2;
    line-height: 1.75rem;
  }

  &-body {
    margin: 1rem 0 2rem 0;

    @include mobile {
      max-width: 100%;
      padding: 1rem;
    }
  }

  &-answer-body {
    display: flex;
    // flex: 2 10 2;
  }

  &-answer-item {
    font-size: 1.125rem;
    font-weight: 300;
    line-height: 1.75rem;
  }

  &-answer-header {
    display: flex;
    justify-content: space-between;
    align-items: baseline;

    @include mobile {
      flex-direction: column;
      margin-bottom: 0.5rem;
    }
  }

  &-answer-author {
    font-weight: 500;
  }

  &-answer-details {
    flex: 1 1 auto;
  }

  &-answer-label {
    margin-right: 2rem;
    flex: 0 0 150px;
  }

  &-answer-datetime {
    font-size: 0.875rem;
    color: $text-muted;
    flex: 1 0 250px;
    text-align: right;
  }

  &-answer-comment {}
}

.question-comment-number {
  @include header-2;
  font-size: 45px;

  @include mobile {
    font-size: 2rem;
  }
}

.question-average-label {
  font-size: 1.5rem;
  font-weight: 300;
  color: #1b5fa6;
  text-align: center;

  @include mobile {
    font-size: 1.125rem;
  }
}

$questionLabels: "Strongly Disagree", "Somewhat Disagree", "Neutral",
  "Somewhat Agree", "Strongly Agree";

.question-sentiment {
  position: relative;
}

.question-sentiment-indicator {
  @include header-2;
  font-size: 30px;

  @include mobile {
    font-size: 1.5rem;
  }
}

@for $i from 1 through 5 {
  .sentiment--#{nth($sentiments, $i)} {
    color: nth($sentimentColors, $i);
  }
}

.meta-label {
  @include card-label;
}

//// Share / Collect Data page
.preview-survey-container {
  color: text-light;
}

::selection,
.comment-highlight {
  color: $text-dark;
  background: $highlighter;
}

.question-result-sentiment {
  color: $accent;
}

.survey-share-options {
  &-container {
    display: flex;
    align-items: flex-start;
    flex-grow: 1;
    margin-top: 4rem;
    position: relative;

    // only nested to not affect current site styles
    .survey-link,
    .survey-code {
      padding: 0.5rem 1rem;
      font-size: 1.25rem;
      line-height: 2.2rem;
      color: $french-gray;
      font-weight: 300;

      &::selection {
        color: $ship-gray;
      }
    }
  }

  &-column {
    flex-basis: 100%;

    @include mobile {
      flex-basis: 100%;
      margin-bottom: 3rem;
      margin-right: auto;
      margin-left: auto;

      &:first-child {
        border-bottom: 1px solid $white-lilac;
        padding-bottom: 3rem;
      }
    }
  }
}

.survey-link-header,
.survey-embed-header {
  margin-bottom: 0.5rem;
}

.survey-link-form {
  display: flex;

  @include mobile {
    flex-wrap: wrap;
  }

  .survey-link {
    flex-grow: 1;
    margin: 0;
    border-right-width: 0;
    flex-basis: 70%;
    width: auto;

    @include mobile {
      border-right-width: 1px;
      flex-basis: 100%;
      margin-bottom: 1rem;
    }
  }

  .survey-link-button {
    @include desktop {
      padding-top: 0;
      padding-bottom: 0;
    }

    width: auto;

    @include mobile {
      flex-basis: 100%;
    }
  }
}

.is-loading {
  padding: 10rem 0;
  position: relative;

  &:before {
    content: "";
    height: 20px;
    width: 25px;
    display: block;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    transform-origin: center;
    animation: loader 2s $ease-elegant infinite;
    background: svg-load("../svg/logo-icon.svg");
    background-repeat: no-repeat;
    background-size: cover;
  }

  >* {
    display: none;
  }
}

@keyframes loader {
  0% {
    transform: scale(1);
    opacity: 0.5;
  }

  50% {
    transform: scale(2);
    opacity: 1;
  }

  100% {
    transform: scale(1);
    opacity: 0.5;
  }
}

.survey-required-comments {
  margin-bottom: 2rem;
}

.screenshot-uploading {
  animation: spin 1s linear infinite;
}

@keyframes spin {
  100% {
    transform: rotate(360deg);
  }
}

.shadow {
  box-shadow: 0px 0px 16px rgba(0, 0, 0, 0.25);
}

.item-label {
  color: $text-muted;
  text-transform: uppercase;
  font-size: 0.75rem;
  font-weight: bold;
}