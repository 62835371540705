.site-footer-container {
  width: 100%;
}

.site-footer {
  padding-top: 3rem;
  padding-bottom: 3rem;
  @extend .site-container;
  align-items: center;
  font-size: 0.875rem;
  color: $text-muted;
}
.site-footer-nav {
  @media (max-width: $mobile-width) {
    lost-column: 1/1;
    margin-bottom: 1rem;
    text-align: center;
  }
}
.site-footer-nav-link {
  color: $text-muted;
  margin: 0 0.5rem;
}
.site-footer-attribution {
  display: flex;
  align-items: center;
  margin-right: 1.25rem;
  color: $text-muted;

  @media (max-width: $mobile-width) {
    lost-column: 1/1;
    text-align: center;
    justify-content: center;
    margin-bottom: 1.5rem;
    margin-right: 0;
  }
  a {
    color: $text-muted;
    &:hover {
      color: $white;
    }
  }
}
.site-footer-details {
  display: flex;
  align-items: center;
  flex-grow: 1;
  color: $text-muted;
}

.site-footer-copyright {
  text-align: right;
  color: $text-muted;
  @media (max-width: $mobile-width) {
    lost-column: 1/1;
    text-align: center;
  }
}
.site-footer-by {
  @media (max-width: $mobile-width) {
    text-align: right;
  }
}
