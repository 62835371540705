.payment-cvc, .payment-exp-month, .payment-exp-year {
  lost-column: 4/12;
}
.payment-verification {
  lost-flex-container: row;
}
.payment-errors {
  color: $orange;
}
.payment-success-message {
  display: none;
}
.payment-expiration-label {
  color: $silver;
  margin-bottom: 1rem;
  font-size: .9rem;
}
.payment-container {
  display: none;
  flex-grow: 2;
  margin-left: 2rem;
  text-align: left;
  @media (max-width: $mobile-width) {
    margin-left: 0;
  }
  &.payment-submitted {
    .payment-form {
      display: none;
      transition: display 2s ease-in-out;
    }
    .payment-success-message {
      display: block;
      transition: display 2s ease-in-out;
    }
  }
}

.payment-amount-notice {
  font-size: 1.4rem;
  margin-bottom: 2rem;
  text-align: center;
}

.payment-form-controls {
  button {
    margin-left: 1rem;
    @media (max-width: $mobile-width) {
      margin-top: 1rem;
      margin-left: 0;
    }
  }
  @media (max-width: $mobile-width) {
    text-align: center;
  }
}
