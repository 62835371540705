// Styles responsible for formatting the in-app collection

#uxrings-frame {
  border: none !important;
  background: white !important;
  display: none !important;
  box-shadow: 0 0 9px 2px rgba(0, 0, 0, 0.15) !important;
  position: fixed !important;
  left: 0 !important;
  right: 0 !important;
  bottom: 0 !important;
  height: 100%;
  max-height: 150px !important;
  width: 100% !important;
  z-index: 99999999 !important; // todo: how do we gaurantee this stays on top?
  transition: all 0.4s cubic-bezier(0.645, 0.045, 0.355, 1);
  @media (max-width: 768px) {
    max-height: 100px !important;
    transition: all 0.5s cubic-bezier(0.645, 0.045, 0.355, 1);
  }

  &.is-expanded {
    @media (max-width: 768px) {
      max-height: 100% !important;
      //top:0;
      height: 100% !important;
    }
  }
}

.no-overflow {
  overfow: hidden;
  height: 100%;
  width: 100%;
  position: fixed;
}
