@mixin product-card {
  cursor: pointer;
  color: $text-white;
  &:hover {
    background: $background-panel-hover;
  }
  .show-delete-product {
    flex-grow: 1;
    &:hover {
      svg path {
        fill: $white;
      }
    }
  }
}

.product-card-container {
  height: 180px;
  overflow: hidden;
  margin-bottom: 1rem;
  display: grid;
  grid-template-columns: 135px 515px 175px auto auto;
  align-items: center;
  column-gap: 1.5rem;
  padding: 2.5rem;
}

.product-add-text {
  margin-top: 2rem;
  color: $text-muted;
  font-size: 1.5rem;
  &:hover {
    color: $text-white;
  }

  .product-card:hover & {
    color: $text-white;
  }
}

.product-card {
  @include product-card;
  &:hover {
    @include overlay;
  }
}

.product-card-survey-link {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: left;
  margin-bottom: 1rem;
  margin-top: 1rem;
  .survey-link-button {
    margin: 0;
  }
}

.product-card-prompt {
  @include header-4;
  text-align: left;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  line-height: 1.3rem;
}

.product-card-name {
  font-weight: 900;
  font-size: 1.5rem;
  overflow: hidden;
  margin-bottom: 0.5rem;
  white-space: nowrap;
  text-overflow: ellipsis;
  flex-shrink: 0;
}

.product-card-survey-count {
  color: $text-muted;
}

.product-card-empty {
  @include product-card;
  border: dashed 3px $athens-gray;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  .icon-uxrings-icon {
    font-size: 8rem;
    color: $athens-gray;
  }
}

.product-card-promo {
  @include product-card;
  border: dashed 3px $athens-gray;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
}

.product-card-promo-headline {
  @include header-4;
  lost-column: 1/1;
}

.product-card-add {
  @include product-card;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  svg:hover path {
    fill: $white;
  }
}
.product-card-add-name {
  margin-bottom: 2rem;
  font-weight: 900;
  font-size: 1.5rem;
  text-align: left;
}
.product-name-field {
  margin-bottom: 7rem;
}
.product-card-details {
  align-items: flex-start;
  display: flex;
  justify-content: flex-start;
}
.product-card-grade {
  font-size: 7rem;
  font-weight: 300;
  lost-column: 1/2;
}
.product-card-dial-container {
  flex-grow: 1;
  display: flex;
  justify-content: flex-end;
}
.product-card-chart {
  display: flex;
  flex-direction: column;
  justify-items: space-between;
  flex-grow: 1;
  align-content: space-between;
  align-items: flex-end;
  .dial {
    margin-bottom: 0;
  }
  svg {
    height: 100%;
    max-width: 120px;
  }
}

.product-card-footer {
  border-top: solid 1px $white-lilac;
  padding-top: 1.5rem;
  margin-top: 1.5rem;
}

.product-card-responses {
  @include header-4;
  font-weight: 600;
}
.product-card-add-initiate {
  font-weight: 900;
  font-size: 1.75rem;
}
.product-card-add-naming {
  display: none;
  height: 100%;
  width: 100%;
  justify-content: space-between;
  flex-direction: column;
  align-items: flex-start;
  form {
    width: 100%;
  }
}
