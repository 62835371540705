@import "colors";

@lost flexbox flex;

$site-width: 1180px;
$mobile-width: 600px;
$tablet-width: 800px;
@mixin tablet {
  @media (max-width: 1000px) {
    @content;
  }
}
@mixin mobile {
  @media (max-width: 768px) {
    @content;
  }
}
@mixin desktop {
  @media (min-width: 768px) {
    @content;
  }
}

html {
  height: 100%;
  background: $background-layout;
}
body {
  min-height: 100%;
  display: flex;
  overflow: auto;
}
.site-wrapper {
  flex: 1;
  display: flex;
  flex-direction: column;
  background: $background-layout;
  align-items: stretch;
  min-height: 100vh;
  .rings.settings &,
  .users.update & {
    background: $alabaster;
  }
}

.site-content {
  flex-grow: 1;
  &--center {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  &--collapse {
    flex-grow: 0;
  }
}

.thin-container {
  max-width: 750px;
  margin-left: auto;
  margin-right: auto;
}
