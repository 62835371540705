// todo: do we need a better name?

body.plugin {
  background: white;
  text-align: center;
  overflow: hidden;
  height: 100%;

  .survey-comments-text {
    height: 90px;
    margin-bottom: 1rem;
  }
}
.plugin-flex-wrap {
  @include mobile {
    display: flex;
    flex-direction: column;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    max-width: 100%;
    background: $alabaster;
    max-height: 100px;
    overflow-y: scroll;
    overflow-x: hidden;
    &.is-expanded {
      max-height: 100%;
    }
  }
}

.confirmation {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-height: 100%;
  height: 100%;
}
.confirmation-title {
  font-size: 2.4rem;
  margin-bottom: 1rem;
  color: $storm-dust;
  font-weight: lighter;
}
.confirmation-description {
  font-weight: bold;
  color: $storm-dust;
  font-size: 1.2rem;
  margin-bottom: 2.5rem;
}
.confirmation .icon-smiley-face {
  display: none;
  @include mobile {
    display: block;
  }
}

.plugin-nps-question {
  padding: 2rem 0;
  @include mobile {
    padding: 1rem 3rem 1rem 1rem;
    flex-grow: 1;

    .plugin-flex-wrap:not(.is-expanded) & {
      padding-top: 100px;
    }
  }

  .is-expanded & {
    @include mobile {
      padding: 10% 1rem 1rem;
      flex-grow: 1;
    }
  }
}

.plugin-question-title {
  font-size: 1.25rem;
  line-height: 1.25em;
  margin-bottom: 1rem;
  color: $storm-dust;
  @include mobile {
    text-align: left;
    font-size: 1.15rem;
  }
  .plugin-flex-wrap:not(.is-expanded) & {
    @include mobile {
      position: absolute;
      top: 50%;
      right: 2.5rem;
      left: 1rem;
      transform: translateY(-50%);
    }
  }
  .is-expanded & {
    @include mobile {
      text-align: center;
      font-size: 1.5rem;
      line-height: 1.15em;
    }
  }
}
.plugin-question-lead {
  text-transform: uppercase;
  font-weight: bold;
  color: $french-gray;
  font-size: 14px;
  margin-bottom: 0.5rem;
  display: none;

  .is-expanded & {
    @include mobile {
      display: block;
    }
  }
}

.plugin-expand {
  position: absolute;
  top: 0;
  left: 0;
  right: 2.5rem;
  width: 90%;
  bottom: 0;
  z-index: 100;
  border: 0;
  background: transparent;
  display: none;
  @include mobile {
    display: block;
  }

  .is-expanded & {
    display: none;
  }
}
.plugin-close {
  position: absolute;
  top: 1rem;
  right: 1rem;
  background: transparent;
  height: 16px;
  width: 16px;

  .plugin-flex-wrap:not(.is-expanded) & {
    @include mobile {
      top: 50%;
      margin-top: -8px;
    }
  }
}

.plugin-feedback-container {
  display: none;
  background: $sebastian;
  padding: 2rem 1rem;
  @include mobile {
    display: block;
    padding: 1rem;
  }
}
.plugin-feedback {
  max-width: 600px;
  margin: 0 auto;
}

.button-plugin {
  @include button($ship-gray);
}

.plugin-feedback-title {
  text-align: left;
  margin-bottom: 0.5rem;
  color: $storm-dust;
}
.plugin .survey-options {
  @include mobile {
    display: none;
  }
}
.survey-options-mobile {
  display: none;
  @include mobile {
    display: block;
    width: 90%;
    margin: 7.5% auto 0;
  }
}
.slider-value {
  position: relative;
  height: 100px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 1rem;
}
.slider-instruction {
  display: block;
  transition: 0.125s all $ease-elegant;
  max-width: 300px;
  margin: 0 auto;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;

  svg {
    display: block;
    height: 60px;
    width: auto;
    animation: 6s $ease-elegant 0s infinite swipe;
  }

  &.hide {
    position: absolute;
    opacity: 0;
  }
}
.slider-value-number {
  display: inline-block;
  font-size: 100px;
  font-weight: 100;
  color: $cathams-blue;
  opacity: 1;
  transition: 0.125s all $ease-elegant;
  &.hide {
    position: absolute;
    opacity: 0;
  }
  &.negative {
    color: $medium-carmine;
  }
  &.positive {
    color: $celestial-blue;
  }
}

.slider-wrap {
  position: relative;
  label {
    position: absolute;
    color: $french-gray;
    font-size: 14px;
  }
}
.label-slider-min {
  left: 0;
}
.label-slider-max {
  right: 0;
}
.survey-options-slider {
  appearance: none;
  width: 100%;
  background: transparent;

  &::webkit-slider-thumb {
    appearance: none;
  }
  &:focus {
    outline: none;
  }
  &::ms-track {
    width: 100%;
    cursor: pointer;
    background: transparent;
    border-color: transparent;
    color: transparent;
  }
}

// slider variables
$track-color: $athens-gray;
$thumb-color: white;

$thumb-radius: 50% !default;
$thumb-height: 48px !default;
$thumb-width: 48px !default;
$thumb-shadow-size: 0px !default;
$thumb-shadow-blur: 5px !default;
$thumb-shadow-color: rgba(0, 0, 0, 0.2) !default;
$thumb-border-width: 0px;
//$thumb-border-color: #eceff1 !default;

$track-width: 100% !default;
$track-height: 14px !default;
$track-shadow-size: 0px !default;
$track-shadow-blur: 0px !default;
$track-shadow-color: rgba(0, 0, 0, 0.2) !default;
$track-border-width: 0px !default;
$track-border-color: #cfd8dc !default;

$track-radius: 7px !default;
$contrast: 5% !default;

@keyframes swipe {
  0%,
  50%,
  100% {
    transform: translateX(0%);
  }
  25% {
    transform: translateX(35%);
  }
  75% {
    transform: translateX(-35%);
  }
}
